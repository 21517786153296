import { addMinutes, format } from 'date-fns';
import { enUS } from 'date-fns/locale';

export const getReadableDateTime = (date?: any) => {
  if (date) {
    return (new Date(date)).toLocaleString();
  } else {
    return (new Date()).toLocaleString();
  }
}

/**
 * Convert the seconds into a formatted time
 */
export const formattedTime = (seconds: number, f: string = 'mm:ss') => {
  let dt = new Date((seconds * 1000));
  dt = addMinutes(dt, dt.getTimezoneOffset());
  return format(dt, 'mm:ss');
}


export const formatBrazilDate = (currentDate: Date) => {
  return new Date(currentDate.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' }));
}

export const getDaysInAMonth = (year: number, month: number) => {
  const daysInTheMonth = new Date(year, month, 0).getDate()
  return Array.from({ length: daysInTheMonth }, (_, index) => index + 1)
}

export const getDaysOfWeekInAMonth = (year: number, month: number) => {
  const days = getDaysInAMonth(year, month);
  const week = days.map((d) => (format(new Date(year, month-1, d), 'EEE')));
  return week;
}

/*
  * Split the month and the year from a given string
  * expecting MMYYYY format
  ************************************************************************************************************/
export const splitMonthYear = (dateMonthYear?: string) => {
  if (!dateMonthYear) return [];
  return (dateMonthYear.match(/(^\w{2}|\w{4}$)/gi) as Array<string>).map(i => Number(i));
}

export const isWeekEnd = (date: Date): boolean => date.getDay() === 0 || date.getDay() === 6;

export const dateWithTimeZone = (timeZone: string, year: number, month: number, day: number, hour: number = 0, minute: number = 0, second: number = 0) => {
  let date = new Date(Date.UTC(year, month, day, hour, minute, second));

  let utcDate = new Date(date.toLocaleString('en-US', { timeZone: "UTC" }));
  let tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
  let offset = utcDate.getTime() - tzDate.getTime();

  date.setTime( date.getTime() + offset );

  return date;
};


// Function to get the month name from the month number
export const getMonthName = (monthNumber: number) => {
  // Create a new date with the given month number and any year and day
  const date = new Date(2022, monthNumber - 1, 1);

  // Format the date using the 'LLLL' format string to get the month name
  const monthName = format(date, 'LLLL', { locale: enUS });

  return monthName;
}


/**
 * Get the local time
 */
export const timeToLocal = (originalTime: number) => {
  let time = originalTime.toString().length === 10 ? originalTime * 1000 : originalTime;
  const d = new Date(time);
  return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds());
}


/**
 * Return the date right in the first second of that date
 */
export const endOfDayISO = (date?: Date | string | number | null, rewind: number = 0, forward = 0) => {

  const newDate = new Date(date || new Date());
  const finalDate = new Date((new Date(newDate)).setDate(newDate.getDate() - rewind + forward));
  return (new Date(`${format(new Date(finalDate), 'yyyy-MM-dd')}T23:59:59`)).toISOString();
}

