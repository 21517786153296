import { createTheme } from '@mui/material/styles';
import { PaletteMode, Theme, ThemeOptions } from '@mui/material';
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

type Customisation = {
  mode?: PaletteMode,
}

export type CustomTheme = Customisation;

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization: Customisation = {}) => {

    // Passing some customisation to the theme
    const themeOption: Customisation = {
      ...customization
    }

    const themeOptions: ThemeOptions = {
      direction: 'ltr',
      palette: themePalette(themeOption),
      typography: themeTypography(themeOption)
    }

    const theme = createTheme(themeOptions);

    theme.components = componentStyleOverrides(theme);


    return theme;
};

export default theme;
