import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { AuthUser } from '../lib/auth/types';
import { hasProductPermission } from '../lib/helpers/user';

type Props = {
  membership?: string[];
  product?: string[];
}

export default function RequireProductPermission({ membership = [], product = []}: Props) {
  const { authUser } = useAuth();

  const gotProductPermission = hasProductPermission(authUser as AuthUser, product);
  const gotMembershipPermission = membership?.includes(authUser?.membership as string);

  if (authUser && (gotProductPermission || gotMembershipPermission)) {
    return <Outlet />;
  }

  if(authUser) {
    return <Navigate to="/account/subscription" replace />;
  }

  return null;
}