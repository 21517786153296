import { TokenPriceResult, TokensRepositoryInterface, SearchTokenResult, GetListTokensByChainFromS3Results } from "./types";
import { API, graphqlOperation } from "aws-amplify";
import { TokenPriceInput } from "../amplify/API";
import { getTokenPrice, searchToken, getListTokensFromS3 } from "../amplify/graphql/queries";

export default class TokensRepositoryAmplify implements TokensRepositoryInterface {

  /**
   * Fetch the list of the selected chain (binance, bsc, eth, etc) or all from the files saved in S3
   * See, consider exchanges also a "chain" for scalability
   */
  async listByChain(chain: string = 'BINANCE') {
    const { data } = await API.graphql(
      graphqlOperation(getListTokensFromS3, { input: { chain: chain.toUpperCase() } })
    ) as GetListTokensByChainFromS3Results;

    if (!data) {
      throw new Error('There was an issue trying to fetch the list of tokens');
    }

    return data.getListTokensFromS3;
  }

  /**
   * Check the token's price
   */
  async search(query: string, chain: string = 'BINANCE') {
    const { data } = await API.graphql(
      graphqlOperation(searchToken, { input: { query, chain } })
    ) as SearchTokenResult;

    if (!data) {
      throw new Error("Searching token failed");
    }

    return data.searchToken;
  }
  /**
   * Check the token's price
   */
  async getPrice(priceParams: TokenPriceInput) {

    if (!priceParams.tokenAddress) throw new Error('Invalid token');

    const { data } = await API.graphql(
      graphqlOperation(getTokenPrice, { input: priceParams })
    ) as TokenPriceResult;

    if (!data) {
      throw new Error("Getting token price failed");
    }

    return data.getTokenPrice;

  }
}
