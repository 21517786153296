import { API, graphqlOperation } from 'aws-amplify';
import { DeleteCryptofyBotTradeResult, GetCryptofyBotTradeResult, ListCryptofyBotTradesResult, CryptofyBotRepositoryInterface, UpdateCryptofyBotTradeResult } from './types';
import { CryptofyBotTrade, CryptofyBotTradeStatus } from '../amplify/API';
import { getCryptofyBotTrade, listCryptofyBotTrades } from '../amplify/graphql/queries';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { deleteCryptofyBotTrade, updateCryptofyBotTrade } from '../amplify/graphql/mutations';
import { ListParams } from '../factories/types';
import orderBy from 'lodash/orderBy';

export default class CryptofyBotRepositoryAmplify implements CryptofyBotRepositoryInterface {

  /**
   * Get the trade details
   */
  async getTradeById(tradeId: string) {
    const { data } = (await API.graphql({
          query: getCryptofyBotTrade,
          variables: { id: tradeId },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      })) as GetCryptofyBotTradeResult;

    if (!data) {
      throw new Error('Error when fetching trade information');
    }

    return data.getCryptofyBotTrade;
  }

  /**
   * Update the trade details
   */
  async saveTrade(tradeId: string, input: Partial<CryptofyBotTrade>) {

    const {
      lastMonitorCheck,
      lastPrice,
      telegramSignalLink,
      createdAt, updatedAt, __typename, owner,
      ...otherInput } = input;

    const { data } = await API.graphql(
      graphqlOperation(updateCryptofyBotTrade, { input: { ...otherInput, id: tradeId } })
    ) as UpdateCryptofyBotTradeResult;

    if (!data) {
      throw new Error("Error saving trade");
    }

    return data.updateCryptofyBotTrade;
  }

  /**
   * Delete token trade
   */
  async deleteTrade(tradeId: string) {

    const { data } = (await API.graphql(
      graphqlOperation(deleteCryptofyBotTrade, { input: { id: tradeId } })
    )) as DeleteCryptofyBotTradeResult;

    if (!data) {
      throw new Error(`Can't delete trade ${tradeId}`);
    }


    return data.deleteCryptofyBotTrade;
  }

  /**
   * Fetch the list of trades associated to the tokens
   */
  async listTrades(params?: ListParams, results?: CryptofyBotTrade[]): Promise<CryptofyBotTrade[]> {
    let { limit = 1000, filter = null, nextToken } = params || {};

    const { data } = (await API.graphql({
          query: listCryptofyBotTrades,
          variables: {
            limit,
            filter,
            nextToken
          },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      })) as ListCryptofyBotTradesResult;


    if (!data) {
      throw new Error("There was an issue trying to fetch the list");
    }

    let items = [ ...(!results ? [] : results), ...data.listCryptofyBotTrades.items ] as CryptofyBotTrade[];

    if (!data.listCryptofyBotTrades.nextToken) {
      return orderBy(items, [(trade) => TranslateCryptofyBotTradeStatus[trade.status], 'updatedAt', 'createdAt', 'type'], ['asc', 'desc', 'desc', 'asc'])
    } else {
      return this.listTrades({ ...params, nextToken: data.listCryptofyBotTrades.nextToken }, items) as Promise<CryptofyBotTrade[]>;
    }
  }
}

const TranslateCryptofyBotTradeStatus = {
  [CryptofyBotTradeStatus.ACTIVE]: 1,
  [CryptofyBotTradeStatus.WAITING_BREAK]: 2,
  [CryptofyBotTradeStatus.WAITING_REACTION]: 3,
  [CryptofyBotTradeStatus.CLOSED_PROFIT]: 4,
  [CryptofyBotTradeStatus.CLOSED_STOPLOSS]: 4,
  [CryptofyBotTradeStatus.WONT_OPEN]: 5,
}
