import orderBy from 'lodash/orderBy';
import { TelegramParticipantsUser, UserStatus } from '../amplify/API';
import { API, graphqlOperation } from 'aws-amplify';
import { getTelegramParticipants } from '../amplify/graphql/queries';
import { removeTelegramMember } from '../amplify/graphql/mutations';
import { GetTelegramParticipantsResult, ManageTelegramGroupsInterface, RemoveTelegramMemberResult, UsersByTelegramId } from './types';
import UsersRepositoryAmplify from '../users/UsersRepositoryAmplify';

export default class ManageTelegramGroupsRepositoryAmplify implements ManageTelegramGroupsInterface {

  private userRepository;

  constructor() {
    this.userRepository = new UsersRepositoryAmplify();
  }


  /**
   * Fetch the list of events associated to the tokens
   */
  async removeMember(telegramId: string, channel: string): Promise<boolean> {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(removeTelegramMember, {
          input: {
            action: 'banChatMember',
            params: { channels: [channel], telegramId }
          }
        })
      )) as RemoveTelegramMemberResult;

      if (!data) {
        throw new Error('Error trying to delete user');
      }

      return data.removeTelegramMember;
    } catch (err) {
      console.log('removeTelegramMember', err);
      return false;
    }
  }
  /**
   * Fetch the list of events associated to the tokens
   */
  async getParticipants(channel: string): Promise<TelegramParticipantsUser[]> {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getTelegramParticipants, {
          input: {
            action: 'getParticipants',
            params: { channel }
          }
        })
      )) as GetTelegramParticipantsResult;

      if (!data) {
        throw new Error('There was an issue trying to fetch the list');
      }

      return orderBy(data.getTelegramParticipants, ['name'], ['asc'])
    } catch (err) {
      console.log('getParticipants', err);
      return [];
    }
  }

  /**
   * Fetch the list of events associated to the tokens
   */
  async getUnwelcomeParticipants(channel: string): Promise<TelegramParticipantsUser[]> {

    const participants = await this.getParticipants(channel);

    let users = await this.userRepository.list();
    let usersByTelegram = users.filter(user => user.telegramId).reduce((acc, user) => {
      if (user.telegramId) {
        acc[user.telegramId] = user;
      }
      return acc;
    }, {} as UsersByTelegramId);


    const unwelcomeUsers = [];

    for (let participant of participants) {
      const user = participant?.telegramId && usersByTelegram[participant?.telegramId];

      if (user) {
        if (user.status !== UserStatus.ACTIVE) {
          unwelcomeUsers.push({ ...participant, status: user.status === UserStatus.INACTIVE ? 'Subscription has ended' : 'Subscription is suspended' })
        }
      } else {
        unwelcomeUsers.push({ ...participant, status: 'Not Found' })
      }
    }

    return unwelcomeUsers.filter(user => !user.isBot);
  }
}
