import {  BillingRepositoryInterface, CreateCheckoutSessionResult, GetCheckoutSessionResult, UpdateSubscriptionResult, GetSubscriptionResult, Subscription, CalculateStripeChangePlansProrationResult } from "./types";
import { CalculateStripeChangePlansProrationInput, CreateStripeCheckoutSessionInput, GetStripeCheckoutSessionInput, Membership, UpdateStripeSubscriptionBehaviours, UpdateStripeSubscriptionInput  } from "../amplify/API";
import { calculateStripeChangePlansProration, getStripeCheckoutSession, getStripeSubscription } from "../amplify/graphql/queries";
import { createStripeCheckoutSession, updateStripeSubscription } from "../amplify/graphql/mutations";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import plansConfig from "../../config/plans";
import { format } from "date-fns";

export default class BillingRepositoryAmplify implements BillingRepositoryInterface {

  /**
   * Calls the backend to create a checkout session for stripe so the user
   * can make the payment
   */
  async createCheckoutSession(input: CreateStripeCheckoutSessionInput) {
    try {
      const { data } = await API.graphql({
        query: createStripeCheckoutSession,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      }) as CreateCheckoutSessionResult;

      if (!data) {
        throw new Error("createCheckoutSession failed");
      }

      return data.createStripeCheckoutSession;
    } catch(err:any) {
      console.warn(err);
      throw Error(err.errors[0].message as string);
    }
  }

  /**
   * Get the stripe checkout session
   */
  async getCheckoutSession(input: GetStripeCheckoutSessionInput) {
    try {
      const { data } = await API.graphql({
        query: getStripeCheckoutSession,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      }) as GetCheckoutSessionResult;

      if (!data) {
        throw new Error("getCheckoutSession failed");
      }

      return data.getStripeCheckoutSession;
    } catch(err:any) {
      console.warn(err);
      throw Error(err.errors[0].message as string);
    }
  }

  /**
   * Get the information about a subscription
   */
  async getSubscription(subscriptionId: string, isTestSubscription?: boolean | null) {
    const { data } = await API.graphql({
      query: getStripeSubscription,
      variables: { input: { subscriptionId, isTestSubscription } },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    }) as GetSubscriptionResult;

    if (!data) {
      throw new Error('Updating subscription failed');
    }

    return this.formatSubscriptionPayload(data.getStripeSubscription);
  }


  /**
   * Update the Subscription.
   * This could be either to downgrade / upgrade or change the period commitment
   */
  async updateSubscription(input: UpdateStripeSubscriptionInput) {

    const { data } = await API.graphql({
      query: updateStripeSubscription,
      variables: { input },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    }) as UpdateSubscriptionResult;

    if (!data) {
      throw new Error('Updating subscription failed');
    }

    return this.formatSubscriptionPayload(data.updateStripeSubscription);
  }

  /**
   * Mark the subscription to be canceled at the end of the cycle
   */
  async cancelSubscription(subscriptionId: string, shouldCancel: boolean, username: string) {
    return this.updateSubscription({
      behaviour: shouldCancel ? UpdateStripeSubscriptionBehaviours.CANCEL_SUBSCRIPTION : UpdateStripeSubscriptionBehaviours.UNCANCEL_SUBSCRIPTION,
      subscriptionId,
      username,
    });
  }

  /**
   * Get the list of all plans we want to display to the user
   */
  async calculateStripeChangePlansProration(input: CalculateStripeChangePlansProrationInput) {
    try {
      const { data } = await API.graphql({
        query: calculateStripeChangePlansProration,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      }) as CalculateStripeChangePlansProrationResult;

      if (!data) {
        throw new Error("calculateStripeChangePlansProration failed");
      }

      return JSON.parse(data.calculateStripeChangePlansProration);
    } catch(err:any) {
      console.warn(err);
      throw Error(err.errors[0].message as string);
    }
  }


  /**
   * Get the list of all plans we want to display to the user
   */
  async getPlans() {
    try {
      return plansConfig;
    } catch(err:any) {
      console.warn(err);
      throw Error(err.errors[0].message as string);
    }
  }

  /**
   * Format the subscription payload to be more readable
   */
  formatSubscriptionPayload(payload: any) {

    try {
      payload = JSON.parse(payload);
      console.log({ payload });

      const formattedSubscription = {
        id: payload.id,
        subscriptionItemId: payload.items.data[0].id,
        billingURL: payload.billingURL,
        billingCycleAnchor: payload.billing_cycle_anchor,
        cancelAt: payload.cancel_at ? payload.cancel_at * 1000 : null,
        cancelAtPeriodEnd: payload.cancel_at_period_end,
        canceledAt: payload.canceled_at ? payload.canceled_at * 1000 : null,
        cancelationDetails: payload.cancelation_details,
        currency: payload.currency,
        currentPeriodStart: payload.current_period_start * 1000,
        currentPeriodEnd: payload.current_period_end * 1000,
        renewalDate: payload.current_period_end * 1000,
        renewalDateFormatted: format(new Date(payload.current_period_end * 1000), 'dd MMM yyyy'),
        customer: payload.customer,
        description: payload.description,
        latestInvoice: payload.latest_invoice,
        livemode: payload.livemode,
        items: payload.items,
        plan: {
          id: payload.plan.id,
          active: payload.plan.active,
          amount: payload.plan.amount  / 100,
          currency: payload.plan.currency?.toUpperCase(),
          interval: payload.plan.interval_count === 12 ? 'Yearly' : payload.plan.interval_count === 3 ? 'Quarterly' : 'Monthly',
          livemode: payload.plan.livemode,
          metadata: this.formatMetadata(payload.plan.metadata),
          nickname: payload.plan.nickname,
          object: payload.plan.object,
          product: payload.plan.product,
          intervalCount: payload.plan.interval_count,
          trialPeriodDays: payload.plan.trial_period_days,
          currentPeriodEnd: payload.current_period_end * 1000
        },
        metadata: this.formatMetadata(payload.metadata),
        membership: payload.plan?.metadata?.membershipName as Membership,
        schedule: payload.schedule,
        status: payload.status,
        startDate: payload.start_date * 1000,
        trialStart: payload.trial_start ? payload.trial_start * 1000 : null,
        trialEnd: payload.trial_end ? payload.trial_end * 1000 : null,
        trialSettings: payload.trial_settings,
        isTrial: (new Date(payload.trial_end * 1000).getTime()) > new Date().getTime(),
        stripePayload: payload
      } as Subscription;

      formattedSubscription.isScheduledDowngrade = (formattedSubscription.cancelAtPeriodEnd && Boolean(formattedSubscription.metadata?.scheduled));
      formattedSubscription.isCancelation = (formattedSubscription.cancelAtPeriodEnd && !Boolean(formattedSubscription.metadata?.scheduled));

      console.log({ formattedSubscription });

      return formattedSubscription;

    } catch(err) {
      console.warn(err);
      return null;
    }
  }

  formatMetadata(metadata: any) {
    let formatted = {};
    try {
      if (typeof metadata === 'string') {
        formatted = JSON.parse(metadata)
      } else {
        formatted = Object.keys(metadata).reduce((acc: any, key: string) => {
          acc[key] = this.formatMetadata(metadata[key]);
          return acc;
      }, {})
      }
    } catch(err) {
      formatted = metadata
    }
    return formatted;

  }
}