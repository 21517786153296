import { API, graphqlOperation } from "aws-amplify";
import { getUser, listUsers } from "../amplify/graphql/queries";
import { CreateUserResult, DeleteUserResult, GetUserResult, ListUsersResult, UpdateUserResult, UsersRepositoryInterface } from "./types";
import orderBy from 'lodash/orderBy';
import { createUser, deleteUser, updateUser } from "../amplify/graphql/mutations";
import { isUserAdmin } from "../helpers/auth";
import { CreateUserInput, User } from "../amplify/API";
import { ListParams } from "../factories/types";
import { sendRequestGET, sendRequestPOST } from "../helpers/amplify-apis";
import { Buffer } from 'buffer';
import { v4 as uuidv4 } from 'uuid';


export default class UsersRepositoryAmplify implements UsersRepositoryInterface {

  async getUser(username: string) {
    let path = '/getUser';
    return await sendRequestGET('AdminQueries', path, { username });
  }

  async getUserGroups(username: string) {
    let path = '/listGroupsForUser';
    return await sendRequestGET('AdminQueries', path, { username });
  }

  async addUserToGroup(username: string, groupName: string) {
    let path = '/addUserToGroup';
    return await sendRequestPOST('AdminQueries', path, { body: { username, groupname: groupName } });
  }

  async removeUserFromGroup(username: string, groupName: string) {
    let path = '/removeUserFromGroup';
    return await sendRequestPOST('AdminQueries', path, { body: { username, groupname: groupName } });
  }

  // async confirmUserSignUp(username: string) {
  //   let path = '/confirmUserSignUp';
  //   return await sendRequest('AdminQueries', path, { body: { username } });
  // }

  async createUser(email: string, name: string) {
    let path = '/createUser';
    const resp = await sendRequestPOST('AdminQueries', path, { body: { email, name } });
    return resp;
  }

  async resendTemporaryPassword(email: string, name: string) {
    let path = '/createUser';
    const resp = await sendRequestPOST('AdminQueries', path, { body: { email, name, resend: true } });
    return resp;
  }

  async enableUser(username: string) {
    let path = '/enabledUser';
    return await sendRequestPOST('AdminQueries', path, { body: { username } });
  }

  async disableUser(username: string) {
    let path = '/disableUser';
    return await sendRequestPOST('AdminQueries', path, { body: { username } });
  }

  async signUserOut(username: string) {
    let path = '/signUserOut';
    return await sendRequestPOST('AdminQueries', path, { body: { username } });
  }


  async deleteUser(username: string) {
    let path = '/deleteUser';
    return await sendRequestPOST('AdminQueries', path, { body: { username } });
  }

  /**
   * Get the event details
   */
  async getByUsername(username: string) {

    const { data } = (await API.graphql(
      graphqlOperation(getUser, { username })
    )) as GetUserResult;    if (!data) {
      throw new Error(`Could not fetch the details fo the user ${username}`);
    }

    return data.getUser;
  }

  /**
   * Create a token and its details
   */
  async create(input: Omit<CreateUserInput, 'username'>) {
    const isAdmin = await isUserAdmin();


    if (!isAdmin) {
      throw new Error('You do not have access to perform this operation');
    }

    const cognitoUser = await this.createUser(input.email, input.name);
    const username = cognitoUser.User.Username;
    const secretKey = this.generateSecretKey(username);
    const formattedName = input.name.trim();

    const { data } = await API.graphql(
      graphqlOperation(createUser, {
        input: {
          username,
          ...input,
          searchName: formattedName.toLowerCase(),
          name: formattedName,
          memberSince: (new Date()).toISOString(),
          dateOfRenewal: (new Date()).toISOString(),
          secretKey
        }
      })
    ) as CreateUserResult;

    if (!data) {
      throw new Error("Error creating new user");
    }

    return data.createUser;
  }

  /**
   * Update the token details
   */
  async save(username: string, input: Partial<User>) {

    let { createdAt, updatedAt, __typename, products, telegramGroups, ...inputDetails } = input;

    const secretKey = this.generateSecretKey(username);

    const name = inputDetails?.name?.trim();
    const searchName = name?.toLowerCase();

    const { data } = await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          ...inputDetails,
          username,
          secretKey,
          ...(name && {
            name,
            searchName
          }),
        }
      })
    ) as UpdateUserResult;

    if (!data) {
      throw new Error("Error saving user");
    }

    return data.updateUser;
  }

  /**
   * Delete token event
   */
  async delete(username: string) {
    const isAdmin = await isUserAdmin();

    if (!isAdmin) {
      throw new Error('You do not have access to perform this operation');
    }

    try {
      await this.deleteUser(username);
    } catch (err) {
      console.log('delete cognito user error', err);
    }

    const { data } = (await API.graphql(
      graphqlOperation(deleteUser, { input: { username } })
    )) as DeleteUserResult;

    if (!data) {
      throw new Error(`User ${username} could not be deleted`);
    }
    return data.deleteUser;
  }


  /**
   * Fetch the list of events associated to the tokens
   */
  async list(params?: ListParams, results?: User[]): Promise<User[]> {

    let { limit = 1000, filter = null, nextToken } = params || {};

    const { data } = (await API.graphql(
      graphqlOperation(listUsers, { limit, filter, nextToken })
    )) as ListUsersResult;
    if (!data) {
      throw new Error("There was an issue trying to fetch the list");
    }

    const items = [ ...(!results ? [] : results), ...data.listUsers.items ] as User[];

    if (!data.listUsers.nextToken) {
      return orderBy(items, ['status', 'expirationDate', 'name', 'createdAt'], ['asc', 'asc', 'asc', 'desc'])
    } else {
      return this.list({ ...params, nextToken: data.listUsers.nextToken }, items) as Promise<User[]>;
    }
  }

  generateSecretKey = (username: string) => {
    const timestamp = ((new Date()).getTime());
    const key = Buffer.from(`${String(timestamp).slice(-4)} ${username} ${uuidv4().slice(0, 3)}`).toString('base64');
    return key;
  }
}
