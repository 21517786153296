import { Navigate } from 'react-router-dom';
import { isLandingPage } from '../lib/helpers';

type Props = {
  redirect: string,
  element: any
}
/**
 * This component will make a decision on what to do when we hit the root of the app.
 * There are a few different ways you can open the / of the app
 * Ex:
 *  - http://localhost:3000
 *  - http://cryptofyhub.com/
 *  - http://www.cryptofyhub.com/
 *  - http://app.cryptofyhub.com/
 *  - http://ENV.cryptofyhub.com/
 *
 * If the customer open http://app.cryptofinvest.com/ we want to redirect that to the the url defined in the router (likely /dashboard)
 * However, any other variation that the customer opens the root, we should show the homepage
 * For dev enviroments, your root will always be the landing page, so use /dashboard as an entry point for testing
 */

export default function RewriteSubDomainRouter({ redirect, element }: Props) {

  if (!isLandingPage()) {
    return <Navigate to={redirect} replace />
  }

  return element;
}