import { App } from "./types";
import AuthRepositoryAmplify from "../auth/AuthRepositoryAmplify";
import TokensRepositoryAmplify from "../tokens/TokensRepositoryAmplify";
import UsersRepositoryAmplify from "../users/UsersRepositoryAmplify";
import PriceAlertsRepositoryAmplify from "../alerts/PriceAlertsRepositoryAmplify";
import CollectionsRepositoryAmplify from "../collections/CollectionsRepositoryAmplify";
import SignalsRepositoryAmplify from "../signals/SignalsRepositoryAmplify";
import ManageTelegramGroupsRepositoryAmplify from "../manage-telegram-groups/ManageTelegramGroupsRepositoryAmplify";
import UserProductsRepositoryAmplify from "../user-products/UserProductsRepositoryAmplify";
import MarketPlaceProductRepositoryAmplify from "../marketplace/MarketPlaceProductRepositoryAmplify";
import UserSignInActivitiesRepositoryAmplify from "../users/UserSignInActivitiesRepositoryAmplify";
import BillingRepositoryAmplify from "../billing/BillingRepositoryAmplify";
import AdsRepositoryAmplify from "../ads/AdsRepositoryAmplify";
import CoursesRepositoryAmplify from "../courses/CoursesRepositoryAmplify";
import CryptofyBotRepositoryAmplify from "../cryptofy-bot-trades/CryptofyBotRepositoryAmplify";
import TradingRepositoryAmplify from "../trading/TradingRepositoryAmplify";
import AutoTradingBotRepositoryAmplify from "../auto-trading-bot/AutoTradingBotRepositoryAmplify";

const authRepository = new AuthRepositoryAmplify();
const tokensRepository = new TokensRepositoryAmplify();
const usersRepository = new UsersRepositoryAmplify();
const collectionsRepository = new CollectionsRepositoryAmplify();
const signalsRepository = new SignalsRepositoryAmplify();
const manageTelegramGroupsRepository = new ManageTelegramGroupsRepositoryAmplify();
const userProductsRepository = new UserProductsRepositoryAmplify();
const marketPlaceProductRepository = new MarketPlaceProductRepositoryAmplify();
const priceAlertsRepository = new PriceAlertsRepositoryAmplify();
const userSignInActivititiesRepository = new UserSignInActivitiesRepositoryAmplify();
const billingRepository = new BillingRepositoryAmplify();
const adsRepository = new AdsRepositoryAmplify();
const coursesRepository = new CoursesRepositoryAmplify();
const tradingRepository = new TradingRepositoryAmplify();
const tradingBotRepository = new CryptofyBotRepositoryAmplify();
const autoTradingBotRepository = new AutoTradingBotRepositoryAmplify();

export const createApp = (): App => {
  return {
    authRepository,
    tokensRepository,
    usersRepository,
    collectionsRepository,
    signalsRepository,
    priceAlertsRepository,
    manageTelegramGroupsRepository,
    userProductsRepository,
    marketPlaceProductRepository,
    userSignInActivititiesRepository,
    billingRepository,
    adsRepository,
    coursesRepository,
    tradingBotRepository,
    autoTradingBotRepository,
    tradingRepository
  }
};
