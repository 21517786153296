import { useEffect } from "react";
import { Outlet } from 'react-router-dom';
import HeaderSimple from "./HeaderSimple";
import Footer from "../../components/Footer";


export default function SimpleLayout() {

  // Hack the background to force the dark background for all
  useEffect(() => {
    document.body.style.background = '#17181f';
  }, [])

  return (
    <>
      <HeaderSimple />
      <Outlet />
      <Footer />
    </>
  );
}