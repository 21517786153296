import { API, graphqlOperation } from 'aws-amplify';
import { CreateSignalResult, DeleteSignalResult, SignalsRepositoryInterface, GetSignalResult, ListSignalsResult, UpdateSignalResult, GetSignalAnalyticsResultGraphQL } from './types';
import { CreateSignalInput, GetSignalAnalyticsInput, Signal, SignalStatus } from '../amplify/API';
import { getSignal, getSignalAnalytics, listSignals } from '../amplify/graphql/queries';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { createSignal, deleteSignal, updateSignal } from '../amplify/graphql/mutations';
import { ListParams } from '../factories/types';
import orderBy from 'lodash/orderBy';
import { parseNestedJSONStrings, removeKeyProperty } from '../helpers';

export default class SignalsRepositoryAmplify implements SignalsRepositoryInterface {

  /**
   * Get the signal details
   */
  async getById(signalId: string) {
    const { data } = (await API.graphql({
          query: getSignal,
          variables: { id: signalId },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      })) as GetSignalResult;

    if (!data) {
      throw new Error('Error when fetching signal information');
    }

    return data.getSignal;
  }

  /**
   * Create a signal and its details
   */
  async create(input: CreateSignalInput) {

    const { data } = await API.graphql(
      graphqlOperation(createSignal, { input })
    ) as CreateSignalResult;

    if (!data) {
      throw new Error('Error when creating new signal');
    }

    return data.createSignal;
  }

  /**
   * Update the signal details
   */
  async save(signalId: string, input: Partial<Signal>) {

    // Telegram and any other messenger service is dealt in the backend.
    // Also we have monitoring that update some properties of this record.
    // Here we need to make sure we never send any updates to these properties that could
    // override and therefore lose the reference to the message.
    let {
      lastMonitorCheck,
      telegramMessages, telegramEntryNotificationId, telegramEntryNotificationDate,
      createdAt, updatedAt, __typename, owner,
      ...otherInput } = input;

    otherInput = removeKeyProperty('__typename', otherInput);

    const { data } = await API.graphql(
      graphqlOperation(updateSignal, { input: { ...otherInput, id: signalId } })
    ) as UpdateSignalResult;

    if (!data) {
      throw new Error("Error saving signal");
    }

    return data.updateSignal;
  }

  /**
   * Delete token signal
   */
  async delete(signalId: string) {

    const { data } = (await API.graphql(
      graphqlOperation(deleteSignal, { input: { id: signalId } })
    )) as DeleteSignalResult;

    if (!data) {
      throw new Error(`Can't delete signal ${signalId}`);
    }


    return data.deleteSignal;
  }

  /**
   * Fetch the list of signals associated to the tokens
   */
  async list(params?: ListParams, results?: Signal[]): Promise<Signal[]> {
    let { limit = 1000, filter = null, nextToken } = params || {};

    const { data } = (await API.graphql({
          query: listSignals,
          variables: {
            limit,
            filter,
            nextToken
          },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      })) as ListSignalsResult;


    if (!data) {
      throw new Error("There was an issue trying to fetch the list");
    }

    let items = [ ...(!results ? [] : results), ...data.listSignals.items ] as Signal[];

    if (!data.listSignals.nextToken) {
      return orderBy(items, [(signal) => TranslateSignalStatus[signal.status], 'updatedAt', 'createdAt', 'type'], ['asc', 'desc', 'desc', 'asc'])
    } else {
      return this.list({ ...params, nextToken: data.listSignals.nextToken }, items) as Promise<Signal[]>;
    }
  }

  /**
   * Get the reports from signals
   */
  async getSignalAnalytics(input: GetSignalAnalyticsInput) {

    const { data } = await API.graphql({
      query: getSignalAnalytics,
      variables: { input },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    }) as GetSignalAnalyticsResultGraphQL;

    if (!data) {
      throw new Error('Cant fetch signal analytics');
    }

    return parseNestedJSONStrings(data.getSignalAnalytics);
  }
}



const TranslateSignalStatus = {
  [SignalStatus.ACTIVE]: 1,
  [SignalStatus.WAITING_BREAK]: 2,
  [SignalStatus.WAITING_REACTION]: 2,
  [SignalStatus.CLOSED]: 3,
  [SignalStatus.INVALID]: 4,
}
