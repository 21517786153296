import { ListUserSignInActivitiesResult, UserSignInActivitiesRepositoryInterface, CreateUserSignInActivityResult } from "./types";
import { API, graphqlOperation } from "aws-amplify";
import { UserSignInActivity } from "../amplify/API";
import { listUserSignInActivities, } from "../amplify/graphql/queries";
import { createUserSignInActivity } from "../amplify/graphql/mutations";
import orderBy from 'lodash/orderBy';
import { ListParams } from "../factories/types";
import { getUserLocation } from "../helpers/location";
import { getDeviceName } from "../helpers";
import { getReadableDateTime } from "../helpers/date-time";

export default class UserSignInActivitiesRepositoryAmplify implements UserSignInActivitiesRepositoryInterface {

  async create(username: string) {
    const location = await getUserLocation();

    const { data } = await API.graphql(
      graphqlOperation(createUserSignInActivity, {
        input: {
          username,
          ip: location.ip,
          timezone: location.timezone,
          country: location.country_name,
          city: location.city,
          browser: navigator.userAgent,
          device: getDeviceName(),
          date: getReadableDateTime()
        }
      })
    ) as CreateUserSignInActivityResult;

    if (!data) {
      throw new Error('Error registering login activity of the user');
    }

    return data.createUserSignInActivity;
  }

  /**
   * Fetch the list of events associated to the tokens
   */
  async list(params?: ListParams, results?: UserSignInActivity[]): Promise<UserSignInActivity[]> {

    let { limit = 1000, filter = null, nextToken } = params || {};

    const { data } = (await API.graphql(
      graphqlOperation(listUserSignInActivities, { limit, filter, nextToken })
    )) as ListUserSignInActivitiesResult;

    if (!data) {
      throw new Error("There was an issue trying to fetch the list");
    }

    const items = [ ...(!results ? [] : results), ...data.listUserSignInActivities.items ] as UserSignInActivity[];

    if (!data.listUserSignInActivities.nextToken) {
      return orderBy(items, ['createdAt'], ['desc'])
    } else {
      return this.list({ ...params, nextToken: data.listUserSignInActivities.nextToken }, items) as Promise<UserSignInActivity[]>;
    }
  }
}
