import AppProvider from "./context/AppProvider";

// routing
import Routes from './routes';

// theming
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, PaletteMode, StyledEngineProvider } from '@mui/material';

// defaultTheme
import themes from './ui/themes';

// context providers
import ConfirmProvider from './context/ConfirmProvider';

// notifications
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BillingProvider from "./context/BillingProvider";
import AuthProvider from "./context/AuthProvider";

export default function App() {


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes({ mode: 'dark'  as PaletteMode })}>
        <ConfirmProvider>
          <AppProvider>
            <AuthProvider>
              <BillingProvider>
                <CssBaseline />
                {/* <GlobalStyles mode={mode} /> */}
                <Routes />
                <ToastContainer />
              </BillingProvider>
            </AuthProvider>
          </AppProvider>
        </ConfirmProvider>
      </ThemeProvider>
    </StyledEngineProvider>

  );
}
