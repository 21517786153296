import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MetaPixel from './lib/helpers/metapixel';

// Analytics
import ReactGA from 'react-ga4';

// Feature flags
import flagsmith from "flagsmith";
import { FlagsmithProvider } from 'flagsmith/react';

// Amplify
import { Amplify } from "aws-amplify";
import awsconfig from './aws-exports'
import { isLandingPage, isLocalHost } from './lib/helpers';
Amplify.configure(awsconfig);

const root = createRoot(document.getElementById('root') as Element);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <FlagsmithProvider options={{ environmentID: 'H4KeWRnaprUJG2caDZfSjr', }} flagsmith={flagsmith}>
        <App />
      </FlagsmithProvider>
    </BrowserRouter>
  </React.StrictMode>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// initialise Google Analytics
if (!isLocalHost()) {
  if (isLandingPage()) {
    ReactGA.initialize('G-G1GQZLCWHK');
  } else {
    ReactGA.initialize('G-TMKL7R370D');
  }
}

MetaPixel.init('527817726219306')