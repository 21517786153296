import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { ListParams } from '../factories/types';
import orderBy from 'lodash/orderBy';
import { getAutoTradingBotConfig, listAutoTradingBotConfigs } from '../amplify/graphql/queries';
import { AutoTradingBotRepositoryInterface, CreateAutoTradingBotConfigResult, DeleteAutoTradingBotConfigResult, GetAutoTradingBotConfigResult, ListAutoTradingBotConfigsResult, UpdateAutoTradingBotConfigResult } from './types';
import { AutoTradingBotConfig, CreateAutoTradingBotConfigInput } from '../amplify/API';
import { createAutoTradingBotConfig, deleteAutoTradingBotConfig, updateAutoTradingBotConfig } from '../amplify/graphql/mutations';

export default class AutoTradingBotRepositoryAmplify implements AutoTradingBotRepositoryInterface {

  /**
   * Get the trade details
   */
  async getConfigById(configId: string) {
    const { data } = await API.graphql(
      graphqlOperation(getAutoTradingBotConfig,  { id: configId })
    ) as GetAutoTradingBotConfigResult;

    if (!data) {
      throw new Error('Error when fetching trade information');
    }

    return data.getAutoTradingBotConfig;
  }


  /**
   * Create a signal and its details
   */
  async createConfig(input: CreateAutoTradingBotConfigInput) {
    const { data } = (await API.graphql({
          query: createAutoTradingBotConfig,
          variables: { input },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      })) as CreateAutoTradingBotConfigResult;

    if (!data) {
      throw new Error('Error when creating new signal');
    }

    return data.createAutoTradingBotConfig;
  }

  /**
   * Update the trade details
   */
  async saveConfig(configId: string, input: Partial<AutoTradingBotConfig>) {

    const { createdAt, updatedAt, __typename, ...otherInput } = input;

    const { data } = await API.graphql(
      graphqlOperation(updateAutoTradingBotConfig, { input: { ...otherInput, id: configId } })
    ) as UpdateAutoTradingBotConfigResult;

    if (!data) {
      throw new Error("Error saving trade");
    }

    return data.updateAutoTradingBotConfig;
  }

  /**
   * Delete token trade
   */
  async deleteConfig(configId: string) {

    const { data } = (await API.graphql(
      graphqlOperation(deleteAutoTradingBotConfig, { input: { id: configId } })
    )) as DeleteAutoTradingBotConfigResult;

    if (!data) {
      throw new Error(`Can't delete trade ${configId}`);
    }


    return data.deleteAutoTradingBotConfig;
  }

  /**
   * Fetch the list of trades associated to the tokens
   */
  async listConfigs(params?: ListParams, results?: AutoTradingBotConfig[]): Promise<AutoTradingBotConfig[]> {
    let { limit = 1000, filter = null, nextToken } = params || {};

    const { data } = (await API.graphql({
          query: listAutoTradingBotConfigs,
          variables: {
            limit,
            filter,
            nextToken
          },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      })) as ListAutoTradingBotConfigsResult;


    if (!data) {
      throw new Error("There was an issue trying to fetch the list");
    }

    let items = [ ...(!results ? [] : results), ...data.listAutoTradingBotConfigs.items ] as AutoTradingBotConfig[];

    if (!data.listAutoTradingBotConfigs.nextToken) {
      return orderBy(items, ['status', 'updatedAt', 'createdAt', 'type'], ['asc', 'desc', 'desc', 'asc'])
    } else {
      return this.listConfigs({ ...params, nextToken: data.listAutoTradingBotConfigs.nextToken }, items) as Promise<AutoTradingBotConfig[]>;
    }
  }
}
