import { API, graphqlOperation } from 'aws-amplify';
import { createLessonProgress, updateLessonProgress } from '../amplify/graphql/mutations';
import { CreateLessonProgressInput, LessonProgress, UpdateLessonProgressInput } from '../amplify/API';
import { CoursesRepositoryInterface, CreateLessonProgressResult, GetLessonProgressResult, ListLessonProgressResult, UpdateLessonProgressResult } from './types';
import { getLessonProgress, listCourseLessonProgressByUsername } from '../amplify/graphql/queries';
import { getErrorMessage } from '../helpers';
import { ListParams } from '../factories/types';

export default class CoursesRepositoryAmplify implements CoursesRepositoryInterface {

  /**
   * Create course progress
   ****************************************************/
  async getLessonProgress(username: string, collectionContentId: string) {

    const { data } = (await API.graphql(
      graphqlOperation(getLessonProgress, { username, collectionContentId })
    )) as GetLessonProgressResult;

    if (!data) {
      throw new Error(`Error trying to get the progress of this lesson ${collectionContentId}`);
    }

    return data.getLessonProgress;
  }


  /**
   * Create lesson progress
   ****************************************************/
  async createLessonProgress(input: CreateLessonProgressInput) {

    const { data } = (await API.graphql(
      graphqlOperation(createLessonProgress, { input })
    )) as CreateLessonProgressResult;

    if (!data) {
      throw new Error(`Error trying to create the progress of this lesson ${input.collectionContentId}`);
    }

    return data.createLessonProgress;
  }


  /**
   * Update lesson progress
   ****************************************************/
  async upsertLessonProgress(input: UpdateLessonProgressInput) {

    let result;
    try {
      result = await this.createLessonProgress(input);
    } catch(err:any) {

      let error = getErrorMessage(err);

        // We update the progress if it already exists, however, if the error is not related to the creation
        // then we want to throw the error
       if (!error.match(/(create the progress|conditional request failed)/)) {
        throw err;
       }

      const { data } = (await API.graphql(
        graphqlOperation(updateLessonProgress, { input })
      )) as UpdateLessonProgressResult;

      if (!data) {
        throw new Error(`Error trying save the progress of this lesson ${input.collectionContentId}`);
      }

      result = data.updateLessonProgress;
    }
    return result;
  }

  /**
   * List all course progress
   ****************************************************/
  async listLessonProgress(username: string, params?: ListParams, results?: LessonProgress[]): Promise<LessonProgress[]> {
    let { limit = 1000, filter = null, nextToken } = params || {};

    const { data } = (await API.graphql(
      graphqlOperation(listCourseLessonProgressByUsername, {
        username,
        limit,
        filter,
        nextToken
      })
    )) as ListLessonProgressResult;

    if (!data) {
      throw new Error(`Error trying to get the list of progress of ${username}`);
    }

    const items = [ ...(!results ? [] : results), ...data.listCourseLessonProgressByUsername.items ] as LessonProgress[];

    if (!data.listCourseLessonProgressByUsername.nextToken) {
      return items;
    } else {
      return this.listLessonProgress(username, { ...params, nextToken: data.listCourseLessonProgressByUsername.nextToken }, items) as Promise<LessonProgress[]>;
    }
  }

}
