import axios from 'axios';

const API_KEY = '';
const headers   = {'user-agent': 'ipapi/ipapi-nodejs/0.3.0'};
const fieldList = ['ip', 'city', 'region', 'country', 'postal',
                  'latitude', 'longitude', 'timezone', 'latlong'];

export const getUserLocation = async (ip?: string, key?: string, field?: string) => {
    let path;
    let isField = false;

    if ((typeof field !== 'undefined') && (field !== '')) {
      if (fieldList.indexOf(field) === -1) {
          return 'Invalid field'
      } else {
          isField = true;
      }
    }

    if (isField) {
      if (typeof ip !== 'undefined') {
          path = '/' + ip + '/' + field + '/';
      } else {
          path = '/' + field + '/';
      }
    } else {
      if (typeof ip !== 'undefined') {
          path = '/' + ip + '/json/';
      } else {
          path = '/json/';
      }
    }

    if ((typeof key !== 'undefined') && (key !== '')){
      path = path + '?key=' + key;
    } else {
      if (API_KEY !== ''){
        path = path + '?key=' + API_KEY;
      }
    }

    const { data } = await axios.get(`https://ipapi.co/${path}`, {
      headers
    })

    return data;
};
