const features = {
  PREMIUM: [
    "+Weekly Newsletter with Market Analysis",
    "+Crypto Daily News Highlights",
    "+Trading Patterns & Indicators",
    "+Crypto & Investing Video Courses",
    "+50+ Trading Signals per Month",
    "+Trading Price Alert Tool",
    "+Interactive Chat with VIP Members and Traders",
  ],
  PRO: [
    "+Weekly Newsletter with Market Analysis",
    "+Crypto Daily News Highlights",
    "+Trading Patterns & Indicators",
    "+Crypto & Investing Video Courses",
    "+Limited Trading Signals",
    "+Trading Price Alert Tool",
    "-Interactive Chat with VIP Members and Traders",
  ],
  BASIC: [
    "+Weekly Newsletter with Market Analysis",
    "+Crypto Daily News Highlights",
    "-Trading Patterns & Indicators",
    "-Crypto & Investing Video Courses",
    "-Trading Signals",
    "-Trading Price Alert Tool",
    "-Interactive Chat with VIP Members and Traders",
  ],
}

export default features;
