import { Controller } from "react-hook-form";
import { forwardRef, memo, useMemo } from "react";
import debounce from "lodash.debounce";
import TextField from '@mui/material/TextField';
import { FormControl, FormHelperText } from "@mui/material";

const DebouncedTextField =  forwardRef(({
  name,
  label,
  onChange,
  debounceTime = 0,
  error,
  rules,
  control,
  showTextError,
  hidden,
  ...inputProps
}: any, ref) => {

  const debouncedOnChange = useMemo(() => {

    if (!onChange) return;
    return debounce(
          (e: React.ChangeEvent<HTMLInputElement>) => onChange(e),
          debounceTime
        )
  }, [debounceTime, onChange]);

  if (hidden) return null;

  return (
    <FormControl fullWidth>
      {control ?
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  label={label}
                  error={Boolean(fieldState.error)}
                  {...inputProps}
                  {...field}
                />
              );
            }}
          />
          :
          <TextField
            label={label}
            name={name}
            id={name}
            ref={ref}
            rules={rules}
            onChange={debouncedOnChange}
            {...inputProps}
          />
        }
      {showTextError ? <FormHelperText sx={{ color: '#f44336'}}>{error}</FormHelperText> : null}
    </FormControl>
  );
});

export default memo(DebouncedTextField);
