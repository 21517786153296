/**
 * Meta Pixel Module
 *
 * @package MetaPixel
 */
//

import { isLocalHost } from ".";

declare global {
  interface Window {
      fbq:any;
  }
}

let initialized = false;
let debug = true;

/**
 * Utilities
 */

/**
 * Warn
 * @param  {...any} args
 */
const warn = (...args: any) => {
  if (!debug) {
    return;
  }
  // eslint-disable-next-line no-console
  console.info(...['[MetaPixel]'].concat(args));
};

/**
 * Log
 * @param  {...any} args
 */
const log = (...args: any) => {
  if (!debug) {
    return;
  }
  // eslint-disable-next-line no-console
  console.info(...['[MetaPixel]'].concat(args));
};

const verifyInit = () => {
  if (isLocalHost()) {
    warn('Localhost detected: Skipping pixel call');
    return false;
  }
  if (!initialized) {
    warn(
      'Pixel not initialized before using call ReactPixel.init with required params',
    );
  }
  return initialized;
};

//
const defaultOptions = {
  autoConfig: true,
  debug: true,
};

export default {
  init(pixelId: string, advancedMatching = {}, options = defaultOptions) {

    log('init', pixelId, advancedMatching, options);

    initialized = (typeof window !== 'undefined') && !!window.fbq;
    /* eslint-disable */
    //@ts-ignore
    !(function(f: any, b: any, e: any, v: any, n: any, t: any, s: any) {
      if (f.fbq) return;
      n = f.fbq = function() {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js',
    );
    /* eslint-enable */

    if (!pixelId) {
      warn('Please insert pixel id for initializing');
    } else {
      if (options.autoConfig === false) {
        window.fbq('set', 'autoConfig', false, pixelId); // eslint-disable-line no-undef
      }

      window.fbq('init', pixelId, advancedMatching); // eslint-disable-line no-undef

      initialized = true;
      debug = options.debug;
    }
  },

  pageView() {
    if (!verifyInit()) {
      return;
    }

    window.fbq('track', 'PageView'); // eslint-disable-line no-undef

    if (debug) {
      log("called fbq('track', 'PageView');");
    }
  },

  // https://madgicx.com/blog/facebook-pixel-events
  track(event: string, data?: any) {
    if (!verifyInit()) {
      return;
    }

    window.fbq('track', event, data); // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('track', '${event}');`);

      if (data) {
        log('with data', data);
      }
    }
  },

  trackSingle(pixel: number | string, title: string, data: any) {
    if (!verifyInit()) {
      return;
    }

    window.fbq('trackSingle', pixel, title, data); // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('trackSingle', '${pixel}', '${title}');`);

      if (data) {
        log('with data', data);
      }
    }
  },

  trackCustom(event:any, data: any) {
    if (!verifyInit()) {
      return;
    }

    window.fbq('trackCustom', event, data); // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('trackCustom', '${event}');`);

      if (data) {
        log('with data', data);
      }
    }
  },

  trackSingleCustom(pixel: number | string, event: any, data: any) {
    if (!verifyInit()) {
      return;
    }

    window.fbq('trackSingle', pixel, event, data); // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('trackSingleCustom', '${pixel}', '${event}');`);

      if (data) {
        log('with data', data);
      }
    }
  },

  grantConsent() {
    if (!verifyInit()) {
      return;
    }

    window.fbq('consent', 'grant');

    if (debug) {
      log(`called fbq('consent', 'grant');`);
    }
  },

  revokeConsent() {
    if (!verifyInit()) {
      return;
    }

    window.fbq('consent', 'revoke');

    if (debug) {
      log(`called fbq('consent', 'revoke');`);
    }
  },

  fbq(...args: any) {
    if (!verifyInit()) {
      return;
    }
    window.fbq(...args); // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('${args.slice(0, 2).join("', '")}')`);

      if (args[2]) {
        log('with data', args[2]);
      }
    }
  },
};