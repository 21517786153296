export const getErrorMessage = (err: any) => {

  if (err && err.message) {
    return err.message;
  }
  if (err && err.errors && err.errors.length) {
    return err.errors[err.errors.length-1].message;
  }

  return err.toString() || "There was an error (-001)";
}

/**
 * Remove the slash from the end of the url
 */
export const stripTrailingSlash = (str: string) => {
    return str.endsWith('/') ?
        str.slice(0, -1) :
        str;
};

/**
 * Create a slug out of a text
 */
export const slugify = (text: string, separator = "-") => {
    return text
        .toString()
        .normalize('NFD')                   // split an accented letter in the base letter and the acent
        .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
        .toLowerCase()
        .replace(/[,;]/g, '')
        .replace(/[^a-z0-9 -_]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
        .trim()
        .replace(/\s+/g, separator);
};

/**
 * Clean up the HTML tags
 ****************************************************************************************************************/
export const cleanupHTMLTags = (str?: string | null) => {
  return (str || '').replace(/<\/?[^>]+(>|$)/g, "");
}


/**
 * Get the device name to log the user sign in
 ****************************************************************************************************************/
export const getDeviceName = () => {
    let device = "Unknown";
    const ua: any = {
        "Generic Linux": /Linux/i,
        "Android": /Android/i,
        "BlackBerry": /BlackBerry/i,
        "Bluebird": /EF500/i,
        "Chrome OS": /CrOS/i,
        "Datalogic": /DL-AXIS/i,
        "Honeywell": /CT50/i,
        "iPad": /iPad/i,
        "iPhone": /iPhone/i,
        "iPod": /iPod/i,
        "macOS": /Macintosh/i,
        "Windows": /IEMobile|Windows/i,
        "Zebra": /TC70|TC55/i,
    }
    Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
    return device;
};

export const isLandingPage = () => {
  let [ subdomain ] = window.location.hostname.split('.');

  return (subdomain !== 'app')
}

/**
 * Check if the current page is localhost
 */
export const isLocalHost = () => {
  return window.location.hostname === 'localhost'
}

export const parseNestedJSONStrings = (obj: any) => {
  // Base case: if the input is not an object or array, return it unmodified.
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // Iterate through array elements or object properties.
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // If the value is a string, attempt to parse it as JSON.
      if (typeof value === 'string') {
        try {
          const parsedValue = JSON.parse(value);

          // If parsing is successful and the result is an object or array, recurse.
          if (typeof parsedValue === 'object' && parsedValue !== null) {
            obj[key] = parseNestedJSONStrings(parsedValue);
          }
        } catch (e) {
          // If parsing fails, leave the value unchanged.
        }
      } else if (typeof value === 'object' && value !== null) {
        // If the value is an object or array, recurse.
        obj[key] = parseNestedJSONStrings(value);
      }
    }
  }

  return obj;
}

/**
 * Format the number like: 39.1298381 to 39.12 or 45.00 to 45.
 ************************************************************************/
export const formatNumber = (num: string | number) => {
  return Number(Number(num).toFixed(2).replace(/(\.00|0$)/gi,''));
}


/**
 * Parse the JSON string without breaking the apis
 ************************************************************************/
export const JSONParse = (item?: string | null) => {
  if (!item) return {};

  try {
    return JSON.parse(item)
  } catch(err) {
    return {}
  }
}

/**
 * This function will be removing the a specific property from the object
 ****************************************************************/
export const removeKeyProperty = (keyProperty: string | string[], obj: any) => {
  let newObj: any = {};
  try {
    newObj = JSON.parse(JSON.stringify(obj));
  } catch(err) {
    console.log('Error parsing JSON', err);
    return obj;
  }

  // Accept array or string in the key property
  const isKeyToRemove = (key: string) => {
    if (Array.isArray(keyProperty)) {
      return keyProperty.includes(key);
    }
    return key === keyProperty;
  };

  for (const key in newObj) {
    if (newObj.hasOwnProperty(key)) {
      if (isKeyToRemove(key)) {
        delete newObj[key];
      } else if (typeof newObj[key] === 'object' && newObj[key] !== null) {
        newObj[key] = removeKeyProperty(keyProperty, newObj[key]);
      }
    }
  }
  return newObj;
};

