import { Box, Button, Modal, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import useToastNotification from "../../../hooks/useToastNotification";
import useUser from "../../../hooks/useUsers";
import { getErrorMessage } from "../../../lib/helpers";
import TermsAndConditions from "../../components/TermsAndConditions";
import CheckBox from "../../components/forms/CheckBox";
import VideoPlayer from "../../components/VideoPlayer";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";

type Props = {
  show: boolean;
  username: string;
}

type FormData = {
  checked: boolean
};

const TermsAndConditionsModal = ({ username, show }: Props) => {
  const [ openModal, setOpenModal ] = useState<boolean>(false);
  const [ accepted, setAccepted ] = useState<boolean>(false);
  const [ isSaving, setIsSaving ] = useState<boolean>(false);
  const [ _, actions ] = useUser(null, { list: false });

  const { showErrorNotification } = useToastNotification ();

  useEffect(() => {
    setOpenModal(show);
  }, [show]);

  /**
   * Setup the default values of the form
   */
  const formActions = useForm<FormData>();
  const checked = useWatch({ control: formActions.control, name: 'checked' });

  /**
   * Handle the deletion of an item
   */
  const handleSubmitForm = async () => {
    try {
      setIsSaving(true);
      await actions.save(username, { termsAndConditions: true });
      setIsSaving(false);
      setAccepted(true);
    } catch(err) {
      showErrorNotification(getErrorMessage(err));
    }
  }

  return (
   <ModalContainer open={openModal}>
      <form onSubmit={formActions.handleSubmit(handleSubmitForm)} style={{ display: "block", padding: '20px' }}>
        <FloatBox>
          {accepted ?
            <>
                <VideoPlayer url="https://www.youtube.com/watch?v=wGMY6Js1kdM" width="100%" height="100%" controls={false} />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 2 }}>
                  <Button variant="contained" type="submit" onClick={() => setOpenModal(false)}>CLOSE</Button>
                </Box>
             </>
            :
            <>
              <Box sx={{ overflowY: 'auto', maxHeight: '480px' }}>
                <TermsAndConditions />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 2 }}>
                <CheckBox name="checked" label="Accept Terms and Conditions" control={formActions.control} />
                <ButtonWithSpinner variant="contained" type="submit" disabled={!checked} showSpinner={isSaving}>ACCEPT</ButtonWithSpinner>
              </Box>
            </>
          }
        </FloatBox>
      </form>
    </ModalContainer>
  );
};
export default TermsAndConditionsModal;


const ModalContainer  = styled(Modal)(() => ({
  '.MuiBackdrop-root': {
    background: 'rgb(0 0 0 / 95%)'
  }
}));

const FloatBox = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    width: '95%'
  }
}));

