import Box from '@mui/material/Box';

import { Container, Typography } from '@mui/material';
import FullWidthWrapper from '../FullwidthWrapper';

import Loading from '../Loading';

export default function AuthLoading() {

  return (
    <FullWidthWrapper>
      <Container sx={{ mt:10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Box sx={{ position: 'relative' }}>
          <Loading type="spin" color="#a9afd7" width={100} height={100}>
            <Typography variant="h6" color="#c3c3c3">Loading...</Typography>
          </Loading>
        </Box>
      </Container>
    </FullWidthWrapper>
  );
}