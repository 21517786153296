import { Box, Divider, Typography } from "@mui/material";

export default function TermsAndConditions() {

  return (
    <Box>
      <Typography gutterBottom variant="h4" sx={{ mb: 2, fontSize: { xs: 20, md: 35 }, textAlign: 'center' }}>Terms and Conditions</Typography>

      <Divider sx={{ mb: 3 }} />

      <Typography variant="body2" sx={{ mb: 2 }}>Cryptofy Hub, is an informational and educational website, on the subject of trading and investing, open to anyone in the community who wants to join. To ensure the content of the site remains user friendly and subject oriented we require that members of the site follow the Terms and conditions when posting any sort of content on the site</Typography>

      <Typography gutterBottom variant="h5">General Terms</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>By using or accessing the site, you thereby agree, (a) that you have received, read and understood these Terms and Conditions, and that these Terms and Conditions create a valid and binding agreement, enforceable against you in accordance with the terms here of, (b) to be bound by these Terms and Conditions, any terms, conditions or other rules, regulations or policies of Cryptofy Hub, as each may be amended or supplemented from time to time in our sole discretion without notice, and (c) that your use of the site shall comply with all applicable local laws, rules or regulations, and that you are solely responsible for your compliance with, familiarity with and understanding of any such laws, rules or regulations applicable to your use of the Site. If you do not agree with any portion of these Terms and Conditions, you are prohibited from using or accessing the Site. </Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Additionally, by using the site, you represent and warrant to us that you: (a) are eighteen (18) years of age or older, (b) are not currently restricted from using the site, or not otherwise prohibited from having an account with us, (c) are not a competitor of Cryptofy Hub, or engaged in any business or activity, directly or indirectly, that could be competitive with the business or activities of Cryptofy Hub, and are not using the Site for any reason that may be in competition with the Site or any other product or service offered by Cryptofy Hub, (d) have full power and authority to enter into and perform these Terms and Conditions, and doing so will not violate any other agreement to which you are a party, (e) will not violate any rights of Cryptofy Hub, including, without limitation, intellectual property rights such as patent, copyright or trademark rights, and (f) agree to provide, operate and maintain, at your sole risk, cost and expense, all equipment, software, and internet access necessary to use the Site</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Without limiting the generality of the foregoing or any other provision hereof, you acknowledge and agree, as follows:</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Not to promote, offer for sale or sell any product or service, including, without limitation, any financial or investment product, security or service, or any contest or promotion.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Not to transmit, send or otherwise post unauthorized commercial communications (such as spam), or other similar materials or advertisements, using the Site.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Not to access the Site using any automated means, including, without limitation, harvesting bots, robots, spiders, or scrapers.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Not to engage in multi-level marketing using the Site, including, without limitation, pyramid schemes and similar marketing concepts.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Not to upload, use or disseminate viruses or other malicious code or other abusive scripts or processes.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Not to solicit login, account or other personal information of another person or request or obtain access to an account of another person.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Not to bully, intimidate, or harass any person.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Not to post content that is hateful, threatening, or pornographic, incites violence, or contains nudity, graphic material, gratuitous violence, or anything else found to be objectionable by Cryptofy Hub in its sole discretion.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Not to take any action that could disable, overburden, or impair the operation or availability of the Site, such as a denial-of-service attack.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Not to engage in manipulative practices designed to obfuscate the true intent of your submissions to the Site, or to artificially generate traffic to another website.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Not to make your account or any portion thereof or the services provided thereby available to the public or any portion thereof.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Not to share personal information with members or request personal information from members.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Violations of these Terms may result in a suspension or permanent revocation of a subscriber’s ability to post any comments, replies or other content on the site. Additionally, subscription plans may be terminated on or before the end of the existing term due to a violation of these Terms & Conditions at our discretion</Typography>

      <Typography gutterBottom variant="h5">Purchases and Refunds</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>Through the site, cryptofyhub.com offers both individual products for sale and subscription, membership, or other periodic fee-based services. For individual products purchased through the site, a valid credit card will be required to complete the purchase and the amount of the purchase will be charged to the credit card concurrently with the completion of the purchase on the site. When you purchase an individual product, you will receive a streaming or digital version; no physical copies of products or other materials will be shipped or provided to you.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>When purchasing subscription, membership or other periodic fee-based services on the site, a valid credit card will be required for billing purposes. You hereby consent to, acknowledge, and allow Cryptofy Hub to store (or cause a third party to store on behalf of Cryptofy Hub) such payment information and agree to the following policies and procedures for payment of subscription, membership or other periodic fees related to the Site</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Immediately upon signing up for an account or other periodic fee-based services on the Site, the fees associated with the first period or term of your selected account or service (as applicable) will be charged to such credit card. Within ten (10) business days prior to the expiration of the initially selected period or term, and each subsequent such period or term until your account or other periodic service is cancelled in accordance with these Terms and Conditions, the fees associated with your selected account or service for the following applicable period or term will be automatically renewed, and an amount for your renewed account or service will be billed and charged to such credit card by Cryptofy Hub in accordance with the Site’s then-current pricing schedule. If the credit card associated with your account or service should expire, terminate or any payment is otherwise rejected by the issuing company, Cryptofy Hub may immediately terminate your account or service. It is solely your responsibility to ensure (1) that valid credit card information remains on file for your account or service and the automatic renewal thereof, and (2) that a valid email address remains on file for your account or service for any communications from Cryptofy Hub related thereto.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>All payments made to Cryptofy Hub in connection with any product purchased or account or service, and any renewal thereof, are non-refundable; Cryptofy Hub is not required to provide, any refunds or credits for any reason, including, without limitation, failure to cancel your account or service prior to its automatic renewal. Cryptofy Hub reserves the right to attribute refunds under special circumstances decided on case by case.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>All fees charged by Cryptofy Hub in connection with any product purchased or account or service of the site are exclusive of any taxes, levies, or duties imposed by any taxing authority, and you shall be, and hereby are, responsible for the payment of all such taxes, levies, or duties arising from your purchase of products or use of the Site.</Typography>

      <Typography gutterBottom variant="h5">Disclaimer</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>All content on this site is provided for informational and educational purposes. Furthermore, information contained herein reflects the opinions of its author and is provided for discussion purposes only. Under no circumstances does this information represent a recommendation to buy or sell digital assets or securities, nor should it be construed as investment advice. Cryptofy Hub is not a Registered Investment Advisor nor licensed or registered with any regulatory agency.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Any information contained in this Cryptofy Hub is not a recommendation to buy or sell any specific digital asset or security. The market analysis, trade ideas & other commentary shared are simply educational content on digital assets or securities.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>Trading requires risking money in pursuit of future gain. Do not risk any money you cannot afford to lose. The content published on Cryptofy Hub does not consider your own individual financial and personal circumstances. It is intended for educational purposes only.</Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>We do not warrant or represent: the completeness or accuracy of the information published on our website; that the material on the website is up to date; that the website will operate without fault; or that the website or any service on the website will remain available. We reserve the right to discontinue or alter any or all of our website services, and to stop publishing our website, at any time in our sole discretion without notice or explanation; and save to the extent expressly provided otherwise in these terms and conditions, you will not be entitled to any compensation or other payment upon the discontinuance or alteration of any website services, or if we stop publishing the website. To the maximum extent permitted by applicable law, we exclude all representations and warranties relating to the subject matter of these terms and conditions, our website, and the use of our website. We will not be liable to you in respect of any business losses, including (without limitation) loss of or damage to profits, income, revenue, use, production, anticipated savings, business, contracts, commercial opportunities, or goodwill. We will not be liable to you in respect of any special, indirect, or consequential loss or damage.</Typography>

    </Box>

  );
}
