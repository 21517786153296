/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const searchToken = /* GraphQL */ `query SearchToken($input: SearchTokenInput) {
  searchToken(input: $input) {
    name
    address
    symbol
    decimals
    chain
    tags
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTokenQueryVariables,
  APITypes.SearchTokenQuery
>;
export const getListTokensFromS3 = /* GraphQL */ `query GetListTokensFromS3($input: ListTokenFromS3Input) {
  getListTokensFromS3(input: $input) {
    address
    name
    symbol
    network
    tags
    decimals
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetListTokensFromS3QueryVariables,
  APITypes.GetListTokensFromS3Query
>;
export const getTelegramParticipants = /* GraphQL */ `query GetTelegramParticipants($input: GetTelegramParticipantsInput) {
  getTelegramParticipants(input: $input) {
    telegramId
    isAdmin
    name
    username
    isBot
    phone
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTelegramParticipantsQueryVariables,
  APITypes.GetTelegramParticipantsQuery
>;
export const getAd = /* GraphQL */ `query GetAd($id: ID!) {
  getAd(id: $id) {
    id
    title
    frequency
    startDate
    endDate
    dates
    telegramChannels
    telegramMessages {
      photo
      message
      callbackActions {
        url
        text
        __typename
      }
      __typename
    }
    adType
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAdQueryVariables, APITypes.GetAdQuery>;
export const listAds = /* GraphQL */ `query ListAds($filter: ModelAdFilterInput, $limit: Int, $nextToken: String) {
  listAds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      frequency
      startDate
      endDate
      dates
      telegramChannels
      telegramMessages {
        photo
        message
        callbackActions {
          url
          text
          __typename
        }
        __typename
      }
      adType
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListAdsQueryVariables, APITypes.ListAdsQuery>;
export const getAutoTradingBotConfig = /* GraphQL */ `query GetAutoTradingBotConfig($id: ID!) {
  getAutoTradingBotConfig(id: $id) {
    id
    name
    apiKey
    apiSecret
    exchange
    amountToTrade
    quoteAsset
    maxNumTrades
    fixedLeverage
    fixedStopLoss
    type
    status
    username
    user {
      username
      email
      name
      initials
      photo
      lastLogin
      lastVisit
      status
      statusReason
      membership
      permissions
      permissionGroup
      expirationDate
      dateOfRenewal
      memberSince
      termsAndConditions
      secretKey
      telegramGroups {
        id
        name
        channel
        label
        link
        __typename
      }
      telegramId
      searchName
      customerId
      currentPlanPeriod
      currentPlanName
      currentPlanId
      currentPlanPrice
      currentPlanCurrency
      subscriptionId
      markedAsCancelled
      isTrial
      isTestSubscription
      products {
        items {
          productId
          username
          expirationDate
          status
          product {
            id
            slug
            name
            externalReference
            icon
            price
            description
            productType
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAutoTradingBotConfigQueryVariables,
  APITypes.GetAutoTradingBotConfigQuery
>;
export const listAutoTradingBotConfigs = /* GraphQL */ `query ListAutoTradingBotConfigs(
  $filter: ModelAutoTradingBotConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  listAutoTradingBotConfigs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      apiKey
      apiSecret
      exchange
      amountToTrade
      quoteAsset
      maxNumTrades
      fixedLeverage
      fixedStopLoss
      type
      status
      username
      user {
        username
        email
        name
        initials
        photo
        lastLogin
        lastVisit
        status
        statusReason
        membership
        permissions
        permissionGroup
        expirationDate
        dateOfRenewal
        memberSince
        termsAndConditions
        secretKey
        telegramGroups {
          id
          name
          channel
          label
          link
          __typename
        }
        telegramId
        searchName
        customerId
        currentPlanPeriod
        currentPlanName
        currentPlanId
        currentPlanPrice
        currentPlanCurrency
        subscriptionId
        markedAsCancelled
        isTrial
        isTestSubscription
        products {
          items {
            productId
            username
            expirationDate
            status
            product {
              id
              slug
              name
              externalReference
              icon
              price
              description
              productType
              status
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAutoTradingBotConfigsQueryVariables,
  APITypes.ListAutoTradingBotConfigsQuery
>;
export const getAutoTradingBotTrade = /* GraphQL */ `query GetAutoTradingBotTrade($id: ID!) {
  getAutoTradingBotTrade(id: $id) {
    id
    exchange
    type
    tokenName
    symbol
    side
    amount
    stopLossPrice
    entryPrice
    closedPrice
    quantity
    leverage
    createOrderId
    createOrderLinkId
    stopLossOrderId
    closedOrderId
    status
    telegramSignalLink
    pnlAmount
    pnlPercentage
    quoteAsset
    username
    signalID
    signal {
      id
      title
      type
      status
      exchange
      isFutures
      video
      thumbnail
      stopLoss {
        value
        percentage
        hit
        __typename
      }
      trailingStopLoss
      targets {
        value
        percentage
        hit
        __typename
      }
      minRetainer {
        value
        percentage
        hit
        __typename
      }
      description
      entryOnActivation
      entry
      futuresOperation
      leverage
      interval
      tags
      link
      linkLabel
      totalGains
      totalGainsFutures
      lastPrice
      lastMonitorCheck
      takeProfitNum
      breakThroughTime
      breakThroughCount
      showOnFreeGroup
      telegramMessages {
        channel
        id
        link
        __typename
      }
      telegramChannels
      telegramEntryNotificationId
      telegramEntryNotificationDate
      tokenName
      tokenSymbol
      tokenAddress
      createdByName
      createdByUserName
      manualOperation
      isTesting
      useCopyTrade
      referenceId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAutoTradingBotTradeQueryVariables,
  APITypes.GetAutoTradingBotTradeQuery
>;
export const listAutoTradingBotTrades = /* GraphQL */ `query ListAutoTradingBotTrades(
  $filter: ModelAutoTradingBotTradeFilterInput
  $limit: Int
  $nextToken: String
) {
  listAutoTradingBotTrades(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      exchange
      type
      tokenName
      symbol
      side
      amount
      stopLossPrice
      entryPrice
      closedPrice
      quantity
      leverage
      createOrderId
      createOrderLinkId
      stopLossOrderId
      closedOrderId
      status
      telegramSignalLink
      pnlAmount
      pnlPercentage
      quoteAsset
      username
      signalID
      signal {
        id
        title
        type
        status
        exchange
        isFutures
        video
        thumbnail
        stopLoss {
          value
          percentage
          hit
          __typename
        }
        trailingStopLoss
        targets {
          value
          percentage
          hit
          __typename
        }
        minRetainer {
          value
          percentage
          hit
          __typename
        }
        description
        entryOnActivation
        entry
        futuresOperation
        leverage
        interval
        tags
        link
        linkLabel
        totalGains
        totalGainsFutures
        lastPrice
        lastMonitorCheck
        takeProfitNum
        breakThroughTime
        breakThroughCount
        showOnFreeGroup
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramChannels
        telegramEntryNotificationId
        telegramEntryNotificationDate
        tokenName
        tokenSymbol
        tokenAddress
        createdByName
        createdByUserName
        manualOperation
        isTesting
        useCopyTrade
        referenceId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAutoTradingBotTradesQueryVariables,
  APITypes.ListAutoTradingBotTradesQuery
>;
export const getBotTradeByUsernameAndSignalId = /* GraphQL */ `query GetBotTradeByUsernameAndSignalId(
  $username: String!
  $signalID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAutoTradingBotTradeFilterInput
  $limit: Int
  $nextToken: String
) {
  getBotTradeByUsernameAndSignalId(
    username: $username
    signalID: $signalID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      exchange
      type
      tokenName
      symbol
      side
      amount
      stopLossPrice
      entryPrice
      closedPrice
      quantity
      leverage
      createOrderId
      createOrderLinkId
      stopLossOrderId
      closedOrderId
      status
      telegramSignalLink
      pnlAmount
      pnlPercentage
      quoteAsset
      username
      signalID
      signal {
        id
        title
        type
        status
        exchange
        isFutures
        video
        thumbnail
        stopLoss {
          value
          percentage
          hit
          __typename
        }
        trailingStopLoss
        targets {
          value
          percentage
          hit
          __typename
        }
        minRetainer {
          value
          percentage
          hit
          __typename
        }
        description
        entryOnActivation
        entry
        futuresOperation
        leverage
        interval
        tags
        link
        linkLabel
        totalGains
        totalGainsFutures
        lastPrice
        lastMonitorCheck
        takeProfitNum
        breakThroughTime
        breakThroughCount
        showOnFreeGroup
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramChannels
        telegramEntryNotificationId
        telegramEntryNotificationDate
        tokenName
        tokenSymbol
        tokenAddress
        createdByName
        createdByUserName
        manualOperation
        isTesting
        useCopyTrade
        referenceId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBotTradeByUsernameAndSignalIdQueryVariables,
  APITypes.GetBotTradeByUsernameAndSignalIdQuery
>;
export const getAutoTradingBotHistory = /* GraphQL */ `query GetAutoTradingBotHistory($id: ID!) {
  getAutoTradingBotHistory(id: $id) {
    id
    exchange
    symbol
    tokenName
    side
    leverage
    action
    content
    payload
    totalGains
    username
    orderID
    type
    signalID
    autoTradingID
    autoTradeInfo {
      id
      exchange
      type
      tokenName
      symbol
      side
      amount
      stopLossPrice
      entryPrice
      closedPrice
      quantity
      leverage
      createOrderId
      createOrderLinkId
      stopLossOrderId
      closedOrderId
      status
      telegramSignalLink
      pnlAmount
      pnlPercentage
      quoteAsset
      username
      signalID
      signal {
        id
        title
        type
        status
        exchange
        isFutures
        video
        thumbnail
        stopLoss {
          value
          percentage
          hit
          __typename
        }
        trailingStopLoss
        targets {
          value
          percentage
          hit
          __typename
        }
        minRetainer {
          value
          percentage
          hit
          __typename
        }
        description
        entryOnActivation
        entry
        futuresOperation
        leverage
        interval
        tags
        link
        linkLabel
        totalGains
        totalGainsFutures
        lastPrice
        lastMonitorCheck
        takeProfitNum
        breakThroughTime
        breakThroughCount
        showOnFreeGroup
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramChannels
        telegramEntryNotificationId
        telegramEntryNotificationDate
        tokenName
        tokenSymbol
        tokenAddress
        createdByName
        createdByUserName
        manualOperation
        isTesting
        useCopyTrade
        referenceId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAutoTradingBotHistoryQueryVariables,
  APITypes.GetAutoTradingBotHistoryQuery
>;
export const listAutoTradingBotHistories = /* GraphQL */ `query ListAutoTradingBotHistories(
  $filter: ModelAutoTradingBotHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listAutoTradingBotHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      exchange
      symbol
      tokenName
      side
      leverage
      action
      content
      payload
      totalGains
      username
      orderID
      type
      signalID
      autoTradingID
      autoTradeInfo {
        id
        exchange
        type
        tokenName
        symbol
        side
        amount
        stopLossPrice
        entryPrice
        closedPrice
        quantity
        leverage
        createOrderId
        createOrderLinkId
        stopLossOrderId
        closedOrderId
        status
        telegramSignalLink
        pnlAmount
        pnlPercentage
        quoteAsset
        username
        signalID
        signal {
          id
          title
          type
          status
          exchange
          isFutures
          video
          thumbnail
          stopLoss {
            value
            percentage
            hit
            __typename
          }
          trailingStopLoss
          targets {
            value
            percentage
            hit
            __typename
          }
          minRetainer {
            value
            percentage
            hit
            __typename
          }
          description
          entryOnActivation
          entry
          futuresOperation
          leverage
          interval
          tags
          link
          linkLabel
          totalGains
          totalGainsFutures
          lastPrice
          lastMonitorCheck
          takeProfitNum
          breakThroughTime
          breakThroughCount
          showOnFreeGroup
          telegramMessages {
            channel
            id
            link
            __typename
          }
          telegramChannels
          telegramEntryNotificationId
          telegramEntryNotificationDate
          tokenName
          tokenSymbol
          tokenAddress
          createdByName
          createdByUserName
          manualOperation
          isTesting
          useCopyTrade
          referenceId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAutoTradingBotHistoriesQueryVariables,
  APITypes.ListAutoTradingBotHistoriesQuery
>;
export const getCollection = /* GraphQL */ `query GetCollection($id: ID!) {
  getCollection(id: $id) {
    id
    title
    description
    status
    tags
    contents {
      items {
        id
        slug
        order
        title
        description
        body
        thumbnail
        image
        video
        duration
        date
        attachments {
          file
          url
          text
          mimeType
          __typename
        }
        status
        collectionID
        collection {
          id
          title
          description
          status
          tags
          contents {
            items {
              id
              slug
              order
              title
              description
              body
              thumbnail
              image
              video
              duration
              date
              status
              collectionID
              authorName
              authorUsername
              telegramChannels
              telegramTemplate
              type
              customFields
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          telegramChannels
          telegramTemplate
          thumbnail
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        comments {
          items {
            id
            contentID
            body
            createdByName
            createdByUserName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        authorName
        authorUsername
        telegramChannels
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramTemplate
        type
        customFields
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    telegramChannels
    telegramTemplate
    thumbnail
    order
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCollectionQueryVariables,
  APITypes.GetCollectionQuery
>;
export const listCollections = /* GraphQL */ `query ListCollections(
  $filter: ModelCollectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listCollections(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      status
      tags
      contents {
        items {
          id
          slug
          order
          title
          description
          body
          thumbnail
          image
          video
          duration
          date
          attachments {
            file
            url
            text
            mimeType
            __typename
          }
          status
          collectionID
          collection {
            id
            title
            description
            status
            tags
            contents {
              nextToken
              __typename
            }
            telegramChannels
            telegramTemplate
            thumbnail
            order
            createdAt
            updatedAt
            owner
            __typename
          }
          comments {
            items {
              id
              contentID
              body
              createdByName
              createdByUserName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          authorName
          authorUsername
          telegramChannels
          telegramMessages {
            channel
            id
            link
            __typename
          }
          telegramTemplate
          type
          customFields
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      telegramChannels
      telegramTemplate
      thumbnail
      order
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCollectionsQueryVariables,
  APITypes.ListCollectionsQuery
>;
export const getCollectionContent = /* GraphQL */ `query GetCollectionContent($id: ID!) {
  getCollectionContent(id: $id) {
    id
    slug
    order
    title
    description
    body
    thumbnail
    image
    video
    duration
    date
    attachments {
      file
      url
      text
      mimeType
      __typename
    }
    status
    collectionID
    collection {
      id
      title
      description
      status
      tags
      contents {
        items {
          id
          slug
          order
          title
          description
          body
          thumbnail
          image
          video
          duration
          date
          attachments {
            file
            url
            text
            mimeType
            __typename
          }
          status
          collectionID
          collection {
            id
            title
            description
            status
            tags
            contents {
              nextToken
              __typename
            }
            telegramChannels
            telegramTemplate
            thumbnail
            order
            createdAt
            updatedAt
            owner
            __typename
          }
          comments {
            items {
              id
              contentID
              body
              createdByName
              createdByUserName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          authorName
          authorUsername
          telegramChannels
          telegramMessages {
            channel
            id
            link
            __typename
          }
          telegramTemplate
          type
          customFields
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      telegramChannels
      telegramTemplate
      thumbnail
      order
      createdAt
      updatedAt
      owner
      __typename
    }
    comments {
      items {
        id
        contentID
        body
        createdByName
        createdByUserName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    authorName
    authorUsername
    telegramChannels
    telegramMessages {
      channel
      id
      link
      __typename
    }
    telegramTemplate
    type
    customFields
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCollectionContentQueryVariables,
  APITypes.GetCollectionContentQuery
>;
export const listCollectionContents = /* GraphQL */ `query ListCollectionContents(
  $filter: ModelCollectionContentFilterInput
  $limit: Int
  $nextToken: String
) {
  listCollectionContents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slug
      order
      title
      description
      body
      thumbnail
      image
      video
      duration
      date
      attachments {
        file
        url
        text
        mimeType
        __typename
      }
      status
      collectionID
      collection {
        id
        title
        description
        status
        tags
        contents {
          items {
            id
            slug
            order
            title
            description
            body
            thumbnail
            image
            video
            duration
            date
            attachments {
              file
              url
              text
              mimeType
              __typename
            }
            status
            collectionID
            collection {
              id
              title
              description
              status
              tags
              telegramChannels
              telegramTemplate
              thumbnail
              order
              createdAt
              updatedAt
              owner
              __typename
            }
            comments {
              nextToken
              __typename
            }
            authorName
            authorUsername
            telegramChannels
            telegramMessages {
              channel
              id
              link
              __typename
            }
            telegramTemplate
            type
            customFields
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        telegramChannels
        telegramTemplate
        thumbnail
        order
        createdAt
        updatedAt
        owner
        __typename
      }
      comments {
        items {
          id
          contentID
          body
          createdByName
          createdByUserName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      authorName
      authorUsername
      telegramChannels
      telegramMessages {
        channel
        id
        link
        __typename
      }
      telegramTemplate
      type
      customFields
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCollectionContentsQueryVariables,
  APITypes.ListCollectionContentsQuery
>;
export const getComment = /* GraphQL */ `query GetComment($id: ID!) {
  getComment(id: $id) {
    id
    contentID
    body
    createdByName
    createdByUserName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentQueryVariables,
  APITypes.GetCommentQuery
>;
export const listComments = /* GraphQL */ `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      contentID
      body
      createdByName
      createdByUserName
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsQueryVariables,
  APITypes.ListCommentsQuery
>;
export const getLessonProgress = /* GraphQL */ `query GetLessonProgress($collectionContentId: String!, $username: String!) {
  getLessonProgress(
    collectionContentId: $collectionContentId
    username: $username
  ) {
    collectionContentId
    username
    progress
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLessonProgressQueryVariables,
  APITypes.GetLessonProgressQuery
>;
export const listLessonProgresses = /* GraphQL */ `query ListLessonProgresses(
  $collectionContentId: String
  $username: ModelStringKeyConditionInput
  $filter: ModelLessonProgressFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listLessonProgresses(
    collectionContentId: $collectionContentId
    username: $username
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      collectionContentId
      username
      progress
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLessonProgressesQueryVariables,
  APITypes.ListLessonProgressesQuery
>;
export const listCourseLessonProgressByUsername = /* GraphQL */ `query ListCourseLessonProgressByUsername(
  $username: String!
  $sortDirection: ModelSortDirection
  $filter: ModelLessonProgressFilterInput
  $limit: Int
  $nextToken: String
) {
  listCourseLessonProgressByUsername(
    username: $username
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      collectionContentId
      username
      progress
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCourseLessonProgressByUsernameQueryVariables,
  APITypes.ListCourseLessonProgressByUsernameQuery
>;
export const getCryptofyBotTrade = /* GraphQL */ `query GetCryptofyBotTrade($id: String!) {
  getCryptofyBotTrade(id: $id) {
    id
    status
    tokenName
    symbol
    exchange
    type
    side
    leverage
    signalEntry
    tradeEntry
    targets {
      value
      percentage
      hit
      __typename
    }
    signalStopLoss {
      value
      percentage
      hit
      __typename
    }
    tradeStopLoss {
      value
      percentage
      hit
      __typename
    }
    peakLossProfit
    currentLossProfit
    telegramSignalLink
    lastMonitorCheck
    lastPrice
    activationManagement {
      crossingTime
      crossingPrice
      wasWithinThreshold
      hasActivated
      __typename
    }
    customFields
    history {
      items {
        id
        action
        message
        tradeId
        trade {
          id
          status
          tokenName
          symbol
          exchange
          type
          side
          leverage
          signalEntry
          tradeEntry
          targets {
            value
            percentage
            hit
            __typename
          }
          signalStopLoss {
            value
            percentage
            hit
            __typename
          }
          tradeStopLoss {
            value
            percentage
            hit
            __typename
          }
          peakLossProfit
          currentLossProfit
          telegramSignalLink
          lastMonitorCheck
          lastPrice
          activationManagement {
            crossingTime
            crossingPrice
            wasWithinThreshold
            hasActivated
            __typename
          }
          customFields
          history {
            items {
              id
              action
              message
              tradeId
              customFields
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signalID
          signal {
            id
            title
            type
            status
            exchange
            isFutures
            video
            thumbnail
            stopLoss {
              value
              percentage
              hit
              __typename
            }
            trailingStopLoss
            targets {
              value
              percentage
              hit
              __typename
            }
            minRetainer {
              value
              percentage
              hit
              __typename
            }
            description
            entryOnActivation
            entry
            futuresOperation
            leverage
            interval
            tags
            link
            linkLabel
            totalGains
            totalGainsFutures
            lastPrice
            lastMonitorCheck
            takeProfitNum
            breakThroughTime
            breakThroughCount
            showOnFreeGroup
            telegramMessages {
              channel
              id
              link
              __typename
            }
            telegramChannels
            telegramEntryNotificationId
            telegramEntryNotificationDate
            tokenName
            tokenSymbol
            tokenAddress
            createdByName
            createdByUserName
            manualOperation
            isTesting
            useCopyTrade
            referenceId
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        customFields
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    signalID
    signal {
      id
      title
      type
      status
      exchange
      isFutures
      video
      thumbnail
      stopLoss {
        value
        percentage
        hit
        __typename
      }
      trailingStopLoss
      targets {
        value
        percentage
        hit
        __typename
      }
      minRetainer {
        value
        percentage
        hit
        __typename
      }
      description
      entryOnActivation
      entry
      futuresOperation
      leverage
      interval
      tags
      link
      linkLabel
      totalGains
      totalGainsFutures
      lastPrice
      lastMonitorCheck
      takeProfitNum
      breakThroughTime
      breakThroughCount
      showOnFreeGroup
      telegramMessages {
        channel
        id
        link
        __typename
      }
      telegramChannels
      telegramEntryNotificationId
      telegramEntryNotificationDate
      tokenName
      tokenSymbol
      tokenAddress
      createdByName
      createdByUserName
      manualOperation
      isTesting
      useCopyTrade
      referenceId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCryptofyBotTradeQueryVariables,
  APITypes.GetCryptofyBotTradeQuery
>;
export const listCryptofyBotTrades = /* GraphQL */ `query ListCryptofyBotTrades(
  $id: String
  $filter: ModelCryptofyBotTradeFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCryptofyBotTrades(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      status
      tokenName
      symbol
      exchange
      type
      side
      leverage
      signalEntry
      tradeEntry
      targets {
        value
        percentage
        hit
        __typename
      }
      signalStopLoss {
        value
        percentage
        hit
        __typename
      }
      tradeStopLoss {
        value
        percentage
        hit
        __typename
      }
      peakLossProfit
      currentLossProfit
      telegramSignalLink
      lastMonitorCheck
      lastPrice
      activationManagement {
        crossingTime
        crossingPrice
        wasWithinThreshold
        hasActivated
        __typename
      }
      customFields
      history {
        items {
          id
          action
          message
          tradeId
          trade {
            id
            status
            tokenName
            symbol
            exchange
            type
            side
            leverage
            signalEntry
            tradeEntry
            targets {
              value
              percentage
              hit
              __typename
            }
            signalStopLoss {
              value
              percentage
              hit
              __typename
            }
            tradeStopLoss {
              value
              percentage
              hit
              __typename
            }
            peakLossProfit
            currentLossProfit
            telegramSignalLink
            lastMonitorCheck
            lastPrice
            activationManagement {
              crossingTime
              crossingPrice
              wasWithinThreshold
              hasActivated
              __typename
            }
            customFields
            history {
              nextToken
              __typename
            }
            signalID
            signal {
              id
              title
              type
              status
              exchange
              isFutures
              video
              thumbnail
              trailingStopLoss
              description
              entryOnActivation
              entry
              futuresOperation
              leverage
              interval
              tags
              link
              linkLabel
              totalGains
              totalGainsFutures
              lastPrice
              lastMonitorCheck
              takeProfitNum
              breakThroughTime
              breakThroughCount
              showOnFreeGroup
              telegramChannels
              telegramEntryNotificationId
              telegramEntryNotificationDate
              tokenName
              tokenSymbol
              tokenAddress
              createdByName
              createdByUserName
              manualOperation
              isTesting
              useCopyTrade
              referenceId
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          customFields
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signalID
      signal {
        id
        title
        type
        status
        exchange
        isFutures
        video
        thumbnail
        stopLoss {
          value
          percentage
          hit
          __typename
        }
        trailingStopLoss
        targets {
          value
          percentage
          hit
          __typename
        }
        minRetainer {
          value
          percentage
          hit
          __typename
        }
        description
        entryOnActivation
        entry
        futuresOperation
        leverage
        interval
        tags
        link
        linkLabel
        totalGains
        totalGainsFutures
        lastPrice
        lastMonitorCheck
        takeProfitNum
        breakThroughTime
        breakThroughCount
        showOnFreeGroup
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramChannels
        telegramEntryNotificationId
        telegramEntryNotificationDate
        tokenName
        tokenSymbol
        tokenAddress
        createdByName
        createdByUserName
        manualOperation
        isTesting
        useCopyTrade
        referenceId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCryptofyBotTradesQueryVariables,
  APITypes.ListCryptofyBotTradesQuery
>;
export const getCryptofyBotTradeHistory = /* GraphQL */ `query GetCryptofyBotTradeHistory($id: ID!) {
  getCryptofyBotTradeHistory(id: $id) {
    id
    action
    message
    tradeId
    trade {
      id
      status
      tokenName
      symbol
      exchange
      type
      side
      leverage
      signalEntry
      tradeEntry
      targets {
        value
        percentage
        hit
        __typename
      }
      signalStopLoss {
        value
        percentage
        hit
        __typename
      }
      tradeStopLoss {
        value
        percentage
        hit
        __typename
      }
      peakLossProfit
      currentLossProfit
      telegramSignalLink
      lastMonitorCheck
      lastPrice
      activationManagement {
        crossingTime
        crossingPrice
        wasWithinThreshold
        hasActivated
        __typename
      }
      customFields
      history {
        items {
          id
          action
          message
          tradeId
          trade {
            id
            status
            tokenName
            symbol
            exchange
            type
            side
            leverage
            signalEntry
            tradeEntry
            targets {
              value
              percentage
              hit
              __typename
            }
            signalStopLoss {
              value
              percentage
              hit
              __typename
            }
            tradeStopLoss {
              value
              percentage
              hit
              __typename
            }
            peakLossProfit
            currentLossProfit
            telegramSignalLink
            lastMonitorCheck
            lastPrice
            activationManagement {
              crossingTime
              crossingPrice
              wasWithinThreshold
              hasActivated
              __typename
            }
            customFields
            history {
              nextToken
              __typename
            }
            signalID
            signal {
              id
              title
              type
              status
              exchange
              isFutures
              video
              thumbnail
              trailingStopLoss
              description
              entryOnActivation
              entry
              futuresOperation
              leverage
              interval
              tags
              link
              linkLabel
              totalGains
              totalGainsFutures
              lastPrice
              lastMonitorCheck
              takeProfitNum
              breakThroughTime
              breakThroughCount
              showOnFreeGroup
              telegramChannels
              telegramEntryNotificationId
              telegramEntryNotificationDate
              tokenName
              tokenSymbol
              tokenAddress
              createdByName
              createdByUserName
              manualOperation
              isTesting
              useCopyTrade
              referenceId
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          customFields
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signalID
      signal {
        id
        title
        type
        status
        exchange
        isFutures
        video
        thumbnail
        stopLoss {
          value
          percentage
          hit
          __typename
        }
        trailingStopLoss
        targets {
          value
          percentage
          hit
          __typename
        }
        minRetainer {
          value
          percentage
          hit
          __typename
        }
        description
        entryOnActivation
        entry
        futuresOperation
        leverage
        interval
        tags
        link
        linkLabel
        totalGains
        totalGainsFutures
        lastPrice
        lastMonitorCheck
        takeProfitNum
        breakThroughTime
        breakThroughCount
        showOnFreeGroup
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramChannels
        telegramEntryNotificationId
        telegramEntryNotificationDate
        tokenName
        tokenSymbol
        tokenAddress
        createdByName
        createdByUserName
        manualOperation
        isTesting
        useCopyTrade
        referenceId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    customFields
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCryptofyBotTradeHistoryQueryVariables,
  APITypes.GetCryptofyBotTradeHistoryQuery
>;
export const listCryptofyBotTradeHistories = /* GraphQL */ `query ListCryptofyBotTradeHistories(
  $filter: ModelCryptofyBotTradeHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCryptofyBotTradeHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      action
      message
      tradeId
      trade {
        id
        status
        tokenName
        symbol
        exchange
        type
        side
        leverage
        signalEntry
        tradeEntry
        targets {
          value
          percentage
          hit
          __typename
        }
        signalStopLoss {
          value
          percentage
          hit
          __typename
        }
        tradeStopLoss {
          value
          percentage
          hit
          __typename
        }
        peakLossProfit
        currentLossProfit
        telegramSignalLink
        lastMonitorCheck
        lastPrice
        activationManagement {
          crossingTime
          crossingPrice
          wasWithinThreshold
          hasActivated
          __typename
        }
        customFields
        history {
          items {
            id
            action
            message
            tradeId
            trade {
              id
              status
              tokenName
              symbol
              exchange
              type
              side
              leverage
              signalEntry
              tradeEntry
              peakLossProfit
              currentLossProfit
              telegramSignalLink
              lastMonitorCheck
              lastPrice
              customFields
              signalID
              createdAt
              updatedAt
              owner
              __typename
            }
            customFields
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signalID
        signal {
          id
          title
          type
          status
          exchange
          isFutures
          video
          thumbnail
          stopLoss {
            value
            percentage
            hit
            __typename
          }
          trailingStopLoss
          targets {
            value
            percentage
            hit
            __typename
          }
          minRetainer {
            value
            percentage
            hit
            __typename
          }
          description
          entryOnActivation
          entry
          futuresOperation
          leverage
          interval
          tags
          link
          linkLabel
          totalGains
          totalGainsFutures
          lastPrice
          lastMonitorCheck
          takeProfitNum
          breakThroughTime
          breakThroughCount
          showOnFreeGroup
          telegramMessages {
            channel
            id
            link
            __typename
          }
          telegramChannels
          telegramEntryNotificationId
          telegramEntryNotificationDate
          tokenName
          tokenSymbol
          tokenAddress
          createdByName
          createdByUserName
          manualOperation
          isTesting
          useCopyTrade
          referenceId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      customFields
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCryptofyBotTradeHistoriesQueryVariables,
  APITypes.ListCryptofyBotTradeHistoriesQuery
>;
export const getMarketPlaceProduct = /* GraphQL */ `query GetMarketPlaceProduct($id: ID!) {
  getMarketPlaceProduct(id: $id) {
    id
    slug
    name
    externalReference
    icon
    price
    description
    productType
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMarketPlaceProductQueryVariables,
  APITypes.GetMarketPlaceProductQuery
>;
export const listMarketPlaceProducts = /* GraphQL */ `query ListMarketPlaceProducts(
  $filter: ModelMarketPlaceProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listMarketPlaceProducts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slug
      name
      externalReference
      icon
      price
      description
      productType
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMarketPlaceProductsQueryVariables,
  APITypes.ListMarketPlaceProductsQuery
>;
export const getMarketPlaceProductBySlug = /* GraphQL */ `query GetMarketPlaceProductBySlug(
  $slug: String!
  $sortDirection: ModelSortDirection
  $filter: ModelMarketPlaceProductFilterInput
  $limit: Int
  $nextToken: String
) {
  getMarketPlaceProductBySlug(
    slug: $slug
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slug
      name
      externalReference
      icon
      price
      description
      productType
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMarketPlaceProductBySlugQueryVariables,
  APITypes.GetMarketPlaceProductBySlugQuery
>;
export const getPriceAlert = /* GraphQL */ `query GetPriceAlert($id: ID!) {
  getPriceAlert(id: $id) {
    id
    status
    type
    price
    description
    tokenName
    tokenAddress
    tokenSymbol
    triggeredDate
    triggeredPrice
    username
    userEmail
    chain
    isFutures
    createdAlertAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPriceAlertQueryVariables,
  APITypes.GetPriceAlertQuery
>;
export const listPriceAlerts = /* GraphQL */ `query ListPriceAlerts(
  $filter: ModelPriceAlertFilterInput
  $limit: Int
  $nextToken: String
) {
  listPriceAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      status
      type
      price
      description
      tokenName
      tokenAddress
      tokenSymbol
      triggeredDate
      triggeredPrice
      username
      userEmail
      chain
      isFutures
      createdAlertAt
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPriceAlertsQueryVariables,
  APITypes.ListPriceAlertsQuery
>;
export const getSignal = /* GraphQL */ `query GetSignal($id: ID!) {
  getSignal(id: $id) {
    id
    title
    type
    status
    exchange
    isFutures
    video
    thumbnail
    stopLoss {
      value
      percentage
      hit
      __typename
    }
    trailingStopLoss
    targets {
      value
      percentage
      hit
      __typename
    }
    minRetainer {
      value
      percentage
      hit
      __typename
    }
    description
    entryOnActivation
    entry
    futuresOperation
    leverage
    interval
    tags
    link
    linkLabel
    totalGains
    totalGainsFutures
    lastPrice
    lastMonitorCheck
    takeProfitNum
    breakThroughTime
    breakThroughCount
    showOnFreeGroup
    telegramMessages {
      channel
      id
      link
      __typename
    }
    telegramChannels
    telegramEntryNotificationId
    telegramEntryNotificationDate
    tokenName
    tokenSymbol
    tokenAddress
    createdByName
    createdByUserName
    manualOperation
    isTesting
    useCopyTrade
    referenceId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSignalQueryVariables, APITypes.GetSignalQuery>;
export const listSignals = /* GraphQL */ `query ListSignals(
  $filter: ModelSignalFilterInput
  $limit: Int
  $nextToken: String
) {
  listSignals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      type
      status
      exchange
      isFutures
      video
      thumbnail
      stopLoss {
        value
        percentage
        hit
        __typename
      }
      trailingStopLoss
      targets {
        value
        percentage
        hit
        __typename
      }
      minRetainer {
        value
        percentage
        hit
        __typename
      }
      description
      entryOnActivation
      entry
      futuresOperation
      leverage
      interval
      tags
      link
      linkLabel
      totalGains
      totalGainsFutures
      lastPrice
      lastMonitorCheck
      takeProfitNum
      breakThroughTime
      breakThroughCount
      showOnFreeGroup
      telegramMessages {
        channel
        id
        link
        __typename
      }
      telegramChannels
      telegramEntryNotificationId
      telegramEntryNotificationDate
      tokenName
      tokenSymbol
      tokenAddress
      createdByName
      createdByUserName
      manualOperation
      isTesting
      useCopyTrade
      referenceId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSignalsQueryVariables,
  APITypes.ListSignalsQuery
>;
export const getSignalByReferenceId = /* GraphQL */ `query GetSignalByReferenceId(
  $referenceId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSignalFilterInput
  $limit: Int
  $nextToken: String
) {
  getSignalByReferenceId(
    referenceId: $referenceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      type
      status
      exchange
      isFutures
      video
      thumbnail
      stopLoss {
        value
        percentage
        hit
        __typename
      }
      trailingStopLoss
      targets {
        value
        percentage
        hit
        __typename
      }
      minRetainer {
        value
        percentage
        hit
        __typename
      }
      description
      entryOnActivation
      entry
      futuresOperation
      leverage
      interval
      tags
      link
      linkLabel
      totalGains
      totalGainsFutures
      lastPrice
      lastMonitorCheck
      takeProfitNum
      breakThroughTime
      breakThroughCount
      showOnFreeGroup
      telegramMessages {
        channel
        id
        link
        __typename
      }
      telegramChannels
      telegramEntryNotificationId
      telegramEntryNotificationDate
      tokenName
      tokenSymbol
      tokenAddress
      createdByName
      createdByUserName
      manualOperation
      isTesting
      useCopyTrade
      referenceId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSignalByReferenceIdQueryVariables,
  APITypes.GetSignalByReferenceIdQuery
>;
export const getTelegramBotState = /* GraphQL */ `query GetTelegramBotState($telegramId: String!) {
  getTelegramBotState(telegramId: $telegramId) {
    telegramId
    nextStep
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTelegramBotStateQueryVariables,
  APITypes.GetTelegramBotStateQuery
>;
export const listTelegramBotStates = /* GraphQL */ `query ListTelegramBotStates(
  $telegramId: String
  $filter: ModelTelegramBotStateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTelegramBotStates(
    telegramId: $telegramId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      telegramId
      nextStep
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTelegramBotStatesQueryVariables,
  APITypes.ListTelegramBotStatesQuery
>;
export const getUser = /* GraphQL */ `query GetUser($username: String!) {
  getUser(username: $username) {
    username
    email
    name
    initials
    photo
    lastLogin
    lastVisit
    status
    statusReason
    membership
    permissions
    permissionGroup
    expirationDate
    dateOfRenewal
    memberSince
    termsAndConditions
    secretKey
    telegramGroups {
      id
      name
      channel
      label
      link
      __typename
    }
    telegramId
    searchName
    customerId
    currentPlanPeriod
    currentPlanName
    currentPlanId
    currentPlanPrice
    currentPlanCurrency
    subscriptionId
    markedAsCancelled
    isTrial
    isTestSubscription
    products {
      items {
        productId
        username
        expirationDate
        status
        product {
          id
          slug
          name
          externalReference
          icon
          price
          description
          productType
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $username: String
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    username: $username
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      username
      email
      name
      initials
      photo
      lastLogin
      lastVisit
      status
      statusReason
      membership
      permissions
      permissionGroup
      expirationDate
      dateOfRenewal
      memberSince
      termsAndConditions
      secretKey
      telegramGroups {
        id
        name
        channel
        label
        link
        __typename
      }
      telegramId
      searchName
      customerId
      currentPlanPeriod
      currentPlanName
      currentPlanId
      currentPlanPrice
      currentPlanCurrency
      subscriptionId
      markedAsCancelled
      isTrial
      isTestSubscription
      products {
        items {
          productId
          username
          expirationDate
          status
          product {
            id
            slug
            name
            externalReference
            icon
            price
            description
            productType
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getUserByEmail = /* GraphQL */ `query GetUserByEmail(
  $email: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      username
      email
      name
      initials
      photo
      lastLogin
      lastVisit
      status
      statusReason
      membership
      permissions
      permissionGroup
      expirationDate
      dateOfRenewal
      memberSince
      termsAndConditions
      secretKey
      telegramGroups {
        id
        name
        channel
        label
        link
        __typename
      }
      telegramId
      searchName
      customerId
      currentPlanPeriod
      currentPlanName
      currentPlanId
      currentPlanPrice
      currentPlanCurrency
      subscriptionId
      markedAsCancelled
      isTrial
      isTestSubscription
      products {
        items {
          productId
          username
          expirationDate
          status
          product {
            id
            slug
            name
            externalReference
            icon
            price
            description
            productType
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByEmailQueryVariables,
  APITypes.GetUserByEmailQuery
>;
export const getUserByTelegramId = /* GraphQL */ `query GetUserByTelegramId(
  $telegramId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserByTelegramId(
    telegramId: $telegramId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      username
      email
      name
      initials
      photo
      lastLogin
      lastVisit
      status
      statusReason
      membership
      permissions
      permissionGroup
      expirationDate
      dateOfRenewal
      memberSince
      termsAndConditions
      secretKey
      telegramGroups {
        id
        name
        channel
        label
        link
        __typename
      }
      telegramId
      searchName
      customerId
      currentPlanPeriod
      currentPlanName
      currentPlanId
      currentPlanPrice
      currentPlanCurrency
      subscriptionId
      markedAsCancelled
      isTrial
      isTestSubscription
      products {
        items {
          productId
          username
          expirationDate
          status
          product {
            id
            slug
            name
            externalReference
            icon
            price
            description
            productType
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByTelegramIdQueryVariables,
  APITypes.GetUserByTelegramIdQuery
>;
export const getUserBySubscriptionId = /* GraphQL */ `query GetUserBySubscriptionId(
  $subscriptionId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserBySubscriptionId(
    subscriptionId: $subscriptionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      username
      email
      name
      initials
      photo
      lastLogin
      lastVisit
      status
      statusReason
      membership
      permissions
      permissionGroup
      expirationDate
      dateOfRenewal
      memberSince
      termsAndConditions
      secretKey
      telegramGroups {
        id
        name
        channel
        label
        link
        __typename
      }
      telegramId
      searchName
      customerId
      currentPlanPeriod
      currentPlanName
      currentPlanId
      currentPlanPrice
      currentPlanCurrency
      subscriptionId
      markedAsCancelled
      isTrial
      isTestSubscription
      products {
        items {
          productId
          username
          expirationDate
          status
          product {
            id
            slug
            name
            externalReference
            icon
            price
            description
            productType
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserBySubscriptionIdQueryVariables,
  APITypes.GetUserBySubscriptionIdQuery
>;
export const getUserSignInActivity = /* GraphQL */ `query GetUserSignInActivity($id: ID!) {
  getUserSignInActivity(id: $id) {
    id
    device
    browser
    ip
    city
    country
    timezone
    date
    username
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserSignInActivityQueryVariables,
  APITypes.GetUserSignInActivityQuery
>;
export const listUserSignInActivities = /* GraphQL */ `query ListUserSignInActivities(
  $filter: ModelUserSignInActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserSignInActivities(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      device
      browser
      ip
      city
      country
      timezone
      date
      username
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserSignInActivitiesQueryVariables,
  APITypes.ListUserSignInActivitiesQuery
>;
export const getUserProduct = /* GraphQL */ `query GetUserProduct($productId: ID!, $username: String!) {
  getUserProduct(productId: $productId, username: $username) {
    productId
    username
    expirationDate
    status
    product {
      id
      slug
      name
      externalReference
      icon
      price
      description
      productType
      status
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserProductQueryVariables,
  APITypes.GetUserProductQuery
>;
export const listUserProducts = /* GraphQL */ `query ListUserProducts(
  $productId: ID
  $username: ModelStringKeyConditionInput
  $filter: ModelUserProductFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserProducts(
    productId: $productId
    username: $username
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      productId
      username
      expirationDate
      status
      product {
        id
        slug
        name
        externalReference
        icon
        price
        description
        productType
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserProductsQueryVariables,
  APITypes.ListUserProductsQuery
>;
export const getUserProductsByUsername = /* GraphQL */ `query GetUserProductsByUsername(
  $username: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserProductFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserProductsByUsername(
    username: $username
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      productId
      username
      expirationDate
      status
      product {
        id
        slug
        name
        externalReference
        icon
        price
        description
        productType
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserProductsByUsernameQueryVariables,
  APITypes.GetUserProductsByUsernameQuery
>;
export const getTokenPrice = /* GraphQL */ `query GetTokenPrice($input: TokenPriceInput) {
  getTokenPrice(input: $input) {
    price
    symbol
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTokenPriceQueryVariables,
  APITypes.GetTokenPriceQuery
>;
export const getSignalAnalytics = /* GraphQL */ `query GetSignalAnalytics($input: GetSignalAnalyticsInput) {
  getSignalAnalytics(input: $input) {
    numbers
    topGainers
    trending
    takeVsLoss
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSignalAnalyticsQueryVariables,
  APITypes.GetSignalAnalyticsQuery
>;
export const getHistoricalPriceData = /* GraphQL */ `query GetHistoricalPriceData($input: GetHistoricalPriceDataInput) {
  getHistoricalPriceData(input: $input) {
    time
    open
    high
    low
    close
    volume
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHistoricalPriceDataQueryVariables,
  APITypes.GetHistoricalPriceDataQuery
>;
export const getStripeCheckoutSession = /* GraphQL */ `query GetStripeCheckoutSession($input: GetStripeCheckoutSessionInput) {
  getStripeCheckoutSession(input: $input) {
    customerId
    orderId
    name
    email
    total
    address {
      city
      country
      line1
      line2
      postal_code
      state
      __typename
    }
    currency
    orderDate
    lineItems {
      id
      amountSubTotal
      amountTotal
      quantity
      currency
      description
      planName
      planInterval
      planId
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStripeCheckoutSessionQueryVariables,
  APITypes.GetStripeCheckoutSessionQuery
>;
export const getStripeSubscription = /* GraphQL */ `query GetStripeSubscription($input: GetStripeSubscriptionInput) {
  getStripeSubscription(input: $input)
}
` as GeneratedQuery<
  APITypes.GetStripeSubscriptionQueryVariables,
  APITypes.GetStripeSubscriptionQuery
>;
export const getStripePlans = /* GraphQL */ `query GetStripePlans($productId: String) {
  getStripePlans(productId: $productId) {
    plans {
      id
      name
      price
      frequency
      interval
      membershipName
      features
      totalPrice
      priority
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStripePlansQueryVariables,
  APITypes.GetStripePlansQuery
>;
export const calculateStripeChangePlansProration = /* GraphQL */ `query CalculateStripeChangePlansProration(
  $input: CalculateStripeChangePlansProrationInput!
) {
  calculateStripeChangePlansProration(input: $input)
}
` as GeneratedQuery<
  APITypes.CalculateStripeChangePlansProrationQueryVariables,
  APITypes.CalculateStripeChangePlansProrationQuery
>;
