import Box from '@mui/material/Box';

export default function Decoration() {
   return (
    <Box
        sx={{
          position: 'absolute',
          zIndex: '-1',
          top: 0,
          left: 0,
          width: '100%',
          height: '170px',
          overflow: 'hidden',
          background: 'linear-gradient(0, rgb(26 28 38) 0%, rgb(11 12 17) 100%)',
        }}
      / >
  );
}