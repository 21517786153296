/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const removeTelegramMember = /* GraphQL */ `mutation RemoveTelegramMember($input: RemoveTelegramMemberInput) {
  removeTelegramMember(input: $input)
}
` as GeneratedMutation<
  APITypes.RemoveTelegramMemberMutationVariables,
  APITypes.RemoveTelegramMemberMutation
>;
export const createAd = /* GraphQL */ `mutation CreateAd($input: CreateAdInput!, $condition: ModelAdConditionInput) {
  createAd(input: $input, condition: $condition) {
    id
    title
    frequency
    startDate
    endDate
    dates
    telegramChannels
    telegramMessages {
      photo
      message
      callbackActions {
        url
        text
        __typename
      }
      __typename
    }
    adType
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdMutationVariables,
  APITypes.CreateAdMutation
>;
export const updateAd = /* GraphQL */ `mutation UpdateAd($input: UpdateAdInput!, $condition: ModelAdConditionInput) {
  updateAd(input: $input, condition: $condition) {
    id
    title
    frequency
    startDate
    endDate
    dates
    telegramChannels
    telegramMessages {
      photo
      message
      callbackActions {
        url
        text
        __typename
      }
      __typename
    }
    adType
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdMutationVariables,
  APITypes.UpdateAdMutation
>;
export const deleteAd = /* GraphQL */ `mutation DeleteAd($input: DeleteAdInput!, $condition: ModelAdConditionInput) {
  deleteAd(input: $input, condition: $condition) {
    id
    title
    frequency
    startDate
    endDate
    dates
    telegramChannels
    telegramMessages {
      photo
      message
      callbackActions {
        url
        text
        __typename
      }
      __typename
    }
    adType
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdMutationVariables,
  APITypes.DeleteAdMutation
>;
export const createAutoTradingBotConfig = /* GraphQL */ `mutation CreateAutoTradingBotConfig(
  $input: CreateAutoTradingBotConfigInput!
  $condition: ModelAutoTradingBotConfigConditionInput
) {
  createAutoTradingBotConfig(input: $input, condition: $condition) {
    id
    name
    apiKey
    apiSecret
    exchange
    amountToTrade
    quoteAsset
    maxNumTrades
    fixedLeverage
    fixedStopLoss
    type
    status
    username
    user {
      username
      email
      name
      initials
      photo
      lastLogin
      lastVisit
      status
      statusReason
      membership
      permissions
      permissionGroup
      expirationDate
      dateOfRenewal
      memberSince
      termsAndConditions
      secretKey
      telegramGroups {
        id
        name
        channel
        label
        link
        __typename
      }
      telegramId
      searchName
      customerId
      currentPlanPeriod
      currentPlanName
      currentPlanId
      currentPlanPrice
      currentPlanCurrency
      subscriptionId
      markedAsCancelled
      isTrial
      isTestSubscription
      products {
        items {
          productId
          username
          expirationDate
          status
          product {
            id
            slug
            name
            externalReference
            icon
            price
            description
            productType
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAutoTradingBotConfigMutationVariables,
  APITypes.CreateAutoTradingBotConfigMutation
>;
export const updateAutoTradingBotConfig = /* GraphQL */ `mutation UpdateAutoTradingBotConfig(
  $input: UpdateAutoTradingBotConfigInput!
  $condition: ModelAutoTradingBotConfigConditionInput
) {
  updateAutoTradingBotConfig(input: $input, condition: $condition) {
    id
    name
    apiKey
    apiSecret
    exchange
    amountToTrade
    quoteAsset
    maxNumTrades
    fixedLeverage
    fixedStopLoss
    type
    status
    username
    user {
      username
      email
      name
      initials
      photo
      lastLogin
      lastVisit
      status
      statusReason
      membership
      permissions
      permissionGroup
      expirationDate
      dateOfRenewal
      memberSince
      termsAndConditions
      secretKey
      telegramGroups {
        id
        name
        channel
        label
        link
        __typename
      }
      telegramId
      searchName
      customerId
      currentPlanPeriod
      currentPlanName
      currentPlanId
      currentPlanPrice
      currentPlanCurrency
      subscriptionId
      markedAsCancelled
      isTrial
      isTestSubscription
      products {
        items {
          productId
          username
          expirationDate
          status
          product {
            id
            slug
            name
            externalReference
            icon
            price
            description
            productType
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAutoTradingBotConfigMutationVariables,
  APITypes.UpdateAutoTradingBotConfigMutation
>;
export const deleteAutoTradingBotConfig = /* GraphQL */ `mutation DeleteAutoTradingBotConfig(
  $input: DeleteAutoTradingBotConfigInput!
  $condition: ModelAutoTradingBotConfigConditionInput
) {
  deleteAutoTradingBotConfig(input: $input, condition: $condition) {
    id
    name
    apiKey
    apiSecret
    exchange
    amountToTrade
    quoteAsset
    maxNumTrades
    fixedLeverage
    fixedStopLoss
    type
    status
    username
    user {
      username
      email
      name
      initials
      photo
      lastLogin
      lastVisit
      status
      statusReason
      membership
      permissions
      permissionGroup
      expirationDate
      dateOfRenewal
      memberSince
      termsAndConditions
      secretKey
      telegramGroups {
        id
        name
        channel
        label
        link
        __typename
      }
      telegramId
      searchName
      customerId
      currentPlanPeriod
      currentPlanName
      currentPlanId
      currentPlanPrice
      currentPlanCurrency
      subscriptionId
      markedAsCancelled
      isTrial
      isTestSubscription
      products {
        items {
          productId
          username
          expirationDate
          status
          product {
            id
            slug
            name
            externalReference
            icon
            price
            description
            productType
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAutoTradingBotConfigMutationVariables,
  APITypes.DeleteAutoTradingBotConfigMutation
>;
export const createAutoTradingBotTrade = /* GraphQL */ `mutation CreateAutoTradingBotTrade(
  $input: CreateAutoTradingBotTradeInput!
  $condition: ModelAutoTradingBotTradeConditionInput
) {
  createAutoTradingBotTrade(input: $input, condition: $condition) {
    id
    exchange
    type
    tokenName
    symbol
    side
    amount
    stopLossPrice
    entryPrice
    closedPrice
    quantity
    leverage
    createOrderId
    createOrderLinkId
    stopLossOrderId
    closedOrderId
    status
    telegramSignalLink
    pnlAmount
    pnlPercentage
    quoteAsset
    username
    signalID
    signal {
      id
      title
      type
      status
      exchange
      isFutures
      video
      thumbnail
      stopLoss {
        value
        percentage
        hit
        __typename
      }
      trailingStopLoss
      targets {
        value
        percentage
        hit
        __typename
      }
      minRetainer {
        value
        percentage
        hit
        __typename
      }
      description
      entryOnActivation
      entry
      futuresOperation
      leverage
      interval
      tags
      link
      linkLabel
      totalGains
      totalGainsFutures
      lastPrice
      lastMonitorCheck
      takeProfitNum
      breakThroughTime
      breakThroughCount
      showOnFreeGroup
      telegramMessages {
        channel
        id
        link
        __typename
      }
      telegramChannels
      telegramEntryNotificationId
      telegramEntryNotificationDate
      tokenName
      tokenSymbol
      tokenAddress
      createdByName
      createdByUserName
      manualOperation
      isTesting
      useCopyTrade
      referenceId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAutoTradingBotTradeMutationVariables,
  APITypes.CreateAutoTradingBotTradeMutation
>;
export const updateAutoTradingBotTrade = /* GraphQL */ `mutation UpdateAutoTradingBotTrade(
  $input: UpdateAutoTradingBotTradeInput!
  $condition: ModelAutoTradingBotTradeConditionInput
) {
  updateAutoTradingBotTrade(input: $input, condition: $condition) {
    id
    exchange
    type
    tokenName
    symbol
    side
    amount
    stopLossPrice
    entryPrice
    closedPrice
    quantity
    leverage
    createOrderId
    createOrderLinkId
    stopLossOrderId
    closedOrderId
    status
    telegramSignalLink
    pnlAmount
    pnlPercentage
    quoteAsset
    username
    signalID
    signal {
      id
      title
      type
      status
      exchange
      isFutures
      video
      thumbnail
      stopLoss {
        value
        percentage
        hit
        __typename
      }
      trailingStopLoss
      targets {
        value
        percentage
        hit
        __typename
      }
      minRetainer {
        value
        percentage
        hit
        __typename
      }
      description
      entryOnActivation
      entry
      futuresOperation
      leverage
      interval
      tags
      link
      linkLabel
      totalGains
      totalGainsFutures
      lastPrice
      lastMonitorCheck
      takeProfitNum
      breakThroughTime
      breakThroughCount
      showOnFreeGroup
      telegramMessages {
        channel
        id
        link
        __typename
      }
      telegramChannels
      telegramEntryNotificationId
      telegramEntryNotificationDate
      tokenName
      tokenSymbol
      tokenAddress
      createdByName
      createdByUserName
      manualOperation
      isTesting
      useCopyTrade
      referenceId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAutoTradingBotTradeMutationVariables,
  APITypes.UpdateAutoTradingBotTradeMutation
>;
export const deleteAutoTradingBotTrade = /* GraphQL */ `mutation DeleteAutoTradingBotTrade(
  $input: DeleteAutoTradingBotTradeInput!
  $condition: ModelAutoTradingBotTradeConditionInput
) {
  deleteAutoTradingBotTrade(input: $input, condition: $condition) {
    id
    exchange
    type
    tokenName
    symbol
    side
    amount
    stopLossPrice
    entryPrice
    closedPrice
    quantity
    leverage
    createOrderId
    createOrderLinkId
    stopLossOrderId
    closedOrderId
    status
    telegramSignalLink
    pnlAmount
    pnlPercentage
    quoteAsset
    username
    signalID
    signal {
      id
      title
      type
      status
      exchange
      isFutures
      video
      thumbnail
      stopLoss {
        value
        percentage
        hit
        __typename
      }
      trailingStopLoss
      targets {
        value
        percentage
        hit
        __typename
      }
      minRetainer {
        value
        percentage
        hit
        __typename
      }
      description
      entryOnActivation
      entry
      futuresOperation
      leverage
      interval
      tags
      link
      linkLabel
      totalGains
      totalGainsFutures
      lastPrice
      lastMonitorCheck
      takeProfitNum
      breakThroughTime
      breakThroughCount
      showOnFreeGroup
      telegramMessages {
        channel
        id
        link
        __typename
      }
      telegramChannels
      telegramEntryNotificationId
      telegramEntryNotificationDate
      tokenName
      tokenSymbol
      tokenAddress
      createdByName
      createdByUserName
      manualOperation
      isTesting
      useCopyTrade
      referenceId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAutoTradingBotTradeMutationVariables,
  APITypes.DeleteAutoTradingBotTradeMutation
>;
export const createAutoTradingBotHistory = /* GraphQL */ `mutation CreateAutoTradingBotHistory(
  $input: CreateAutoTradingBotHistoryInput!
  $condition: ModelAutoTradingBotHistoryConditionInput
) {
  createAutoTradingBotHistory(input: $input, condition: $condition) {
    id
    exchange
    symbol
    tokenName
    side
    leverage
    action
    content
    payload
    totalGains
    username
    orderID
    type
    signalID
    autoTradingID
    autoTradeInfo {
      id
      exchange
      type
      tokenName
      symbol
      side
      amount
      stopLossPrice
      entryPrice
      closedPrice
      quantity
      leverage
      createOrderId
      createOrderLinkId
      stopLossOrderId
      closedOrderId
      status
      telegramSignalLink
      pnlAmount
      pnlPercentage
      quoteAsset
      username
      signalID
      signal {
        id
        title
        type
        status
        exchange
        isFutures
        video
        thumbnail
        stopLoss {
          value
          percentage
          hit
          __typename
        }
        trailingStopLoss
        targets {
          value
          percentage
          hit
          __typename
        }
        minRetainer {
          value
          percentage
          hit
          __typename
        }
        description
        entryOnActivation
        entry
        futuresOperation
        leverage
        interval
        tags
        link
        linkLabel
        totalGains
        totalGainsFutures
        lastPrice
        lastMonitorCheck
        takeProfitNum
        breakThroughTime
        breakThroughCount
        showOnFreeGroup
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramChannels
        telegramEntryNotificationId
        telegramEntryNotificationDate
        tokenName
        tokenSymbol
        tokenAddress
        createdByName
        createdByUserName
        manualOperation
        isTesting
        useCopyTrade
        referenceId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAutoTradingBotHistoryMutationVariables,
  APITypes.CreateAutoTradingBotHistoryMutation
>;
export const updateAutoTradingBotHistory = /* GraphQL */ `mutation UpdateAutoTradingBotHistory(
  $input: UpdateAutoTradingBotHistoryInput!
  $condition: ModelAutoTradingBotHistoryConditionInput
) {
  updateAutoTradingBotHistory(input: $input, condition: $condition) {
    id
    exchange
    symbol
    tokenName
    side
    leverage
    action
    content
    payload
    totalGains
    username
    orderID
    type
    signalID
    autoTradingID
    autoTradeInfo {
      id
      exchange
      type
      tokenName
      symbol
      side
      amount
      stopLossPrice
      entryPrice
      closedPrice
      quantity
      leverage
      createOrderId
      createOrderLinkId
      stopLossOrderId
      closedOrderId
      status
      telegramSignalLink
      pnlAmount
      pnlPercentage
      quoteAsset
      username
      signalID
      signal {
        id
        title
        type
        status
        exchange
        isFutures
        video
        thumbnail
        stopLoss {
          value
          percentage
          hit
          __typename
        }
        trailingStopLoss
        targets {
          value
          percentage
          hit
          __typename
        }
        minRetainer {
          value
          percentage
          hit
          __typename
        }
        description
        entryOnActivation
        entry
        futuresOperation
        leverage
        interval
        tags
        link
        linkLabel
        totalGains
        totalGainsFutures
        lastPrice
        lastMonitorCheck
        takeProfitNum
        breakThroughTime
        breakThroughCount
        showOnFreeGroup
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramChannels
        telegramEntryNotificationId
        telegramEntryNotificationDate
        tokenName
        tokenSymbol
        tokenAddress
        createdByName
        createdByUserName
        manualOperation
        isTesting
        useCopyTrade
        referenceId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAutoTradingBotHistoryMutationVariables,
  APITypes.UpdateAutoTradingBotHistoryMutation
>;
export const deleteAutoTradingBotHistory = /* GraphQL */ `mutation DeleteAutoTradingBotHistory(
  $input: DeleteAutoTradingBotHistoryInput!
  $condition: ModelAutoTradingBotHistoryConditionInput
) {
  deleteAutoTradingBotHistory(input: $input, condition: $condition) {
    id
    exchange
    symbol
    tokenName
    side
    leverage
    action
    content
    payload
    totalGains
    username
    orderID
    type
    signalID
    autoTradingID
    autoTradeInfo {
      id
      exchange
      type
      tokenName
      symbol
      side
      amount
      stopLossPrice
      entryPrice
      closedPrice
      quantity
      leverage
      createOrderId
      createOrderLinkId
      stopLossOrderId
      closedOrderId
      status
      telegramSignalLink
      pnlAmount
      pnlPercentage
      quoteAsset
      username
      signalID
      signal {
        id
        title
        type
        status
        exchange
        isFutures
        video
        thumbnail
        stopLoss {
          value
          percentage
          hit
          __typename
        }
        trailingStopLoss
        targets {
          value
          percentage
          hit
          __typename
        }
        minRetainer {
          value
          percentage
          hit
          __typename
        }
        description
        entryOnActivation
        entry
        futuresOperation
        leverage
        interval
        tags
        link
        linkLabel
        totalGains
        totalGainsFutures
        lastPrice
        lastMonitorCheck
        takeProfitNum
        breakThroughTime
        breakThroughCount
        showOnFreeGroup
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramChannels
        telegramEntryNotificationId
        telegramEntryNotificationDate
        tokenName
        tokenSymbol
        tokenAddress
        createdByName
        createdByUserName
        manualOperation
        isTesting
        useCopyTrade
        referenceId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAutoTradingBotHistoryMutationVariables,
  APITypes.DeleteAutoTradingBotHistoryMutation
>;
export const createCollection = /* GraphQL */ `mutation CreateCollection(
  $input: CreateCollectionInput!
  $condition: ModelCollectionConditionInput
) {
  createCollection(input: $input, condition: $condition) {
    id
    title
    description
    status
    tags
    contents {
      items {
        id
        slug
        order
        title
        description
        body
        thumbnail
        image
        video
        duration
        date
        attachments {
          file
          url
          text
          mimeType
          __typename
        }
        status
        collectionID
        collection {
          id
          title
          description
          status
          tags
          contents {
            items {
              id
              slug
              order
              title
              description
              body
              thumbnail
              image
              video
              duration
              date
              status
              collectionID
              authorName
              authorUsername
              telegramChannels
              telegramTemplate
              type
              customFields
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          telegramChannels
          telegramTemplate
          thumbnail
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        comments {
          items {
            id
            contentID
            body
            createdByName
            createdByUserName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        authorName
        authorUsername
        telegramChannels
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramTemplate
        type
        customFields
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    telegramChannels
    telegramTemplate
    thumbnail
    order
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCollectionMutationVariables,
  APITypes.CreateCollectionMutation
>;
export const updateCollection = /* GraphQL */ `mutation UpdateCollection(
  $input: UpdateCollectionInput!
  $condition: ModelCollectionConditionInput
) {
  updateCollection(input: $input, condition: $condition) {
    id
    title
    description
    status
    tags
    contents {
      items {
        id
        slug
        order
        title
        description
        body
        thumbnail
        image
        video
        duration
        date
        attachments {
          file
          url
          text
          mimeType
          __typename
        }
        status
        collectionID
        collection {
          id
          title
          description
          status
          tags
          contents {
            items {
              id
              slug
              order
              title
              description
              body
              thumbnail
              image
              video
              duration
              date
              status
              collectionID
              authorName
              authorUsername
              telegramChannels
              telegramTemplate
              type
              customFields
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          telegramChannels
          telegramTemplate
          thumbnail
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        comments {
          items {
            id
            contentID
            body
            createdByName
            createdByUserName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        authorName
        authorUsername
        telegramChannels
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramTemplate
        type
        customFields
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    telegramChannels
    telegramTemplate
    thumbnail
    order
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCollectionMutationVariables,
  APITypes.UpdateCollectionMutation
>;
export const deleteCollection = /* GraphQL */ `mutation DeleteCollection(
  $input: DeleteCollectionInput!
  $condition: ModelCollectionConditionInput
) {
  deleteCollection(input: $input, condition: $condition) {
    id
    title
    description
    status
    tags
    contents {
      items {
        id
        slug
        order
        title
        description
        body
        thumbnail
        image
        video
        duration
        date
        attachments {
          file
          url
          text
          mimeType
          __typename
        }
        status
        collectionID
        collection {
          id
          title
          description
          status
          tags
          contents {
            items {
              id
              slug
              order
              title
              description
              body
              thumbnail
              image
              video
              duration
              date
              status
              collectionID
              authorName
              authorUsername
              telegramChannels
              telegramTemplate
              type
              customFields
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          telegramChannels
          telegramTemplate
          thumbnail
          order
          createdAt
          updatedAt
          owner
          __typename
        }
        comments {
          items {
            id
            contentID
            body
            createdByName
            createdByUserName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        authorName
        authorUsername
        telegramChannels
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramTemplate
        type
        customFields
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    telegramChannels
    telegramTemplate
    thumbnail
    order
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCollectionMutationVariables,
  APITypes.DeleteCollectionMutation
>;
export const createCollectionContent = /* GraphQL */ `mutation CreateCollectionContent(
  $input: CreateCollectionContentInput!
  $condition: ModelCollectionContentConditionInput
) {
  createCollectionContent(input: $input, condition: $condition) {
    id
    slug
    order
    title
    description
    body
    thumbnail
    image
    video
    duration
    date
    attachments {
      file
      url
      text
      mimeType
      __typename
    }
    status
    collectionID
    collection {
      id
      title
      description
      status
      tags
      contents {
        items {
          id
          slug
          order
          title
          description
          body
          thumbnail
          image
          video
          duration
          date
          attachments {
            file
            url
            text
            mimeType
            __typename
          }
          status
          collectionID
          collection {
            id
            title
            description
            status
            tags
            contents {
              nextToken
              __typename
            }
            telegramChannels
            telegramTemplate
            thumbnail
            order
            createdAt
            updatedAt
            owner
            __typename
          }
          comments {
            items {
              id
              contentID
              body
              createdByName
              createdByUserName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          authorName
          authorUsername
          telegramChannels
          telegramMessages {
            channel
            id
            link
            __typename
          }
          telegramTemplate
          type
          customFields
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      telegramChannels
      telegramTemplate
      thumbnail
      order
      createdAt
      updatedAt
      owner
      __typename
    }
    comments {
      items {
        id
        contentID
        body
        createdByName
        createdByUserName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    authorName
    authorUsername
    telegramChannels
    telegramMessages {
      channel
      id
      link
      __typename
    }
    telegramTemplate
    type
    customFields
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCollectionContentMutationVariables,
  APITypes.CreateCollectionContentMutation
>;
export const updateCollectionContent = /* GraphQL */ `mutation UpdateCollectionContent(
  $input: UpdateCollectionContentInput!
  $condition: ModelCollectionContentConditionInput
) {
  updateCollectionContent(input: $input, condition: $condition) {
    id
    slug
    order
    title
    description
    body
    thumbnail
    image
    video
    duration
    date
    attachments {
      file
      url
      text
      mimeType
      __typename
    }
    status
    collectionID
    collection {
      id
      title
      description
      status
      tags
      contents {
        items {
          id
          slug
          order
          title
          description
          body
          thumbnail
          image
          video
          duration
          date
          attachments {
            file
            url
            text
            mimeType
            __typename
          }
          status
          collectionID
          collection {
            id
            title
            description
            status
            tags
            contents {
              nextToken
              __typename
            }
            telegramChannels
            telegramTemplate
            thumbnail
            order
            createdAt
            updatedAt
            owner
            __typename
          }
          comments {
            items {
              id
              contentID
              body
              createdByName
              createdByUserName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          authorName
          authorUsername
          telegramChannels
          telegramMessages {
            channel
            id
            link
            __typename
          }
          telegramTemplate
          type
          customFields
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      telegramChannels
      telegramTemplate
      thumbnail
      order
      createdAt
      updatedAt
      owner
      __typename
    }
    comments {
      items {
        id
        contentID
        body
        createdByName
        createdByUserName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    authorName
    authorUsername
    telegramChannels
    telegramMessages {
      channel
      id
      link
      __typename
    }
    telegramTemplate
    type
    customFields
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCollectionContentMutationVariables,
  APITypes.UpdateCollectionContentMutation
>;
export const deleteCollectionContent = /* GraphQL */ `mutation DeleteCollectionContent(
  $input: DeleteCollectionContentInput!
  $condition: ModelCollectionContentConditionInput
) {
  deleteCollectionContent(input: $input, condition: $condition) {
    id
    slug
    order
    title
    description
    body
    thumbnail
    image
    video
    duration
    date
    attachments {
      file
      url
      text
      mimeType
      __typename
    }
    status
    collectionID
    collection {
      id
      title
      description
      status
      tags
      contents {
        items {
          id
          slug
          order
          title
          description
          body
          thumbnail
          image
          video
          duration
          date
          attachments {
            file
            url
            text
            mimeType
            __typename
          }
          status
          collectionID
          collection {
            id
            title
            description
            status
            tags
            contents {
              nextToken
              __typename
            }
            telegramChannels
            telegramTemplate
            thumbnail
            order
            createdAt
            updatedAt
            owner
            __typename
          }
          comments {
            items {
              id
              contentID
              body
              createdByName
              createdByUserName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          authorName
          authorUsername
          telegramChannels
          telegramMessages {
            channel
            id
            link
            __typename
          }
          telegramTemplate
          type
          customFields
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      telegramChannels
      telegramTemplate
      thumbnail
      order
      createdAt
      updatedAt
      owner
      __typename
    }
    comments {
      items {
        id
        contentID
        body
        createdByName
        createdByUserName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    authorName
    authorUsername
    telegramChannels
    telegramMessages {
      channel
      id
      link
      __typename
    }
    telegramTemplate
    type
    customFields
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCollectionContentMutationVariables,
  APITypes.DeleteCollectionContentMutation
>;
export const createComment = /* GraphQL */ `mutation CreateComment(
  $input: CreateCommentInput!
  $condition: ModelCommentConditionInput
) {
  createComment(input: $input, condition: $condition) {
    id
    contentID
    body
    createdByName
    createdByUserName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentMutationVariables,
  APITypes.CreateCommentMutation
>;
export const updateComment = /* GraphQL */ `mutation UpdateComment(
  $input: UpdateCommentInput!
  $condition: ModelCommentConditionInput
) {
  updateComment(input: $input, condition: $condition) {
    id
    contentID
    body
    createdByName
    createdByUserName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommentMutationVariables,
  APITypes.UpdateCommentMutation
>;
export const deleteComment = /* GraphQL */ `mutation DeleteComment(
  $input: DeleteCommentInput!
  $condition: ModelCommentConditionInput
) {
  deleteComment(input: $input, condition: $condition) {
    id
    contentID
    body
    createdByName
    createdByUserName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommentMutationVariables,
  APITypes.DeleteCommentMutation
>;
export const createLessonProgress = /* GraphQL */ `mutation CreateLessonProgress(
  $input: CreateLessonProgressInput!
  $condition: ModelLessonProgressConditionInput
) {
  createLessonProgress(input: $input, condition: $condition) {
    collectionContentId
    username
    progress
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLessonProgressMutationVariables,
  APITypes.CreateLessonProgressMutation
>;
export const updateLessonProgress = /* GraphQL */ `mutation UpdateLessonProgress(
  $input: UpdateLessonProgressInput!
  $condition: ModelLessonProgressConditionInput
) {
  updateLessonProgress(input: $input, condition: $condition) {
    collectionContentId
    username
    progress
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLessonProgressMutationVariables,
  APITypes.UpdateLessonProgressMutation
>;
export const deleteLessonProgress = /* GraphQL */ `mutation DeleteLessonProgress(
  $input: DeleteLessonProgressInput!
  $condition: ModelLessonProgressConditionInput
) {
  deleteLessonProgress(input: $input, condition: $condition) {
    collectionContentId
    username
    progress
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLessonProgressMutationVariables,
  APITypes.DeleteLessonProgressMutation
>;
export const createCryptofyBotTrade = /* GraphQL */ `mutation CreateCryptofyBotTrade(
  $input: CreateCryptofyBotTradeInput!
  $condition: ModelCryptofyBotTradeConditionInput
) {
  createCryptofyBotTrade(input: $input, condition: $condition) {
    id
    status
    tokenName
    symbol
    exchange
    type
    side
    leverage
    signalEntry
    tradeEntry
    targets {
      value
      percentage
      hit
      __typename
    }
    signalStopLoss {
      value
      percentage
      hit
      __typename
    }
    tradeStopLoss {
      value
      percentage
      hit
      __typename
    }
    peakLossProfit
    currentLossProfit
    telegramSignalLink
    lastMonitorCheck
    lastPrice
    activationManagement {
      crossingTime
      crossingPrice
      wasWithinThreshold
      hasActivated
      __typename
    }
    customFields
    history {
      items {
        id
        action
        message
        tradeId
        trade {
          id
          status
          tokenName
          symbol
          exchange
          type
          side
          leverage
          signalEntry
          tradeEntry
          targets {
            value
            percentage
            hit
            __typename
          }
          signalStopLoss {
            value
            percentage
            hit
            __typename
          }
          tradeStopLoss {
            value
            percentage
            hit
            __typename
          }
          peakLossProfit
          currentLossProfit
          telegramSignalLink
          lastMonitorCheck
          lastPrice
          activationManagement {
            crossingTime
            crossingPrice
            wasWithinThreshold
            hasActivated
            __typename
          }
          customFields
          history {
            items {
              id
              action
              message
              tradeId
              customFields
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signalID
          signal {
            id
            title
            type
            status
            exchange
            isFutures
            video
            thumbnail
            stopLoss {
              value
              percentage
              hit
              __typename
            }
            trailingStopLoss
            targets {
              value
              percentage
              hit
              __typename
            }
            minRetainer {
              value
              percentage
              hit
              __typename
            }
            description
            entryOnActivation
            entry
            futuresOperation
            leverage
            interval
            tags
            link
            linkLabel
            totalGains
            totalGainsFutures
            lastPrice
            lastMonitorCheck
            takeProfitNum
            breakThroughTime
            breakThroughCount
            showOnFreeGroup
            telegramMessages {
              channel
              id
              link
              __typename
            }
            telegramChannels
            telegramEntryNotificationId
            telegramEntryNotificationDate
            tokenName
            tokenSymbol
            tokenAddress
            createdByName
            createdByUserName
            manualOperation
            isTesting
            useCopyTrade
            referenceId
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        customFields
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    signalID
    signal {
      id
      title
      type
      status
      exchange
      isFutures
      video
      thumbnail
      stopLoss {
        value
        percentage
        hit
        __typename
      }
      trailingStopLoss
      targets {
        value
        percentage
        hit
        __typename
      }
      minRetainer {
        value
        percentage
        hit
        __typename
      }
      description
      entryOnActivation
      entry
      futuresOperation
      leverage
      interval
      tags
      link
      linkLabel
      totalGains
      totalGainsFutures
      lastPrice
      lastMonitorCheck
      takeProfitNum
      breakThroughTime
      breakThroughCount
      showOnFreeGroup
      telegramMessages {
        channel
        id
        link
        __typename
      }
      telegramChannels
      telegramEntryNotificationId
      telegramEntryNotificationDate
      tokenName
      tokenSymbol
      tokenAddress
      createdByName
      createdByUserName
      manualOperation
      isTesting
      useCopyTrade
      referenceId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCryptofyBotTradeMutationVariables,
  APITypes.CreateCryptofyBotTradeMutation
>;
export const updateCryptofyBotTrade = /* GraphQL */ `mutation UpdateCryptofyBotTrade(
  $input: UpdateCryptofyBotTradeInput!
  $condition: ModelCryptofyBotTradeConditionInput
) {
  updateCryptofyBotTrade(input: $input, condition: $condition) {
    id
    status
    tokenName
    symbol
    exchange
    type
    side
    leverage
    signalEntry
    tradeEntry
    targets {
      value
      percentage
      hit
      __typename
    }
    signalStopLoss {
      value
      percentage
      hit
      __typename
    }
    tradeStopLoss {
      value
      percentage
      hit
      __typename
    }
    peakLossProfit
    currentLossProfit
    telegramSignalLink
    lastMonitorCheck
    lastPrice
    activationManagement {
      crossingTime
      crossingPrice
      wasWithinThreshold
      hasActivated
      __typename
    }
    customFields
    history {
      items {
        id
        action
        message
        tradeId
        trade {
          id
          status
          tokenName
          symbol
          exchange
          type
          side
          leverage
          signalEntry
          tradeEntry
          targets {
            value
            percentage
            hit
            __typename
          }
          signalStopLoss {
            value
            percentage
            hit
            __typename
          }
          tradeStopLoss {
            value
            percentage
            hit
            __typename
          }
          peakLossProfit
          currentLossProfit
          telegramSignalLink
          lastMonitorCheck
          lastPrice
          activationManagement {
            crossingTime
            crossingPrice
            wasWithinThreshold
            hasActivated
            __typename
          }
          customFields
          history {
            items {
              id
              action
              message
              tradeId
              customFields
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signalID
          signal {
            id
            title
            type
            status
            exchange
            isFutures
            video
            thumbnail
            stopLoss {
              value
              percentage
              hit
              __typename
            }
            trailingStopLoss
            targets {
              value
              percentage
              hit
              __typename
            }
            minRetainer {
              value
              percentage
              hit
              __typename
            }
            description
            entryOnActivation
            entry
            futuresOperation
            leverage
            interval
            tags
            link
            linkLabel
            totalGains
            totalGainsFutures
            lastPrice
            lastMonitorCheck
            takeProfitNum
            breakThroughTime
            breakThroughCount
            showOnFreeGroup
            telegramMessages {
              channel
              id
              link
              __typename
            }
            telegramChannels
            telegramEntryNotificationId
            telegramEntryNotificationDate
            tokenName
            tokenSymbol
            tokenAddress
            createdByName
            createdByUserName
            manualOperation
            isTesting
            useCopyTrade
            referenceId
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        customFields
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    signalID
    signal {
      id
      title
      type
      status
      exchange
      isFutures
      video
      thumbnail
      stopLoss {
        value
        percentage
        hit
        __typename
      }
      trailingStopLoss
      targets {
        value
        percentage
        hit
        __typename
      }
      minRetainer {
        value
        percentage
        hit
        __typename
      }
      description
      entryOnActivation
      entry
      futuresOperation
      leverage
      interval
      tags
      link
      linkLabel
      totalGains
      totalGainsFutures
      lastPrice
      lastMonitorCheck
      takeProfitNum
      breakThroughTime
      breakThroughCount
      showOnFreeGroup
      telegramMessages {
        channel
        id
        link
        __typename
      }
      telegramChannels
      telegramEntryNotificationId
      telegramEntryNotificationDate
      tokenName
      tokenSymbol
      tokenAddress
      createdByName
      createdByUserName
      manualOperation
      isTesting
      useCopyTrade
      referenceId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCryptofyBotTradeMutationVariables,
  APITypes.UpdateCryptofyBotTradeMutation
>;
export const deleteCryptofyBotTrade = /* GraphQL */ `mutation DeleteCryptofyBotTrade(
  $input: DeleteCryptofyBotTradeInput!
  $condition: ModelCryptofyBotTradeConditionInput
) {
  deleteCryptofyBotTrade(input: $input, condition: $condition) {
    id
    status
    tokenName
    symbol
    exchange
    type
    side
    leverage
    signalEntry
    tradeEntry
    targets {
      value
      percentage
      hit
      __typename
    }
    signalStopLoss {
      value
      percentage
      hit
      __typename
    }
    tradeStopLoss {
      value
      percentage
      hit
      __typename
    }
    peakLossProfit
    currentLossProfit
    telegramSignalLink
    lastMonitorCheck
    lastPrice
    activationManagement {
      crossingTime
      crossingPrice
      wasWithinThreshold
      hasActivated
      __typename
    }
    customFields
    history {
      items {
        id
        action
        message
        tradeId
        trade {
          id
          status
          tokenName
          symbol
          exchange
          type
          side
          leverage
          signalEntry
          tradeEntry
          targets {
            value
            percentage
            hit
            __typename
          }
          signalStopLoss {
            value
            percentage
            hit
            __typename
          }
          tradeStopLoss {
            value
            percentage
            hit
            __typename
          }
          peakLossProfit
          currentLossProfit
          telegramSignalLink
          lastMonitorCheck
          lastPrice
          activationManagement {
            crossingTime
            crossingPrice
            wasWithinThreshold
            hasActivated
            __typename
          }
          customFields
          history {
            items {
              id
              action
              message
              tradeId
              customFields
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signalID
          signal {
            id
            title
            type
            status
            exchange
            isFutures
            video
            thumbnail
            stopLoss {
              value
              percentage
              hit
              __typename
            }
            trailingStopLoss
            targets {
              value
              percentage
              hit
              __typename
            }
            minRetainer {
              value
              percentage
              hit
              __typename
            }
            description
            entryOnActivation
            entry
            futuresOperation
            leverage
            interval
            tags
            link
            linkLabel
            totalGains
            totalGainsFutures
            lastPrice
            lastMonitorCheck
            takeProfitNum
            breakThroughTime
            breakThroughCount
            showOnFreeGroup
            telegramMessages {
              channel
              id
              link
              __typename
            }
            telegramChannels
            telegramEntryNotificationId
            telegramEntryNotificationDate
            tokenName
            tokenSymbol
            tokenAddress
            createdByName
            createdByUserName
            manualOperation
            isTesting
            useCopyTrade
            referenceId
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        customFields
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    signalID
    signal {
      id
      title
      type
      status
      exchange
      isFutures
      video
      thumbnail
      stopLoss {
        value
        percentage
        hit
        __typename
      }
      trailingStopLoss
      targets {
        value
        percentage
        hit
        __typename
      }
      minRetainer {
        value
        percentage
        hit
        __typename
      }
      description
      entryOnActivation
      entry
      futuresOperation
      leverage
      interval
      tags
      link
      linkLabel
      totalGains
      totalGainsFutures
      lastPrice
      lastMonitorCheck
      takeProfitNum
      breakThroughTime
      breakThroughCount
      showOnFreeGroup
      telegramMessages {
        channel
        id
        link
        __typename
      }
      telegramChannels
      telegramEntryNotificationId
      telegramEntryNotificationDate
      tokenName
      tokenSymbol
      tokenAddress
      createdByName
      createdByUserName
      manualOperation
      isTesting
      useCopyTrade
      referenceId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCryptofyBotTradeMutationVariables,
  APITypes.DeleteCryptofyBotTradeMutation
>;
export const createCryptofyBotTradeHistory = /* GraphQL */ `mutation CreateCryptofyBotTradeHistory(
  $input: CreateCryptofyBotTradeHistoryInput!
  $condition: ModelCryptofyBotTradeHistoryConditionInput
) {
  createCryptofyBotTradeHistory(input: $input, condition: $condition) {
    id
    action
    message
    tradeId
    trade {
      id
      status
      tokenName
      symbol
      exchange
      type
      side
      leverage
      signalEntry
      tradeEntry
      targets {
        value
        percentage
        hit
        __typename
      }
      signalStopLoss {
        value
        percentage
        hit
        __typename
      }
      tradeStopLoss {
        value
        percentage
        hit
        __typename
      }
      peakLossProfit
      currentLossProfit
      telegramSignalLink
      lastMonitorCheck
      lastPrice
      activationManagement {
        crossingTime
        crossingPrice
        wasWithinThreshold
        hasActivated
        __typename
      }
      customFields
      history {
        items {
          id
          action
          message
          tradeId
          trade {
            id
            status
            tokenName
            symbol
            exchange
            type
            side
            leverage
            signalEntry
            tradeEntry
            targets {
              value
              percentage
              hit
              __typename
            }
            signalStopLoss {
              value
              percentage
              hit
              __typename
            }
            tradeStopLoss {
              value
              percentage
              hit
              __typename
            }
            peakLossProfit
            currentLossProfit
            telegramSignalLink
            lastMonitorCheck
            lastPrice
            activationManagement {
              crossingTime
              crossingPrice
              wasWithinThreshold
              hasActivated
              __typename
            }
            customFields
            history {
              nextToken
              __typename
            }
            signalID
            signal {
              id
              title
              type
              status
              exchange
              isFutures
              video
              thumbnail
              trailingStopLoss
              description
              entryOnActivation
              entry
              futuresOperation
              leverage
              interval
              tags
              link
              linkLabel
              totalGains
              totalGainsFutures
              lastPrice
              lastMonitorCheck
              takeProfitNum
              breakThroughTime
              breakThroughCount
              showOnFreeGroup
              telegramChannels
              telegramEntryNotificationId
              telegramEntryNotificationDate
              tokenName
              tokenSymbol
              tokenAddress
              createdByName
              createdByUserName
              manualOperation
              isTesting
              useCopyTrade
              referenceId
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          customFields
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signalID
      signal {
        id
        title
        type
        status
        exchange
        isFutures
        video
        thumbnail
        stopLoss {
          value
          percentage
          hit
          __typename
        }
        trailingStopLoss
        targets {
          value
          percentage
          hit
          __typename
        }
        minRetainer {
          value
          percentage
          hit
          __typename
        }
        description
        entryOnActivation
        entry
        futuresOperation
        leverage
        interval
        tags
        link
        linkLabel
        totalGains
        totalGainsFutures
        lastPrice
        lastMonitorCheck
        takeProfitNum
        breakThroughTime
        breakThroughCount
        showOnFreeGroup
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramChannels
        telegramEntryNotificationId
        telegramEntryNotificationDate
        tokenName
        tokenSymbol
        tokenAddress
        createdByName
        createdByUserName
        manualOperation
        isTesting
        useCopyTrade
        referenceId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    customFields
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCryptofyBotTradeHistoryMutationVariables,
  APITypes.CreateCryptofyBotTradeHistoryMutation
>;
export const updateCryptofyBotTradeHistory = /* GraphQL */ `mutation UpdateCryptofyBotTradeHistory(
  $input: UpdateCryptofyBotTradeHistoryInput!
  $condition: ModelCryptofyBotTradeHistoryConditionInput
) {
  updateCryptofyBotTradeHistory(input: $input, condition: $condition) {
    id
    action
    message
    tradeId
    trade {
      id
      status
      tokenName
      symbol
      exchange
      type
      side
      leverage
      signalEntry
      tradeEntry
      targets {
        value
        percentage
        hit
        __typename
      }
      signalStopLoss {
        value
        percentage
        hit
        __typename
      }
      tradeStopLoss {
        value
        percentage
        hit
        __typename
      }
      peakLossProfit
      currentLossProfit
      telegramSignalLink
      lastMonitorCheck
      lastPrice
      activationManagement {
        crossingTime
        crossingPrice
        wasWithinThreshold
        hasActivated
        __typename
      }
      customFields
      history {
        items {
          id
          action
          message
          tradeId
          trade {
            id
            status
            tokenName
            symbol
            exchange
            type
            side
            leverage
            signalEntry
            tradeEntry
            targets {
              value
              percentage
              hit
              __typename
            }
            signalStopLoss {
              value
              percentage
              hit
              __typename
            }
            tradeStopLoss {
              value
              percentage
              hit
              __typename
            }
            peakLossProfit
            currentLossProfit
            telegramSignalLink
            lastMonitorCheck
            lastPrice
            activationManagement {
              crossingTime
              crossingPrice
              wasWithinThreshold
              hasActivated
              __typename
            }
            customFields
            history {
              nextToken
              __typename
            }
            signalID
            signal {
              id
              title
              type
              status
              exchange
              isFutures
              video
              thumbnail
              trailingStopLoss
              description
              entryOnActivation
              entry
              futuresOperation
              leverage
              interval
              tags
              link
              linkLabel
              totalGains
              totalGainsFutures
              lastPrice
              lastMonitorCheck
              takeProfitNum
              breakThroughTime
              breakThroughCount
              showOnFreeGroup
              telegramChannels
              telegramEntryNotificationId
              telegramEntryNotificationDate
              tokenName
              tokenSymbol
              tokenAddress
              createdByName
              createdByUserName
              manualOperation
              isTesting
              useCopyTrade
              referenceId
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          customFields
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signalID
      signal {
        id
        title
        type
        status
        exchange
        isFutures
        video
        thumbnail
        stopLoss {
          value
          percentage
          hit
          __typename
        }
        trailingStopLoss
        targets {
          value
          percentage
          hit
          __typename
        }
        minRetainer {
          value
          percentage
          hit
          __typename
        }
        description
        entryOnActivation
        entry
        futuresOperation
        leverage
        interval
        tags
        link
        linkLabel
        totalGains
        totalGainsFutures
        lastPrice
        lastMonitorCheck
        takeProfitNum
        breakThroughTime
        breakThroughCount
        showOnFreeGroup
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramChannels
        telegramEntryNotificationId
        telegramEntryNotificationDate
        tokenName
        tokenSymbol
        tokenAddress
        createdByName
        createdByUserName
        manualOperation
        isTesting
        useCopyTrade
        referenceId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    customFields
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCryptofyBotTradeHistoryMutationVariables,
  APITypes.UpdateCryptofyBotTradeHistoryMutation
>;
export const deleteCryptofyBotTradeHistory = /* GraphQL */ `mutation DeleteCryptofyBotTradeHistory(
  $input: DeleteCryptofyBotTradeHistoryInput!
  $condition: ModelCryptofyBotTradeHistoryConditionInput
) {
  deleteCryptofyBotTradeHistory(input: $input, condition: $condition) {
    id
    action
    message
    tradeId
    trade {
      id
      status
      tokenName
      symbol
      exchange
      type
      side
      leverage
      signalEntry
      tradeEntry
      targets {
        value
        percentage
        hit
        __typename
      }
      signalStopLoss {
        value
        percentage
        hit
        __typename
      }
      tradeStopLoss {
        value
        percentage
        hit
        __typename
      }
      peakLossProfit
      currentLossProfit
      telegramSignalLink
      lastMonitorCheck
      lastPrice
      activationManagement {
        crossingTime
        crossingPrice
        wasWithinThreshold
        hasActivated
        __typename
      }
      customFields
      history {
        items {
          id
          action
          message
          tradeId
          trade {
            id
            status
            tokenName
            symbol
            exchange
            type
            side
            leverage
            signalEntry
            tradeEntry
            targets {
              value
              percentage
              hit
              __typename
            }
            signalStopLoss {
              value
              percentage
              hit
              __typename
            }
            tradeStopLoss {
              value
              percentage
              hit
              __typename
            }
            peakLossProfit
            currentLossProfit
            telegramSignalLink
            lastMonitorCheck
            lastPrice
            activationManagement {
              crossingTime
              crossingPrice
              wasWithinThreshold
              hasActivated
              __typename
            }
            customFields
            history {
              nextToken
              __typename
            }
            signalID
            signal {
              id
              title
              type
              status
              exchange
              isFutures
              video
              thumbnail
              trailingStopLoss
              description
              entryOnActivation
              entry
              futuresOperation
              leverage
              interval
              tags
              link
              linkLabel
              totalGains
              totalGainsFutures
              lastPrice
              lastMonitorCheck
              takeProfitNum
              breakThroughTime
              breakThroughCount
              showOnFreeGroup
              telegramChannels
              telegramEntryNotificationId
              telegramEntryNotificationDate
              tokenName
              tokenSymbol
              tokenAddress
              createdByName
              createdByUserName
              manualOperation
              isTesting
              useCopyTrade
              referenceId
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          customFields
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signalID
      signal {
        id
        title
        type
        status
        exchange
        isFutures
        video
        thumbnail
        stopLoss {
          value
          percentage
          hit
          __typename
        }
        trailingStopLoss
        targets {
          value
          percentage
          hit
          __typename
        }
        minRetainer {
          value
          percentage
          hit
          __typename
        }
        description
        entryOnActivation
        entry
        futuresOperation
        leverage
        interval
        tags
        link
        linkLabel
        totalGains
        totalGainsFutures
        lastPrice
        lastMonitorCheck
        takeProfitNum
        breakThroughTime
        breakThroughCount
        showOnFreeGroup
        telegramMessages {
          channel
          id
          link
          __typename
        }
        telegramChannels
        telegramEntryNotificationId
        telegramEntryNotificationDate
        tokenName
        tokenSymbol
        tokenAddress
        createdByName
        createdByUserName
        manualOperation
        isTesting
        useCopyTrade
        referenceId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    customFields
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCryptofyBotTradeHistoryMutationVariables,
  APITypes.DeleteCryptofyBotTradeHistoryMutation
>;
export const createMarketPlaceProduct = /* GraphQL */ `mutation CreateMarketPlaceProduct(
  $input: CreateMarketPlaceProductInput!
  $condition: ModelMarketPlaceProductConditionInput
) {
  createMarketPlaceProduct(input: $input, condition: $condition) {
    id
    slug
    name
    externalReference
    icon
    price
    description
    productType
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMarketPlaceProductMutationVariables,
  APITypes.CreateMarketPlaceProductMutation
>;
export const updateMarketPlaceProduct = /* GraphQL */ `mutation UpdateMarketPlaceProduct(
  $input: UpdateMarketPlaceProductInput!
  $condition: ModelMarketPlaceProductConditionInput
) {
  updateMarketPlaceProduct(input: $input, condition: $condition) {
    id
    slug
    name
    externalReference
    icon
    price
    description
    productType
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMarketPlaceProductMutationVariables,
  APITypes.UpdateMarketPlaceProductMutation
>;
export const deleteMarketPlaceProduct = /* GraphQL */ `mutation DeleteMarketPlaceProduct(
  $input: DeleteMarketPlaceProductInput!
  $condition: ModelMarketPlaceProductConditionInput
) {
  deleteMarketPlaceProduct(input: $input, condition: $condition) {
    id
    slug
    name
    externalReference
    icon
    price
    description
    productType
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMarketPlaceProductMutationVariables,
  APITypes.DeleteMarketPlaceProductMutation
>;
export const createPriceAlert = /* GraphQL */ `mutation CreatePriceAlert(
  $input: CreatePriceAlertInput!
  $condition: ModelPriceAlertConditionInput
) {
  createPriceAlert(input: $input, condition: $condition) {
    id
    status
    type
    price
    description
    tokenName
    tokenAddress
    tokenSymbol
    triggeredDate
    triggeredPrice
    username
    userEmail
    chain
    isFutures
    createdAlertAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePriceAlertMutationVariables,
  APITypes.CreatePriceAlertMutation
>;
export const updatePriceAlert = /* GraphQL */ `mutation UpdatePriceAlert(
  $input: UpdatePriceAlertInput!
  $condition: ModelPriceAlertConditionInput
) {
  updatePriceAlert(input: $input, condition: $condition) {
    id
    status
    type
    price
    description
    tokenName
    tokenAddress
    tokenSymbol
    triggeredDate
    triggeredPrice
    username
    userEmail
    chain
    isFutures
    createdAlertAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePriceAlertMutationVariables,
  APITypes.UpdatePriceAlertMutation
>;
export const deletePriceAlert = /* GraphQL */ `mutation DeletePriceAlert(
  $input: DeletePriceAlertInput!
  $condition: ModelPriceAlertConditionInput
) {
  deletePriceAlert(input: $input, condition: $condition) {
    id
    status
    type
    price
    description
    tokenName
    tokenAddress
    tokenSymbol
    triggeredDate
    triggeredPrice
    username
    userEmail
    chain
    isFutures
    createdAlertAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePriceAlertMutationVariables,
  APITypes.DeletePriceAlertMutation
>;
export const createSignal = /* GraphQL */ `mutation CreateSignal(
  $input: CreateSignalInput!
  $condition: ModelSignalConditionInput
) {
  createSignal(input: $input, condition: $condition) {
    id
    title
    type
    status
    exchange
    isFutures
    video
    thumbnail
    stopLoss {
      value
      percentage
      hit
      __typename
    }
    trailingStopLoss
    targets {
      value
      percentage
      hit
      __typename
    }
    minRetainer {
      value
      percentage
      hit
      __typename
    }
    description
    entryOnActivation
    entry
    futuresOperation
    leverage
    interval
    tags
    link
    linkLabel
    totalGains
    totalGainsFutures
    lastPrice
    lastMonitorCheck
    takeProfitNum
    breakThroughTime
    breakThroughCount
    showOnFreeGroup
    telegramMessages {
      channel
      id
      link
      __typename
    }
    telegramChannels
    telegramEntryNotificationId
    telegramEntryNotificationDate
    tokenName
    tokenSymbol
    tokenAddress
    createdByName
    createdByUserName
    manualOperation
    isTesting
    useCopyTrade
    referenceId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSignalMutationVariables,
  APITypes.CreateSignalMutation
>;
export const updateSignal = /* GraphQL */ `mutation UpdateSignal(
  $input: UpdateSignalInput!
  $condition: ModelSignalConditionInput
) {
  updateSignal(input: $input, condition: $condition) {
    id
    title
    type
    status
    exchange
    isFutures
    video
    thumbnail
    stopLoss {
      value
      percentage
      hit
      __typename
    }
    trailingStopLoss
    targets {
      value
      percentage
      hit
      __typename
    }
    minRetainer {
      value
      percentage
      hit
      __typename
    }
    description
    entryOnActivation
    entry
    futuresOperation
    leverage
    interval
    tags
    link
    linkLabel
    totalGains
    totalGainsFutures
    lastPrice
    lastMonitorCheck
    takeProfitNum
    breakThroughTime
    breakThroughCount
    showOnFreeGroup
    telegramMessages {
      channel
      id
      link
      __typename
    }
    telegramChannels
    telegramEntryNotificationId
    telegramEntryNotificationDate
    tokenName
    tokenSymbol
    tokenAddress
    createdByName
    createdByUserName
    manualOperation
    isTesting
    useCopyTrade
    referenceId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSignalMutationVariables,
  APITypes.UpdateSignalMutation
>;
export const deleteSignal = /* GraphQL */ `mutation DeleteSignal(
  $input: DeleteSignalInput!
  $condition: ModelSignalConditionInput
) {
  deleteSignal(input: $input, condition: $condition) {
    id
    title
    type
    status
    exchange
    isFutures
    video
    thumbnail
    stopLoss {
      value
      percentage
      hit
      __typename
    }
    trailingStopLoss
    targets {
      value
      percentage
      hit
      __typename
    }
    minRetainer {
      value
      percentage
      hit
      __typename
    }
    description
    entryOnActivation
    entry
    futuresOperation
    leverage
    interval
    tags
    link
    linkLabel
    totalGains
    totalGainsFutures
    lastPrice
    lastMonitorCheck
    takeProfitNum
    breakThroughTime
    breakThroughCount
    showOnFreeGroup
    telegramMessages {
      channel
      id
      link
      __typename
    }
    telegramChannels
    telegramEntryNotificationId
    telegramEntryNotificationDate
    tokenName
    tokenSymbol
    tokenAddress
    createdByName
    createdByUserName
    manualOperation
    isTesting
    useCopyTrade
    referenceId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSignalMutationVariables,
  APITypes.DeleteSignalMutation
>;
export const createTelegramBotState = /* GraphQL */ `mutation CreateTelegramBotState(
  $input: CreateTelegramBotStateInput!
  $condition: ModelTelegramBotStateConditionInput
) {
  createTelegramBotState(input: $input, condition: $condition) {
    telegramId
    nextStep
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTelegramBotStateMutationVariables,
  APITypes.CreateTelegramBotStateMutation
>;
export const updateTelegramBotState = /* GraphQL */ `mutation UpdateTelegramBotState(
  $input: UpdateTelegramBotStateInput!
  $condition: ModelTelegramBotStateConditionInput
) {
  updateTelegramBotState(input: $input, condition: $condition) {
    telegramId
    nextStep
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTelegramBotStateMutationVariables,
  APITypes.UpdateTelegramBotStateMutation
>;
export const deleteTelegramBotState = /* GraphQL */ `mutation DeleteTelegramBotState(
  $input: DeleteTelegramBotStateInput!
  $condition: ModelTelegramBotStateConditionInput
) {
  deleteTelegramBotState(input: $input, condition: $condition) {
    telegramId
    nextStep
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTelegramBotStateMutationVariables,
  APITypes.DeleteTelegramBotStateMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    username
    email
    name
    initials
    photo
    lastLogin
    lastVisit
    status
    statusReason
    membership
    permissions
    permissionGroup
    expirationDate
    dateOfRenewal
    memberSince
    termsAndConditions
    secretKey
    telegramGroups {
      id
      name
      channel
      label
      link
      __typename
    }
    telegramId
    searchName
    customerId
    currentPlanPeriod
    currentPlanName
    currentPlanId
    currentPlanPrice
    currentPlanCurrency
    subscriptionId
    markedAsCancelled
    isTrial
    isTestSubscription
    products {
      items {
        productId
        username
        expirationDate
        status
        product {
          id
          slug
          name
          externalReference
          icon
          price
          description
          productType
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    username
    email
    name
    initials
    photo
    lastLogin
    lastVisit
    status
    statusReason
    membership
    permissions
    permissionGroup
    expirationDate
    dateOfRenewal
    memberSince
    termsAndConditions
    secretKey
    telegramGroups {
      id
      name
      channel
      label
      link
      __typename
    }
    telegramId
    searchName
    customerId
    currentPlanPeriod
    currentPlanName
    currentPlanId
    currentPlanPrice
    currentPlanCurrency
    subscriptionId
    markedAsCancelled
    isTrial
    isTestSubscription
    products {
      items {
        productId
        username
        expirationDate
        status
        product {
          id
          slug
          name
          externalReference
          icon
          price
          description
          productType
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    username
    email
    name
    initials
    photo
    lastLogin
    lastVisit
    status
    statusReason
    membership
    permissions
    permissionGroup
    expirationDate
    dateOfRenewal
    memberSince
    termsAndConditions
    secretKey
    telegramGroups {
      id
      name
      channel
      label
      link
      __typename
    }
    telegramId
    searchName
    customerId
    currentPlanPeriod
    currentPlanName
    currentPlanId
    currentPlanPrice
    currentPlanCurrency
    subscriptionId
    markedAsCancelled
    isTrial
    isTestSubscription
    products {
      items {
        productId
        username
        expirationDate
        status
        product {
          id
          slug
          name
          externalReference
          icon
          price
          description
          productType
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createUserSignInActivity = /* GraphQL */ `mutation CreateUserSignInActivity(
  $input: CreateUserSignInActivityInput!
  $condition: ModelUserSignInActivityConditionInput
) {
  createUserSignInActivity(input: $input, condition: $condition) {
    id
    device
    browser
    ip
    city
    country
    timezone
    date
    username
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserSignInActivityMutationVariables,
  APITypes.CreateUserSignInActivityMutation
>;
export const updateUserSignInActivity = /* GraphQL */ `mutation UpdateUserSignInActivity(
  $input: UpdateUserSignInActivityInput!
  $condition: ModelUserSignInActivityConditionInput
) {
  updateUserSignInActivity(input: $input, condition: $condition) {
    id
    device
    browser
    ip
    city
    country
    timezone
    date
    username
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserSignInActivityMutationVariables,
  APITypes.UpdateUserSignInActivityMutation
>;
export const deleteUserSignInActivity = /* GraphQL */ `mutation DeleteUserSignInActivity(
  $input: DeleteUserSignInActivityInput!
  $condition: ModelUserSignInActivityConditionInput
) {
  deleteUserSignInActivity(input: $input, condition: $condition) {
    id
    device
    browser
    ip
    city
    country
    timezone
    date
    username
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserSignInActivityMutationVariables,
  APITypes.DeleteUserSignInActivityMutation
>;
export const createUserProduct = /* GraphQL */ `mutation CreateUserProduct(
  $input: CreateUserProductInput!
  $condition: ModelUserProductConditionInput
) {
  createUserProduct(input: $input, condition: $condition) {
    productId
    username
    expirationDate
    status
    product {
      id
      slug
      name
      externalReference
      icon
      price
      description
      productType
      status
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserProductMutationVariables,
  APITypes.CreateUserProductMutation
>;
export const updateUserProduct = /* GraphQL */ `mutation UpdateUserProduct(
  $input: UpdateUserProductInput!
  $condition: ModelUserProductConditionInput
) {
  updateUserProduct(input: $input, condition: $condition) {
    productId
    username
    expirationDate
    status
    product {
      id
      slug
      name
      externalReference
      icon
      price
      description
      productType
      status
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserProductMutationVariables,
  APITypes.UpdateUserProductMutation
>;
export const deleteUserProduct = /* GraphQL */ `mutation DeleteUserProduct(
  $input: DeleteUserProductInput!
  $condition: ModelUserProductConditionInput
) {
  deleteUserProduct(input: $input, condition: $condition) {
    productId
    username
    expirationDate
    status
    product {
      id
      slug
      name
      externalReference
      icon
      price
      description
      productType
      status
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserProductMutationVariables,
  APITypes.DeleteUserProductMutation
>;
export const createStripeCheckoutSession = /* GraphQL */ `mutation CreateStripeCheckoutSession(
  $input: CreateStripeCheckoutSessionInput!
) {
  createStripeCheckoutSession(input: $input) {
    sessionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStripeCheckoutSessionMutationVariables,
  APITypes.CreateStripeCheckoutSessionMutation
>;
export const updateStripeSubscription = /* GraphQL */ `mutation UpdateStripeSubscription($input: UpdateStripeSubscriptionInput!) {
  updateStripeSubscription(input: $input)
}
` as GeneratedMutation<
  APITypes.UpdateStripeSubscriptionMutationVariables,
  APITypes.UpdateStripeSubscriptionMutation
>;
export const sendNewsletter = /* GraphQL */ `mutation SendNewsletter($input: SendNewsletterInput) {
  sendNewsletter(input: $input)
}
` as GeneratedMutation<
  APITypes.SendNewsletterMutationVariables,
  APITypes.SendNewsletterMutation
>;
export const subscribeToMailingList = /* GraphQL */ `mutation SubscribeToMailingList($input: SubscribeToMailingListInput) {
  subscribeToMailingList(input: $input) {
    data {
      Code
      Context
      Error
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubscribeToMailingListMutationVariables,
  APITypes.SubscribeToMailingListMutation
>;
