import { CreateMarketPlaceProductResult, DeleteMarketPlaceProductResult, GetMarketPlaceProductResult, ListMarketPlaceProductsResult, MarketPlaceProductRepositoryInterface, UpdateMarketPlaceProductResult } from "./types";
import { API, graphqlOperation } from "aws-amplify";
import { CreateMarketPlaceProductInput, UpdateMarketPlaceProductInput, MarketPlaceProduct } from "../amplify/API";
import { getMarketPlaceProduct, listMarketPlaceProducts, } from "../amplify/graphql/queries";
import { createMarketPlaceProduct, deleteMarketPlaceProduct, updateMarketPlaceProduct } from "../amplify/graphql/mutations";
import orderBy from 'lodash/orderBy';
import { ListParams } from "../factories/types";

export default class MarketPlaceProductRepositoryAmplify implements MarketPlaceProductRepositoryInterface {

  /**
   * Fetch the product details
   */
  async getById(productId: string) {
    const { data } = await API.graphql(
      graphqlOperation(getMarketPlaceProduct, { id: productId })
    ) as GetMarketPlaceProductResult;

    if (!data) {
      throw new Error(`Cant get the details of ${productId}`);
    }

    return data.getMarketPlaceProduct;
  }

  /**
   * Create a product and its details
   */
  async create(input: CreateMarketPlaceProductInput) {
    const { data } = await API.graphql(
      graphqlOperation(createMarketPlaceProduct, { input })
    ) as CreateMarketPlaceProductResult;

    if (!data) {
      throw new Error('Error trying to create new product');
    }

    return data.createMarketPlaceProduct;
  }

  /**
   * Update the product details
   */
  async save(productId: string, input: Partial<UpdateMarketPlaceProductInput>) {
    const { data } = await API.graphql(
      graphqlOperation(updateMarketPlaceProduct, { input: { ...input, id: productId } })
    ) as UpdateMarketPlaceProductResult;

    if (!data) {
      throw new Error(`Error trying to save product ${productId}`);
    }

    return data.updateMarketPlaceProduct;
  }

  /**
   * Delete product
   */
  async delete(productId: string) {
    const { data } = (await API.graphql(
      graphqlOperation(deleteMarketPlaceProduct, { input: { id: productId } })
    )) as DeleteMarketPlaceProductResult;

    if (!data) {
      throw new Error(`ERror trying to delete product ${productId}`);
    }

    return data.deleteMarketPlaceProduct;
  }

  /**
   * Fetch the list of events associated to the tokens
   */
  async list(params?: ListParams, results?: MarketPlaceProduct[]): Promise<MarketPlaceProduct[]> {

    let { limit = 1000, filter = null, nextToken } = params || {};

    const { data } = (await API.graphql(
      graphqlOperation(listMarketPlaceProducts, { limit, filter, nextToken })
    )) as ListMarketPlaceProductsResult;

    if (!data) {
      throw new Error("There was an issue trying to fetch the list");
    }

    const items = [ ...(!results ? [] : results), ...data.listMarketPlaceProducts.items ] as MarketPlaceProduct[];

    if (!data.listMarketPlaceProducts.nextToken) {
      return orderBy(items, ['status', 'createdAt'], ['asc', 'desc'])
    } else {
      return this.list({ ...params, nextToken: data.listMarketPlaceProducts.nextToken }, items) as Promise<MarketPlaceProduct[]>;
    }
  }
}
