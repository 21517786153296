import { CreateAdResult, DeleteAdResult, GetAdResult, ListAdsResult, AdsRepositoryInterface, UpdateAdResult } from "./types";
import { API, graphqlOperation } from "aws-amplify";
import { CreateAdInput, UpdateAdInput, Ad } from "../amplify/API";
import { getAd, listAds, } from "../amplify/graphql/queries";
import { createAd, deleteAd, updateAd } from "../amplify/graphql/mutations";
import orderBy from 'lodash/orderBy';
import { ListParams } from "../factories/types";

export default class AdsRepositoryAmplify implements AdsRepositoryInterface {

  /**
   * Fetch the ad details
   */
  async getById(adId: string) {
    const { data } = await API.graphql(
      graphqlOperation(getAd, { id: adId })
    ) as GetAdResult;

    if (!data) {
      throw new Error(`Could not get the details of ${adId}`);
    }

    return data.getAd;
  }

  /**
   * Create a ad and its details
   */
  async create(input: CreateAdInput) {
    const { data } = await API.graphql(
      graphqlOperation(createAd, { input })
    ) as CreateAdResult;

    if (!data) {
      throw new Error('There was an error trying to create a new ad');
    }

    return data.createAd;
  }

  /**
   * Update the ad details
   */
  async save(adId: string, input: Partial<UpdateAdInput>) {
    const { data } = await API.graphql(
      graphqlOperation(updateAd, { input: { ...input, id: adId } })
    ) as UpdateAdResult;

    if (!data) {
      throw new Error(`There was an error trying to save the ad ${adId}`);
    }

    return data.updateAd;
  }

  /**
   * Delete ad
   */
  async delete(adId: string) {
    const { data } = (await API.graphql(
      graphqlOperation(deleteAd, { input: { id: adId } })
    )) as DeleteAdResult;

    if (!data) {
      throw new Error(`There was an error trying to delete the ad ${adId}`);
    }

    return data.deleteAd;
  }

  /**
   * Fetch the list of events associated to the tokens
   */
  async list(params?: ListParams, results?: Ad[]): Promise<Ad[]> {

    let { limit = 1000, filter = null, nextToken } = params || {};

    const { data } = (await API.graphql(
      graphqlOperation(listAds, { limit, filter, nextToken })
    )) as ListAdsResult;

    if (!data) {
      throw new Error('There was an issue trying to fetch the list');
    }

    const items = [ ...(!results ? [] : results), ...data.listAds.items ] as Ad[];

    if (!data.listAds.nextToken) {
      return orderBy(items, ['status', 'createdAt'], ['asc', 'desc'])
    } else {
      return this.list({ ...params, nextToken: data.listAds.nextToken }, items) as Promise<Ad[]>;
    }
  }
}
