import DashboardIcon from '@mui/icons-material/Dashboard';
import SignalIcon from '@mui/icons-material/CandlestickChart';
import AlertIcon from '@mui/icons-material/NotificationsActive';
import ArticleIcon from '@mui/icons-material/Newspaper';
import CoursesIcon from '@mui/icons-material/School';
import GroupsIcon from '@mui/icons-material/Groups';
import NewsletterIcon from '@mui/icons-material/Feed';
import PatternIcon from '@mui/icons-material/Pattern';

const menuItems = [
  {
    type: 'menu',
    icon: <DashboardIcon color="inherit" />,
    label: 'Dashboard',
    link: '/dashboard',
    isProtected: true,
  },
  {
    type: 'menu',
    icon: <GroupsIcon color="inherit" />,
    label: 'Telegram Groups',
    link: '/telegram-groups',
    // window: 'new',
    isProtected: true,
  },
  {
    type: 'menu',
    icon: <CoursesIcon color="inherit" />,
    label: 'Courses',
    link: '/courses',
    membership: ['PRO', 'PREMIUM'],
    isProtected: true,
  },
  {
    type: 'menu',
    icon: <SignalIcon color="inherit" />,
    label: 'Signals',
    link: '/signals',
    membership: ['PRO', 'PREMIUM'],
    isProtected: true,
    expanded: true,
    hideExpand: true,
    children: [
        {
          type: 'menu',
          label: 'Analytics',
          link: '/signals/analytics',
          membership: ['BASIC', 'PRO', 'PREMIUM'],
          isProtected: true,
        },
        {
          type: 'menu',
          label: 'Latest Signals',
          link: '/signals/latest-signals',
          membership: ['BASIC', 'PRO', 'PREMIUM'],
          isProtected: true,
        },
        {
          type: 'menu',
          label: 'Signal List',
          link: '/signals/list',
          membership: ['BASIC', 'PRO', 'PREMIUM'],
          isProtected: true,
        },
      ]
  },
  {
    type: 'menu',
    icon: <AlertIcon color="inherit" />,
    label: 'Price Alerts',
    link: '/price-alerts',
    membership: ['PRO', 'PREMIUM'],
    isProtected: true,
  },
  {
    type: 'menu',
    icon: <NewsletterIcon color="inherit" />,
    label: 'Newsletters',
    link: '/newsletters',
    membership: ['BASIC', 'PRO', 'PREMIUM'],
    isProtected: true,
  },
  {
    type: 'menu',
    icon: <ArticleIcon color="inherit" />,
    label: 'Daily News',
    link: '/dailynews',
    membership: ['BASIC', 'PRO', 'PREMIUM'],
    isProtected: true,
  },
  {
    type: 'menu',
    icon: <PatternIcon color="inherit" />,
    label: 'Trading Patterns',
    link: '/trading-patterns',
    membership: ['PRO', 'PREMIUM'],
    isProtected: true,
  }
];

export default menuItems;
