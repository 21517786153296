import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import useSubscribeMailingList from '../../../hooks/useSubscribeMailingList';
import { useForm } from "react-hook-form";
import TextField from '../forms/TextField';
import useToastNotification from '../../../hooks/useToastNotification';
import { getErrorMessage } from '../../../lib/helpers';
import { useState } from 'react';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';

type FormData = {
  email: string
}

export default function SignUpUpdates() {
  const [ isSaving, setSaving ] = useState<boolean>();
  const { subscribeToMailingMarketing } = useSubscribeMailingList();
  const { showSuccessNotification, showErrorNotification } = useToastNotification();

  const formActions = useForm<FormData>();

  const handleSubmitForm = async ({ email }: FormData) => {
    setSaving(true);
    try {
      await subscribeToMailingMarketing({ email });
      showSuccessNotification('You have been successfully subscribed to our mailing list');
      formActions.reset({ email: '' });
    } catch(err) {
      console.log(err);
      showErrorNotification(getErrorMessage(err));
    }
    setSaving(false);
  }

  return (
    <form onSubmit={formActions.handleSubmit(handleSubmitForm)} style={{ display: 'block'  }}>
      <Box sx={{ my: 2 }}>
        <SignUpUpdatesInput
          placeholder="Your e-mail address"
          variant="filled"
          name="email"
          autoComplete="off"
          control={formActions.control}
          disabled={isSaving}
          rules={{
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address'
            }
          }}
          InputProps={{
            endAdornment: <Button variant="contained" type="submit" disabled={isSaving}>Subscribe</Button>
          }}
        />
      </Box>
    </form>
  );
}

const SignUpUpdatesInput = styled(TextField)({
  '& .Mui-error': {
    border:'1px solid #e14646 !important'
  },
  '& .MuiFilledInput-root': {
    background: '#3f4245',
    borderRadius: '20px',
    boxShadow: 'unset',
    padding: 0,
    '&:before, &:after': {
      display: 'none'
    }
  },
  '& .MuiInputBase-input': {
    padding: 12,
    paddingLeft: 15
  },
  '& .MuiButtonBase-root': {
    padding: '10px 40px',
    borderRadius: '20px'
  }

});