/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

import { CustomTheme } from ".";


declare module '@mui/material/styles' {

  interface Palette {
    gradient: Palette['primary'];
    mainMenuGradient: Palette['primary'];
    secondaryGradient: Palette['primary'];
    neutral: Palette['primary'];
    primaryContrast: Palette['primary'];
    primaryActive: Palette['primary'];
    secondaryNeutral: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    gradient?: PaletteOptions['primary'];
    mainMenuGradient?: PaletteOptions['primary'];
    secondaryGradient?: PaletteOptions['primary'];
    neutral?: PaletteOptions['primary'];
    primaryContrast?: PaletteOptions['primary'];
    primaryActive?: PaletteOptions['primary'];
    secondaryNeutral?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    gradient: true;
    mainMenuGradient: true;
    secondaryGradient: true;
    neutral: true;
    primaryContrast: true;
    primaryActive: true;
    secondaryNeutral: true;
  }

  interface ButtonPropsVariantOverrides {
    gradient: true;
    mainMenuGradient: true;
    secondaryGradient: true;
    neutral: true;
    primaryContrast: true;
    primaryActive: true;
    secondaryNeutral: true;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    gradient: true;
    mainMenuGradient: true;
    secondaryGradient: true;
    neutral: true;
    primaryContrast: true;
    primaryActive: true;
    secondaryNeutral: true;
  }
}

export default function themePalette(theme: CustomTheme) {
  return {
    mode: theme.mode || 'dark',
    primary: {
      main: '#5d60cc',
    },
    primaryContrast: {
      main: '#3A3B60',
      contrastText: '#fff',
    },
    primaryActive: {
      main: '#0d9488',
    },
    secondary: {
      main: '#da3754',
    },
    neutral: {
      main: '#2c2c2c',
    },
    secondaryNeutral: {
      main: '#64748B',
      contrastText: '#fff',
    },


    gradient: {
      light: 'linear-gradient(45deg,#303f9f,#1976d2)',
      main: 'linear-gradient(45deg,#303f9f,#1976d2)',
      dark: 'linear-gradient(90deg, rgb(26 28 38) 0%, rgb(14 14 14) 100%)'
    },
    secondaryGradient: {
      light: 'linear-gradient(45deg,#303f9f,#1976d2)',
      main: 'linear-gradient(45deg,#303f9f,#1976d2)',
      dark: 'linear-gradient(139.73deg, rgb(49 55 71) 0%, rgb(30 30 30) 100%)'
    },
    mainMenuGradient: {
      light: 'linear-gradient(45deg,#303f9f,#1976d2)',
      main: 'linear-gradient(45deg,#303f9f,#1976d2)',
      dark: 'linear-gradient(149deg, rgb(22 21 21) 0%, rgb(86 85 101) 100%)'
    },
  };
}

