import { UserProduct } from "../amplify/API";
import { AuthUser } from "../auth/types";

// If name is single word, just take first two chars, but if more, then take 1st chars of first and last names
export const getInitials = (name: string) => {
	return name.trim().split(' ').reduce((acc, cur, idx, arr) => acc + (arr.length > 1 ? (idx === 0 || idx === arr.length - 1 ? cur.substring(0, 1) : '') : cur.substring(0, 2)), '').toUpperCase();
}

/**
 * Verify if the user have access to the product that is accessing
 */
export const hasProductPermission = (user: AuthUser, productSlug: string | string[]) => {

  // If its an ADMIN then it should have access to any product
  if (user?.permissionGroup.includes('ADMIN')) return true;

  if (typeof productSlug === 'string')  productSlug = [productSlug];

  const { products } = user;

  const userProductList = products?.items as UserProduct[];
  const userProductListIds = userProductList.reduce((acc, product) => [(product.product?.slug as string), ...acc], [] as string[]);

  const hasProduct = productSlug.filter(product => userProductListIds.includes(product)).length > 0;
  return Boolean(hasProduct);
}