import { Link, Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import AccountPopover from '../AppLayout/AccountPopover';
import useAuth from '../../../hooks/useAuth';

import Logo from '../../../assets/logo-horizontal-dark.png';

type Props = {
  homeLink?: string
}

const HeaderSimple = ({ homeLink = '/' }: Props) => {
  const { authUser } = useAuth();
  const theme = useTheme();

  return (
    <Box position="relative">
      <AppBar
          position="sticky"
          sx={[{
            transition: theme.transitions.create('padding', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            boxShadow: "-5px -3px 8px 0px #000000",
            pr: 2,
          },
          ]}
        >
          <Toolbar disableGutters>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
            {authUser &&
              <Box sx={{ width: 50 }} />
            }
              <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Link href={homeLink}><img src={Logo} alt="Cryptofy Hub" height="50"/></Link>
              </Box>
            </Box>
            {authUser &&
              <Stack sx={{ width: 50, flexGrow: 0 }} flexDirection="row" alignItems="center">
                <AccountPopover simple={true}/>
              </Stack>
            }
          </Toolbar>
      </AppBar>
    </Box>
  );
};
export default HeaderSimple;