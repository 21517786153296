import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useGoogleAnalytics from '../hooks/useGoogleAnalytics';
import MetaPixel from '../lib/helpers/metapixel';

export default function TrackingRoute() {
  const GA = useGoogleAnalytics();

  useEffect(() => {
    GA.sendPageView(window.location.pathname, window.location.search);
    MetaPixel.pageView();

  }, [window.location.pathname]);

  return <Outlet />
}