import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { Box } from "@mui/material";
import { useEffect } from "react";
import useGoogleAnalytics from "../../../hooks/useGoogleAnalytics";

export default function RegisterSuccess() {
  const navigate = useNavigate();
  const GA = useGoogleAnalytics();

  useEffect(() => {
    GA.sendEvent('registration_successful');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
    	<Alert severity="success">
        Your account has been verified!
      </Alert>

      <Button variant="contained" fullWidth onClick={() => navigate('/login')} sx={{ mt: 2}}>
        Login now
      </Button>
    </Box>
  );
}
