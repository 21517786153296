/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type RemoveTelegramMemberInput = {
  action?: string | null,
  params?: RemoveTelegramParticipantsParams | null,
};

export type RemoveTelegramParticipantsParams = {
  channels?: Array< string | null > | null,
  telegramId?: string | null,
};

export type CreateAdInput = {
  id?: string | null,
  title?: string | null,
  frequency?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  dates?: Array< string | null > | null,
  telegramChannels?: Array< TelegramChannel | null > | null,
  telegramMessages?: Array< TelegramMessageInput | null > | null,
  adType?: AdType | null,
  status: AdStatus,
};

export enum TelegramChannel {
  ADMIN_CHANNEL = "ADMIN_CHANNEL",
  SIGNALS_CHANNEL = "SIGNALS_CHANNEL",
  FREE_CHANNEL = "FREE_CHANNEL",
  VIP_CHAT_CHANNEL = "VIP_CHAT_CHANNEL",
  NEWS_CHANNEL = "NEWS_CHANNEL",
  NEWSLETTERS_CHANNEL = "NEWSLETTERS_CHANNEL",
}


export type TelegramMessageInput = {
  photo?: string | null,
  message?: string | null,
  callbackActions?: Array< TelegramCallbackActionsInput | null > | null,
};

export type TelegramCallbackActionsInput = {
  url?: string | null,
  text?: string | null,
};

export enum AdType {
  FREQUENCY = "FREQUENCY",
  DATE = "DATE",
}


export enum AdStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type ModelAdConditionInput = {
  title?: ModelStringInput | null,
  frequency?: ModelIntInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  dates?: ModelStringInput | null,
  telegramChannels?: ModelTelegramChannelListInput | null,
  adType?: ModelAdTypeInput | null,
  status?: ModelAdStatusInput | null,
  and?: Array< ModelAdConditionInput | null > | null,
  or?: Array< ModelAdConditionInput | null > | null,
  not?: ModelAdConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelTelegramChannelListInput = {
  eq?: Array< TelegramChannel | null > | null,
  ne?: Array< TelegramChannel | null > | null,
  contains?: TelegramChannel | null,
  notContains?: TelegramChannel | null,
};

export type ModelAdTypeInput = {
  eq?: AdType | null,
  ne?: AdType | null,
};

export type ModelAdStatusInput = {
  eq?: AdStatus | null,
  ne?: AdStatus | null,
};

export type Ad = {
  __typename: "Ad",
  id: string,
  title?: string | null,
  frequency?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  dates?: Array< string | null > | null,
  telegramChannels?: Array< TelegramChannel | null > | null,
  telegramMessages?:  Array<TelegramMessage | null > | null,
  adType?: AdType | null,
  status: AdStatus,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type TelegramMessage = {
  __typename: "TelegramMessage",
  photo?: string | null,
  message?: string | null,
  callbackActions?:  Array<TelegramCallbackActions | null > | null,
};

export type TelegramCallbackActions = {
  __typename: "TelegramCallbackActions",
  url?: string | null,
  text?: string | null,
};

export type UpdateAdInput = {
  id: string,
  title?: string | null,
  frequency?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  dates?: Array< string | null > | null,
  telegramChannels?: Array< TelegramChannel | null > | null,
  telegramMessages?: Array< TelegramMessageInput | null > | null,
  adType?: AdType | null,
  status?: AdStatus | null,
};

export type DeleteAdInput = {
  id: string,
};

export type CreateAutoTradingBotConfigInput = {
  id?: string | null,
  name: string,
  apiKey: string,
  apiSecret: string,
  exchange?: AutoTradingExchange | null,
  amountToTrade?: number | null,
  quoteAsset?: string | null,
  maxNumTrades?: number | null,
  fixedLeverage?: number | null,
  fixedStopLoss?: number | null,
  type: AutoTradingBotConfigTypes,
  status: AutoTradingBotConfigStatus,
  username: string,
};

export enum AutoTradingExchange {
  BYBIT = "BYBIT",
}


export enum AutoTradingBotConfigTypes {
  SPOT = "SPOT",
  FUTURES = "FUTURES",
}


export enum AutoTradingBotConfigStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type ModelAutoTradingBotConfigConditionInput = {
  name?: ModelStringInput | null,
  apiKey?: ModelStringInput | null,
  apiSecret?: ModelStringInput | null,
  exchange?: ModelAutoTradingExchangeInput | null,
  amountToTrade?: ModelFloatInput | null,
  quoteAsset?: ModelStringInput | null,
  maxNumTrades?: ModelIntInput | null,
  fixedLeverage?: ModelIntInput | null,
  fixedStopLoss?: ModelIntInput | null,
  type?: ModelAutoTradingBotConfigTypesInput | null,
  status?: ModelAutoTradingBotConfigStatusInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelAutoTradingBotConfigConditionInput | null > | null,
  or?: Array< ModelAutoTradingBotConfigConditionInput | null > | null,
  not?: ModelAutoTradingBotConfigConditionInput | null,
};

export type ModelAutoTradingExchangeInput = {
  eq?: AutoTradingExchange | null,
  ne?: AutoTradingExchange | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelAutoTradingBotConfigTypesInput = {
  eq?: AutoTradingBotConfigTypes | null,
  ne?: AutoTradingBotConfigTypes | null,
};

export type ModelAutoTradingBotConfigStatusInput = {
  eq?: AutoTradingBotConfigStatus | null,
  ne?: AutoTradingBotConfigStatus | null,
};

export type AutoTradingBotConfig = {
  __typename: "AutoTradingBotConfig",
  id: string,
  name: string,
  apiKey: string,
  apiSecret: string,
  exchange?: AutoTradingExchange | null,
  amountToTrade?: number | null,
  quoteAsset?: string | null,
  maxNumTrades?: number | null,
  fixedLeverage?: number | null,
  fixedStopLoss?: number | null,
  type: AutoTradingBotConfigTypes,
  status: AutoTradingBotConfigStatus,
  username: string,
  user?: User | null,
  createdAt: string,
  updatedAt: string,
};

export type User = {
  __typename: "User",
  username: string,
  email: string,
  name: string,
  initials?: string | null,
  photo?: string | null,
  lastLogin?: string | null,
  lastVisit?: string | null,
  status: UserStatus,
  statusReason?: string | null,
  membership: Membership,
  permissions?: Array< string | null > | null,
  permissionGroup: UserPermissionGroup,
  expirationDate?: string | null,
  dateOfRenewal?: string | null,
  memberSince?: string | null,
  termsAndConditions?: boolean | null,
  secretKey?: string | null,
  telegramGroups?:  Array<UserTelegramGroupAllowed | null > | null,
  telegramId?: string | null,
  searchName?: string | null,
  customerId?: string | null,
  currentPlanPeriod?: number | null,
  currentPlanName?: string | null,
  currentPlanId?: string | null,
  currentPlanPrice?: number | null,
  currentPlanCurrency?: string | null,
  subscriptionId?: string | null,
  markedAsCancelled?: boolean | null,
  isTrial?: boolean | null,
  isTestSubscription?: boolean | null,
  products?: ModelUserProductConnection | null,
  createdAt: string,
  updatedAt: string,
};

export enum UserStatus {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  INACTIVE = "INACTIVE",
}


export enum Membership {
  ADMIN = "ADMIN",
  BASIC = "BASIC",
  PRO = "PRO",
  PREMIUM = "PREMIUM",
}


export enum UserPermissionGroup {
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
  USER = "USER",
}


export type UserTelegramGroupAllowed = {
  __typename: "UserTelegramGroupAllowed",
  id?: string | null,
  name?: string | null,
  channel?: string | null,
  label?: string | null,
  link?: string | null,
};

export type ModelUserProductConnection = {
  __typename: "ModelUserProductConnection",
  items:  Array<UserProduct | null >,
  nextToken?: string | null,
};

export type UserProduct = {
  __typename: "UserProduct",
  productId: string,
  username: string,
  expirationDate?: string | null,
  status?: UserProductStatus | null,
  product?: MarketPlaceProduct | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export enum UserProductStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type MarketPlaceProduct = {
  __typename: "MarketPlaceProduct",
  id: string,
  slug: string,
  name: string,
  externalReference?: string | null,
  icon?: string | null,
  price?: number | null,
  description?: string | null,
  productType?: MarketPlaceProductType | null,
  status?: MarketPlaceProductStatus | null,
  createdAt: string,
  updatedAt: string,
};

export enum MarketPlaceProductType {
  TELEGRAM_CHANNEL = "TELEGRAM_CHANNEL",
  PRODUCT = "PRODUCT",
}


export enum MarketPlaceProductStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type UpdateAutoTradingBotConfigInput = {
  id: string,
  name?: string | null,
  apiKey?: string | null,
  apiSecret?: string | null,
  exchange?: AutoTradingExchange | null,
  amountToTrade?: number | null,
  quoteAsset?: string | null,
  maxNumTrades?: number | null,
  fixedLeverage?: number | null,
  fixedStopLoss?: number | null,
  type?: AutoTradingBotConfigTypes | null,
  status?: AutoTradingBotConfigStatus | null,
  username?: string | null,
};

export type DeleteAutoTradingBotConfigInput = {
  id: string,
};

export type CreateAutoTradingBotTradeInput = {
  id?: string | null,
  exchange?: AutoTradingExchange | null,
  type?: AutoTradingBotConfigTypes | null,
  tokenName?: string | null,
  symbol?: string | null,
  side?: string | null,
  amount?: number | null,
  stopLossPrice?: number | null,
  entryPrice?: number | null,
  closedPrice?: number | null,
  quantity?: number | null,
  leverage?: number | null,
  createOrderId?: string | null,
  createOrderLinkId?: string | null,
  stopLossOrderId?: string | null,
  closedOrderId?: string | null,
  status?: AutoTradingBotTradeStatus | null,
  telegramSignalLink?: string | null,
  pnlAmount?: number | null,
  pnlPercentage?: number | null,
  quoteAsset?: string | null,
  username?: string | null,
  signalID: string,
};

export enum AutoTradingBotTradeStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
}


export type ModelAutoTradingBotTradeConditionInput = {
  exchange?: ModelAutoTradingExchangeInput | null,
  type?: ModelAutoTradingBotConfigTypesInput | null,
  tokenName?: ModelStringInput | null,
  symbol?: ModelStringInput | null,
  side?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  stopLossPrice?: ModelFloatInput | null,
  entryPrice?: ModelFloatInput | null,
  closedPrice?: ModelFloatInput | null,
  quantity?: ModelFloatInput | null,
  leverage?: ModelIntInput | null,
  createOrderId?: ModelStringInput | null,
  createOrderLinkId?: ModelStringInput | null,
  stopLossOrderId?: ModelStringInput | null,
  closedOrderId?: ModelStringInput | null,
  status?: ModelAutoTradingBotTradeStatusInput | null,
  telegramSignalLink?: ModelStringInput | null,
  pnlAmount?: ModelFloatInput | null,
  pnlPercentage?: ModelFloatInput | null,
  quoteAsset?: ModelStringInput | null,
  username?: ModelStringInput | null,
  signalID?: ModelIDInput | null,
  and?: Array< ModelAutoTradingBotTradeConditionInput | null > | null,
  or?: Array< ModelAutoTradingBotTradeConditionInput | null > | null,
  not?: ModelAutoTradingBotTradeConditionInput | null,
};

export type ModelAutoTradingBotTradeStatusInput = {
  eq?: AutoTradingBotTradeStatus | null,
  ne?: AutoTradingBotTradeStatus | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type AutoTradingBotTrade = {
  __typename: "AutoTradingBotTrade",
  id: string,
  exchange?: AutoTradingExchange | null,
  type?: AutoTradingBotConfigTypes | null,
  tokenName?: string | null,
  symbol?: string | null,
  side?: string | null,
  amount?: number | null,
  stopLossPrice?: number | null,
  entryPrice?: number | null,
  closedPrice?: number | null,
  quantity?: number | null,
  leverage?: number | null,
  createOrderId?: string | null,
  createOrderLinkId?: string | null,
  stopLossOrderId?: string | null,
  closedOrderId?: string | null,
  status?: AutoTradingBotTradeStatus | null,
  telegramSignalLink?: string | null,
  pnlAmount?: number | null,
  pnlPercentage?: number | null,
  quoteAsset?: string | null,
  username?: string | null,
  signalID: string,
  signal?: Signal | null,
  createdAt: string,
  updatedAt: string,
};

export type Signal = {
  __typename: "Signal",
  id: string,
  title?: string | null,
  type?: SignalType | null,
  status: SignalStatus,
  exchange: SignalExchange,
  isFutures?: boolean | null,
  video?: string | null,
  thumbnail?: string | null,
  stopLoss?: SignalTarget | null,
  trailingStopLoss?: number | null,
  targets?:  Array<SignalTarget | null > | null,
  minRetainer?: SignalTarget | null,
  description?: string | null,
  entryOnActivation?: number | null,
  entry?: number | null,
  futuresOperation?: SignalFuturesOperation | null,
  leverage?: number | null,
  interval?: string | null,
  tags?: Array< string | null > | null,
  link?: string | null,
  linkLabel?: string | null,
  totalGains?: number | null,
  totalGainsFutures?: number | null,
  lastPrice?: number | null,
  lastMonitorCheck?: string | null,
  takeProfitNum?: number | null,
  breakThroughTime?: number | null,
  breakThroughCount?: number | null,
  showOnFreeGroup?: boolean | null,
  telegramMessages?:  Array<TelegramMessageResult | null > | null,
  telegramChannels?: Array< TelegramChannel | null > | null,
  telegramEntryNotificationId?: number | null,
  telegramEntryNotificationDate?: string | null,
  tokenName?: string | null,
  tokenSymbol?: string | null,
  tokenAddress?: string | null,
  createdByName?: string | null,
  createdByUserName?: string | null,
  manualOperation?: boolean | null,
  isTesting?: boolean | null,
  useCopyTrade?: boolean | null,
  referenceId?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export enum SignalType {
  SCALPING = "SCALPING",
  DAY_TRADE = "DAY_TRADE",
  SWING_TRADE = "SWING_TRADE",
}


export enum SignalStatus {
  CLOSED = "CLOSED",
  INVALID = "INVALID",
  ACTIVE = "ACTIVE",
  WAITING_REACTION = "WAITING_REACTION",
  WAITING_BREAK = "WAITING_BREAK",
}


export enum SignalExchange {
  BINANCE = "BINANCE",
  BYBIT = "BYBIT",
}


export type SignalTarget = {
  __typename: "SignalTarget",
  value?: number | null,
  percentage?: number | null,
  hit?: boolean | null,
};

export enum SignalFuturesOperation {
  LONG = "LONG",
  SHORT = "SHORT",
}


export type TelegramMessageResult = {
  __typename: "TelegramMessageResult",
  channel?: string | null,
  id?: string | null,
  link?: string | null,
};

export type UpdateAutoTradingBotTradeInput = {
  id: string,
  exchange?: AutoTradingExchange | null,
  type?: AutoTradingBotConfigTypes | null,
  tokenName?: string | null,
  symbol?: string | null,
  side?: string | null,
  amount?: number | null,
  stopLossPrice?: number | null,
  entryPrice?: number | null,
  closedPrice?: number | null,
  quantity?: number | null,
  leverage?: number | null,
  createOrderId?: string | null,
  createOrderLinkId?: string | null,
  stopLossOrderId?: string | null,
  closedOrderId?: string | null,
  status?: AutoTradingBotTradeStatus | null,
  telegramSignalLink?: string | null,
  pnlAmount?: number | null,
  pnlPercentage?: number | null,
  quoteAsset?: string | null,
  username?: string | null,
  signalID?: string | null,
};

export type DeleteAutoTradingBotTradeInput = {
  id: string,
};

export type CreateAutoTradingBotHistoryInput = {
  id?: string | null,
  exchange?: AutoTradingExchange | null,
  symbol?: string | null,
  tokenName?: string | null,
  side?: string | null,
  leverage?: number | null,
  action?: string | null,
  content?: string | null,
  payload?: string | null,
  totalGains?: number | null,
  username?: string | null,
  orderID?: string | null,
  type?: AutoTradingBotHistoryType | null,
  signalID?: string | null,
  autoTradingID?: string | null,
};

export enum AutoTradingBotHistoryType {
  ERROR = "ERROR",
  TRADE = "TRADE",
}


export type ModelAutoTradingBotHistoryConditionInput = {
  exchange?: ModelAutoTradingExchangeInput | null,
  symbol?: ModelStringInput | null,
  tokenName?: ModelStringInput | null,
  side?: ModelStringInput | null,
  leverage?: ModelIntInput | null,
  action?: ModelStringInput | null,
  content?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  totalGains?: ModelFloatInput | null,
  username?: ModelStringInput | null,
  orderID?: ModelStringInput | null,
  type?: ModelAutoTradingBotHistoryTypeInput | null,
  signalID?: ModelIDInput | null,
  autoTradingID?: ModelIDInput | null,
  and?: Array< ModelAutoTradingBotHistoryConditionInput | null > | null,
  or?: Array< ModelAutoTradingBotHistoryConditionInput | null > | null,
  not?: ModelAutoTradingBotHistoryConditionInput | null,
};

export type ModelAutoTradingBotHistoryTypeInput = {
  eq?: AutoTradingBotHistoryType | null,
  ne?: AutoTradingBotHistoryType | null,
};

export type AutoTradingBotHistory = {
  __typename: "AutoTradingBotHistory",
  id: string,
  exchange?: AutoTradingExchange | null,
  symbol?: string | null,
  tokenName?: string | null,
  side?: string | null,
  leverage?: number | null,
  action?: string | null,
  content?: string | null,
  payload?: string | null,
  totalGains?: number | null,
  username?: string | null,
  orderID?: string | null,
  type?: AutoTradingBotHistoryType | null,
  signalID?: string | null,
  autoTradingID?: string | null,
  autoTradeInfo?: AutoTradingBotTrade | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAutoTradingBotHistoryInput = {
  id: string,
  exchange?: AutoTradingExchange | null,
  symbol?: string | null,
  tokenName?: string | null,
  side?: string | null,
  leverage?: number | null,
  action?: string | null,
  content?: string | null,
  payload?: string | null,
  totalGains?: number | null,
  username?: string | null,
  orderID?: string | null,
  type?: AutoTradingBotHistoryType | null,
  signalID?: string | null,
  autoTradingID?: string | null,
};

export type DeleteAutoTradingBotHistoryInput = {
  id: string,
};

export type CreateCollectionInput = {
  id?: string | null,
  title: string,
  description: string,
  status: CollectionStatus,
  tags?: Array< string | null > | null,
  telegramChannels?: Array< TelegramChannel | null > | null,
  telegramTemplate?: string | null,
  thumbnail?: string | null,
  order?: number | null,
};

export enum CollectionStatus {
  DRAFT = "DRAFT",
  SCHEDULED = "SCHEDULED",
  PUBLISHED = "PUBLISHED",
}


export type ModelCollectionConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelCollectionStatusInput | null,
  tags?: ModelStringInput | null,
  telegramChannels?: ModelTelegramChannelListInput | null,
  telegramTemplate?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  order?: ModelFloatInput | null,
  and?: Array< ModelCollectionConditionInput | null > | null,
  or?: Array< ModelCollectionConditionInput | null > | null,
  not?: ModelCollectionConditionInput | null,
};

export type ModelCollectionStatusInput = {
  eq?: CollectionStatus | null,
  ne?: CollectionStatus | null,
};

export type Collection = {
  __typename: "Collection",
  id: string,
  title: string,
  description: string,
  status: CollectionStatus,
  tags?: Array< string | null > | null,
  contents?: ModelCollectionContentConnection | null,
  telegramChannels?: Array< TelegramChannel | null > | null,
  telegramTemplate?: string | null,
  thumbnail?: string | null,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelCollectionContentConnection = {
  __typename: "ModelCollectionContentConnection",
  items:  Array<CollectionContent | null >,
  nextToken?: string | null,
};

export type CollectionContent = {
  __typename: "CollectionContent",
  id: string,
  slug?: string | null,
  order: number,
  title: string,
  description: string,
  body?: string | null,
  thumbnail?: string | null,
  image?: string | null,
  video?: string | null,
  duration?: number | null,
  date?: string | null,
  attachments?:  Array<CollectionAttachment | null > | null,
  status: CollectionStatus,
  collectionID: string,
  collection?: Collection | null,
  comments?: ModelCommentConnection | null,
  authorName?: string | null,
  authorUsername?: string | null,
  telegramChannels?: Array< TelegramChannel | null > | null,
  telegramMessages?:  Array<TelegramMessageResult | null > | null,
  telegramTemplate?: string | null,
  type?: CollectionContentType | null,
  customFields?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type CollectionAttachment = {
  __typename: "CollectionAttachment",
  file?: string | null,
  url?: string | null,
  text?: string | null,
  mimeType?: string | null,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  contentID: string,
  body: string,
  createdByName?: string | null,
  createdByUserName?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export enum CollectionContentType {
  CONTENT = "CONTENT",
  MODULE = "MODULE",
}


export type UpdateCollectionInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  status?: CollectionStatus | null,
  tags?: Array< string | null > | null,
  telegramChannels?: Array< TelegramChannel | null > | null,
  telegramTemplate?: string | null,
  thumbnail?: string | null,
  order?: number | null,
};

export type DeleteCollectionInput = {
  id: string,
};

export type CreateCollectionContentInput = {
  id?: string | null,
  slug?: string | null,
  order: number,
  title: string,
  description: string,
  body?: string | null,
  thumbnail?: string | null,
  image?: string | null,
  video?: string | null,
  duration?: number | null,
  date?: string | null,
  attachments?: Array< CollectionAttachmentInput | null > | null,
  status: CollectionStatus,
  collectionID: string,
  authorName?: string | null,
  authorUsername?: string | null,
  telegramChannels?: Array< TelegramChannel | null > | null,
  telegramMessages?: Array< TelegramMessageResultInput | null > | null,
  telegramTemplate?: string | null,
  type?: CollectionContentType | null,
  customFields?: string | null,
};

export type CollectionAttachmentInput = {
  file?: string | null,
  url?: string | null,
  text?: string | null,
  mimeType?: string | null,
};

export type TelegramMessageResultInput = {
  channel?: string | null,
  id?: string | null,
  link?: string | null,
};

export type ModelCollectionContentConditionInput = {
  slug?: ModelStringInput | null,
  order?: ModelFloatInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  body?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  image?: ModelStringInput | null,
  video?: ModelStringInput | null,
  duration?: ModelFloatInput | null,
  date?: ModelStringInput | null,
  status?: ModelCollectionStatusInput | null,
  collectionID?: ModelIDInput | null,
  authorName?: ModelStringInput | null,
  authorUsername?: ModelStringInput | null,
  telegramChannels?: ModelTelegramChannelListInput | null,
  telegramTemplate?: ModelStringInput | null,
  type?: ModelCollectionContentTypeInput | null,
  customFields?: ModelStringInput | null,
  and?: Array< ModelCollectionContentConditionInput | null > | null,
  or?: Array< ModelCollectionContentConditionInput | null > | null,
  not?: ModelCollectionContentConditionInput | null,
};

export type ModelCollectionContentTypeInput = {
  eq?: CollectionContentType | null,
  ne?: CollectionContentType | null,
};

export type UpdateCollectionContentInput = {
  id: string,
  slug?: string | null,
  order?: number | null,
  title?: string | null,
  description?: string | null,
  body?: string | null,
  thumbnail?: string | null,
  image?: string | null,
  video?: string | null,
  duration?: number | null,
  date?: string | null,
  attachments?: Array< CollectionAttachmentInput | null > | null,
  status?: CollectionStatus | null,
  collectionID?: string | null,
  authorName?: string | null,
  authorUsername?: string | null,
  telegramChannels?: Array< TelegramChannel | null > | null,
  telegramMessages?: Array< TelegramMessageResultInput | null > | null,
  telegramTemplate?: string | null,
  type?: CollectionContentType | null,
  customFields?: string | null,
};

export type DeleteCollectionContentInput = {
  id: string,
};

export type CreateCommentInput = {
  id?: string | null,
  contentID: string,
  body: string,
  createdByName?: string | null,
  createdByUserName?: string | null,
};

export type ModelCommentConditionInput = {
  contentID?: ModelIDInput | null,
  body?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  createdByUserName?: ModelStringInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
};

export type UpdateCommentInput = {
  id: string,
  contentID?: string | null,
  body?: string | null,
  createdByName?: string | null,
  createdByUserName?: string | null,
};

export type DeleteCommentInput = {
  id: string,
};

export type CreateLessonProgressInput = {
  collectionContentId: string,
  username: string,
  progress?: number | null,
};

export type ModelLessonProgressConditionInput = {
  progress?: ModelFloatInput | null,
  and?: Array< ModelLessonProgressConditionInput | null > | null,
  or?: Array< ModelLessonProgressConditionInput | null > | null,
  not?: ModelLessonProgressConditionInput | null,
};

export type LessonProgress = {
  __typename: "LessonProgress",
  collectionContentId: string,
  username: string,
  progress?: number | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateLessonProgressInput = {
  collectionContentId: string,
  username: string,
  progress?: number | null,
};

export type DeleteLessonProgressInput = {
  collectionContentId: string,
  username: string,
};

export type CreateCryptofyBotTradeInput = {
  id?: string | null,
  status: CryptofyBotTradeStatus,
  tokenName: string,
  symbol: string,
  exchange: SignalExchange,
  type: CryptofyBotTradeTypes,
  side?: CryptofyBotTradeSides | null,
  leverage?: number | null,
  signalEntry?: number | null,
  tradeEntry?: number | null,
  targets?: Array< SignalTargetInput | null > | null,
  signalStopLoss?: SignalTargetInput | null,
  tradeStopLoss?: SignalTargetInput | null,
  peakLossProfit?: number | null,
  currentLossProfit?: number | null,
  telegramSignalLink?: string | null,
  lastMonitorCheck?: string | null,
  lastPrice?: number | null,
  activationManagement?: CryptofyBotActivationManagementInput | null,
  customFields?: string | null,
  signalID?: string | null,
};

export enum CryptofyBotTradeStatus {
  WONT_OPEN = "WONT_OPEN",
  CLOSED_STOPLOSS = "CLOSED_STOPLOSS",
  CLOSED_PROFIT = "CLOSED_PROFIT",
  ACTIVE = "ACTIVE",
  WAITING_REACTION = "WAITING_REACTION",
  WAITING_BREAK = "WAITING_BREAK",
}


export enum CryptofyBotTradeTypes {
  SPOT = "SPOT",
  FUTURES = "FUTURES",
}


export enum CryptofyBotTradeSides {
  LONG = "LONG",
  SHORT = "SHORT",
}


export type SignalTargetInput = {
  value?: number | null,
  percentage?: number | null,
  hit?: boolean | null,
};

export type CryptofyBotActivationManagementInput = {
  crossingTime?: number | null,
  crossingPrice?: number | null,
  wasWithinThreshold?: boolean | null,
  hasActivated?: boolean | null,
};

export type ModelCryptofyBotTradeConditionInput = {
  status?: ModelCryptofyBotTradeStatusInput | null,
  tokenName?: ModelStringInput | null,
  symbol?: ModelStringInput | null,
  exchange?: ModelSignalExchangeInput | null,
  type?: ModelCryptofyBotTradeTypesInput | null,
  side?: ModelCryptofyBotTradeSidesInput | null,
  leverage?: ModelIntInput | null,
  signalEntry?: ModelFloatInput | null,
  tradeEntry?: ModelFloatInput | null,
  peakLossProfit?: ModelFloatInput | null,
  currentLossProfit?: ModelFloatInput | null,
  telegramSignalLink?: ModelStringInput | null,
  lastMonitorCheck?: ModelStringInput | null,
  lastPrice?: ModelFloatInput | null,
  customFields?: ModelStringInput | null,
  signalID?: ModelIDInput | null,
  and?: Array< ModelCryptofyBotTradeConditionInput | null > | null,
  or?: Array< ModelCryptofyBotTradeConditionInput | null > | null,
  not?: ModelCryptofyBotTradeConditionInput | null,
};

export type ModelCryptofyBotTradeStatusInput = {
  eq?: CryptofyBotTradeStatus | null,
  ne?: CryptofyBotTradeStatus | null,
};

export type ModelSignalExchangeInput = {
  eq?: SignalExchange | null,
  ne?: SignalExchange | null,
};

export type ModelCryptofyBotTradeTypesInput = {
  eq?: CryptofyBotTradeTypes | null,
  ne?: CryptofyBotTradeTypes | null,
};

export type ModelCryptofyBotTradeSidesInput = {
  eq?: CryptofyBotTradeSides | null,
  ne?: CryptofyBotTradeSides | null,
};

export type CryptofyBotTrade = {
  __typename: "CryptofyBotTrade",
  id: string,
  status: CryptofyBotTradeStatus,
  tokenName: string,
  symbol: string,
  exchange: SignalExchange,
  type: CryptofyBotTradeTypes,
  side?: CryptofyBotTradeSides | null,
  leverage?: number | null,
  signalEntry?: number | null,
  tradeEntry?: number | null,
  targets?:  Array<SignalTarget | null > | null,
  signalStopLoss?: SignalTarget | null,
  tradeStopLoss?: SignalTarget | null,
  peakLossProfit?: number | null,
  currentLossProfit?: number | null,
  telegramSignalLink?: string | null,
  lastMonitorCheck?: string | null,
  lastPrice?: number | null,
  activationManagement?: CryptofyBotActivationManagement | null,
  customFields?: string | null,
  history?: ModelCryptofyBotTradeHistoryConnection | null,
  signalID?: string | null,
  signal?: Signal | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type CryptofyBotActivationManagement = {
  __typename: "CryptofyBotActivationManagement",
  crossingTime?: number | null,
  crossingPrice?: number | null,
  wasWithinThreshold?: boolean | null,
  hasActivated?: boolean | null,
};

export type ModelCryptofyBotTradeHistoryConnection = {
  __typename: "ModelCryptofyBotTradeHistoryConnection",
  items:  Array<CryptofyBotTradeHistory | null >,
  nextToken?: string | null,
};

export type CryptofyBotTradeHistory = {
  __typename: "CryptofyBotTradeHistory",
  id: string,
  action: CryptofyBotTradeHistoryActions,
  message?: string | null,
  tradeId?: string | null,
  trade?: CryptofyBotTrade | null,
  customFields?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export enum CryptofyBotTradeHistoryActions {
  OPEN_TRADE = "OPEN_TRADE",
  CLOSED_STOPLOSS = "CLOSED_STOPLOSS",
  CLOSED_PROFIT = "CLOSED_PROFIT",
  ADJUSTED_STOPLOSS = "ADJUSTED_STOPLOSS",
  CLOSE_TO_BREAKOUT = "CLOSE_TO_BREAKOUT",
  CLOSE_TO_REACTION = "CLOSE_TO_REACTION",
  FAKE_BREAKOUT = "FAKE_BREAKOUT",
  TARGET_HIT = "TARGET_HIT",
  WONT_OPEN = "WONT_OPEN",
}


export type UpdateCryptofyBotTradeInput = {
  id: string,
  status?: CryptofyBotTradeStatus | null,
  tokenName?: string | null,
  symbol?: string | null,
  exchange?: SignalExchange | null,
  type?: CryptofyBotTradeTypes | null,
  side?: CryptofyBotTradeSides | null,
  leverage?: number | null,
  signalEntry?: number | null,
  tradeEntry?: number | null,
  targets?: Array< SignalTargetInput | null > | null,
  signalStopLoss?: SignalTargetInput | null,
  tradeStopLoss?: SignalTargetInput | null,
  peakLossProfit?: number | null,
  currentLossProfit?: number | null,
  telegramSignalLink?: string | null,
  lastMonitorCheck?: string | null,
  lastPrice?: number | null,
  activationManagement?: CryptofyBotActivationManagementInput | null,
  customFields?: string | null,
  signalID?: string | null,
};

export type DeleteCryptofyBotTradeInput = {
  id: string,
};

export type CreateCryptofyBotTradeHistoryInput = {
  id?: string | null,
  action: CryptofyBotTradeHistoryActions,
  message?: string | null,
  tradeId?: string | null,
  customFields?: string | null,
};

export type ModelCryptofyBotTradeHistoryConditionInput = {
  action?: ModelCryptofyBotTradeHistoryActionsInput | null,
  message?: ModelStringInput | null,
  tradeId?: ModelStringInput | null,
  customFields?: ModelStringInput | null,
  and?: Array< ModelCryptofyBotTradeHistoryConditionInput | null > | null,
  or?: Array< ModelCryptofyBotTradeHistoryConditionInput | null > | null,
  not?: ModelCryptofyBotTradeHistoryConditionInput | null,
};

export type ModelCryptofyBotTradeHistoryActionsInput = {
  eq?: CryptofyBotTradeHistoryActions | null,
  ne?: CryptofyBotTradeHistoryActions | null,
};

export type UpdateCryptofyBotTradeHistoryInput = {
  id: string,
  action?: CryptofyBotTradeHistoryActions | null,
  message?: string | null,
  tradeId?: string | null,
  customFields?: string | null,
};

export type DeleteCryptofyBotTradeHistoryInput = {
  id: string,
};

export type CreateMarketPlaceProductInput = {
  id?: string | null,
  slug: string,
  name: string,
  externalReference?: string | null,
  icon?: string | null,
  price?: number | null,
  description?: string | null,
  productType?: MarketPlaceProductType | null,
  status?: MarketPlaceProductStatus | null,
};

export type ModelMarketPlaceProductConditionInput = {
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  externalReference?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  description?: ModelStringInput | null,
  productType?: ModelMarketPlaceProductTypeInput | null,
  status?: ModelMarketPlaceProductStatusInput | null,
  and?: Array< ModelMarketPlaceProductConditionInput | null > | null,
  or?: Array< ModelMarketPlaceProductConditionInput | null > | null,
  not?: ModelMarketPlaceProductConditionInput | null,
};

export type ModelMarketPlaceProductTypeInput = {
  eq?: MarketPlaceProductType | null,
  ne?: MarketPlaceProductType | null,
};

export type ModelMarketPlaceProductStatusInput = {
  eq?: MarketPlaceProductStatus | null,
  ne?: MarketPlaceProductStatus | null,
};

export type UpdateMarketPlaceProductInput = {
  id: string,
  slug?: string | null,
  name?: string | null,
  externalReference?: string | null,
  icon?: string | null,
  price?: number | null,
  description?: string | null,
  productType?: MarketPlaceProductType | null,
  status?: MarketPlaceProductStatus | null,
};

export type DeleteMarketPlaceProductInput = {
  id: string,
};

export type CreatePriceAlertInput = {
  id?: string | null,
  status: PriceAlertStatus,
  type: PriceAlertType,
  price: string,
  description?: string | null,
  tokenName: string,
  tokenAddress: string,
  tokenSymbol: string,
  triggeredDate?: string | null,
  triggeredPrice?: number | null,
  username?: string | null,
  userEmail?: string | null,
  chain?: string | null,
  isFutures?: boolean | null,
  createdAlertAt?: string | null,
};

export enum PriceAlertStatus {
  TRIGGERED = "TRIGGERED",
  ACTIVE = "ACTIVE",
  INVALID = "INVALID",
}


export enum PriceAlertType {
  RISEABOVE = "RISEABOVE",
  DROPBELOW = "DROPBELOW",
}


export type ModelPriceAlertConditionInput = {
  status?: ModelPriceAlertStatusInput | null,
  type?: ModelPriceAlertTypeInput | null,
  price?: ModelStringInput | null,
  description?: ModelStringInput | null,
  tokenName?: ModelStringInput | null,
  tokenAddress?: ModelStringInput | null,
  tokenSymbol?: ModelStringInput | null,
  triggeredDate?: ModelStringInput | null,
  triggeredPrice?: ModelFloatInput | null,
  username?: ModelStringInput | null,
  userEmail?: ModelStringInput | null,
  chain?: ModelStringInput | null,
  isFutures?: ModelBooleanInput | null,
  createdAlertAt?: ModelStringInput | null,
  and?: Array< ModelPriceAlertConditionInput | null > | null,
  or?: Array< ModelPriceAlertConditionInput | null > | null,
  not?: ModelPriceAlertConditionInput | null,
};

export type ModelPriceAlertStatusInput = {
  eq?: PriceAlertStatus | null,
  ne?: PriceAlertStatus | null,
};

export type ModelPriceAlertTypeInput = {
  eq?: PriceAlertType | null,
  ne?: PriceAlertType | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type PriceAlert = {
  __typename: "PriceAlert",
  id: string,
  status: PriceAlertStatus,
  type: PriceAlertType,
  price: string,
  description?: string | null,
  tokenName: string,
  tokenAddress: string,
  tokenSymbol: string,
  triggeredDate?: string | null,
  triggeredPrice?: number | null,
  username?: string | null,
  userEmail?: string | null,
  chain?: string | null,
  isFutures?: boolean | null,
  createdAlertAt?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdatePriceAlertInput = {
  id: string,
  status?: PriceAlertStatus | null,
  type?: PriceAlertType | null,
  price?: string | null,
  description?: string | null,
  tokenName?: string | null,
  tokenAddress?: string | null,
  tokenSymbol?: string | null,
  triggeredDate?: string | null,
  triggeredPrice?: number | null,
  username?: string | null,
  userEmail?: string | null,
  chain?: string | null,
  isFutures?: boolean | null,
  createdAlertAt?: string | null,
};

export type DeletePriceAlertInput = {
  id: string,
};

export type CreateSignalInput = {
  id?: string | null,
  title?: string | null,
  type?: SignalType | null,
  status: SignalStatus,
  exchange: SignalExchange,
  isFutures?: boolean | null,
  video?: string | null,
  thumbnail?: string | null,
  stopLoss?: SignalTargetInput | null,
  trailingStopLoss?: number | null,
  targets?: Array< SignalTargetInput | null > | null,
  minRetainer?: SignalTargetInput | null,
  description?: string | null,
  entryOnActivation?: number | null,
  entry?: number | null,
  futuresOperation?: SignalFuturesOperation | null,
  leverage?: number | null,
  interval?: string | null,
  tags?: Array< string | null > | null,
  link?: string | null,
  linkLabel?: string | null,
  totalGains?: number | null,
  totalGainsFutures?: number | null,
  lastPrice?: number | null,
  lastMonitorCheck?: string | null,
  takeProfitNum?: number | null,
  breakThroughTime?: number | null,
  breakThroughCount?: number | null,
  showOnFreeGroup?: boolean | null,
  telegramMessages?: Array< TelegramMessageResultInput | null > | null,
  telegramChannels?: Array< TelegramChannel | null > | null,
  telegramEntryNotificationId?: number | null,
  telegramEntryNotificationDate?: string | null,
  tokenName?: string | null,
  tokenSymbol?: string | null,
  tokenAddress?: string | null,
  createdByName?: string | null,
  createdByUserName?: string | null,
  manualOperation?: boolean | null,
  isTesting?: boolean | null,
  useCopyTrade?: boolean | null,
  referenceId?: string | null,
};

export type ModelSignalConditionInput = {
  title?: ModelStringInput | null,
  type?: ModelSignalTypeInput | null,
  status?: ModelSignalStatusInput | null,
  exchange?: ModelSignalExchangeInput | null,
  isFutures?: ModelBooleanInput | null,
  video?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  trailingStopLoss?: ModelFloatInput | null,
  description?: ModelStringInput | null,
  entryOnActivation?: ModelFloatInput | null,
  entry?: ModelFloatInput | null,
  futuresOperation?: ModelSignalFuturesOperationInput | null,
  leverage?: ModelIntInput | null,
  interval?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  link?: ModelStringInput | null,
  linkLabel?: ModelStringInput | null,
  totalGains?: ModelFloatInput | null,
  totalGainsFutures?: ModelFloatInput | null,
  lastPrice?: ModelFloatInput | null,
  lastMonitorCheck?: ModelStringInput | null,
  takeProfitNum?: ModelIntInput | null,
  breakThroughTime?: ModelIntInput | null,
  breakThroughCount?: ModelIntInput | null,
  showOnFreeGroup?: ModelBooleanInput | null,
  telegramChannels?: ModelTelegramChannelListInput | null,
  telegramEntryNotificationId?: ModelIntInput | null,
  telegramEntryNotificationDate?: ModelStringInput | null,
  tokenName?: ModelStringInput | null,
  tokenSymbol?: ModelStringInput | null,
  tokenAddress?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  createdByUserName?: ModelStringInput | null,
  manualOperation?: ModelBooleanInput | null,
  isTesting?: ModelBooleanInput | null,
  useCopyTrade?: ModelBooleanInput | null,
  referenceId?: ModelStringInput | null,
  and?: Array< ModelSignalConditionInput | null > | null,
  or?: Array< ModelSignalConditionInput | null > | null,
  not?: ModelSignalConditionInput | null,
};

export type ModelSignalTypeInput = {
  eq?: SignalType | null,
  ne?: SignalType | null,
};

export type ModelSignalStatusInput = {
  eq?: SignalStatus | null,
  ne?: SignalStatus | null,
};

export type ModelSignalFuturesOperationInput = {
  eq?: SignalFuturesOperation | null,
  ne?: SignalFuturesOperation | null,
};

export type UpdateSignalInput = {
  id: string,
  title?: string | null,
  type?: SignalType | null,
  status?: SignalStatus | null,
  exchange?: SignalExchange | null,
  isFutures?: boolean | null,
  video?: string | null,
  thumbnail?: string | null,
  stopLoss?: SignalTargetInput | null,
  trailingStopLoss?: number | null,
  targets?: Array< SignalTargetInput | null > | null,
  minRetainer?: SignalTargetInput | null,
  description?: string | null,
  entryOnActivation?: number | null,
  entry?: number | null,
  futuresOperation?: SignalFuturesOperation | null,
  leverage?: number | null,
  interval?: string | null,
  tags?: Array< string | null > | null,
  link?: string | null,
  linkLabel?: string | null,
  totalGains?: number | null,
  totalGainsFutures?: number | null,
  lastPrice?: number | null,
  lastMonitorCheck?: string | null,
  takeProfitNum?: number | null,
  breakThroughTime?: number | null,
  breakThroughCount?: number | null,
  showOnFreeGroup?: boolean | null,
  telegramMessages?: Array< TelegramMessageResultInput | null > | null,
  telegramChannels?: Array< TelegramChannel | null > | null,
  telegramEntryNotificationId?: number | null,
  telegramEntryNotificationDate?: string | null,
  tokenName?: string | null,
  tokenSymbol?: string | null,
  tokenAddress?: string | null,
  createdByName?: string | null,
  createdByUserName?: string | null,
  manualOperation?: boolean | null,
  isTesting?: boolean | null,
  useCopyTrade?: boolean | null,
  referenceId?: string | null,
};

export type DeleteSignalInput = {
  id: string,
};

export type CreateTelegramBotStateInput = {
  telegramId: string,
  nextStep?: string | null,
};

export type ModelTelegramBotStateConditionInput = {
  nextStep?: ModelStringInput | null,
  and?: Array< ModelTelegramBotStateConditionInput | null > | null,
  or?: Array< ModelTelegramBotStateConditionInput | null > | null,
  not?: ModelTelegramBotStateConditionInput | null,
};

export type TelegramBotState = {
  __typename: "TelegramBotState",
  telegramId: string,
  nextStep?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTelegramBotStateInput = {
  telegramId: string,
  nextStep?: string | null,
};

export type DeleteTelegramBotStateInput = {
  telegramId: string,
};

export type CreateUserInput = {
  username: string,
  email: string,
  name: string,
  initials?: string | null,
  photo?: string | null,
  lastLogin?: string | null,
  lastVisit?: string | null,
  status: UserStatus,
  statusReason?: string | null,
  membership: Membership,
  permissions?: Array< string | null > | null,
  permissionGroup: UserPermissionGroup,
  expirationDate?: string | null,
  dateOfRenewal?: string | null,
  memberSince?: string | null,
  termsAndConditions?: boolean | null,
  secretKey?: string | null,
  telegramGroups?: Array< UserTelegramGroupAllowedInput | null > | null,
  telegramId?: string | null,
  searchName?: string | null,
  customerId?: string | null,
  currentPlanPeriod?: number | null,
  currentPlanName?: string | null,
  currentPlanId?: string | null,
  currentPlanPrice?: number | null,
  currentPlanCurrency?: string | null,
  subscriptionId?: string | null,
  markedAsCancelled?: boolean | null,
  isTrial?: boolean | null,
  isTestSubscription?: boolean | null,
};

export type UserTelegramGroupAllowedInput = {
  id?: string | null,
  name?: string | null,
  channel?: string | null,
  label?: string | null,
  link?: string | null,
};

export type ModelUserConditionInput = {
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  initials?: ModelStringInput | null,
  photo?: ModelStringInput | null,
  lastLogin?: ModelStringInput | null,
  lastVisit?: ModelStringInput | null,
  status?: ModelUserStatusInput | null,
  statusReason?: ModelStringInput | null,
  membership?: ModelMembershipInput | null,
  permissions?: ModelStringInput | null,
  permissionGroup?: ModelUserPermissionGroupInput | null,
  expirationDate?: ModelStringInput | null,
  dateOfRenewal?: ModelStringInput | null,
  memberSince?: ModelStringInput | null,
  termsAndConditions?: ModelBooleanInput | null,
  secretKey?: ModelStringInput | null,
  telegramId?: ModelStringInput | null,
  searchName?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  currentPlanPeriod?: ModelIntInput | null,
  currentPlanName?: ModelStringInput | null,
  currentPlanId?: ModelStringInput | null,
  currentPlanPrice?: ModelFloatInput | null,
  currentPlanCurrency?: ModelStringInput | null,
  subscriptionId?: ModelStringInput | null,
  markedAsCancelled?: ModelBooleanInput | null,
  isTrial?: ModelBooleanInput | null,
  isTestSubscription?: ModelBooleanInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelUserStatusInput = {
  eq?: UserStatus | null,
  ne?: UserStatus | null,
};

export type ModelMembershipInput = {
  eq?: Membership | null,
  ne?: Membership | null,
};

export type ModelUserPermissionGroupInput = {
  eq?: UserPermissionGroup | null,
  ne?: UserPermissionGroup | null,
};

export type UpdateUserInput = {
  username: string,
  email?: string | null,
  name?: string | null,
  initials?: string | null,
  photo?: string | null,
  lastLogin?: string | null,
  lastVisit?: string | null,
  status?: UserStatus | null,
  statusReason?: string | null,
  membership?: Membership | null,
  permissions?: Array< string | null > | null,
  permissionGroup?: UserPermissionGroup | null,
  expirationDate?: string | null,
  dateOfRenewal?: string | null,
  memberSince?: string | null,
  termsAndConditions?: boolean | null,
  secretKey?: string | null,
  telegramGroups?: Array< UserTelegramGroupAllowedInput | null > | null,
  telegramId?: string | null,
  searchName?: string | null,
  customerId?: string | null,
  currentPlanPeriod?: number | null,
  currentPlanName?: string | null,
  currentPlanId?: string | null,
  currentPlanPrice?: number | null,
  currentPlanCurrency?: string | null,
  subscriptionId?: string | null,
  markedAsCancelled?: boolean | null,
  isTrial?: boolean | null,
  isTestSubscription?: boolean | null,
};

export type DeleteUserInput = {
  username: string,
};

export type CreateUserSignInActivityInput = {
  id?: string | null,
  device?: string | null,
  browser?: string | null,
  ip?: string | null,
  city?: string | null,
  country?: string | null,
  timezone?: string | null,
  date?: string | null,
  username?: string | null,
};

export type ModelUserSignInActivityConditionInput = {
  device?: ModelStringInput | null,
  browser?: ModelStringInput | null,
  ip?: ModelStringInput | null,
  city?: ModelStringInput | null,
  country?: ModelStringInput | null,
  timezone?: ModelStringInput | null,
  date?: ModelStringInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelUserSignInActivityConditionInput | null > | null,
  or?: Array< ModelUserSignInActivityConditionInput | null > | null,
  not?: ModelUserSignInActivityConditionInput | null,
};

export type UserSignInActivity = {
  __typename: "UserSignInActivity",
  id: string,
  device?: string | null,
  browser?: string | null,
  ip?: string | null,
  city?: string | null,
  country?: string | null,
  timezone?: string | null,
  date?: string | null,
  username?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateUserSignInActivityInput = {
  id: string,
  device?: string | null,
  browser?: string | null,
  ip?: string | null,
  city?: string | null,
  country?: string | null,
  timezone?: string | null,
  date?: string | null,
  username?: string | null,
};

export type DeleteUserSignInActivityInput = {
  id: string,
};

export type CreateUserProductInput = {
  productId: string,
  username: string,
  expirationDate?: string | null,
  status?: UserProductStatus | null,
};

export type ModelUserProductConditionInput = {
  expirationDate?: ModelStringInput | null,
  status?: ModelUserProductStatusInput | null,
  and?: Array< ModelUserProductConditionInput | null > | null,
  or?: Array< ModelUserProductConditionInput | null > | null,
  not?: ModelUserProductConditionInput | null,
};

export type ModelUserProductStatusInput = {
  eq?: UserProductStatus | null,
  ne?: UserProductStatus | null,
};

export type UpdateUserProductInput = {
  productId: string,
  username: string,
  expirationDate?: string | null,
  status?: UserProductStatus | null,
};

export type DeleteUserProductInput = {
  productId: string,
  username: string,
};

export type CreateStripeCheckoutSessionInput = {
  mode?: StripeModeTypes | null,
  userEmail?: string | null,
  customerId?: string | null,
  lineItems?: Array< StripeCheckoutLineItems > | null,
  paymentMethodType?: StripePaymentTypes | null,
  allowPromotionCodes?: boolean | null,
  automaticTax?: boolean | null,
  currency?: string | null,
  reference?: string | null,
  cancelURL?: string | null,
  successURL?: string | null,
  freeTrialDays?: number | null,
  isTestSubscription?: boolean | null,
};

export enum StripeModeTypes {
  payment = "payment",
  subscription = "subscription",
}


export type StripeCheckoutLineItems = {
  price: string,
  quantity: number,
};

export enum StripePaymentTypes {
  card = "card",
}


export type CreateStripeCheckoutSessionResult = {
  __typename: "CreateStripeCheckoutSessionResult",
  sessionId: string,
};

export type UpdateStripeSubscriptionInput = {
  subscriptionId: string,
  behaviour: UpdateStripeSubscriptionBehaviours,
  username: string,
  newPlanId?: string | null,
  newPlanName?: string | null,
  prorationDate?: number | null,
  comments?: string | null,
  isTestSubscription?: boolean | null,
};

export enum UpdateStripeSubscriptionBehaviours {
  UPGRADE = "UPGRADE",
  CHANGE_COMMITMENT = "CHANGE_COMMITMENT",
  SCHEDULE_DOWNGRADE = "SCHEDULE_DOWNGRADE",
  CANCEL_SCHEDULED_DOWNGRADE = "CANCEL_SCHEDULED_DOWNGRADE",
  CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION",
  UNCANCEL_SUBSCRIPTION = "UNCANCEL_SUBSCRIPTION",
}


export type SendNewsletterInput = {
  collectionContentId: string,
};

export type SubscribeToMailingListInput = {
  mailingListId: string,
  memberInfo?: MailingListMember | null,
};

export type MailingListMember = {
  email: string,
  name?: string | null,
};

export type SubscribeToMailingListResult = {
  __typename: "SubscribeToMailingListResult",
  data?: SubscribeMailingListData | null,
};

export type SubscribeMailingListData = {
  __typename: "SubscribeMailingListData",
  Code?: number | null,
  Context?: string | null,
  Error?: string | null,
};

export type SearchTokenInput = {
  query: string,
  chain?: string | null,
};

export type SearchToken = {
  __typename: "SearchToken",
  name: string,
  address: string,
  symbol: string,
  decimals?: string | null,
  chain?: string | null,
  tags?: Array< string | null > | null,
};

export type ListTokenFromS3Input = {
  chain?: string | null,
};

export type ListTokenFromS3Result = {
  __typename: "ListTokenFromS3Result",
  address?: string | null,
  name?: string | null,
  symbol?: string | null,
  network?: Array< string | null > | null,
  tags?: Array< string | null > | null,
  decimals?: number | null,
};

export type GetTelegramParticipantsInput = {
  action?: string | null,
  params?: GetTelegramParticipantsParams | null,
};

export type GetTelegramParticipantsParams = {
  channel?: string | null,
};

export type TelegramParticipantsUser = {
  __typename: "TelegramParticipantsUser",
  telegramId: string,
  isAdmin?: boolean | null,
  name?: string | null,
  username?: string | null,
  isBot?: boolean | null,
  phone?: string | null,
  status?: string | null,
};

export type ModelAdFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  frequency?: ModelIntInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  dates?: ModelStringInput | null,
  telegramChannels?: ModelTelegramChannelListInput | null,
  adType?: ModelAdTypeInput | null,
  status?: ModelAdStatusInput | null,
  and?: Array< ModelAdFilterInput | null > | null,
  or?: Array< ModelAdFilterInput | null > | null,
  not?: ModelAdFilterInput | null,
};

export type ModelAdConnection = {
  __typename: "ModelAdConnection",
  items:  Array<Ad | null >,
  nextToken?: string | null,
};

export type ModelAutoTradingBotConfigFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  apiKey?: ModelStringInput | null,
  apiSecret?: ModelStringInput | null,
  exchange?: ModelAutoTradingExchangeInput | null,
  amountToTrade?: ModelFloatInput | null,
  quoteAsset?: ModelStringInput | null,
  maxNumTrades?: ModelIntInput | null,
  fixedLeverage?: ModelIntInput | null,
  fixedStopLoss?: ModelIntInput | null,
  type?: ModelAutoTradingBotConfigTypesInput | null,
  status?: ModelAutoTradingBotConfigStatusInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelAutoTradingBotConfigFilterInput | null > | null,
  or?: Array< ModelAutoTradingBotConfigFilterInput | null > | null,
  not?: ModelAutoTradingBotConfigFilterInput | null,
};

export type ModelAutoTradingBotConfigConnection = {
  __typename: "ModelAutoTradingBotConfigConnection",
  items:  Array<AutoTradingBotConfig | null >,
  nextToken?: string | null,
};

export type ModelAutoTradingBotTradeFilterInput = {
  id?: ModelIDInput | null,
  exchange?: ModelAutoTradingExchangeInput | null,
  type?: ModelAutoTradingBotConfigTypesInput | null,
  tokenName?: ModelStringInput | null,
  symbol?: ModelStringInput | null,
  side?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  stopLossPrice?: ModelFloatInput | null,
  entryPrice?: ModelFloatInput | null,
  closedPrice?: ModelFloatInput | null,
  quantity?: ModelFloatInput | null,
  leverage?: ModelIntInput | null,
  createOrderId?: ModelStringInput | null,
  createOrderLinkId?: ModelStringInput | null,
  stopLossOrderId?: ModelStringInput | null,
  closedOrderId?: ModelStringInput | null,
  status?: ModelAutoTradingBotTradeStatusInput | null,
  telegramSignalLink?: ModelStringInput | null,
  pnlAmount?: ModelFloatInput | null,
  pnlPercentage?: ModelFloatInput | null,
  quoteAsset?: ModelStringInput | null,
  username?: ModelStringInput | null,
  signalID?: ModelIDInput | null,
  and?: Array< ModelAutoTradingBotTradeFilterInput | null > | null,
  or?: Array< ModelAutoTradingBotTradeFilterInput | null > | null,
  not?: ModelAutoTradingBotTradeFilterInput | null,
};

export type ModelAutoTradingBotTradeConnection = {
  __typename: "ModelAutoTradingBotTradeConnection",
  items:  Array<AutoTradingBotTrade | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAutoTradingBotHistoryFilterInput = {
  id?: ModelIDInput | null,
  exchange?: ModelAutoTradingExchangeInput | null,
  symbol?: ModelStringInput | null,
  tokenName?: ModelStringInput | null,
  side?: ModelStringInput | null,
  leverage?: ModelIntInput | null,
  action?: ModelStringInput | null,
  content?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  totalGains?: ModelFloatInput | null,
  username?: ModelStringInput | null,
  orderID?: ModelStringInput | null,
  type?: ModelAutoTradingBotHistoryTypeInput | null,
  signalID?: ModelIDInput | null,
  autoTradingID?: ModelIDInput | null,
  and?: Array< ModelAutoTradingBotHistoryFilterInput | null > | null,
  or?: Array< ModelAutoTradingBotHistoryFilterInput | null > | null,
  not?: ModelAutoTradingBotHistoryFilterInput | null,
};

export type ModelAutoTradingBotHistoryConnection = {
  __typename: "ModelAutoTradingBotHistoryConnection",
  items:  Array<AutoTradingBotHistory | null >,
  nextToken?: string | null,
};

export type ModelCollectionFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelCollectionStatusInput | null,
  tags?: ModelStringInput | null,
  telegramChannels?: ModelTelegramChannelListInput | null,
  telegramTemplate?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  order?: ModelFloatInput | null,
  and?: Array< ModelCollectionFilterInput | null > | null,
  or?: Array< ModelCollectionFilterInput | null > | null,
  not?: ModelCollectionFilterInput | null,
};

export type ModelCollectionConnection = {
  __typename: "ModelCollectionConnection",
  items:  Array<Collection | null >,
  nextToken?: string | null,
};

export type ModelCollectionContentFilterInput = {
  id?: ModelIDInput | null,
  slug?: ModelStringInput | null,
  order?: ModelFloatInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  body?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  image?: ModelStringInput | null,
  video?: ModelStringInput | null,
  duration?: ModelFloatInput | null,
  date?: ModelStringInput | null,
  status?: ModelCollectionStatusInput | null,
  collectionID?: ModelIDInput | null,
  authorName?: ModelStringInput | null,
  authorUsername?: ModelStringInput | null,
  telegramChannels?: ModelTelegramChannelListInput | null,
  telegramTemplate?: ModelStringInput | null,
  type?: ModelCollectionContentTypeInput | null,
  customFields?: ModelStringInput | null,
  and?: Array< ModelCollectionContentFilterInput | null > | null,
  or?: Array< ModelCollectionContentFilterInput | null > | null,
  not?: ModelCollectionContentFilterInput | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  contentID?: ModelIDInput | null,
  body?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  createdByUserName?: ModelStringInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelLessonProgressFilterInput = {
  collectionContentId?: ModelStringInput | null,
  username?: ModelStringInput | null,
  progress?: ModelFloatInput | null,
  and?: Array< ModelLessonProgressFilterInput | null > | null,
  or?: Array< ModelLessonProgressFilterInput | null > | null,
  not?: ModelLessonProgressFilterInput | null,
};

export type ModelLessonProgressConnection = {
  __typename: "ModelLessonProgressConnection",
  items:  Array<LessonProgress | null >,
  nextToken?: string | null,
};

export type ModelCryptofyBotTradeFilterInput = {
  id?: ModelStringInput | null,
  status?: ModelCryptofyBotTradeStatusInput | null,
  tokenName?: ModelStringInput | null,
  symbol?: ModelStringInput | null,
  exchange?: ModelSignalExchangeInput | null,
  type?: ModelCryptofyBotTradeTypesInput | null,
  side?: ModelCryptofyBotTradeSidesInput | null,
  leverage?: ModelIntInput | null,
  signalEntry?: ModelFloatInput | null,
  tradeEntry?: ModelFloatInput | null,
  peakLossProfit?: ModelFloatInput | null,
  currentLossProfit?: ModelFloatInput | null,
  telegramSignalLink?: ModelStringInput | null,
  lastMonitorCheck?: ModelStringInput | null,
  lastPrice?: ModelFloatInput | null,
  customFields?: ModelStringInput | null,
  signalID?: ModelIDInput | null,
  and?: Array< ModelCryptofyBotTradeFilterInput | null > | null,
  or?: Array< ModelCryptofyBotTradeFilterInput | null > | null,
  not?: ModelCryptofyBotTradeFilterInput | null,
};

export type ModelCryptofyBotTradeConnection = {
  __typename: "ModelCryptofyBotTradeConnection",
  items:  Array<CryptofyBotTrade | null >,
  nextToken?: string | null,
};

export type ModelCryptofyBotTradeHistoryFilterInput = {
  id?: ModelIDInput | null,
  action?: ModelCryptofyBotTradeHistoryActionsInput | null,
  message?: ModelStringInput | null,
  tradeId?: ModelStringInput | null,
  customFields?: ModelStringInput | null,
  and?: Array< ModelCryptofyBotTradeHistoryFilterInput | null > | null,
  or?: Array< ModelCryptofyBotTradeHistoryFilterInput | null > | null,
  not?: ModelCryptofyBotTradeHistoryFilterInput | null,
};

export type ModelMarketPlaceProductFilterInput = {
  id?: ModelIDInput | null,
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  externalReference?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  description?: ModelStringInput | null,
  productType?: ModelMarketPlaceProductTypeInput | null,
  status?: ModelMarketPlaceProductStatusInput | null,
  and?: Array< ModelMarketPlaceProductFilterInput | null > | null,
  or?: Array< ModelMarketPlaceProductFilterInput | null > | null,
  not?: ModelMarketPlaceProductFilterInput | null,
};

export type ModelMarketPlaceProductConnection = {
  __typename: "ModelMarketPlaceProductConnection",
  items:  Array<MarketPlaceProduct | null >,
  nextToken?: string | null,
};

export type ModelPriceAlertFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelPriceAlertStatusInput | null,
  type?: ModelPriceAlertTypeInput | null,
  price?: ModelStringInput | null,
  description?: ModelStringInput | null,
  tokenName?: ModelStringInput | null,
  tokenAddress?: ModelStringInput | null,
  tokenSymbol?: ModelStringInput | null,
  triggeredDate?: ModelStringInput | null,
  triggeredPrice?: ModelFloatInput | null,
  username?: ModelStringInput | null,
  userEmail?: ModelStringInput | null,
  chain?: ModelStringInput | null,
  isFutures?: ModelBooleanInput | null,
  createdAlertAt?: ModelStringInput | null,
  and?: Array< ModelPriceAlertFilterInput | null > | null,
  or?: Array< ModelPriceAlertFilterInput | null > | null,
  not?: ModelPriceAlertFilterInput | null,
};

export type ModelPriceAlertConnection = {
  __typename: "ModelPriceAlertConnection",
  items:  Array<PriceAlert | null >,
  nextToken?: string | null,
};

export type ModelSignalFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  type?: ModelSignalTypeInput | null,
  status?: ModelSignalStatusInput | null,
  exchange?: ModelSignalExchangeInput | null,
  isFutures?: ModelBooleanInput | null,
  video?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  trailingStopLoss?: ModelFloatInput | null,
  description?: ModelStringInput | null,
  entryOnActivation?: ModelFloatInput | null,
  entry?: ModelFloatInput | null,
  futuresOperation?: ModelSignalFuturesOperationInput | null,
  leverage?: ModelIntInput | null,
  interval?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  link?: ModelStringInput | null,
  linkLabel?: ModelStringInput | null,
  totalGains?: ModelFloatInput | null,
  totalGainsFutures?: ModelFloatInput | null,
  lastPrice?: ModelFloatInput | null,
  lastMonitorCheck?: ModelStringInput | null,
  takeProfitNum?: ModelIntInput | null,
  breakThroughTime?: ModelIntInput | null,
  breakThroughCount?: ModelIntInput | null,
  showOnFreeGroup?: ModelBooleanInput | null,
  telegramChannels?: ModelTelegramChannelListInput | null,
  telegramEntryNotificationId?: ModelIntInput | null,
  telegramEntryNotificationDate?: ModelStringInput | null,
  tokenName?: ModelStringInput | null,
  tokenSymbol?: ModelStringInput | null,
  tokenAddress?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  createdByUserName?: ModelStringInput | null,
  manualOperation?: ModelBooleanInput | null,
  isTesting?: ModelBooleanInput | null,
  useCopyTrade?: ModelBooleanInput | null,
  referenceId?: ModelStringInput | null,
  and?: Array< ModelSignalFilterInput | null > | null,
  or?: Array< ModelSignalFilterInput | null > | null,
  not?: ModelSignalFilterInput | null,
};

export type ModelSignalConnection = {
  __typename: "ModelSignalConnection",
  items:  Array<Signal | null >,
  nextToken?: string | null,
};

export type ModelTelegramBotStateFilterInput = {
  telegramId?: ModelStringInput | null,
  nextStep?: ModelStringInput | null,
  and?: Array< ModelTelegramBotStateFilterInput | null > | null,
  or?: Array< ModelTelegramBotStateFilterInput | null > | null,
  not?: ModelTelegramBotStateFilterInput | null,
};

export type ModelTelegramBotStateConnection = {
  __typename: "ModelTelegramBotStateConnection",
  items:  Array<TelegramBotState | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  initials?: ModelStringInput | null,
  photo?: ModelStringInput | null,
  lastLogin?: ModelStringInput | null,
  lastVisit?: ModelStringInput | null,
  status?: ModelUserStatusInput | null,
  statusReason?: ModelStringInput | null,
  membership?: ModelMembershipInput | null,
  permissions?: ModelStringInput | null,
  permissionGroup?: ModelUserPermissionGroupInput | null,
  expirationDate?: ModelStringInput | null,
  dateOfRenewal?: ModelStringInput | null,
  memberSince?: ModelStringInput | null,
  termsAndConditions?: ModelBooleanInput | null,
  secretKey?: ModelStringInput | null,
  telegramId?: ModelStringInput | null,
  searchName?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  currentPlanPeriod?: ModelIntInput | null,
  currentPlanName?: ModelStringInput | null,
  currentPlanId?: ModelStringInput | null,
  currentPlanPrice?: ModelFloatInput | null,
  currentPlanCurrency?: ModelStringInput | null,
  subscriptionId?: ModelStringInput | null,
  markedAsCancelled?: ModelBooleanInput | null,
  isTrial?: ModelBooleanInput | null,
  isTestSubscription?: ModelBooleanInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelUserSignInActivityFilterInput = {
  id?: ModelIDInput | null,
  device?: ModelStringInput | null,
  browser?: ModelStringInput | null,
  ip?: ModelStringInput | null,
  city?: ModelStringInput | null,
  country?: ModelStringInput | null,
  timezone?: ModelStringInput | null,
  date?: ModelStringInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelUserSignInActivityFilterInput | null > | null,
  or?: Array< ModelUserSignInActivityFilterInput | null > | null,
  not?: ModelUserSignInActivityFilterInput | null,
};

export type ModelUserSignInActivityConnection = {
  __typename: "ModelUserSignInActivityConnection",
  items:  Array<UserSignInActivity | null >,
  nextToken?: string | null,
};

export type ModelUserProductFilterInput = {
  productId?: ModelIDInput | null,
  username?: ModelStringInput | null,
  expirationDate?: ModelStringInput | null,
  status?: ModelUserProductStatusInput | null,
  and?: Array< ModelUserProductFilterInput | null > | null,
  or?: Array< ModelUserProductFilterInput | null > | null,
  not?: ModelUserProductFilterInput | null,
};

export type TokenPriceInput = {
  tokenAddress: string,
  exchange?: SignalExchange | null,
  isFutures?: boolean | null,
};

export type TokenPriceResult = {
  __typename: "TokenPriceResult",
  price?: number | null,
  symbol?: string | null,
};

export type GetSignalAnalyticsInput = {
  filename?: string | null,
};

export type GetSignalAnalyticsResult = {
  __typename: "GetSignalAnalyticsResult",
  numbers?: string | null,
  topGainers?: string | null,
  trending?: string | null,
  takeVsLoss?: string | null,
};

export type GetHistoricalPriceDataInput = {
  exchange: string,
  pair: string,
  pastMinutes?: number | null,
  dateEnd?: string | null,
  timeframe?: string | null,
  marketType?: string | null,
};

export type GetHistoricalPriceDataItem = {
  __typename: "GetHistoricalPriceDataItem",
  time?: number | null,
  open?: number | null,
  high?: number | null,
  low?: number | null,
  close?: number | null,
  volume?: number | null,
};

export type GetStripeCheckoutSessionInput = {
  sessionId: string,
  isTestSubscription?: boolean | null,
};

export type GetStripeCheckoutSessionResult = {
  __typename: "GetStripeCheckoutSessionResult",
  customerId: string,
  orderId?: string | null,
  name: string,
  email: string,
  total: number,
  address?: CheckoutAddress | null,
  currency: string,
  orderDate: string,
  lineItems?:  Array<GetStripeCheckoutSessionLineItems > | null,
};

export type CheckoutAddress = {
  __typename: "CheckoutAddress",
  city?: string | null,
  country?: string | null,
  line1?: string | null,
  line2?: string | null,
  postal_code?: string | null,
  state?: string | null,
};

export type GetStripeCheckoutSessionLineItems = {
  __typename: "GetStripeCheckoutSessionLineItems",
  id: string,
  amountSubTotal: number,
  amountTotal: number,
  quantity: number,
  currency: string,
  description: string,
  planName?: string | null,
  planInterval?: string | null,
  planId?: string | null,
};

export type GetStripeSubscriptionInput = {
  subscriptionId: string,
  isTestSubscription?: boolean | null,
};

export type GetStripePlansResult = {
  __typename: "GetStripePlansResult",
  plans?:  Array<StripePlan | null > | null,
};

export type StripePlan = {
  __typename: "StripePlan",
  id: string,
  name: string,
  price: number,
  frequency: string,
  interval: number,
  membershipName?: string | null,
  features?: Array< string | null > | null,
  totalPrice?: number | null,
  priority?: number | null,
};

export type CalculateStripeChangePlansProrationInput = {
  subscriptionId: string,
  subscriptionItemId: string,
  customerId: string,
  newPlanId: string,
  isTrial?: boolean | null,
  isTestSubscription?: boolean | null,
};

export type ModelSubscriptionAdFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  frequency?: ModelSubscriptionIntInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  dates?: ModelSubscriptionStringInput | null,
  telegramChannels?: ModelSubscriptionStringInput | null,
  adType?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionAutoTradingBotConfigFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  apiKey?: ModelSubscriptionStringInput | null,
  apiSecret?: ModelSubscriptionStringInput | null,
  exchange?: ModelSubscriptionStringInput | null,
  amountToTrade?: ModelSubscriptionFloatInput | null,
  quoteAsset?: ModelSubscriptionStringInput | null,
  maxNumTrades?: ModelSubscriptionIntInput | null,
  fixedLeverage?: ModelSubscriptionIntInput | null,
  fixedStopLoss?: ModelSubscriptionIntInput | null,
  type?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAutoTradingBotConfigFilterInput | null > | null,
  or?: Array< ModelSubscriptionAutoTradingBotConfigFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionAutoTradingBotTradeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  exchange?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  tokenName?: ModelSubscriptionStringInput | null,
  symbol?: ModelSubscriptionStringInput | null,
  side?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionFloatInput | null,
  stopLossPrice?: ModelSubscriptionFloatInput | null,
  entryPrice?: ModelSubscriptionFloatInput | null,
  closedPrice?: ModelSubscriptionFloatInput | null,
  quantity?: ModelSubscriptionFloatInput | null,
  leverage?: ModelSubscriptionIntInput | null,
  createOrderId?: ModelSubscriptionStringInput | null,
  createOrderLinkId?: ModelSubscriptionStringInput | null,
  stopLossOrderId?: ModelSubscriptionStringInput | null,
  closedOrderId?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  telegramSignalLink?: ModelSubscriptionStringInput | null,
  pnlAmount?: ModelSubscriptionFloatInput | null,
  pnlPercentage?: ModelSubscriptionFloatInput | null,
  quoteAsset?: ModelSubscriptionStringInput | null,
  signalID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionAutoTradingBotTradeFilterInput | null > | null,
  or?: Array< ModelSubscriptionAutoTradingBotTradeFilterInput | null > | null,
};

export type ModelSubscriptionAutoTradingBotHistoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  exchange?: ModelSubscriptionStringInput | null,
  symbol?: ModelSubscriptionStringInput | null,
  tokenName?: ModelSubscriptionStringInput | null,
  side?: ModelSubscriptionStringInput | null,
  leverage?: ModelSubscriptionIntInput | null,
  action?: ModelSubscriptionStringInput | null,
  content?: ModelSubscriptionStringInput | null,
  payload?: ModelSubscriptionStringInput | null,
  totalGains?: ModelSubscriptionFloatInput | null,
  orderID?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  signalID?: ModelSubscriptionIDInput | null,
  autoTradingID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionAutoTradingBotHistoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionAutoTradingBotHistoryFilterInput | null > | null,
};

export type ModelSubscriptionCollectionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  telegramChannels?: ModelSubscriptionStringInput | null,
  telegramTemplate?: ModelSubscriptionStringInput | null,
  thumbnail?: ModelSubscriptionStringInput | null,
  order?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionCollectionFilterInput | null > | null,
  or?: Array< ModelSubscriptionCollectionFilterInput | null > | null,
};

export type ModelSubscriptionCollectionContentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  slug?: ModelSubscriptionStringInput | null,
  order?: ModelSubscriptionFloatInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  body?: ModelSubscriptionStringInput | null,
  thumbnail?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  video?: ModelSubscriptionStringInput | null,
  duration?: ModelSubscriptionFloatInput | null,
  date?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  collectionID?: ModelSubscriptionIDInput | null,
  authorName?: ModelSubscriptionStringInput | null,
  authorUsername?: ModelSubscriptionStringInput | null,
  telegramChannels?: ModelSubscriptionStringInput | null,
  telegramTemplate?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  customFields?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCollectionContentFilterInput | null > | null,
  or?: Array< ModelSubscriptionCollectionContentFilterInput | null > | null,
};

export type ModelSubscriptionCommentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  contentID?: ModelSubscriptionIDInput | null,
  body?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  createdByUserName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCommentFilterInput | null > | null,
  or?: Array< ModelSubscriptionCommentFilterInput | null > | null,
};

export type ModelSubscriptionLessonProgressFilterInput = {
  collectionContentId?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  progress?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionLessonProgressFilterInput | null > | null,
  or?: Array< ModelSubscriptionLessonProgressFilterInput | null > | null,
};

export type ModelSubscriptionCryptofyBotTradeFilterInput = {
  id?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  tokenName?: ModelSubscriptionStringInput | null,
  symbol?: ModelSubscriptionStringInput | null,
  exchange?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  side?: ModelSubscriptionStringInput | null,
  leverage?: ModelSubscriptionIntInput | null,
  signalEntry?: ModelSubscriptionFloatInput | null,
  tradeEntry?: ModelSubscriptionFloatInput | null,
  peakLossProfit?: ModelSubscriptionFloatInput | null,
  currentLossProfit?: ModelSubscriptionFloatInput | null,
  telegramSignalLink?: ModelSubscriptionStringInput | null,
  lastMonitorCheck?: ModelSubscriptionStringInput | null,
  lastPrice?: ModelSubscriptionFloatInput | null,
  customFields?: ModelSubscriptionStringInput | null,
  signalID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCryptofyBotTradeFilterInput | null > | null,
  or?: Array< ModelSubscriptionCryptofyBotTradeFilterInput | null > | null,
};

export type ModelSubscriptionCryptofyBotTradeHistoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  action?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  tradeId?: ModelSubscriptionStringInput | null,
  customFields?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCryptofyBotTradeHistoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionCryptofyBotTradeHistoryFilterInput | null > | null,
};

export type ModelSubscriptionMarketPlaceProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  slug?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  externalReference?: ModelSubscriptionStringInput | null,
  icon?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  description?: ModelSubscriptionStringInput | null,
  productType?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMarketPlaceProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionMarketPlaceProductFilterInput | null > | null,
};

export type ModelSubscriptionPriceAlertFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  tokenName?: ModelSubscriptionStringInput | null,
  tokenAddress?: ModelSubscriptionStringInput | null,
  tokenSymbol?: ModelSubscriptionStringInput | null,
  triggeredDate?: ModelSubscriptionStringInput | null,
  triggeredPrice?: ModelSubscriptionFloatInput | null,
  username?: ModelSubscriptionStringInput | null,
  userEmail?: ModelSubscriptionStringInput | null,
  chain?: ModelSubscriptionStringInput | null,
  isFutures?: ModelSubscriptionBooleanInput | null,
  createdAlertAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPriceAlertFilterInput | null > | null,
  or?: Array< ModelSubscriptionPriceAlertFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionSignalFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  exchange?: ModelSubscriptionStringInput | null,
  isFutures?: ModelSubscriptionBooleanInput | null,
  video?: ModelSubscriptionStringInput | null,
  thumbnail?: ModelSubscriptionStringInput | null,
  trailingStopLoss?: ModelSubscriptionFloatInput | null,
  description?: ModelSubscriptionStringInput | null,
  entryOnActivation?: ModelSubscriptionFloatInput | null,
  entry?: ModelSubscriptionFloatInput | null,
  futuresOperation?: ModelSubscriptionStringInput | null,
  leverage?: ModelSubscriptionIntInput | null,
  interval?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  link?: ModelSubscriptionStringInput | null,
  linkLabel?: ModelSubscriptionStringInput | null,
  totalGains?: ModelSubscriptionFloatInput | null,
  totalGainsFutures?: ModelSubscriptionFloatInput | null,
  lastPrice?: ModelSubscriptionFloatInput | null,
  lastMonitorCheck?: ModelSubscriptionStringInput | null,
  takeProfitNum?: ModelSubscriptionIntInput | null,
  breakThroughTime?: ModelSubscriptionIntInput | null,
  breakThroughCount?: ModelSubscriptionIntInput | null,
  showOnFreeGroup?: ModelSubscriptionBooleanInput | null,
  telegramChannels?: ModelSubscriptionStringInput | null,
  telegramEntryNotificationId?: ModelSubscriptionIntInput | null,
  telegramEntryNotificationDate?: ModelSubscriptionStringInput | null,
  tokenName?: ModelSubscriptionStringInput | null,
  tokenSymbol?: ModelSubscriptionStringInput | null,
  tokenAddress?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  createdByUserName?: ModelSubscriptionStringInput | null,
  manualOperation?: ModelSubscriptionBooleanInput | null,
  isTesting?: ModelSubscriptionBooleanInput | null,
  useCopyTrade?: ModelSubscriptionBooleanInput | null,
  referenceId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSignalFilterInput | null > | null,
  or?: Array< ModelSubscriptionSignalFilterInput | null > | null,
};

export type ModelSubscriptionTelegramBotStateFilterInput = {
  telegramId?: ModelSubscriptionStringInput | null,
  nextStep?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTelegramBotStateFilterInput | null > | null,
  or?: Array< ModelSubscriptionTelegramBotStateFilterInput | null > | null,
};

export type ModelSubscriptionUserSignInActivityFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  device?: ModelSubscriptionStringInput | null,
  browser?: ModelSubscriptionStringInput | null,
  ip?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  timezone?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserSignInActivityFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserSignInActivityFilterInput | null > | null,
};

export type ModelSubscriptionUserProductFilterInput = {
  productId?: ModelSubscriptionIDInput | null,
  username?: ModelSubscriptionStringInput | null,
  expirationDate?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserProductFilterInput | null > | null,
};

export type RemoveTelegramMemberMutationVariables = {
  input?: RemoveTelegramMemberInput | null,
};

export type RemoveTelegramMemberMutation = {
  removeTelegramMember?: boolean | null,
};

export type CreateAdMutationVariables = {
  input: CreateAdInput,
  condition?: ModelAdConditionInput | null,
};

export type CreateAdMutation = {
  createAd?:  {
    __typename: "Ad",
    id: string,
    title?: string | null,
    frequency?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    dates?: Array< string | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessage",
      photo?: string | null,
      message?: string | null,
      callbackActions?:  Array< {
        __typename: "TelegramCallbackActions",
        url?: string | null,
        text?: string | null,
      } | null > | null,
    } | null > | null,
    adType?: AdType | null,
    status: AdStatus,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateAdMutationVariables = {
  input: UpdateAdInput,
  condition?: ModelAdConditionInput | null,
};

export type UpdateAdMutation = {
  updateAd?:  {
    __typename: "Ad",
    id: string,
    title?: string | null,
    frequency?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    dates?: Array< string | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessage",
      photo?: string | null,
      message?: string | null,
      callbackActions?:  Array< {
        __typename: "TelegramCallbackActions",
        url?: string | null,
        text?: string | null,
      } | null > | null,
    } | null > | null,
    adType?: AdType | null,
    status: AdStatus,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteAdMutationVariables = {
  input: DeleteAdInput,
  condition?: ModelAdConditionInput | null,
};

export type DeleteAdMutation = {
  deleteAd?:  {
    __typename: "Ad",
    id: string,
    title?: string | null,
    frequency?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    dates?: Array< string | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessage",
      photo?: string | null,
      message?: string | null,
      callbackActions?:  Array< {
        __typename: "TelegramCallbackActions",
        url?: string | null,
        text?: string | null,
      } | null > | null,
    } | null > | null,
    adType?: AdType | null,
    status: AdStatus,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateAutoTradingBotConfigMutationVariables = {
  input: CreateAutoTradingBotConfigInput,
  condition?: ModelAutoTradingBotConfigConditionInput | null,
};

export type CreateAutoTradingBotConfigMutation = {
  createAutoTradingBotConfig?:  {
    __typename: "AutoTradingBotConfig",
    id: string,
    name: string,
    apiKey: string,
    apiSecret: string,
    exchange?: AutoTradingExchange | null,
    amountToTrade?: number | null,
    quoteAsset?: string | null,
    maxNumTrades?: number | null,
    fixedLeverage?: number | null,
    fixedStopLoss?: number | null,
    type: AutoTradingBotConfigTypes,
    status: AutoTradingBotConfigStatus,
    username: string,
    user?:  {
      __typename: "User",
      username: string,
      email: string,
      name: string,
      initials?: string | null,
      photo?: string | null,
      lastLogin?: string | null,
      lastVisit?: string | null,
      status: UserStatus,
      statusReason?: string | null,
      membership: Membership,
      permissions?: Array< string | null > | null,
      permissionGroup: UserPermissionGroup,
      expirationDate?: string | null,
      dateOfRenewal?: string | null,
      memberSince?: string | null,
      termsAndConditions?: boolean | null,
      secretKey?: string | null,
      telegramGroups?:  Array< {
        __typename: "UserTelegramGroupAllowed",
        id?: string | null,
        name?: string | null,
        channel?: string | null,
        label?: string | null,
        link?: string | null,
      } | null > | null,
      telegramId?: string | null,
      searchName?: string | null,
      customerId?: string | null,
      currentPlanPeriod?: number | null,
      currentPlanName?: string | null,
      currentPlanId?: string | null,
      currentPlanPrice?: number | null,
      currentPlanCurrency?: string | null,
      subscriptionId?: string | null,
      markedAsCancelled?: boolean | null,
      isTrial?: boolean | null,
      isTestSubscription?: boolean | null,
      products?:  {
        __typename: "ModelUserProductConnection",
        items:  Array< {
          __typename: "UserProduct",
          productId: string,
          username: string,
          expirationDate?: string | null,
          status?: UserProductStatus | null,
          product?:  {
            __typename: "MarketPlaceProduct",
            id: string,
            slug: string,
            name: string,
            externalReference?: string | null,
            icon?: string | null,
            price?: number | null,
            description?: string | null,
            productType?: MarketPlaceProductType | null,
            status?: MarketPlaceProductStatus | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAutoTradingBotConfigMutationVariables = {
  input: UpdateAutoTradingBotConfigInput,
  condition?: ModelAutoTradingBotConfigConditionInput | null,
};

export type UpdateAutoTradingBotConfigMutation = {
  updateAutoTradingBotConfig?:  {
    __typename: "AutoTradingBotConfig",
    id: string,
    name: string,
    apiKey: string,
    apiSecret: string,
    exchange?: AutoTradingExchange | null,
    amountToTrade?: number | null,
    quoteAsset?: string | null,
    maxNumTrades?: number | null,
    fixedLeverage?: number | null,
    fixedStopLoss?: number | null,
    type: AutoTradingBotConfigTypes,
    status: AutoTradingBotConfigStatus,
    username: string,
    user?:  {
      __typename: "User",
      username: string,
      email: string,
      name: string,
      initials?: string | null,
      photo?: string | null,
      lastLogin?: string | null,
      lastVisit?: string | null,
      status: UserStatus,
      statusReason?: string | null,
      membership: Membership,
      permissions?: Array< string | null > | null,
      permissionGroup: UserPermissionGroup,
      expirationDate?: string | null,
      dateOfRenewal?: string | null,
      memberSince?: string | null,
      termsAndConditions?: boolean | null,
      secretKey?: string | null,
      telegramGroups?:  Array< {
        __typename: "UserTelegramGroupAllowed",
        id?: string | null,
        name?: string | null,
        channel?: string | null,
        label?: string | null,
        link?: string | null,
      } | null > | null,
      telegramId?: string | null,
      searchName?: string | null,
      customerId?: string | null,
      currentPlanPeriod?: number | null,
      currentPlanName?: string | null,
      currentPlanId?: string | null,
      currentPlanPrice?: number | null,
      currentPlanCurrency?: string | null,
      subscriptionId?: string | null,
      markedAsCancelled?: boolean | null,
      isTrial?: boolean | null,
      isTestSubscription?: boolean | null,
      products?:  {
        __typename: "ModelUserProductConnection",
        items:  Array< {
          __typename: "UserProduct",
          productId: string,
          username: string,
          expirationDate?: string | null,
          status?: UserProductStatus | null,
          product?:  {
            __typename: "MarketPlaceProduct",
            id: string,
            slug: string,
            name: string,
            externalReference?: string | null,
            icon?: string | null,
            price?: number | null,
            description?: string | null,
            productType?: MarketPlaceProductType | null,
            status?: MarketPlaceProductStatus | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAutoTradingBotConfigMutationVariables = {
  input: DeleteAutoTradingBotConfigInput,
  condition?: ModelAutoTradingBotConfigConditionInput | null,
};

export type DeleteAutoTradingBotConfigMutation = {
  deleteAutoTradingBotConfig?:  {
    __typename: "AutoTradingBotConfig",
    id: string,
    name: string,
    apiKey: string,
    apiSecret: string,
    exchange?: AutoTradingExchange | null,
    amountToTrade?: number | null,
    quoteAsset?: string | null,
    maxNumTrades?: number | null,
    fixedLeverage?: number | null,
    fixedStopLoss?: number | null,
    type: AutoTradingBotConfigTypes,
    status: AutoTradingBotConfigStatus,
    username: string,
    user?:  {
      __typename: "User",
      username: string,
      email: string,
      name: string,
      initials?: string | null,
      photo?: string | null,
      lastLogin?: string | null,
      lastVisit?: string | null,
      status: UserStatus,
      statusReason?: string | null,
      membership: Membership,
      permissions?: Array< string | null > | null,
      permissionGroup: UserPermissionGroup,
      expirationDate?: string | null,
      dateOfRenewal?: string | null,
      memberSince?: string | null,
      termsAndConditions?: boolean | null,
      secretKey?: string | null,
      telegramGroups?:  Array< {
        __typename: "UserTelegramGroupAllowed",
        id?: string | null,
        name?: string | null,
        channel?: string | null,
        label?: string | null,
        link?: string | null,
      } | null > | null,
      telegramId?: string | null,
      searchName?: string | null,
      customerId?: string | null,
      currentPlanPeriod?: number | null,
      currentPlanName?: string | null,
      currentPlanId?: string | null,
      currentPlanPrice?: number | null,
      currentPlanCurrency?: string | null,
      subscriptionId?: string | null,
      markedAsCancelled?: boolean | null,
      isTrial?: boolean | null,
      isTestSubscription?: boolean | null,
      products?:  {
        __typename: "ModelUserProductConnection",
        items:  Array< {
          __typename: "UserProduct",
          productId: string,
          username: string,
          expirationDate?: string | null,
          status?: UserProductStatus | null,
          product?:  {
            __typename: "MarketPlaceProduct",
            id: string,
            slug: string,
            name: string,
            externalReference?: string | null,
            icon?: string | null,
            price?: number | null,
            description?: string | null,
            productType?: MarketPlaceProductType | null,
            status?: MarketPlaceProductStatus | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAutoTradingBotTradeMutationVariables = {
  input: CreateAutoTradingBotTradeInput,
  condition?: ModelAutoTradingBotTradeConditionInput | null,
};

export type CreateAutoTradingBotTradeMutation = {
  createAutoTradingBotTrade?:  {
    __typename: "AutoTradingBotTrade",
    id: string,
    exchange?: AutoTradingExchange | null,
    type?: AutoTradingBotConfigTypes | null,
    tokenName?: string | null,
    symbol?: string | null,
    side?: string | null,
    amount?: number | null,
    stopLossPrice?: number | null,
    entryPrice?: number | null,
    closedPrice?: number | null,
    quantity?: number | null,
    leverage?: number | null,
    createOrderId?: string | null,
    createOrderLinkId?: string | null,
    stopLossOrderId?: string | null,
    closedOrderId?: string | null,
    status?: AutoTradingBotTradeStatus | null,
    telegramSignalLink?: string | null,
    pnlAmount?: number | null,
    pnlPercentage?: number | null,
    quoteAsset?: string | null,
    username?: string | null,
    signalID: string,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAutoTradingBotTradeMutationVariables = {
  input: UpdateAutoTradingBotTradeInput,
  condition?: ModelAutoTradingBotTradeConditionInput | null,
};

export type UpdateAutoTradingBotTradeMutation = {
  updateAutoTradingBotTrade?:  {
    __typename: "AutoTradingBotTrade",
    id: string,
    exchange?: AutoTradingExchange | null,
    type?: AutoTradingBotConfigTypes | null,
    tokenName?: string | null,
    symbol?: string | null,
    side?: string | null,
    amount?: number | null,
    stopLossPrice?: number | null,
    entryPrice?: number | null,
    closedPrice?: number | null,
    quantity?: number | null,
    leverage?: number | null,
    createOrderId?: string | null,
    createOrderLinkId?: string | null,
    stopLossOrderId?: string | null,
    closedOrderId?: string | null,
    status?: AutoTradingBotTradeStatus | null,
    telegramSignalLink?: string | null,
    pnlAmount?: number | null,
    pnlPercentage?: number | null,
    quoteAsset?: string | null,
    username?: string | null,
    signalID: string,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAutoTradingBotTradeMutationVariables = {
  input: DeleteAutoTradingBotTradeInput,
  condition?: ModelAutoTradingBotTradeConditionInput | null,
};

export type DeleteAutoTradingBotTradeMutation = {
  deleteAutoTradingBotTrade?:  {
    __typename: "AutoTradingBotTrade",
    id: string,
    exchange?: AutoTradingExchange | null,
    type?: AutoTradingBotConfigTypes | null,
    tokenName?: string | null,
    symbol?: string | null,
    side?: string | null,
    amount?: number | null,
    stopLossPrice?: number | null,
    entryPrice?: number | null,
    closedPrice?: number | null,
    quantity?: number | null,
    leverage?: number | null,
    createOrderId?: string | null,
    createOrderLinkId?: string | null,
    stopLossOrderId?: string | null,
    closedOrderId?: string | null,
    status?: AutoTradingBotTradeStatus | null,
    telegramSignalLink?: string | null,
    pnlAmount?: number | null,
    pnlPercentage?: number | null,
    quoteAsset?: string | null,
    username?: string | null,
    signalID: string,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAutoTradingBotHistoryMutationVariables = {
  input: CreateAutoTradingBotHistoryInput,
  condition?: ModelAutoTradingBotHistoryConditionInput | null,
};

export type CreateAutoTradingBotHistoryMutation = {
  createAutoTradingBotHistory?:  {
    __typename: "AutoTradingBotHistory",
    id: string,
    exchange?: AutoTradingExchange | null,
    symbol?: string | null,
    tokenName?: string | null,
    side?: string | null,
    leverage?: number | null,
    action?: string | null,
    content?: string | null,
    payload?: string | null,
    totalGains?: number | null,
    username?: string | null,
    orderID?: string | null,
    type?: AutoTradingBotHistoryType | null,
    signalID?: string | null,
    autoTradingID?: string | null,
    autoTradeInfo?:  {
      __typename: "AutoTradingBotTrade",
      id: string,
      exchange?: AutoTradingExchange | null,
      type?: AutoTradingBotConfigTypes | null,
      tokenName?: string | null,
      symbol?: string | null,
      side?: string | null,
      amount?: number | null,
      stopLossPrice?: number | null,
      entryPrice?: number | null,
      closedPrice?: number | null,
      quantity?: number | null,
      leverage?: number | null,
      createOrderId?: string | null,
      createOrderLinkId?: string | null,
      stopLossOrderId?: string | null,
      closedOrderId?: string | null,
      status?: AutoTradingBotTradeStatus | null,
      telegramSignalLink?: string | null,
      pnlAmount?: number | null,
      pnlPercentage?: number | null,
      quoteAsset?: string | null,
      username?: string | null,
      signalID: string,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAutoTradingBotHistoryMutationVariables = {
  input: UpdateAutoTradingBotHistoryInput,
  condition?: ModelAutoTradingBotHistoryConditionInput | null,
};

export type UpdateAutoTradingBotHistoryMutation = {
  updateAutoTradingBotHistory?:  {
    __typename: "AutoTradingBotHistory",
    id: string,
    exchange?: AutoTradingExchange | null,
    symbol?: string | null,
    tokenName?: string | null,
    side?: string | null,
    leverage?: number | null,
    action?: string | null,
    content?: string | null,
    payload?: string | null,
    totalGains?: number | null,
    username?: string | null,
    orderID?: string | null,
    type?: AutoTradingBotHistoryType | null,
    signalID?: string | null,
    autoTradingID?: string | null,
    autoTradeInfo?:  {
      __typename: "AutoTradingBotTrade",
      id: string,
      exchange?: AutoTradingExchange | null,
      type?: AutoTradingBotConfigTypes | null,
      tokenName?: string | null,
      symbol?: string | null,
      side?: string | null,
      amount?: number | null,
      stopLossPrice?: number | null,
      entryPrice?: number | null,
      closedPrice?: number | null,
      quantity?: number | null,
      leverage?: number | null,
      createOrderId?: string | null,
      createOrderLinkId?: string | null,
      stopLossOrderId?: string | null,
      closedOrderId?: string | null,
      status?: AutoTradingBotTradeStatus | null,
      telegramSignalLink?: string | null,
      pnlAmount?: number | null,
      pnlPercentage?: number | null,
      quoteAsset?: string | null,
      username?: string | null,
      signalID: string,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAutoTradingBotHistoryMutationVariables = {
  input: DeleteAutoTradingBotHistoryInput,
  condition?: ModelAutoTradingBotHistoryConditionInput | null,
};

export type DeleteAutoTradingBotHistoryMutation = {
  deleteAutoTradingBotHistory?:  {
    __typename: "AutoTradingBotHistory",
    id: string,
    exchange?: AutoTradingExchange | null,
    symbol?: string | null,
    tokenName?: string | null,
    side?: string | null,
    leverage?: number | null,
    action?: string | null,
    content?: string | null,
    payload?: string | null,
    totalGains?: number | null,
    username?: string | null,
    orderID?: string | null,
    type?: AutoTradingBotHistoryType | null,
    signalID?: string | null,
    autoTradingID?: string | null,
    autoTradeInfo?:  {
      __typename: "AutoTradingBotTrade",
      id: string,
      exchange?: AutoTradingExchange | null,
      type?: AutoTradingBotConfigTypes | null,
      tokenName?: string | null,
      symbol?: string | null,
      side?: string | null,
      amount?: number | null,
      stopLossPrice?: number | null,
      entryPrice?: number | null,
      closedPrice?: number | null,
      quantity?: number | null,
      leverage?: number | null,
      createOrderId?: string | null,
      createOrderLinkId?: string | null,
      stopLossOrderId?: string | null,
      closedOrderId?: string | null,
      status?: AutoTradingBotTradeStatus | null,
      telegramSignalLink?: string | null,
      pnlAmount?: number | null,
      pnlPercentage?: number | null,
      quoteAsset?: string | null,
      username?: string | null,
      signalID: string,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCollectionMutationVariables = {
  input: CreateCollectionInput,
  condition?: ModelCollectionConditionInput | null,
};

export type CreateCollectionMutation = {
  createCollection?:  {
    __typename: "Collection",
    id: string,
    title: string,
    description: string,
    status: CollectionStatus,
    tags?: Array< string | null > | null,
    contents?:  {
      __typename: "ModelCollectionContentConnection",
      items:  Array< {
        __typename: "CollectionContent",
        id: string,
        slug?: string | null,
        order: number,
        title: string,
        description: string,
        body?: string | null,
        thumbnail?: string | null,
        image?: string | null,
        video?: string | null,
        duration?: number | null,
        date?: string | null,
        attachments?:  Array< {
          __typename: "CollectionAttachment",
          file?: string | null,
          url?: string | null,
          text?: string | null,
          mimeType?: string | null,
        } | null > | null,
        status: CollectionStatus,
        collectionID: string,
        collection?:  {
          __typename: "Collection",
          id: string,
          title: string,
          description: string,
          status: CollectionStatus,
          tags?: Array< string | null > | null,
          contents?:  {
            __typename: "ModelCollectionContentConnection",
            items:  Array< {
              __typename: "CollectionContent",
              id: string,
              slug?: string | null,
              order: number,
              title: string,
              description: string,
              body?: string | null,
              thumbnail?: string | null,
              image?: string | null,
              video?: string | null,
              duration?: number | null,
              date?: string | null,
              status: CollectionStatus,
              collectionID: string,
              authorName?: string | null,
              authorUsername?: string | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramTemplate?: string | null,
              type?: CollectionContentType | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramTemplate?: string | null,
          thumbnail?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            contentID: string,
            body: string,
            createdByName?: string | null,
            createdByUserName?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        authorName?: string | null,
        authorUsername?: string | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramTemplate?: string | null,
        type?: CollectionContentType | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramTemplate?: string | null,
    thumbnail?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCollectionMutationVariables = {
  input: UpdateCollectionInput,
  condition?: ModelCollectionConditionInput | null,
};

export type UpdateCollectionMutation = {
  updateCollection?:  {
    __typename: "Collection",
    id: string,
    title: string,
    description: string,
    status: CollectionStatus,
    tags?: Array< string | null > | null,
    contents?:  {
      __typename: "ModelCollectionContentConnection",
      items:  Array< {
        __typename: "CollectionContent",
        id: string,
        slug?: string | null,
        order: number,
        title: string,
        description: string,
        body?: string | null,
        thumbnail?: string | null,
        image?: string | null,
        video?: string | null,
        duration?: number | null,
        date?: string | null,
        attachments?:  Array< {
          __typename: "CollectionAttachment",
          file?: string | null,
          url?: string | null,
          text?: string | null,
          mimeType?: string | null,
        } | null > | null,
        status: CollectionStatus,
        collectionID: string,
        collection?:  {
          __typename: "Collection",
          id: string,
          title: string,
          description: string,
          status: CollectionStatus,
          tags?: Array< string | null > | null,
          contents?:  {
            __typename: "ModelCollectionContentConnection",
            items:  Array< {
              __typename: "CollectionContent",
              id: string,
              slug?: string | null,
              order: number,
              title: string,
              description: string,
              body?: string | null,
              thumbnail?: string | null,
              image?: string | null,
              video?: string | null,
              duration?: number | null,
              date?: string | null,
              status: CollectionStatus,
              collectionID: string,
              authorName?: string | null,
              authorUsername?: string | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramTemplate?: string | null,
              type?: CollectionContentType | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramTemplate?: string | null,
          thumbnail?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            contentID: string,
            body: string,
            createdByName?: string | null,
            createdByUserName?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        authorName?: string | null,
        authorUsername?: string | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramTemplate?: string | null,
        type?: CollectionContentType | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramTemplate?: string | null,
    thumbnail?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCollectionMutationVariables = {
  input: DeleteCollectionInput,
  condition?: ModelCollectionConditionInput | null,
};

export type DeleteCollectionMutation = {
  deleteCollection?:  {
    __typename: "Collection",
    id: string,
    title: string,
    description: string,
    status: CollectionStatus,
    tags?: Array< string | null > | null,
    contents?:  {
      __typename: "ModelCollectionContentConnection",
      items:  Array< {
        __typename: "CollectionContent",
        id: string,
        slug?: string | null,
        order: number,
        title: string,
        description: string,
        body?: string | null,
        thumbnail?: string | null,
        image?: string | null,
        video?: string | null,
        duration?: number | null,
        date?: string | null,
        attachments?:  Array< {
          __typename: "CollectionAttachment",
          file?: string | null,
          url?: string | null,
          text?: string | null,
          mimeType?: string | null,
        } | null > | null,
        status: CollectionStatus,
        collectionID: string,
        collection?:  {
          __typename: "Collection",
          id: string,
          title: string,
          description: string,
          status: CollectionStatus,
          tags?: Array< string | null > | null,
          contents?:  {
            __typename: "ModelCollectionContentConnection",
            items:  Array< {
              __typename: "CollectionContent",
              id: string,
              slug?: string | null,
              order: number,
              title: string,
              description: string,
              body?: string | null,
              thumbnail?: string | null,
              image?: string | null,
              video?: string | null,
              duration?: number | null,
              date?: string | null,
              status: CollectionStatus,
              collectionID: string,
              authorName?: string | null,
              authorUsername?: string | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramTemplate?: string | null,
              type?: CollectionContentType | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramTemplate?: string | null,
          thumbnail?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            contentID: string,
            body: string,
            createdByName?: string | null,
            createdByUserName?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        authorName?: string | null,
        authorUsername?: string | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramTemplate?: string | null,
        type?: CollectionContentType | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramTemplate?: string | null,
    thumbnail?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCollectionContentMutationVariables = {
  input: CreateCollectionContentInput,
  condition?: ModelCollectionContentConditionInput | null,
};

export type CreateCollectionContentMutation = {
  createCollectionContent?:  {
    __typename: "CollectionContent",
    id: string,
    slug?: string | null,
    order: number,
    title: string,
    description: string,
    body?: string | null,
    thumbnail?: string | null,
    image?: string | null,
    video?: string | null,
    duration?: number | null,
    date?: string | null,
    attachments?:  Array< {
      __typename: "CollectionAttachment",
      file?: string | null,
      url?: string | null,
      text?: string | null,
      mimeType?: string | null,
    } | null > | null,
    status: CollectionStatus,
    collectionID: string,
    collection?:  {
      __typename: "Collection",
      id: string,
      title: string,
      description: string,
      status: CollectionStatus,
      tags?: Array< string | null > | null,
      contents?:  {
        __typename: "ModelCollectionContentConnection",
        items:  Array< {
          __typename: "CollectionContent",
          id: string,
          slug?: string | null,
          order: number,
          title: string,
          description: string,
          body?: string | null,
          thumbnail?: string | null,
          image?: string | null,
          video?: string | null,
          duration?: number | null,
          date?: string | null,
          attachments?:  Array< {
            __typename: "CollectionAttachment",
            file?: string | null,
            url?: string | null,
            text?: string | null,
            mimeType?: string | null,
          } | null > | null,
          status: CollectionStatus,
          collectionID: string,
          collection?:  {
            __typename: "Collection",
            id: string,
            title: string,
            description: string,
            status: CollectionStatus,
            tags?: Array< string | null > | null,
            contents?:  {
              __typename: "ModelCollectionContentConnection",
              nextToken?: string | null,
            } | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramTemplate?: string | null,
            thumbnail?: string | null,
            order?: number | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              contentID: string,
              body: string,
              createdByName?: string | null,
              createdByUserName?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          authorName?: string | null,
          authorUsername?: string | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramMessages?:  Array< {
            __typename: "TelegramMessageResult",
            channel?: string | null,
            id?: string | null,
            link?: string | null,
          } | null > | null,
          telegramTemplate?: string | null,
          type?: CollectionContentType | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramTemplate?: string | null,
      thumbnail?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        contentID: string,
        body: string,
        createdByName?: string | null,
        createdByUserName?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    authorName?: string | null,
    authorUsername?: string | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramTemplate?: string | null,
    type?: CollectionContentType | null,
    customFields?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCollectionContentMutationVariables = {
  input: UpdateCollectionContentInput,
  condition?: ModelCollectionContentConditionInput | null,
};

export type UpdateCollectionContentMutation = {
  updateCollectionContent?:  {
    __typename: "CollectionContent",
    id: string,
    slug?: string | null,
    order: number,
    title: string,
    description: string,
    body?: string | null,
    thumbnail?: string | null,
    image?: string | null,
    video?: string | null,
    duration?: number | null,
    date?: string | null,
    attachments?:  Array< {
      __typename: "CollectionAttachment",
      file?: string | null,
      url?: string | null,
      text?: string | null,
      mimeType?: string | null,
    } | null > | null,
    status: CollectionStatus,
    collectionID: string,
    collection?:  {
      __typename: "Collection",
      id: string,
      title: string,
      description: string,
      status: CollectionStatus,
      tags?: Array< string | null > | null,
      contents?:  {
        __typename: "ModelCollectionContentConnection",
        items:  Array< {
          __typename: "CollectionContent",
          id: string,
          slug?: string | null,
          order: number,
          title: string,
          description: string,
          body?: string | null,
          thumbnail?: string | null,
          image?: string | null,
          video?: string | null,
          duration?: number | null,
          date?: string | null,
          attachments?:  Array< {
            __typename: "CollectionAttachment",
            file?: string | null,
            url?: string | null,
            text?: string | null,
            mimeType?: string | null,
          } | null > | null,
          status: CollectionStatus,
          collectionID: string,
          collection?:  {
            __typename: "Collection",
            id: string,
            title: string,
            description: string,
            status: CollectionStatus,
            tags?: Array< string | null > | null,
            contents?:  {
              __typename: "ModelCollectionContentConnection",
              nextToken?: string | null,
            } | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramTemplate?: string | null,
            thumbnail?: string | null,
            order?: number | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              contentID: string,
              body: string,
              createdByName?: string | null,
              createdByUserName?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          authorName?: string | null,
          authorUsername?: string | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramMessages?:  Array< {
            __typename: "TelegramMessageResult",
            channel?: string | null,
            id?: string | null,
            link?: string | null,
          } | null > | null,
          telegramTemplate?: string | null,
          type?: CollectionContentType | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramTemplate?: string | null,
      thumbnail?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        contentID: string,
        body: string,
        createdByName?: string | null,
        createdByUserName?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    authorName?: string | null,
    authorUsername?: string | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramTemplate?: string | null,
    type?: CollectionContentType | null,
    customFields?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCollectionContentMutationVariables = {
  input: DeleteCollectionContentInput,
  condition?: ModelCollectionContentConditionInput | null,
};

export type DeleteCollectionContentMutation = {
  deleteCollectionContent?:  {
    __typename: "CollectionContent",
    id: string,
    slug?: string | null,
    order: number,
    title: string,
    description: string,
    body?: string | null,
    thumbnail?: string | null,
    image?: string | null,
    video?: string | null,
    duration?: number | null,
    date?: string | null,
    attachments?:  Array< {
      __typename: "CollectionAttachment",
      file?: string | null,
      url?: string | null,
      text?: string | null,
      mimeType?: string | null,
    } | null > | null,
    status: CollectionStatus,
    collectionID: string,
    collection?:  {
      __typename: "Collection",
      id: string,
      title: string,
      description: string,
      status: CollectionStatus,
      tags?: Array< string | null > | null,
      contents?:  {
        __typename: "ModelCollectionContentConnection",
        items:  Array< {
          __typename: "CollectionContent",
          id: string,
          slug?: string | null,
          order: number,
          title: string,
          description: string,
          body?: string | null,
          thumbnail?: string | null,
          image?: string | null,
          video?: string | null,
          duration?: number | null,
          date?: string | null,
          attachments?:  Array< {
            __typename: "CollectionAttachment",
            file?: string | null,
            url?: string | null,
            text?: string | null,
            mimeType?: string | null,
          } | null > | null,
          status: CollectionStatus,
          collectionID: string,
          collection?:  {
            __typename: "Collection",
            id: string,
            title: string,
            description: string,
            status: CollectionStatus,
            tags?: Array< string | null > | null,
            contents?:  {
              __typename: "ModelCollectionContentConnection",
              nextToken?: string | null,
            } | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramTemplate?: string | null,
            thumbnail?: string | null,
            order?: number | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              contentID: string,
              body: string,
              createdByName?: string | null,
              createdByUserName?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          authorName?: string | null,
          authorUsername?: string | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramMessages?:  Array< {
            __typename: "TelegramMessageResult",
            channel?: string | null,
            id?: string | null,
            link?: string | null,
          } | null > | null,
          telegramTemplate?: string | null,
          type?: CollectionContentType | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramTemplate?: string | null,
      thumbnail?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        contentID: string,
        body: string,
        createdByName?: string | null,
        createdByUserName?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    authorName?: string | null,
    authorUsername?: string | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramTemplate?: string | null,
    type?: CollectionContentType | null,
    customFields?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
    contentID: string,
    body: string,
    createdByName?: string | null,
    createdByUserName?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    id: string,
    contentID: string,
    body: string,
    createdByName?: string | null,
    createdByUserName?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    contentID: string,
    body: string,
    createdByName?: string | null,
    createdByUserName?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateLessonProgressMutationVariables = {
  input: CreateLessonProgressInput,
  condition?: ModelLessonProgressConditionInput | null,
};

export type CreateLessonProgressMutation = {
  createLessonProgress?:  {
    __typename: "LessonProgress",
    collectionContentId: string,
    username: string,
    progress?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateLessonProgressMutationVariables = {
  input: UpdateLessonProgressInput,
  condition?: ModelLessonProgressConditionInput | null,
};

export type UpdateLessonProgressMutation = {
  updateLessonProgress?:  {
    __typename: "LessonProgress",
    collectionContentId: string,
    username: string,
    progress?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteLessonProgressMutationVariables = {
  input: DeleteLessonProgressInput,
  condition?: ModelLessonProgressConditionInput | null,
};

export type DeleteLessonProgressMutation = {
  deleteLessonProgress?:  {
    __typename: "LessonProgress",
    collectionContentId: string,
    username: string,
    progress?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCryptofyBotTradeMutationVariables = {
  input: CreateCryptofyBotTradeInput,
  condition?: ModelCryptofyBotTradeConditionInput | null,
};

export type CreateCryptofyBotTradeMutation = {
  createCryptofyBotTrade?:  {
    __typename: "CryptofyBotTrade",
    id: string,
    status: CryptofyBotTradeStatus,
    tokenName: string,
    symbol: string,
    exchange: SignalExchange,
    type: CryptofyBotTradeTypes,
    side?: CryptofyBotTradeSides | null,
    leverage?: number | null,
    signalEntry?: number | null,
    tradeEntry?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    signalStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    tradeStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    peakLossProfit?: number | null,
    currentLossProfit?: number | null,
    telegramSignalLink?: string | null,
    lastMonitorCheck?: string | null,
    lastPrice?: number | null,
    activationManagement?:  {
      __typename: "CryptofyBotActivationManagement",
      crossingTime?: number | null,
      crossingPrice?: number | null,
      wasWithinThreshold?: boolean | null,
      hasActivated?: boolean | null,
    } | null,
    customFields?: string | null,
    history?:  {
      __typename: "ModelCryptofyBotTradeHistoryConnection",
      items:  Array< {
        __typename: "CryptofyBotTradeHistory",
        id: string,
        action: CryptofyBotTradeHistoryActions,
        message?: string | null,
        tradeId?: string | null,
        trade?:  {
          __typename: "CryptofyBotTrade",
          id: string,
          status: CryptofyBotTradeStatus,
          tokenName: string,
          symbol: string,
          exchange: SignalExchange,
          type: CryptofyBotTradeTypes,
          side?: CryptofyBotTradeSides | null,
          leverage?: number | null,
          signalEntry?: number | null,
          tradeEntry?: number | null,
          targets?:  Array< {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null > | null,
          signalStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          tradeStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          peakLossProfit?: number | null,
          currentLossProfit?: number | null,
          telegramSignalLink?: string | null,
          lastMonitorCheck?: string | null,
          lastPrice?: number | null,
          activationManagement?:  {
            __typename: "CryptofyBotActivationManagement",
            crossingTime?: number | null,
            crossingPrice?: number | null,
            wasWithinThreshold?: boolean | null,
            hasActivated?: boolean | null,
          } | null,
          customFields?: string | null,
          history?:  {
            __typename: "ModelCryptofyBotTradeHistoryConnection",
            items:  Array< {
              __typename: "CryptofyBotTradeHistory",
              id: string,
              action: CryptofyBotTradeHistoryActions,
              message?: string | null,
              tradeId?: string | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          signalID?: string | null,
          signal?:  {
            __typename: "Signal",
            id: string,
            title?: string | null,
            type?: SignalType | null,
            status: SignalStatus,
            exchange: SignalExchange,
            isFutures?: boolean | null,
            video?: string | null,
            thumbnail?: string | null,
            stopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            trailingStopLoss?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            minRetainer?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            description?: string | null,
            entryOnActivation?: number | null,
            entry?: number | null,
            futuresOperation?: SignalFuturesOperation | null,
            leverage?: number | null,
            interval?: string | null,
            tags?: Array< string | null > | null,
            link?: string | null,
            linkLabel?: string | null,
            totalGains?: number | null,
            totalGainsFutures?: number | null,
            lastPrice?: number | null,
            lastMonitorCheck?: string | null,
            takeProfitNum?: number | null,
            breakThroughTime?: number | null,
            breakThroughCount?: number | null,
            showOnFreeGroup?: boolean | null,
            telegramMessages?:  Array< {
              __typename: "TelegramMessageResult",
              channel?: string | null,
              id?: string | null,
              link?: string | null,
            } | null > | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramEntryNotificationId?: number | null,
            telegramEntryNotificationDate?: string | null,
            tokenName?: string | null,
            tokenSymbol?: string | null,
            tokenAddress?: string | null,
            createdByName?: string | null,
            createdByUserName?: string | null,
            manualOperation?: boolean | null,
            isTesting?: boolean | null,
            useCopyTrade?: boolean | null,
            referenceId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    signalID?: string | null,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCryptofyBotTradeMutationVariables = {
  input: UpdateCryptofyBotTradeInput,
  condition?: ModelCryptofyBotTradeConditionInput | null,
};

export type UpdateCryptofyBotTradeMutation = {
  updateCryptofyBotTrade?:  {
    __typename: "CryptofyBotTrade",
    id: string,
    status: CryptofyBotTradeStatus,
    tokenName: string,
    symbol: string,
    exchange: SignalExchange,
    type: CryptofyBotTradeTypes,
    side?: CryptofyBotTradeSides | null,
    leverage?: number | null,
    signalEntry?: number | null,
    tradeEntry?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    signalStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    tradeStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    peakLossProfit?: number | null,
    currentLossProfit?: number | null,
    telegramSignalLink?: string | null,
    lastMonitorCheck?: string | null,
    lastPrice?: number | null,
    activationManagement?:  {
      __typename: "CryptofyBotActivationManagement",
      crossingTime?: number | null,
      crossingPrice?: number | null,
      wasWithinThreshold?: boolean | null,
      hasActivated?: boolean | null,
    } | null,
    customFields?: string | null,
    history?:  {
      __typename: "ModelCryptofyBotTradeHistoryConnection",
      items:  Array< {
        __typename: "CryptofyBotTradeHistory",
        id: string,
        action: CryptofyBotTradeHistoryActions,
        message?: string | null,
        tradeId?: string | null,
        trade?:  {
          __typename: "CryptofyBotTrade",
          id: string,
          status: CryptofyBotTradeStatus,
          tokenName: string,
          symbol: string,
          exchange: SignalExchange,
          type: CryptofyBotTradeTypes,
          side?: CryptofyBotTradeSides | null,
          leverage?: number | null,
          signalEntry?: number | null,
          tradeEntry?: number | null,
          targets?:  Array< {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null > | null,
          signalStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          tradeStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          peakLossProfit?: number | null,
          currentLossProfit?: number | null,
          telegramSignalLink?: string | null,
          lastMonitorCheck?: string | null,
          lastPrice?: number | null,
          activationManagement?:  {
            __typename: "CryptofyBotActivationManagement",
            crossingTime?: number | null,
            crossingPrice?: number | null,
            wasWithinThreshold?: boolean | null,
            hasActivated?: boolean | null,
          } | null,
          customFields?: string | null,
          history?:  {
            __typename: "ModelCryptofyBotTradeHistoryConnection",
            items:  Array< {
              __typename: "CryptofyBotTradeHistory",
              id: string,
              action: CryptofyBotTradeHistoryActions,
              message?: string | null,
              tradeId?: string | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          signalID?: string | null,
          signal?:  {
            __typename: "Signal",
            id: string,
            title?: string | null,
            type?: SignalType | null,
            status: SignalStatus,
            exchange: SignalExchange,
            isFutures?: boolean | null,
            video?: string | null,
            thumbnail?: string | null,
            stopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            trailingStopLoss?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            minRetainer?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            description?: string | null,
            entryOnActivation?: number | null,
            entry?: number | null,
            futuresOperation?: SignalFuturesOperation | null,
            leverage?: number | null,
            interval?: string | null,
            tags?: Array< string | null > | null,
            link?: string | null,
            linkLabel?: string | null,
            totalGains?: number | null,
            totalGainsFutures?: number | null,
            lastPrice?: number | null,
            lastMonitorCheck?: string | null,
            takeProfitNum?: number | null,
            breakThroughTime?: number | null,
            breakThroughCount?: number | null,
            showOnFreeGroup?: boolean | null,
            telegramMessages?:  Array< {
              __typename: "TelegramMessageResult",
              channel?: string | null,
              id?: string | null,
              link?: string | null,
            } | null > | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramEntryNotificationId?: number | null,
            telegramEntryNotificationDate?: string | null,
            tokenName?: string | null,
            tokenSymbol?: string | null,
            tokenAddress?: string | null,
            createdByName?: string | null,
            createdByUserName?: string | null,
            manualOperation?: boolean | null,
            isTesting?: boolean | null,
            useCopyTrade?: boolean | null,
            referenceId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    signalID?: string | null,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCryptofyBotTradeMutationVariables = {
  input: DeleteCryptofyBotTradeInput,
  condition?: ModelCryptofyBotTradeConditionInput | null,
};

export type DeleteCryptofyBotTradeMutation = {
  deleteCryptofyBotTrade?:  {
    __typename: "CryptofyBotTrade",
    id: string,
    status: CryptofyBotTradeStatus,
    tokenName: string,
    symbol: string,
    exchange: SignalExchange,
    type: CryptofyBotTradeTypes,
    side?: CryptofyBotTradeSides | null,
    leverage?: number | null,
    signalEntry?: number | null,
    tradeEntry?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    signalStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    tradeStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    peakLossProfit?: number | null,
    currentLossProfit?: number | null,
    telegramSignalLink?: string | null,
    lastMonitorCheck?: string | null,
    lastPrice?: number | null,
    activationManagement?:  {
      __typename: "CryptofyBotActivationManagement",
      crossingTime?: number | null,
      crossingPrice?: number | null,
      wasWithinThreshold?: boolean | null,
      hasActivated?: boolean | null,
    } | null,
    customFields?: string | null,
    history?:  {
      __typename: "ModelCryptofyBotTradeHistoryConnection",
      items:  Array< {
        __typename: "CryptofyBotTradeHistory",
        id: string,
        action: CryptofyBotTradeHistoryActions,
        message?: string | null,
        tradeId?: string | null,
        trade?:  {
          __typename: "CryptofyBotTrade",
          id: string,
          status: CryptofyBotTradeStatus,
          tokenName: string,
          symbol: string,
          exchange: SignalExchange,
          type: CryptofyBotTradeTypes,
          side?: CryptofyBotTradeSides | null,
          leverage?: number | null,
          signalEntry?: number | null,
          tradeEntry?: number | null,
          targets?:  Array< {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null > | null,
          signalStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          tradeStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          peakLossProfit?: number | null,
          currentLossProfit?: number | null,
          telegramSignalLink?: string | null,
          lastMonitorCheck?: string | null,
          lastPrice?: number | null,
          activationManagement?:  {
            __typename: "CryptofyBotActivationManagement",
            crossingTime?: number | null,
            crossingPrice?: number | null,
            wasWithinThreshold?: boolean | null,
            hasActivated?: boolean | null,
          } | null,
          customFields?: string | null,
          history?:  {
            __typename: "ModelCryptofyBotTradeHistoryConnection",
            items:  Array< {
              __typename: "CryptofyBotTradeHistory",
              id: string,
              action: CryptofyBotTradeHistoryActions,
              message?: string | null,
              tradeId?: string | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          signalID?: string | null,
          signal?:  {
            __typename: "Signal",
            id: string,
            title?: string | null,
            type?: SignalType | null,
            status: SignalStatus,
            exchange: SignalExchange,
            isFutures?: boolean | null,
            video?: string | null,
            thumbnail?: string | null,
            stopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            trailingStopLoss?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            minRetainer?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            description?: string | null,
            entryOnActivation?: number | null,
            entry?: number | null,
            futuresOperation?: SignalFuturesOperation | null,
            leverage?: number | null,
            interval?: string | null,
            tags?: Array< string | null > | null,
            link?: string | null,
            linkLabel?: string | null,
            totalGains?: number | null,
            totalGainsFutures?: number | null,
            lastPrice?: number | null,
            lastMonitorCheck?: string | null,
            takeProfitNum?: number | null,
            breakThroughTime?: number | null,
            breakThroughCount?: number | null,
            showOnFreeGroup?: boolean | null,
            telegramMessages?:  Array< {
              __typename: "TelegramMessageResult",
              channel?: string | null,
              id?: string | null,
              link?: string | null,
            } | null > | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramEntryNotificationId?: number | null,
            telegramEntryNotificationDate?: string | null,
            tokenName?: string | null,
            tokenSymbol?: string | null,
            tokenAddress?: string | null,
            createdByName?: string | null,
            createdByUserName?: string | null,
            manualOperation?: boolean | null,
            isTesting?: boolean | null,
            useCopyTrade?: boolean | null,
            referenceId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    signalID?: string | null,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCryptofyBotTradeHistoryMutationVariables = {
  input: CreateCryptofyBotTradeHistoryInput,
  condition?: ModelCryptofyBotTradeHistoryConditionInput | null,
};

export type CreateCryptofyBotTradeHistoryMutation = {
  createCryptofyBotTradeHistory?:  {
    __typename: "CryptofyBotTradeHistory",
    id: string,
    action: CryptofyBotTradeHistoryActions,
    message?: string | null,
    tradeId?: string | null,
    trade?:  {
      __typename: "CryptofyBotTrade",
      id: string,
      status: CryptofyBotTradeStatus,
      tokenName: string,
      symbol: string,
      exchange: SignalExchange,
      type: CryptofyBotTradeTypes,
      side?: CryptofyBotTradeSides | null,
      leverage?: number | null,
      signalEntry?: number | null,
      tradeEntry?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      signalStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      tradeStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      peakLossProfit?: number | null,
      currentLossProfit?: number | null,
      telegramSignalLink?: string | null,
      lastMonitorCheck?: string | null,
      lastPrice?: number | null,
      activationManagement?:  {
        __typename: "CryptofyBotActivationManagement",
        crossingTime?: number | null,
        crossingPrice?: number | null,
        wasWithinThreshold?: boolean | null,
        hasActivated?: boolean | null,
      } | null,
      customFields?: string | null,
      history?:  {
        __typename: "ModelCryptofyBotTradeHistoryConnection",
        items:  Array< {
          __typename: "CryptofyBotTradeHistory",
          id: string,
          action: CryptofyBotTradeHistoryActions,
          message?: string | null,
          tradeId?: string | null,
          trade?:  {
            __typename: "CryptofyBotTrade",
            id: string,
            status: CryptofyBotTradeStatus,
            tokenName: string,
            symbol: string,
            exchange: SignalExchange,
            type: CryptofyBotTradeTypes,
            side?: CryptofyBotTradeSides | null,
            leverage?: number | null,
            signalEntry?: number | null,
            tradeEntry?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            signalStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            tradeStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            peakLossProfit?: number | null,
            currentLossProfit?: number | null,
            telegramSignalLink?: string | null,
            lastMonitorCheck?: string | null,
            lastPrice?: number | null,
            activationManagement?:  {
              __typename: "CryptofyBotActivationManagement",
              crossingTime?: number | null,
              crossingPrice?: number | null,
              wasWithinThreshold?: boolean | null,
              hasActivated?: boolean | null,
            } | null,
            customFields?: string | null,
            history?:  {
              __typename: "ModelCryptofyBotTradeHistoryConnection",
              nextToken?: string | null,
            } | null,
            signalID?: string | null,
            signal?:  {
              __typename: "Signal",
              id: string,
              title?: string | null,
              type?: SignalType | null,
              status: SignalStatus,
              exchange: SignalExchange,
              isFutures?: boolean | null,
              video?: string | null,
              thumbnail?: string | null,
              trailingStopLoss?: number | null,
              description?: string | null,
              entryOnActivation?: number | null,
              entry?: number | null,
              futuresOperation?: SignalFuturesOperation | null,
              leverage?: number | null,
              interval?: string | null,
              tags?: Array< string | null > | null,
              link?: string | null,
              linkLabel?: string | null,
              totalGains?: number | null,
              totalGainsFutures?: number | null,
              lastPrice?: number | null,
              lastMonitorCheck?: string | null,
              takeProfitNum?: number | null,
              breakThroughTime?: number | null,
              breakThroughCount?: number | null,
              showOnFreeGroup?: boolean | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramEntryNotificationId?: number | null,
              telegramEntryNotificationDate?: string | null,
              tokenName?: string | null,
              tokenSymbol?: string | null,
              tokenAddress?: string | null,
              createdByName?: string | null,
              createdByUserName?: string | null,
              manualOperation?: boolean | null,
              isTesting?: boolean | null,
              useCopyTrade?: boolean | null,
              referenceId?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      signalID?: string | null,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    customFields?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCryptofyBotTradeHistoryMutationVariables = {
  input: UpdateCryptofyBotTradeHistoryInput,
  condition?: ModelCryptofyBotTradeHistoryConditionInput | null,
};

export type UpdateCryptofyBotTradeHistoryMutation = {
  updateCryptofyBotTradeHistory?:  {
    __typename: "CryptofyBotTradeHistory",
    id: string,
    action: CryptofyBotTradeHistoryActions,
    message?: string | null,
    tradeId?: string | null,
    trade?:  {
      __typename: "CryptofyBotTrade",
      id: string,
      status: CryptofyBotTradeStatus,
      tokenName: string,
      symbol: string,
      exchange: SignalExchange,
      type: CryptofyBotTradeTypes,
      side?: CryptofyBotTradeSides | null,
      leverage?: number | null,
      signalEntry?: number | null,
      tradeEntry?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      signalStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      tradeStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      peakLossProfit?: number | null,
      currentLossProfit?: number | null,
      telegramSignalLink?: string | null,
      lastMonitorCheck?: string | null,
      lastPrice?: number | null,
      activationManagement?:  {
        __typename: "CryptofyBotActivationManagement",
        crossingTime?: number | null,
        crossingPrice?: number | null,
        wasWithinThreshold?: boolean | null,
        hasActivated?: boolean | null,
      } | null,
      customFields?: string | null,
      history?:  {
        __typename: "ModelCryptofyBotTradeHistoryConnection",
        items:  Array< {
          __typename: "CryptofyBotTradeHistory",
          id: string,
          action: CryptofyBotTradeHistoryActions,
          message?: string | null,
          tradeId?: string | null,
          trade?:  {
            __typename: "CryptofyBotTrade",
            id: string,
            status: CryptofyBotTradeStatus,
            tokenName: string,
            symbol: string,
            exchange: SignalExchange,
            type: CryptofyBotTradeTypes,
            side?: CryptofyBotTradeSides | null,
            leverage?: number | null,
            signalEntry?: number | null,
            tradeEntry?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            signalStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            tradeStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            peakLossProfit?: number | null,
            currentLossProfit?: number | null,
            telegramSignalLink?: string | null,
            lastMonitorCheck?: string | null,
            lastPrice?: number | null,
            activationManagement?:  {
              __typename: "CryptofyBotActivationManagement",
              crossingTime?: number | null,
              crossingPrice?: number | null,
              wasWithinThreshold?: boolean | null,
              hasActivated?: boolean | null,
            } | null,
            customFields?: string | null,
            history?:  {
              __typename: "ModelCryptofyBotTradeHistoryConnection",
              nextToken?: string | null,
            } | null,
            signalID?: string | null,
            signal?:  {
              __typename: "Signal",
              id: string,
              title?: string | null,
              type?: SignalType | null,
              status: SignalStatus,
              exchange: SignalExchange,
              isFutures?: boolean | null,
              video?: string | null,
              thumbnail?: string | null,
              trailingStopLoss?: number | null,
              description?: string | null,
              entryOnActivation?: number | null,
              entry?: number | null,
              futuresOperation?: SignalFuturesOperation | null,
              leverage?: number | null,
              interval?: string | null,
              tags?: Array< string | null > | null,
              link?: string | null,
              linkLabel?: string | null,
              totalGains?: number | null,
              totalGainsFutures?: number | null,
              lastPrice?: number | null,
              lastMonitorCheck?: string | null,
              takeProfitNum?: number | null,
              breakThroughTime?: number | null,
              breakThroughCount?: number | null,
              showOnFreeGroup?: boolean | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramEntryNotificationId?: number | null,
              telegramEntryNotificationDate?: string | null,
              tokenName?: string | null,
              tokenSymbol?: string | null,
              tokenAddress?: string | null,
              createdByName?: string | null,
              createdByUserName?: string | null,
              manualOperation?: boolean | null,
              isTesting?: boolean | null,
              useCopyTrade?: boolean | null,
              referenceId?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      signalID?: string | null,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    customFields?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCryptofyBotTradeHistoryMutationVariables = {
  input: DeleteCryptofyBotTradeHistoryInput,
  condition?: ModelCryptofyBotTradeHistoryConditionInput | null,
};

export type DeleteCryptofyBotTradeHistoryMutation = {
  deleteCryptofyBotTradeHistory?:  {
    __typename: "CryptofyBotTradeHistory",
    id: string,
    action: CryptofyBotTradeHistoryActions,
    message?: string | null,
    tradeId?: string | null,
    trade?:  {
      __typename: "CryptofyBotTrade",
      id: string,
      status: CryptofyBotTradeStatus,
      tokenName: string,
      symbol: string,
      exchange: SignalExchange,
      type: CryptofyBotTradeTypes,
      side?: CryptofyBotTradeSides | null,
      leverage?: number | null,
      signalEntry?: number | null,
      tradeEntry?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      signalStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      tradeStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      peakLossProfit?: number | null,
      currentLossProfit?: number | null,
      telegramSignalLink?: string | null,
      lastMonitorCheck?: string | null,
      lastPrice?: number | null,
      activationManagement?:  {
        __typename: "CryptofyBotActivationManagement",
        crossingTime?: number | null,
        crossingPrice?: number | null,
        wasWithinThreshold?: boolean | null,
        hasActivated?: boolean | null,
      } | null,
      customFields?: string | null,
      history?:  {
        __typename: "ModelCryptofyBotTradeHistoryConnection",
        items:  Array< {
          __typename: "CryptofyBotTradeHistory",
          id: string,
          action: CryptofyBotTradeHistoryActions,
          message?: string | null,
          tradeId?: string | null,
          trade?:  {
            __typename: "CryptofyBotTrade",
            id: string,
            status: CryptofyBotTradeStatus,
            tokenName: string,
            symbol: string,
            exchange: SignalExchange,
            type: CryptofyBotTradeTypes,
            side?: CryptofyBotTradeSides | null,
            leverage?: number | null,
            signalEntry?: number | null,
            tradeEntry?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            signalStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            tradeStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            peakLossProfit?: number | null,
            currentLossProfit?: number | null,
            telegramSignalLink?: string | null,
            lastMonitorCheck?: string | null,
            lastPrice?: number | null,
            activationManagement?:  {
              __typename: "CryptofyBotActivationManagement",
              crossingTime?: number | null,
              crossingPrice?: number | null,
              wasWithinThreshold?: boolean | null,
              hasActivated?: boolean | null,
            } | null,
            customFields?: string | null,
            history?:  {
              __typename: "ModelCryptofyBotTradeHistoryConnection",
              nextToken?: string | null,
            } | null,
            signalID?: string | null,
            signal?:  {
              __typename: "Signal",
              id: string,
              title?: string | null,
              type?: SignalType | null,
              status: SignalStatus,
              exchange: SignalExchange,
              isFutures?: boolean | null,
              video?: string | null,
              thumbnail?: string | null,
              trailingStopLoss?: number | null,
              description?: string | null,
              entryOnActivation?: number | null,
              entry?: number | null,
              futuresOperation?: SignalFuturesOperation | null,
              leverage?: number | null,
              interval?: string | null,
              tags?: Array< string | null > | null,
              link?: string | null,
              linkLabel?: string | null,
              totalGains?: number | null,
              totalGainsFutures?: number | null,
              lastPrice?: number | null,
              lastMonitorCheck?: string | null,
              takeProfitNum?: number | null,
              breakThroughTime?: number | null,
              breakThroughCount?: number | null,
              showOnFreeGroup?: boolean | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramEntryNotificationId?: number | null,
              telegramEntryNotificationDate?: string | null,
              tokenName?: string | null,
              tokenSymbol?: string | null,
              tokenAddress?: string | null,
              createdByName?: string | null,
              createdByUserName?: string | null,
              manualOperation?: boolean | null,
              isTesting?: boolean | null,
              useCopyTrade?: boolean | null,
              referenceId?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      signalID?: string | null,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    customFields?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateMarketPlaceProductMutationVariables = {
  input: CreateMarketPlaceProductInput,
  condition?: ModelMarketPlaceProductConditionInput | null,
};

export type CreateMarketPlaceProductMutation = {
  createMarketPlaceProduct?:  {
    __typename: "MarketPlaceProduct",
    id: string,
    slug: string,
    name: string,
    externalReference?: string | null,
    icon?: string | null,
    price?: number | null,
    description?: string | null,
    productType?: MarketPlaceProductType | null,
    status?: MarketPlaceProductStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMarketPlaceProductMutationVariables = {
  input: UpdateMarketPlaceProductInput,
  condition?: ModelMarketPlaceProductConditionInput | null,
};

export type UpdateMarketPlaceProductMutation = {
  updateMarketPlaceProduct?:  {
    __typename: "MarketPlaceProduct",
    id: string,
    slug: string,
    name: string,
    externalReference?: string | null,
    icon?: string | null,
    price?: number | null,
    description?: string | null,
    productType?: MarketPlaceProductType | null,
    status?: MarketPlaceProductStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMarketPlaceProductMutationVariables = {
  input: DeleteMarketPlaceProductInput,
  condition?: ModelMarketPlaceProductConditionInput | null,
};

export type DeleteMarketPlaceProductMutation = {
  deleteMarketPlaceProduct?:  {
    __typename: "MarketPlaceProduct",
    id: string,
    slug: string,
    name: string,
    externalReference?: string | null,
    icon?: string | null,
    price?: number | null,
    description?: string | null,
    productType?: MarketPlaceProductType | null,
    status?: MarketPlaceProductStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePriceAlertMutationVariables = {
  input: CreatePriceAlertInput,
  condition?: ModelPriceAlertConditionInput | null,
};

export type CreatePriceAlertMutation = {
  createPriceAlert?:  {
    __typename: "PriceAlert",
    id: string,
    status: PriceAlertStatus,
    type: PriceAlertType,
    price: string,
    description?: string | null,
    tokenName: string,
    tokenAddress: string,
    tokenSymbol: string,
    triggeredDate?: string | null,
    triggeredPrice?: number | null,
    username?: string | null,
    userEmail?: string | null,
    chain?: string | null,
    isFutures?: boolean | null,
    createdAlertAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePriceAlertMutationVariables = {
  input: UpdatePriceAlertInput,
  condition?: ModelPriceAlertConditionInput | null,
};

export type UpdatePriceAlertMutation = {
  updatePriceAlert?:  {
    __typename: "PriceAlert",
    id: string,
    status: PriceAlertStatus,
    type: PriceAlertType,
    price: string,
    description?: string | null,
    tokenName: string,
    tokenAddress: string,
    tokenSymbol: string,
    triggeredDate?: string | null,
    triggeredPrice?: number | null,
    username?: string | null,
    userEmail?: string | null,
    chain?: string | null,
    isFutures?: boolean | null,
    createdAlertAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePriceAlertMutationVariables = {
  input: DeletePriceAlertInput,
  condition?: ModelPriceAlertConditionInput | null,
};

export type DeletePriceAlertMutation = {
  deletePriceAlert?:  {
    __typename: "PriceAlert",
    id: string,
    status: PriceAlertStatus,
    type: PriceAlertType,
    price: string,
    description?: string | null,
    tokenName: string,
    tokenAddress: string,
    tokenSymbol: string,
    triggeredDate?: string | null,
    triggeredPrice?: number | null,
    username?: string | null,
    userEmail?: string | null,
    chain?: string | null,
    isFutures?: boolean | null,
    createdAlertAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateSignalMutationVariables = {
  input: CreateSignalInput,
  condition?: ModelSignalConditionInput | null,
};

export type CreateSignalMutation = {
  createSignal?:  {
    __typename: "Signal",
    id: string,
    title?: string | null,
    type?: SignalType | null,
    status: SignalStatus,
    exchange: SignalExchange,
    isFutures?: boolean | null,
    video?: string | null,
    thumbnail?: string | null,
    stopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    trailingStopLoss?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    minRetainer?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    description?: string | null,
    entryOnActivation?: number | null,
    entry?: number | null,
    futuresOperation?: SignalFuturesOperation | null,
    leverage?: number | null,
    interval?: string | null,
    tags?: Array< string | null > | null,
    link?: string | null,
    linkLabel?: string | null,
    totalGains?: number | null,
    totalGainsFutures?: number | null,
    lastPrice?: number | null,
    lastMonitorCheck?: string | null,
    takeProfitNum?: number | null,
    breakThroughTime?: number | null,
    breakThroughCount?: number | null,
    showOnFreeGroup?: boolean | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramEntryNotificationId?: number | null,
    telegramEntryNotificationDate?: string | null,
    tokenName?: string | null,
    tokenSymbol?: string | null,
    tokenAddress?: string | null,
    createdByName?: string | null,
    createdByUserName?: string | null,
    manualOperation?: boolean | null,
    isTesting?: boolean | null,
    useCopyTrade?: boolean | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateSignalMutationVariables = {
  input: UpdateSignalInput,
  condition?: ModelSignalConditionInput | null,
};

export type UpdateSignalMutation = {
  updateSignal?:  {
    __typename: "Signal",
    id: string,
    title?: string | null,
    type?: SignalType | null,
    status: SignalStatus,
    exchange: SignalExchange,
    isFutures?: boolean | null,
    video?: string | null,
    thumbnail?: string | null,
    stopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    trailingStopLoss?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    minRetainer?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    description?: string | null,
    entryOnActivation?: number | null,
    entry?: number | null,
    futuresOperation?: SignalFuturesOperation | null,
    leverage?: number | null,
    interval?: string | null,
    tags?: Array< string | null > | null,
    link?: string | null,
    linkLabel?: string | null,
    totalGains?: number | null,
    totalGainsFutures?: number | null,
    lastPrice?: number | null,
    lastMonitorCheck?: string | null,
    takeProfitNum?: number | null,
    breakThroughTime?: number | null,
    breakThroughCount?: number | null,
    showOnFreeGroup?: boolean | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramEntryNotificationId?: number | null,
    telegramEntryNotificationDate?: string | null,
    tokenName?: string | null,
    tokenSymbol?: string | null,
    tokenAddress?: string | null,
    createdByName?: string | null,
    createdByUserName?: string | null,
    manualOperation?: boolean | null,
    isTesting?: boolean | null,
    useCopyTrade?: boolean | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteSignalMutationVariables = {
  input: DeleteSignalInput,
  condition?: ModelSignalConditionInput | null,
};

export type DeleteSignalMutation = {
  deleteSignal?:  {
    __typename: "Signal",
    id: string,
    title?: string | null,
    type?: SignalType | null,
    status: SignalStatus,
    exchange: SignalExchange,
    isFutures?: boolean | null,
    video?: string | null,
    thumbnail?: string | null,
    stopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    trailingStopLoss?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    minRetainer?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    description?: string | null,
    entryOnActivation?: number | null,
    entry?: number | null,
    futuresOperation?: SignalFuturesOperation | null,
    leverage?: number | null,
    interval?: string | null,
    tags?: Array< string | null > | null,
    link?: string | null,
    linkLabel?: string | null,
    totalGains?: number | null,
    totalGainsFutures?: number | null,
    lastPrice?: number | null,
    lastMonitorCheck?: string | null,
    takeProfitNum?: number | null,
    breakThroughTime?: number | null,
    breakThroughCount?: number | null,
    showOnFreeGroup?: boolean | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramEntryNotificationId?: number | null,
    telegramEntryNotificationDate?: string | null,
    tokenName?: string | null,
    tokenSymbol?: string | null,
    tokenAddress?: string | null,
    createdByName?: string | null,
    createdByUserName?: string | null,
    manualOperation?: boolean | null,
    isTesting?: boolean | null,
    useCopyTrade?: boolean | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateTelegramBotStateMutationVariables = {
  input: CreateTelegramBotStateInput,
  condition?: ModelTelegramBotStateConditionInput | null,
};

export type CreateTelegramBotStateMutation = {
  createTelegramBotState?:  {
    __typename: "TelegramBotState",
    telegramId: string,
    nextStep?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTelegramBotStateMutationVariables = {
  input: UpdateTelegramBotStateInput,
  condition?: ModelTelegramBotStateConditionInput | null,
};

export type UpdateTelegramBotStateMutation = {
  updateTelegramBotState?:  {
    __typename: "TelegramBotState",
    telegramId: string,
    nextStep?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTelegramBotStateMutationVariables = {
  input: DeleteTelegramBotStateInput,
  condition?: ModelTelegramBotStateConditionInput | null,
};

export type DeleteTelegramBotStateMutation = {
  deleteTelegramBotState?:  {
    __typename: "TelegramBotState",
    telegramId: string,
    nextStep?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    username: string,
    email: string,
    name: string,
    initials?: string | null,
    photo?: string | null,
    lastLogin?: string | null,
    lastVisit?: string | null,
    status: UserStatus,
    statusReason?: string | null,
    membership: Membership,
    permissions?: Array< string | null > | null,
    permissionGroup: UserPermissionGroup,
    expirationDate?: string | null,
    dateOfRenewal?: string | null,
    memberSince?: string | null,
    termsAndConditions?: boolean | null,
    secretKey?: string | null,
    telegramGroups?:  Array< {
      __typename: "UserTelegramGroupAllowed",
      id?: string | null,
      name?: string | null,
      channel?: string | null,
      label?: string | null,
      link?: string | null,
    } | null > | null,
    telegramId?: string | null,
    searchName?: string | null,
    customerId?: string | null,
    currentPlanPeriod?: number | null,
    currentPlanName?: string | null,
    currentPlanId?: string | null,
    currentPlanPrice?: number | null,
    currentPlanCurrency?: string | null,
    subscriptionId?: string | null,
    markedAsCancelled?: boolean | null,
    isTrial?: boolean | null,
    isTestSubscription?: boolean | null,
    products?:  {
      __typename: "ModelUserProductConnection",
      items:  Array< {
        __typename: "UserProduct",
        productId: string,
        username: string,
        expirationDate?: string | null,
        status?: UserProductStatus | null,
        product?:  {
          __typename: "MarketPlaceProduct",
          id: string,
          slug: string,
          name: string,
          externalReference?: string | null,
          icon?: string | null,
          price?: number | null,
          description?: string | null,
          productType?: MarketPlaceProductType | null,
          status?: MarketPlaceProductStatus | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    username: string,
    email: string,
    name: string,
    initials?: string | null,
    photo?: string | null,
    lastLogin?: string | null,
    lastVisit?: string | null,
    status: UserStatus,
    statusReason?: string | null,
    membership: Membership,
    permissions?: Array< string | null > | null,
    permissionGroup: UserPermissionGroup,
    expirationDate?: string | null,
    dateOfRenewal?: string | null,
    memberSince?: string | null,
    termsAndConditions?: boolean | null,
    secretKey?: string | null,
    telegramGroups?:  Array< {
      __typename: "UserTelegramGroupAllowed",
      id?: string | null,
      name?: string | null,
      channel?: string | null,
      label?: string | null,
      link?: string | null,
    } | null > | null,
    telegramId?: string | null,
    searchName?: string | null,
    customerId?: string | null,
    currentPlanPeriod?: number | null,
    currentPlanName?: string | null,
    currentPlanId?: string | null,
    currentPlanPrice?: number | null,
    currentPlanCurrency?: string | null,
    subscriptionId?: string | null,
    markedAsCancelled?: boolean | null,
    isTrial?: boolean | null,
    isTestSubscription?: boolean | null,
    products?:  {
      __typename: "ModelUserProductConnection",
      items:  Array< {
        __typename: "UserProduct",
        productId: string,
        username: string,
        expirationDate?: string | null,
        status?: UserProductStatus | null,
        product?:  {
          __typename: "MarketPlaceProduct",
          id: string,
          slug: string,
          name: string,
          externalReference?: string | null,
          icon?: string | null,
          price?: number | null,
          description?: string | null,
          productType?: MarketPlaceProductType | null,
          status?: MarketPlaceProductStatus | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    username: string,
    email: string,
    name: string,
    initials?: string | null,
    photo?: string | null,
    lastLogin?: string | null,
    lastVisit?: string | null,
    status: UserStatus,
    statusReason?: string | null,
    membership: Membership,
    permissions?: Array< string | null > | null,
    permissionGroup: UserPermissionGroup,
    expirationDate?: string | null,
    dateOfRenewal?: string | null,
    memberSince?: string | null,
    termsAndConditions?: boolean | null,
    secretKey?: string | null,
    telegramGroups?:  Array< {
      __typename: "UserTelegramGroupAllowed",
      id?: string | null,
      name?: string | null,
      channel?: string | null,
      label?: string | null,
      link?: string | null,
    } | null > | null,
    telegramId?: string | null,
    searchName?: string | null,
    customerId?: string | null,
    currentPlanPeriod?: number | null,
    currentPlanName?: string | null,
    currentPlanId?: string | null,
    currentPlanPrice?: number | null,
    currentPlanCurrency?: string | null,
    subscriptionId?: string | null,
    markedAsCancelled?: boolean | null,
    isTrial?: boolean | null,
    isTestSubscription?: boolean | null,
    products?:  {
      __typename: "ModelUserProductConnection",
      items:  Array< {
        __typename: "UserProduct",
        productId: string,
        username: string,
        expirationDate?: string | null,
        status?: UserProductStatus | null,
        product?:  {
          __typename: "MarketPlaceProduct",
          id: string,
          slug: string,
          name: string,
          externalReference?: string | null,
          icon?: string | null,
          price?: number | null,
          description?: string | null,
          productType?: MarketPlaceProductType | null,
          status?: MarketPlaceProductStatus | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserSignInActivityMutationVariables = {
  input: CreateUserSignInActivityInput,
  condition?: ModelUserSignInActivityConditionInput | null,
};

export type CreateUserSignInActivityMutation = {
  createUserSignInActivity?:  {
    __typename: "UserSignInActivity",
    id: string,
    device?: string | null,
    browser?: string | null,
    ip?: string | null,
    city?: string | null,
    country?: string | null,
    timezone?: string | null,
    date?: string | null,
    username?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserSignInActivityMutationVariables = {
  input: UpdateUserSignInActivityInput,
  condition?: ModelUserSignInActivityConditionInput | null,
};

export type UpdateUserSignInActivityMutation = {
  updateUserSignInActivity?:  {
    __typename: "UserSignInActivity",
    id: string,
    device?: string | null,
    browser?: string | null,
    ip?: string | null,
    city?: string | null,
    country?: string | null,
    timezone?: string | null,
    date?: string | null,
    username?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserSignInActivityMutationVariables = {
  input: DeleteUserSignInActivityInput,
  condition?: ModelUserSignInActivityConditionInput | null,
};

export type DeleteUserSignInActivityMutation = {
  deleteUserSignInActivity?:  {
    __typename: "UserSignInActivity",
    id: string,
    device?: string | null,
    browser?: string | null,
    ip?: string | null,
    city?: string | null,
    country?: string | null,
    timezone?: string | null,
    date?: string | null,
    username?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateUserProductMutationVariables = {
  input: CreateUserProductInput,
  condition?: ModelUserProductConditionInput | null,
};

export type CreateUserProductMutation = {
  createUserProduct?:  {
    __typename: "UserProduct",
    productId: string,
    username: string,
    expirationDate?: string | null,
    status?: UserProductStatus | null,
    product?:  {
      __typename: "MarketPlaceProduct",
      id: string,
      slug: string,
      name: string,
      externalReference?: string | null,
      icon?: string | null,
      price?: number | null,
      description?: string | null,
      productType?: MarketPlaceProductType | null,
      status?: MarketPlaceProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserProductMutationVariables = {
  input: UpdateUserProductInput,
  condition?: ModelUserProductConditionInput | null,
};

export type UpdateUserProductMutation = {
  updateUserProduct?:  {
    __typename: "UserProduct",
    productId: string,
    username: string,
    expirationDate?: string | null,
    status?: UserProductStatus | null,
    product?:  {
      __typename: "MarketPlaceProduct",
      id: string,
      slug: string,
      name: string,
      externalReference?: string | null,
      icon?: string | null,
      price?: number | null,
      description?: string | null,
      productType?: MarketPlaceProductType | null,
      status?: MarketPlaceProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserProductMutationVariables = {
  input: DeleteUserProductInput,
  condition?: ModelUserProductConditionInput | null,
};

export type DeleteUserProductMutation = {
  deleteUserProduct?:  {
    __typename: "UserProduct",
    productId: string,
    username: string,
    expirationDate?: string | null,
    status?: UserProductStatus | null,
    product?:  {
      __typename: "MarketPlaceProduct",
      id: string,
      slug: string,
      name: string,
      externalReference?: string | null,
      icon?: string | null,
      price?: number | null,
      description?: string | null,
      productType?: MarketPlaceProductType | null,
      status?: MarketPlaceProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateStripeCheckoutSessionMutationVariables = {
  input: CreateStripeCheckoutSessionInput,
};

export type CreateStripeCheckoutSessionMutation = {
  createStripeCheckoutSession:  {
    __typename: "CreateStripeCheckoutSessionResult",
    sessionId: string,
  },
};

export type UpdateStripeSubscriptionMutationVariables = {
  input: UpdateStripeSubscriptionInput,
};

export type UpdateStripeSubscriptionMutation = {
  updateStripeSubscription?: string | null,
};

export type SendNewsletterMutationVariables = {
  input?: SendNewsletterInput | null,
};

export type SendNewsletterMutation = {
  sendNewsletter?: boolean | null,
};

export type SubscribeToMailingListMutationVariables = {
  input?: SubscribeToMailingListInput | null,
};

export type SubscribeToMailingListMutation = {
  subscribeToMailingList?:  {
    __typename: "SubscribeToMailingListResult",
    data?:  {
      __typename: "SubscribeMailingListData",
      Code?: number | null,
      Context?: string | null,
      Error?: string | null,
    } | null,
  } | null,
};

export type SearchTokenQueryVariables = {
  input?: SearchTokenInput | null,
};

export type SearchTokenQuery = {
  searchToken?:  Array< {
    __typename: "SearchToken",
    name: string,
    address: string,
    symbol: string,
    decimals?: string | null,
    chain?: string | null,
    tags?: Array< string | null > | null,
  } | null > | null,
};

export type GetListTokensFromS3QueryVariables = {
  input?: ListTokenFromS3Input | null,
};

export type GetListTokensFromS3Query = {
  getListTokensFromS3?:  Array< {
    __typename: "ListTokenFromS3Result",
    address?: string | null,
    name?: string | null,
    symbol?: string | null,
    network?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    decimals?: number | null,
  } | null > | null,
};

export type GetTelegramParticipantsQueryVariables = {
  input?: GetTelegramParticipantsInput | null,
};

export type GetTelegramParticipantsQuery = {
  getTelegramParticipants?:  Array< {
    __typename: "TelegramParticipantsUser",
    telegramId: string,
    isAdmin?: boolean | null,
    name?: string | null,
    username?: string | null,
    isBot?: boolean | null,
    phone?: string | null,
    status?: string | null,
  } | null > | null,
};

export type GetAdQueryVariables = {
  id: string,
};

export type GetAdQuery = {
  getAd?:  {
    __typename: "Ad",
    id: string,
    title?: string | null,
    frequency?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    dates?: Array< string | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessage",
      photo?: string | null,
      message?: string | null,
      callbackActions?:  Array< {
        __typename: "TelegramCallbackActions",
        url?: string | null,
        text?: string | null,
      } | null > | null,
    } | null > | null,
    adType?: AdType | null,
    status: AdStatus,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListAdsQueryVariables = {
  filter?: ModelAdFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdsQuery = {
  listAds?:  {
    __typename: "ModelAdConnection",
    items:  Array< {
      __typename: "Ad",
      id: string,
      title?: string | null,
      frequency?: number | null,
      startDate?: string | null,
      endDate?: string | null,
      dates?: Array< string | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessage",
        photo?: string | null,
        message?: string | null,
        callbackActions?:  Array< {
          __typename: "TelegramCallbackActions",
          url?: string | null,
          text?: string | null,
        } | null > | null,
      } | null > | null,
      adType?: AdType | null,
      status: AdStatus,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAutoTradingBotConfigQueryVariables = {
  id: string,
};

export type GetAutoTradingBotConfigQuery = {
  getAutoTradingBotConfig?:  {
    __typename: "AutoTradingBotConfig",
    id: string,
    name: string,
    apiKey: string,
    apiSecret: string,
    exchange?: AutoTradingExchange | null,
    amountToTrade?: number | null,
    quoteAsset?: string | null,
    maxNumTrades?: number | null,
    fixedLeverage?: number | null,
    fixedStopLoss?: number | null,
    type: AutoTradingBotConfigTypes,
    status: AutoTradingBotConfigStatus,
    username: string,
    user?:  {
      __typename: "User",
      username: string,
      email: string,
      name: string,
      initials?: string | null,
      photo?: string | null,
      lastLogin?: string | null,
      lastVisit?: string | null,
      status: UserStatus,
      statusReason?: string | null,
      membership: Membership,
      permissions?: Array< string | null > | null,
      permissionGroup: UserPermissionGroup,
      expirationDate?: string | null,
      dateOfRenewal?: string | null,
      memberSince?: string | null,
      termsAndConditions?: boolean | null,
      secretKey?: string | null,
      telegramGroups?:  Array< {
        __typename: "UserTelegramGroupAllowed",
        id?: string | null,
        name?: string | null,
        channel?: string | null,
        label?: string | null,
        link?: string | null,
      } | null > | null,
      telegramId?: string | null,
      searchName?: string | null,
      customerId?: string | null,
      currentPlanPeriod?: number | null,
      currentPlanName?: string | null,
      currentPlanId?: string | null,
      currentPlanPrice?: number | null,
      currentPlanCurrency?: string | null,
      subscriptionId?: string | null,
      markedAsCancelled?: boolean | null,
      isTrial?: boolean | null,
      isTestSubscription?: boolean | null,
      products?:  {
        __typename: "ModelUserProductConnection",
        items:  Array< {
          __typename: "UserProduct",
          productId: string,
          username: string,
          expirationDate?: string | null,
          status?: UserProductStatus | null,
          product?:  {
            __typename: "MarketPlaceProduct",
            id: string,
            slug: string,
            name: string,
            externalReference?: string | null,
            icon?: string | null,
            price?: number | null,
            description?: string | null,
            productType?: MarketPlaceProductType | null,
            status?: MarketPlaceProductStatus | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAutoTradingBotConfigsQueryVariables = {
  filter?: ModelAutoTradingBotConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAutoTradingBotConfigsQuery = {
  listAutoTradingBotConfigs?:  {
    __typename: "ModelAutoTradingBotConfigConnection",
    items:  Array< {
      __typename: "AutoTradingBotConfig",
      id: string,
      name: string,
      apiKey: string,
      apiSecret: string,
      exchange?: AutoTradingExchange | null,
      amountToTrade?: number | null,
      quoteAsset?: string | null,
      maxNumTrades?: number | null,
      fixedLeverage?: number | null,
      fixedStopLoss?: number | null,
      type: AutoTradingBotConfigTypes,
      status: AutoTradingBotConfigStatus,
      username: string,
      user?:  {
        __typename: "User",
        username: string,
        email: string,
        name: string,
        initials?: string | null,
        photo?: string | null,
        lastLogin?: string | null,
        lastVisit?: string | null,
        status: UserStatus,
        statusReason?: string | null,
        membership: Membership,
        permissions?: Array< string | null > | null,
        permissionGroup: UserPermissionGroup,
        expirationDate?: string | null,
        dateOfRenewal?: string | null,
        memberSince?: string | null,
        termsAndConditions?: boolean | null,
        secretKey?: string | null,
        telegramGroups?:  Array< {
          __typename: "UserTelegramGroupAllowed",
          id?: string | null,
          name?: string | null,
          channel?: string | null,
          label?: string | null,
          link?: string | null,
        } | null > | null,
        telegramId?: string | null,
        searchName?: string | null,
        customerId?: string | null,
        currentPlanPeriod?: number | null,
        currentPlanName?: string | null,
        currentPlanId?: string | null,
        currentPlanPrice?: number | null,
        currentPlanCurrency?: string | null,
        subscriptionId?: string | null,
        markedAsCancelled?: boolean | null,
        isTrial?: boolean | null,
        isTestSubscription?: boolean | null,
        products?:  {
          __typename: "ModelUserProductConnection",
          items:  Array< {
            __typename: "UserProduct",
            productId: string,
            username: string,
            expirationDate?: string | null,
            status?: UserProductStatus | null,
            product?:  {
              __typename: "MarketPlaceProduct",
              id: string,
              slug: string,
              name: string,
              externalReference?: string | null,
              icon?: string | null,
              price?: number | null,
              description?: string | null,
              productType?: MarketPlaceProductType | null,
              status?: MarketPlaceProductStatus | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAutoTradingBotTradeQueryVariables = {
  id: string,
};

export type GetAutoTradingBotTradeQuery = {
  getAutoTradingBotTrade?:  {
    __typename: "AutoTradingBotTrade",
    id: string,
    exchange?: AutoTradingExchange | null,
    type?: AutoTradingBotConfigTypes | null,
    tokenName?: string | null,
    symbol?: string | null,
    side?: string | null,
    amount?: number | null,
    stopLossPrice?: number | null,
    entryPrice?: number | null,
    closedPrice?: number | null,
    quantity?: number | null,
    leverage?: number | null,
    createOrderId?: string | null,
    createOrderLinkId?: string | null,
    stopLossOrderId?: string | null,
    closedOrderId?: string | null,
    status?: AutoTradingBotTradeStatus | null,
    telegramSignalLink?: string | null,
    pnlAmount?: number | null,
    pnlPercentage?: number | null,
    quoteAsset?: string | null,
    username?: string | null,
    signalID: string,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAutoTradingBotTradesQueryVariables = {
  filter?: ModelAutoTradingBotTradeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAutoTradingBotTradesQuery = {
  listAutoTradingBotTrades?:  {
    __typename: "ModelAutoTradingBotTradeConnection",
    items:  Array< {
      __typename: "AutoTradingBotTrade",
      id: string,
      exchange?: AutoTradingExchange | null,
      type?: AutoTradingBotConfigTypes | null,
      tokenName?: string | null,
      symbol?: string | null,
      side?: string | null,
      amount?: number | null,
      stopLossPrice?: number | null,
      entryPrice?: number | null,
      closedPrice?: number | null,
      quantity?: number | null,
      leverage?: number | null,
      createOrderId?: string | null,
      createOrderLinkId?: string | null,
      stopLossOrderId?: string | null,
      closedOrderId?: string | null,
      status?: AutoTradingBotTradeStatus | null,
      telegramSignalLink?: string | null,
      pnlAmount?: number | null,
      pnlPercentage?: number | null,
      quoteAsset?: string | null,
      username?: string | null,
      signalID: string,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBotTradeByUsernameAndSignalIdQueryVariables = {
  username: string,
  signalID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAutoTradingBotTradeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetBotTradeByUsernameAndSignalIdQuery = {
  getBotTradeByUsernameAndSignalId?:  {
    __typename: "ModelAutoTradingBotTradeConnection",
    items:  Array< {
      __typename: "AutoTradingBotTrade",
      id: string,
      exchange?: AutoTradingExchange | null,
      type?: AutoTradingBotConfigTypes | null,
      tokenName?: string | null,
      symbol?: string | null,
      side?: string | null,
      amount?: number | null,
      stopLossPrice?: number | null,
      entryPrice?: number | null,
      closedPrice?: number | null,
      quantity?: number | null,
      leverage?: number | null,
      createOrderId?: string | null,
      createOrderLinkId?: string | null,
      stopLossOrderId?: string | null,
      closedOrderId?: string | null,
      status?: AutoTradingBotTradeStatus | null,
      telegramSignalLink?: string | null,
      pnlAmount?: number | null,
      pnlPercentage?: number | null,
      quoteAsset?: string | null,
      username?: string | null,
      signalID: string,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAutoTradingBotHistoryQueryVariables = {
  id: string,
};

export type GetAutoTradingBotHistoryQuery = {
  getAutoTradingBotHistory?:  {
    __typename: "AutoTradingBotHistory",
    id: string,
    exchange?: AutoTradingExchange | null,
    symbol?: string | null,
    tokenName?: string | null,
    side?: string | null,
    leverage?: number | null,
    action?: string | null,
    content?: string | null,
    payload?: string | null,
    totalGains?: number | null,
    username?: string | null,
    orderID?: string | null,
    type?: AutoTradingBotHistoryType | null,
    signalID?: string | null,
    autoTradingID?: string | null,
    autoTradeInfo?:  {
      __typename: "AutoTradingBotTrade",
      id: string,
      exchange?: AutoTradingExchange | null,
      type?: AutoTradingBotConfigTypes | null,
      tokenName?: string | null,
      symbol?: string | null,
      side?: string | null,
      amount?: number | null,
      stopLossPrice?: number | null,
      entryPrice?: number | null,
      closedPrice?: number | null,
      quantity?: number | null,
      leverage?: number | null,
      createOrderId?: string | null,
      createOrderLinkId?: string | null,
      stopLossOrderId?: string | null,
      closedOrderId?: string | null,
      status?: AutoTradingBotTradeStatus | null,
      telegramSignalLink?: string | null,
      pnlAmount?: number | null,
      pnlPercentage?: number | null,
      quoteAsset?: string | null,
      username?: string | null,
      signalID: string,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAutoTradingBotHistoriesQueryVariables = {
  filter?: ModelAutoTradingBotHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAutoTradingBotHistoriesQuery = {
  listAutoTradingBotHistories?:  {
    __typename: "ModelAutoTradingBotHistoryConnection",
    items:  Array< {
      __typename: "AutoTradingBotHistory",
      id: string,
      exchange?: AutoTradingExchange | null,
      symbol?: string | null,
      tokenName?: string | null,
      side?: string | null,
      leverage?: number | null,
      action?: string | null,
      content?: string | null,
      payload?: string | null,
      totalGains?: number | null,
      username?: string | null,
      orderID?: string | null,
      type?: AutoTradingBotHistoryType | null,
      signalID?: string | null,
      autoTradingID?: string | null,
      autoTradeInfo?:  {
        __typename: "AutoTradingBotTrade",
        id: string,
        exchange?: AutoTradingExchange | null,
        type?: AutoTradingBotConfigTypes | null,
        tokenName?: string | null,
        symbol?: string | null,
        side?: string | null,
        amount?: number | null,
        stopLossPrice?: number | null,
        entryPrice?: number | null,
        closedPrice?: number | null,
        quantity?: number | null,
        leverage?: number | null,
        createOrderId?: string | null,
        createOrderLinkId?: string | null,
        stopLossOrderId?: string | null,
        closedOrderId?: string | null,
        status?: AutoTradingBotTradeStatus | null,
        telegramSignalLink?: string | null,
        pnlAmount?: number | null,
        pnlPercentage?: number | null,
        quoteAsset?: string | null,
        username?: string | null,
        signalID: string,
        signal?:  {
          __typename: "Signal",
          id: string,
          title?: string | null,
          type?: SignalType | null,
          status: SignalStatus,
          exchange: SignalExchange,
          isFutures?: boolean | null,
          video?: string | null,
          thumbnail?: string | null,
          stopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          trailingStopLoss?: number | null,
          targets?:  Array< {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null > | null,
          minRetainer?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          description?: string | null,
          entryOnActivation?: number | null,
          entry?: number | null,
          futuresOperation?: SignalFuturesOperation | null,
          leverage?: number | null,
          interval?: string | null,
          tags?: Array< string | null > | null,
          link?: string | null,
          linkLabel?: string | null,
          totalGains?: number | null,
          totalGainsFutures?: number | null,
          lastPrice?: number | null,
          lastMonitorCheck?: string | null,
          takeProfitNum?: number | null,
          breakThroughTime?: number | null,
          breakThroughCount?: number | null,
          showOnFreeGroup?: boolean | null,
          telegramMessages?:  Array< {
            __typename: "TelegramMessageResult",
            channel?: string | null,
            id?: string | null,
            link?: string | null,
          } | null > | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramEntryNotificationId?: number | null,
          telegramEntryNotificationDate?: string | null,
          tokenName?: string | null,
          tokenSymbol?: string | null,
          tokenAddress?: string | null,
          createdByName?: string | null,
          createdByUserName?: string | null,
          manualOperation?: boolean | null,
          isTesting?: boolean | null,
          useCopyTrade?: boolean | null,
          referenceId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCollectionQueryVariables = {
  id: string,
};

export type GetCollectionQuery = {
  getCollection?:  {
    __typename: "Collection",
    id: string,
    title: string,
    description: string,
    status: CollectionStatus,
    tags?: Array< string | null > | null,
    contents?:  {
      __typename: "ModelCollectionContentConnection",
      items:  Array< {
        __typename: "CollectionContent",
        id: string,
        slug?: string | null,
        order: number,
        title: string,
        description: string,
        body?: string | null,
        thumbnail?: string | null,
        image?: string | null,
        video?: string | null,
        duration?: number | null,
        date?: string | null,
        attachments?:  Array< {
          __typename: "CollectionAttachment",
          file?: string | null,
          url?: string | null,
          text?: string | null,
          mimeType?: string | null,
        } | null > | null,
        status: CollectionStatus,
        collectionID: string,
        collection?:  {
          __typename: "Collection",
          id: string,
          title: string,
          description: string,
          status: CollectionStatus,
          tags?: Array< string | null > | null,
          contents?:  {
            __typename: "ModelCollectionContentConnection",
            items:  Array< {
              __typename: "CollectionContent",
              id: string,
              slug?: string | null,
              order: number,
              title: string,
              description: string,
              body?: string | null,
              thumbnail?: string | null,
              image?: string | null,
              video?: string | null,
              duration?: number | null,
              date?: string | null,
              status: CollectionStatus,
              collectionID: string,
              authorName?: string | null,
              authorUsername?: string | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramTemplate?: string | null,
              type?: CollectionContentType | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramTemplate?: string | null,
          thumbnail?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            contentID: string,
            body: string,
            createdByName?: string | null,
            createdByUserName?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        authorName?: string | null,
        authorUsername?: string | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramTemplate?: string | null,
        type?: CollectionContentType | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramTemplate?: string | null,
    thumbnail?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCollectionsQueryVariables = {
  filter?: ModelCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCollectionsQuery = {
  listCollections?:  {
    __typename: "ModelCollectionConnection",
    items:  Array< {
      __typename: "Collection",
      id: string,
      title: string,
      description: string,
      status: CollectionStatus,
      tags?: Array< string | null > | null,
      contents?:  {
        __typename: "ModelCollectionContentConnection",
        items:  Array< {
          __typename: "CollectionContent",
          id: string,
          slug?: string | null,
          order: number,
          title: string,
          description: string,
          body?: string | null,
          thumbnail?: string | null,
          image?: string | null,
          video?: string | null,
          duration?: number | null,
          date?: string | null,
          attachments?:  Array< {
            __typename: "CollectionAttachment",
            file?: string | null,
            url?: string | null,
            text?: string | null,
            mimeType?: string | null,
          } | null > | null,
          status: CollectionStatus,
          collectionID: string,
          collection?:  {
            __typename: "Collection",
            id: string,
            title: string,
            description: string,
            status: CollectionStatus,
            tags?: Array< string | null > | null,
            contents?:  {
              __typename: "ModelCollectionContentConnection",
              nextToken?: string | null,
            } | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramTemplate?: string | null,
            thumbnail?: string | null,
            order?: number | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              contentID: string,
              body: string,
              createdByName?: string | null,
              createdByUserName?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          authorName?: string | null,
          authorUsername?: string | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramMessages?:  Array< {
            __typename: "TelegramMessageResult",
            channel?: string | null,
            id?: string | null,
            link?: string | null,
          } | null > | null,
          telegramTemplate?: string | null,
          type?: CollectionContentType | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramTemplate?: string | null,
      thumbnail?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCollectionContentQueryVariables = {
  id: string,
};

export type GetCollectionContentQuery = {
  getCollectionContent?:  {
    __typename: "CollectionContent",
    id: string,
    slug?: string | null,
    order: number,
    title: string,
    description: string,
    body?: string | null,
    thumbnail?: string | null,
    image?: string | null,
    video?: string | null,
    duration?: number | null,
    date?: string | null,
    attachments?:  Array< {
      __typename: "CollectionAttachment",
      file?: string | null,
      url?: string | null,
      text?: string | null,
      mimeType?: string | null,
    } | null > | null,
    status: CollectionStatus,
    collectionID: string,
    collection?:  {
      __typename: "Collection",
      id: string,
      title: string,
      description: string,
      status: CollectionStatus,
      tags?: Array< string | null > | null,
      contents?:  {
        __typename: "ModelCollectionContentConnection",
        items:  Array< {
          __typename: "CollectionContent",
          id: string,
          slug?: string | null,
          order: number,
          title: string,
          description: string,
          body?: string | null,
          thumbnail?: string | null,
          image?: string | null,
          video?: string | null,
          duration?: number | null,
          date?: string | null,
          attachments?:  Array< {
            __typename: "CollectionAttachment",
            file?: string | null,
            url?: string | null,
            text?: string | null,
            mimeType?: string | null,
          } | null > | null,
          status: CollectionStatus,
          collectionID: string,
          collection?:  {
            __typename: "Collection",
            id: string,
            title: string,
            description: string,
            status: CollectionStatus,
            tags?: Array< string | null > | null,
            contents?:  {
              __typename: "ModelCollectionContentConnection",
              nextToken?: string | null,
            } | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramTemplate?: string | null,
            thumbnail?: string | null,
            order?: number | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              contentID: string,
              body: string,
              createdByName?: string | null,
              createdByUserName?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          authorName?: string | null,
          authorUsername?: string | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramMessages?:  Array< {
            __typename: "TelegramMessageResult",
            channel?: string | null,
            id?: string | null,
            link?: string | null,
          } | null > | null,
          telegramTemplate?: string | null,
          type?: CollectionContentType | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramTemplate?: string | null,
      thumbnail?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        contentID: string,
        body: string,
        createdByName?: string | null,
        createdByUserName?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    authorName?: string | null,
    authorUsername?: string | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramTemplate?: string | null,
    type?: CollectionContentType | null,
    customFields?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCollectionContentsQueryVariables = {
  filter?: ModelCollectionContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCollectionContentsQuery = {
  listCollectionContents?:  {
    __typename: "ModelCollectionContentConnection",
    items:  Array< {
      __typename: "CollectionContent",
      id: string,
      slug?: string | null,
      order: number,
      title: string,
      description: string,
      body?: string | null,
      thumbnail?: string | null,
      image?: string | null,
      video?: string | null,
      duration?: number | null,
      date?: string | null,
      attachments?:  Array< {
        __typename: "CollectionAttachment",
        file?: string | null,
        url?: string | null,
        text?: string | null,
        mimeType?: string | null,
      } | null > | null,
      status: CollectionStatus,
      collectionID: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        title: string,
        description: string,
        status: CollectionStatus,
        tags?: Array< string | null > | null,
        contents?:  {
          __typename: "ModelCollectionContentConnection",
          items:  Array< {
            __typename: "CollectionContent",
            id: string,
            slug?: string | null,
            order: number,
            title: string,
            description: string,
            body?: string | null,
            thumbnail?: string | null,
            image?: string | null,
            video?: string | null,
            duration?: number | null,
            date?: string | null,
            attachments?:  Array< {
              __typename: "CollectionAttachment",
              file?: string | null,
              url?: string | null,
              text?: string | null,
              mimeType?: string | null,
            } | null > | null,
            status: CollectionStatus,
            collectionID: string,
            collection?:  {
              __typename: "Collection",
              id: string,
              title: string,
              description: string,
              status: CollectionStatus,
              tags?: Array< string | null > | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramTemplate?: string | null,
              thumbnail?: string | null,
              order?: number | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              nextToken?: string | null,
            } | null,
            authorName?: string | null,
            authorUsername?: string | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramMessages?:  Array< {
              __typename: "TelegramMessageResult",
              channel?: string | null,
              id?: string | null,
              link?: string | null,
            } | null > | null,
            telegramTemplate?: string | null,
            type?: CollectionContentType | null,
            customFields?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramTemplate?: string | null,
        thumbnail?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          contentID: string,
          body: string,
          createdByName?: string | null,
          createdByUserName?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      authorName?: string | null,
      authorUsername?: string | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramTemplate?: string | null,
      type?: CollectionContentType | null,
      customFields?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    id: string,
    contentID: string,
    body: string,
    createdByName?: string | null,
    createdByUserName?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      contentID: string,
      body: string,
      createdByName?: string | null,
      createdByUserName?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLessonProgressQueryVariables = {
  collectionContentId: string,
  username: string,
};

export type GetLessonProgressQuery = {
  getLessonProgress?:  {
    __typename: "LessonProgress",
    collectionContentId: string,
    username: string,
    progress?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListLessonProgressesQueryVariables = {
  collectionContentId?: string | null,
  username?: ModelStringKeyConditionInput | null,
  filter?: ModelLessonProgressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLessonProgressesQuery = {
  listLessonProgresses?:  {
    __typename: "ModelLessonProgressConnection",
    items:  Array< {
      __typename: "LessonProgress",
      collectionContentId: string,
      username: string,
      progress?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCourseLessonProgressByUsernameQueryVariables = {
  username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLessonProgressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCourseLessonProgressByUsernameQuery = {
  listCourseLessonProgressByUsername?:  {
    __typename: "ModelLessonProgressConnection",
    items:  Array< {
      __typename: "LessonProgress",
      collectionContentId: string,
      username: string,
      progress?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCryptofyBotTradeQueryVariables = {
  id: string,
};

export type GetCryptofyBotTradeQuery = {
  getCryptofyBotTrade?:  {
    __typename: "CryptofyBotTrade",
    id: string,
    status: CryptofyBotTradeStatus,
    tokenName: string,
    symbol: string,
    exchange: SignalExchange,
    type: CryptofyBotTradeTypes,
    side?: CryptofyBotTradeSides | null,
    leverage?: number | null,
    signalEntry?: number | null,
    tradeEntry?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    signalStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    tradeStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    peakLossProfit?: number | null,
    currentLossProfit?: number | null,
    telegramSignalLink?: string | null,
    lastMonitorCheck?: string | null,
    lastPrice?: number | null,
    activationManagement?:  {
      __typename: "CryptofyBotActivationManagement",
      crossingTime?: number | null,
      crossingPrice?: number | null,
      wasWithinThreshold?: boolean | null,
      hasActivated?: boolean | null,
    } | null,
    customFields?: string | null,
    history?:  {
      __typename: "ModelCryptofyBotTradeHistoryConnection",
      items:  Array< {
        __typename: "CryptofyBotTradeHistory",
        id: string,
        action: CryptofyBotTradeHistoryActions,
        message?: string | null,
        tradeId?: string | null,
        trade?:  {
          __typename: "CryptofyBotTrade",
          id: string,
          status: CryptofyBotTradeStatus,
          tokenName: string,
          symbol: string,
          exchange: SignalExchange,
          type: CryptofyBotTradeTypes,
          side?: CryptofyBotTradeSides | null,
          leverage?: number | null,
          signalEntry?: number | null,
          tradeEntry?: number | null,
          targets?:  Array< {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null > | null,
          signalStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          tradeStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          peakLossProfit?: number | null,
          currentLossProfit?: number | null,
          telegramSignalLink?: string | null,
          lastMonitorCheck?: string | null,
          lastPrice?: number | null,
          activationManagement?:  {
            __typename: "CryptofyBotActivationManagement",
            crossingTime?: number | null,
            crossingPrice?: number | null,
            wasWithinThreshold?: boolean | null,
            hasActivated?: boolean | null,
          } | null,
          customFields?: string | null,
          history?:  {
            __typename: "ModelCryptofyBotTradeHistoryConnection",
            items:  Array< {
              __typename: "CryptofyBotTradeHistory",
              id: string,
              action: CryptofyBotTradeHistoryActions,
              message?: string | null,
              tradeId?: string | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          signalID?: string | null,
          signal?:  {
            __typename: "Signal",
            id: string,
            title?: string | null,
            type?: SignalType | null,
            status: SignalStatus,
            exchange: SignalExchange,
            isFutures?: boolean | null,
            video?: string | null,
            thumbnail?: string | null,
            stopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            trailingStopLoss?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            minRetainer?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            description?: string | null,
            entryOnActivation?: number | null,
            entry?: number | null,
            futuresOperation?: SignalFuturesOperation | null,
            leverage?: number | null,
            interval?: string | null,
            tags?: Array< string | null > | null,
            link?: string | null,
            linkLabel?: string | null,
            totalGains?: number | null,
            totalGainsFutures?: number | null,
            lastPrice?: number | null,
            lastMonitorCheck?: string | null,
            takeProfitNum?: number | null,
            breakThroughTime?: number | null,
            breakThroughCount?: number | null,
            showOnFreeGroup?: boolean | null,
            telegramMessages?:  Array< {
              __typename: "TelegramMessageResult",
              channel?: string | null,
              id?: string | null,
              link?: string | null,
            } | null > | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramEntryNotificationId?: number | null,
            telegramEntryNotificationDate?: string | null,
            tokenName?: string | null,
            tokenSymbol?: string | null,
            tokenAddress?: string | null,
            createdByName?: string | null,
            createdByUserName?: string | null,
            manualOperation?: boolean | null,
            isTesting?: boolean | null,
            useCopyTrade?: boolean | null,
            referenceId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    signalID?: string | null,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCryptofyBotTradesQueryVariables = {
  id?: string | null,
  filter?: ModelCryptofyBotTradeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCryptofyBotTradesQuery = {
  listCryptofyBotTrades?:  {
    __typename: "ModelCryptofyBotTradeConnection",
    items:  Array< {
      __typename: "CryptofyBotTrade",
      id: string,
      status: CryptofyBotTradeStatus,
      tokenName: string,
      symbol: string,
      exchange: SignalExchange,
      type: CryptofyBotTradeTypes,
      side?: CryptofyBotTradeSides | null,
      leverage?: number | null,
      signalEntry?: number | null,
      tradeEntry?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      signalStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      tradeStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      peakLossProfit?: number | null,
      currentLossProfit?: number | null,
      telegramSignalLink?: string | null,
      lastMonitorCheck?: string | null,
      lastPrice?: number | null,
      activationManagement?:  {
        __typename: "CryptofyBotActivationManagement",
        crossingTime?: number | null,
        crossingPrice?: number | null,
        wasWithinThreshold?: boolean | null,
        hasActivated?: boolean | null,
      } | null,
      customFields?: string | null,
      history?:  {
        __typename: "ModelCryptofyBotTradeHistoryConnection",
        items:  Array< {
          __typename: "CryptofyBotTradeHistory",
          id: string,
          action: CryptofyBotTradeHistoryActions,
          message?: string | null,
          tradeId?: string | null,
          trade?:  {
            __typename: "CryptofyBotTrade",
            id: string,
            status: CryptofyBotTradeStatus,
            tokenName: string,
            symbol: string,
            exchange: SignalExchange,
            type: CryptofyBotTradeTypes,
            side?: CryptofyBotTradeSides | null,
            leverage?: number | null,
            signalEntry?: number | null,
            tradeEntry?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            signalStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            tradeStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            peakLossProfit?: number | null,
            currentLossProfit?: number | null,
            telegramSignalLink?: string | null,
            lastMonitorCheck?: string | null,
            lastPrice?: number | null,
            activationManagement?:  {
              __typename: "CryptofyBotActivationManagement",
              crossingTime?: number | null,
              crossingPrice?: number | null,
              wasWithinThreshold?: boolean | null,
              hasActivated?: boolean | null,
            } | null,
            customFields?: string | null,
            history?:  {
              __typename: "ModelCryptofyBotTradeHistoryConnection",
              nextToken?: string | null,
            } | null,
            signalID?: string | null,
            signal?:  {
              __typename: "Signal",
              id: string,
              title?: string | null,
              type?: SignalType | null,
              status: SignalStatus,
              exchange: SignalExchange,
              isFutures?: boolean | null,
              video?: string | null,
              thumbnail?: string | null,
              trailingStopLoss?: number | null,
              description?: string | null,
              entryOnActivation?: number | null,
              entry?: number | null,
              futuresOperation?: SignalFuturesOperation | null,
              leverage?: number | null,
              interval?: string | null,
              tags?: Array< string | null > | null,
              link?: string | null,
              linkLabel?: string | null,
              totalGains?: number | null,
              totalGainsFutures?: number | null,
              lastPrice?: number | null,
              lastMonitorCheck?: string | null,
              takeProfitNum?: number | null,
              breakThroughTime?: number | null,
              breakThroughCount?: number | null,
              showOnFreeGroup?: boolean | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramEntryNotificationId?: number | null,
              telegramEntryNotificationDate?: string | null,
              tokenName?: string | null,
              tokenSymbol?: string | null,
              tokenAddress?: string | null,
              createdByName?: string | null,
              createdByUserName?: string | null,
              manualOperation?: boolean | null,
              isTesting?: boolean | null,
              useCopyTrade?: boolean | null,
              referenceId?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      signalID?: string | null,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCryptofyBotTradeHistoryQueryVariables = {
  id: string,
};

export type GetCryptofyBotTradeHistoryQuery = {
  getCryptofyBotTradeHistory?:  {
    __typename: "CryptofyBotTradeHistory",
    id: string,
    action: CryptofyBotTradeHistoryActions,
    message?: string | null,
    tradeId?: string | null,
    trade?:  {
      __typename: "CryptofyBotTrade",
      id: string,
      status: CryptofyBotTradeStatus,
      tokenName: string,
      symbol: string,
      exchange: SignalExchange,
      type: CryptofyBotTradeTypes,
      side?: CryptofyBotTradeSides | null,
      leverage?: number | null,
      signalEntry?: number | null,
      tradeEntry?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      signalStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      tradeStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      peakLossProfit?: number | null,
      currentLossProfit?: number | null,
      telegramSignalLink?: string | null,
      lastMonitorCheck?: string | null,
      lastPrice?: number | null,
      activationManagement?:  {
        __typename: "CryptofyBotActivationManagement",
        crossingTime?: number | null,
        crossingPrice?: number | null,
        wasWithinThreshold?: boolean | null,
        hasActivated?: boolean | null,
      } | null,
      customFields?: string | null,
      history?:  {
        __typename: "ModelCryptofyBotTradeHistoryConnection",
        items:  Array< {
          __typename: "CryptofyBotTradeHistory",
          id: string,
          action: CryptofyBotTradeHistoryActions,
          message?: string | null,
          tradeId?: string | null,
          trade?:  {
            __typename: "CryptofyBotTrade",
            id: string,
            status: CryptofyBotTradeStatus,
            tokenName: string,
            symbol: string,
            exchange: SignalExchange,
            type: CryptofyBotTradeTypes,
            side?: CryptofyBotTradeSides | null,
            leverage?: number | null,
            signalEntry?: number | null,
            tradeEntry?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            signalStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            tradeStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            peakLossProfit?: number | null,
            currentLossProfit?: number | null,
            telegramSignalLink?: string | null,
            lastMonitorCheck?: string | null,
            lastPrice?: number | null,
            activationManagement?:  {
              __typename: "CryptofyBotActivationManagement",
              crossingTime?: number | null,
              crossingPrice?: number | null,
              wasWithinThreshold?: boolean | null,
              hasActivated?: boolean | null,
            } | null,
            customFields?: string | null,
            history?:  {
              __typename: "ModelCryptofyBotTradeHistoryConnection",
              nextToken?: string | null,
            } | null,
            signalID?: string | null,
            signal?:  {
              __typename: "Signal",
              id: string,
              title?: string | null,
              type?: SignalType | null,
              status: SignalStatus,
              exchange: SignalExchange,
              isFutures?: boolean | null,
              video?: string | null,
              thumbnail?: string | null,
              trailingStopLoss?: number | null,
              description?: string | null,
              entryOnActivation?: number | null,
              entry?: number | null,
              futuresOperation?: SignalFuturesOperation | null,
              leverage?: number | null,
              interval?: string | null,
              tags?: Array< string | null > | null,
              link?: string | null,
              linkLabel?: string | null,
              totalGains?: number | null,
              totalGainsFutures?: number | null,
              lastPrice?: number | null,
              lastMonitorCheck?: string | null,
              takeProfitNum?: number | null,
              breakThroughTime?: number | null,
              breakThroughCount?: number | null,
              showOnFreeGroup?: boolean | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramEntryNotificationId?: number | null,
              telegramEntryNotificationDate?: string | null,
              tokenName?: string | null,
              tokenSymbol?: string | null,
              tokenAddress?: string | null,
              createdByName?: string | null,
              createdByUserName?: string | null,
              manualOperation?: boolean | null,
              isTesting?: boolean | null,
              useCopyTrade?: boolean | null,
              referenceId?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      signalID?: string | null,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    customFields?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCryptofyBotTradeHistoriesQueryVariables = {
  filter?: ModelCryptofyBotTradeHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCryptofyBotTradeHistoriesQuery = {
  listCryptofyBotTradeHistories?:  {
    __typename: "ModelCryptofyBotTradeHistoryConnection",
    items:  Array< {
      __typename: "CryptofyBotTradeHistory",
      id: string,
      action: CryptofyBotTradeHistoryActions,
      message?: string | null,
      tradeId?: string | null,
      trade?:  {
        __typename: "CryptofyBotTrade",
        id: string,
        status: CryptofyBotTradeStatus,
        tokenName: string,
        symbol: string,
        exchange: SignalExchange,
        type: CryptofyBotTradeTypes,
        side?: CryptofyBotTradeSides | null,
        leverage?: number | null,
        signalEntry?: number | null,
        tradeEntry?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        signalStopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        tradeStopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        peakLossProfit?: number | null,
        currentLossProfit?: number | null,
        telegramSignalLink?: string | null,
        lastMonitorCheck?: string | null,
        lastPrice?: number | null,
        activationManagement?:  {
          __typename: "CryptofyBotActivationManagement",
          crossingTime?: number | null,
          crossingPrice?: number | null,
          wasWithinThreshold?: boolean | null,
          hasActivated?: boolean | null,
        } | null,
        customFields?: string | null,
        history?:  {
          __typename: "ModelCryptofyBotTradeHistoryConnection",
          items:  Array< {
            __typename: "CryptofyBotTradeHistory",
            id: string,
            action: CryptofyBotTradeHistoryActions,
            message?: string | null,
            tradeId?: string | null,
            trade?:  {
              __typename: "CryptofyBotTrade",
              id: string,
              status: CryptofyBotTradeStatus,
              tokenName: string,
              symbol: string,
              exchange: SignalExchange,
              type: CryptofyBotTradeTypes,
              side?: CryptofyBotTradeSides | null,
              leverage?: number | null,
              signalEntry?: number | null,
              tradeEntry?: number | null,
              peakLossProfit?: number | null,
              currentLossProfit?: number | null,
              telegramSignalLink?: string | null,
              lastMonitorCheck?: string | null,
              lastPrice?: number | null,
              customFields?: string | null,
              signalID?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            customFields?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        signalID?: string | null,
        signal?:  {
          __typename: "Signal",
          id: string,
          title?: string | null,
          type?: SignalType | null,
          status: SignalStatus,
          exchange: SignalExchange,
          isFutures?: boolean | null,
          video?: string | null,
          thumbnail?: string | null,
          stopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          trailingStopLoss?: number | null,
          targets?:  Array< {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null > | null,
          minRetainer?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          description?: string | null,
          entryOnActivation?: number | null,
          entry?: number | null,
          futuresOperation?: SignalFuturesOperation | null,
          leverage?: number | null,
          interval?: string | null,
          tags?: Array< string | null > | null,
          link?: string | null,
          linkLabel?: string | null,
          totalGains?: number | null,
          totalGainsFutures?: number | null,
          lastPrice?: number | null,
          lastMonitorCheck?: string | null,
          takeProfitNum?: number | null,
          breakThroughTime?: number | null,
          breakThroughCount?: number | null,
          showOnFreeGroup?: boolean | null,
          telegramMessages?:  Array< {
            __typename: "TelegramMessageResult",
            channel?: string | null,
            id?: string | null,
            link?: string | null,
          } | null > | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramEntryNotificationId?: number | null,
          telegramEntryNotificationDate?: string | null,
          tokenName?: string | null,
          tokenSymbol?: string | null,
          tokenAddress?: string | null,
          createdByName?: string | null,
          createdByUserName?: string | null,
          manualOperation?: boolean | null,
          isTesting?: boolean | null,
          useCopyTrade?: boolean | null,
          referenceId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFields?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMarketPlaceProductQueryVariables = {
  id: string,
};

export type GetMarketPlaceProductQuery = {
  getMarketPlaceProduct?:  {
    __typename: "MarketPlaceProduct",
    id: string,
    slug: string,
    name: string,
    externalReference?: string | null,
    icon?: string | null,
    price?: number | null,
    description?: string | null,
    productType?: MarketPlaceProductType | null,
    status?: MarketPlaceProductStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMarketPlaceProductsQueryVariables = {
  filter?: ModelMarketPlaceProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMarketPlaceProductsQuery = {
  listMarketPlaceProducts?:  {
    __typename: "ModelMarketPlaceProductConnection",
    items:  Array< {
      __typename: "MarketPlaceProduct",
      id: string,
      slug: string,
      name: string,
      externalReference?: string | null,
      icon?: string | null,
      price?: number | null,
      description?: string | null,
      productType?: MarketPlaceProductType | null,
      status?: MarketPlaceProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMarketPlaceProductBySlugQueryVariables = {
  slug: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMarketPlaceProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetMarketPlaceProductBySlugQuery = {
  getMarketPlaceProductBySlug?:  {
    __typename: "ModelMarketPlaceProductConnection",
    items:  Array< {
      __typename: "MarketPlaceProduct",
      id: string,
      slug: string,
      name: string,
      externalReference?: string | null,
      icon?: string | null,
      price?: number | null,
      description?: string | null,
      productType?: MarketPlaceProductType | null,
      status?: MarketPlaceProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPriceAlertQueryVariables = {
  id: string,
};

export type GetPriceAlertQuery = {
  getPriceAlert?:  {
    __typename: "PriceAlert",
    id: string,
    status: PriceAlertStatus,
    type: PriceAlertType,
    price: string,
    description?: string | null,
    tokenName: string,
    tokenAddress: string,
    tokenSymbol: string,
    triggeredDate?: string | null,
    triggeredPrice?: number | null,
    username?: string | null,
    userEmail?: string | null,
    chain?: string | null,
    isFutures?: boolean | null,
    createdAlertAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPriceAlertsQueryVariables = {
  filter?: ModelPriceAlertFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPriceAlertsQuery = {
  listPriceAlerts?:  {
    __typename: "ModelPriceAlertConnection",
    items:  Array< {
      __typename: "PriceAlert",
      id: string,
      status: PriceAlertStatus,
      type: PriceAlertType,
      price: string,
      description?: string | null,
      tokenName: string,
      tokenAddress: string,
      tokenSymbol: string,
      triggeredDate?: string | null,
      triggeredPrice?: number | null,
      username?: string | null,
      userEmail?: string | null,
      chain?: string | null,
      isFutures?: boolean | null,
      createdAlertAt?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSignalQueryVariables = {
  id: string,
};

export type GetSignalQuery = {
  getSignal?:  {
    __typename: "Signal",
    id: string,
    title?: string | null,
    type?: SignalType | null,
    status: SignalStatus,
    exchange: SignalExchange,
    isFutures?: boolean | null,
    video?: string | null,
    thumbnail?: string | null,
    stopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    trailingStopLoss?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    minRetainer?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    description?: string | null,
    entryOnActivation?: number | null,
    entry?: number | null,
    futuresOperation?: SignalFuturesOperation | null,
    leverage?: number | null,
    interval?: string | null,
    tags?: Array< string | null > | null,
    link?: string | null,
    linkLabel?: string | null,
    totalGains?: number | null,
    totalGainsFutures?: number | null,
    lastPrice?: number | null,
    lastMonitorCheck?: string | null,
    takeProfitNum?: number | null,
    breakThroughTime?: number | null,
    breakThroughCount?: number | null,
    showOnFreeGroup?: boolean | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramEntryNotificationId?: number | null,
    telegramEntryNotificationDate?: string | null,
    tokenName?: string | null,
    tokenSymbol?: string | null,
    tokenAddress?: string | null,
    createdByName?: string | null,
    createdByUserName?: string | null,
    manualOperation?: boolean | null,
    isTesting?: boolean | null,
    useCopyTrade?: boolean | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListSignalsQueryVariables = {
  filter?: ModelSignalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSignalsQuery = {
  listSignals?:  {
    __typename: "ModelSignalConnection",
    items:  Array< {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSignalByReferenceIdQueryVariables = {
  referenceId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSignalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSignalByReferenceIdQuery = {
  getSignalByReferenceId?:  {
    __typename: "ModelSignalConnection",
    items:  Array< {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTelegramBotStateQueryVariables = {
  telegramId: string,
};

export type GetTelegramBotStateQuery = {
  getTelegramBotState?:  {
    __typename: "TelegramBotState",
    telegramId: string,
    nextStep?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTelegramBotStatesQueryVariables = {
  telegramId?: string | null,
  filter?: ModelTelegramBotStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTelegramBotStatesQuery = {
  listTelegramBotStates?:  {
    __typename: "ModelTelegramBotStateConnection",
    items:  Array< {
      __typename: "TelegramBotState",
      telegramId: string,
      nextStep?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  username: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    username: string,
    email: string,
    name: string,
    initials?: string | null,
    photo?: string | null,
    lastLogin?: string | null,
    lastVisit?: string | null,
    status: UserStatus,
    statusReason?: string | null,
    membership: Membership,
    permissions?: Array< string | null > | null,
    permissionGroup: UserPermissionGroup,
    expirationDate?: string | null,
    dateOfRenewal?: string | null,
    memberSince?: string | null,
    termsAndConditions?: boolean | null,
    secretKey?: string | null,
    telegramGroups?:  Array< {
      __typename: "UserTelegramGroupAllowed",
      id?: string | null,
      name?: string | null,
      channel?: string | null,
      label?: string | null,
      link?: string | null,
    } | null > | null,
    telegramId?: string | null,
    searchName?: string | null,
    customerId?: string | null,
    currentPlanPeriod?: number | null,
    currentPlanName?: string | null,
    currentPlanId?: string | null,
    currentPlanPrice?: number | null,
    currentPlanCurrency?: string | null,
    subscriptionId?: string | null,
    markedAsCancelled?: boolean | null,
    isTrial?: boolean | null,
    isTestSubscription?: boolean | null,
    products?:  {
      __typename: "ModelUserProductConnection",
      items:  Array< {
        __typename: "UserProduct",
        productId: string,
        username: string,
        expirationDate?: string | null,
        status?: UserProductStatus | null,
        product?:  {
          __typename: "MarketPlaceProduct",
          id: string,
          slug: string,
          name: string,
          externalReference?: string | null,
          icon?: string | null,
          price?: number | null,
          description?: string | null,
          productType?: MarketPlaceProductType | null,
          status?: MarketPlaceProductStatus | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  username?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      username: string,
      email: string,
      name: string,
      initials?: string | null,
      photo?: string | null,
      lastLogin?: string | null,
      lastVisit?: string | null,
      status: UserStatus,
      statusReason?: string | null,
      membership: Membership,
      permissions?: Array< string | null > | null,
      permissionGroup: UserPermissionGroup,
      expirationDate?: string | null,
      dateOfRenewal?: string | null,
      memberSince?: string | null,
      termsAndConditions?: boolean | null,
      secretKey?: string | null,
      telegramGroups?:  Array< {
        __typename: "UserTelegramGroupAllowed",
        id?: string | null,
        name?: string | null,
        channel?: string | null,
        label?: string | null,
        link?: string | null,
      } | null > | null,
      telegramId?: string | null,
      searchName?: string | null,
      customerId?: string | null,
      currentPlanPeriod?: number | null,
      currentPlanName?: string | null,
      currentPlanId?: string | null,
      currentPlanPrice?: number | null,
      currentPlanCurrency?: string | null,
      subscriptionId?: string | null,
      markedAsCancelled?: boolean | null,
      isTrial?: boolean | null,
      isTestSubscription?: boolean | null,
      products?:  {
        __typename: "ModelUserProductConnection",
        items:  Array< {
          __typename: "UserProduct",
          productId: string,
          username: string,
          expirationDate?: string | null,
          status?: UserProductStatus | null,
          product?:  {
            __typename: "MarketPlaceProduct",
            id: string,
            slug: string,
            name: string,
            externalReference?: string | null,
            icon?: string | null,
            price?: number | null,
            description?: string | null,
            productType?: MarketPlaceProductType | null,
            status?: MarketPlaceProductStatus | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserByEmailQuery = {
  getUserByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      username: string,
      email: string,
      name: string,
      initials?: string | null,
      photo?: string | null,
      lastLogin?: string | null,
      lastVisit?: string | null,
      status: UserStatus,
      statusReason?: string | null,
      membership: Membership,
      permissions?: Array< string | null > | null,
      permissionGroup: UserPermissionGroup,
      expirationDate?: string | null,
      dateOfRenewal?: string | null,
      memberSince?: string | null,
      termsAndConditions?: boolean | null,
      secretKey?: string | null,
      telegramGroups?:  Array< {
        __typename: "UserTelegramGroupAllowed",
        id?: string | null,
        name?: string | null,
        channel?: string | null,
        label?: string | null,
        link?: string | null,
      } | null > | null,
      telegramId?: string | null,
      searchName?: string | null,
      customerId?: string | null,
      currentPlanPeriod?: number | null,
      currentPlanName?: string | null,
      currentPlanId?: string | null,
      currentPlanPrice?: number | null,
      currentPlanCurrency?: string | null,
      subscriptionId?: string | null,
      markedAsCancelled?: boolean | null,
      isTrial?: boolean | null,
      isTestSubscription?: boolean | null,
      products?:  {
        __typename: "ModelUserProductConnection",
        items:  Array< {
          __typename: "UserProduct",
          productId: string,
          username: string,
          expirationDate?: string | null,
          status?: UserProductStatus | null,
          product?:  {
            __typename: "MarketPlaceProduct",
            id: string,
            slug: string,
            name: string,
            externalReference?: string | null,
            icon?: string | null,
            price?: number | null,
            description?: string | null,
            productType?: MarketPlaceProductType | null,
            status?: MarketPlaceProductStatus | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserByTelegramIdQueryVariables = {
  telegramId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserByTelegramIdQuery = {
  getUserByTelegramId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      username: string,
      email: string,
      name: string,
      initials?: string | null,
      photo?: string | null,
      lastLogin?: string | null,
      lastVisit?: string | null,
      status: UserStatus,
      statusReason?: string | null,
      membership: Membership,
      permissions?: Array< string | null > | null,
      permissionGroup: UserPermissionGroup,
      expirationDate?: string | null,
      dateOfRenewal?: string | null,
      memberSince?: string | null,
      termsAndConditions?: boolean | null,
      secretKey?: string | null,
      telegramGroups?:  Array< {
        __typename: "UserTelegramGroupAllowed",
        id?: string | null,
        name?: string | null,
        channel?: string | null,
        label?: string | null,
        link?: string | null,
      } | null > | null,
      telegramId?: string | null,
      searchName?: string | null,
      customerId?: string | null,
      currentPlanPeriod?: number | null,
      currentPlanName?: string | null,
      currentPlanId?: string | null,
      currentPlanPrice?: number | null,
      currentPlanCurrency?: string | null,
      subscriptionId?: string | null,
      markedAsCancelled?: boolean | null,
      isTrial?: boolean | null,
      isTestSubscription?: boolean | null,
      products?:  {
        __typename: "ModelUserProductConnection",
        items:  Array< {
          __typename: "UserProduct",
          productId: string,
          username: string,
          expirationDate?: string | null,
          status?: UserProductStatus | null,
          product?:  {
            __typename: "MarketPlaceProduct",
            id: string,
            slug: string,
            name: string,
            externalReference?: string | null,
            icon?: string | null,
            price?: number | null,
            description?: string | null,
            productType?: MarketPlaceProductType | null,
            status?: MarketPlaceProductStatus | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserBySubscriptionIdQueryVariables = {
  subscriptionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserBySubscriptionIdQuery = {
  getUserBySubscriptionId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      username: string,
      email: string,
      name: string,
      initials?: string | null,
      photo?: string | null,
      lastLogin?: string | null,
      lastVisit?: string | null,
      status: UserStatus,
      statusReason?: string | null,
      membership: Membership,
      permissions?: Array< string | null > | null,
      permissionGroup: UserPermissionGroup,
      expirationDate?: string | null,
      dateOfRenewal?: string | null,
      memberSince?: string | null,
      termsAndConditions?: boolean | null,
      secretKey?: string | null,
      telegramGroups?:  Array< {
        __typename: "UserTelegramGroupAllowed",
        id?: string | null,
        name?: string | null,
        channel?: string | null,
        label?: string | null,
        link?: string | null,
      } | null > | null,
      telegramId?: string | null,
      searchName?: string | null,
      customerId?: string | null,
      currentPlanPeriod?: number | null,
      currentPlanName?: string | null,
      currentPlanId?: string | null,
      currentPlanPrice?: number | null,
      currentPlanCurrency?: string | null,
      subscriptionId?: string | null,
      markedAsCancelled?: boolean | null,
      isTrial?: boolean | null,
      isTestSubscription?: boolean | null,
      products?:  {
        __typename: "ModelUserProductConnection",
        items:  Array< {
          __typename: "UserProduct",
          productId: string,
          username: string,
          expirationDate?: string | null,
          status?: UserProductStatus | null,
          product?:  {
            __typename: "MarketPlaceProduct",
            id: string,
            slug: string,
            name: string,
            externalReference?: string | null,
            icon?: string | null,
            price?: number | null,
            description?: string | null,
            productType?: MarketPlaceProductType | null,
            status?: MarketPlaceProductStatus | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserSignInActivityQueryVariables = {
  id: string,
};

export type GetUserSignInActivityQuery = {
  getUserSignInActivity?:  {
    __typename: "UserSignInActivity",
    id: string,
    device?: string | null,
    browser?: string | null,
    ip?: string | null,
    city?: string | null,
    country?: string | null,
    timezone?: string | null,
    date?: string | null,
    username?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUserSignInActivitiesQueryVariables = {
  filter?: ModelUserSignInActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserSignInActivitiesQuery = {
  listUserSignInActivities?:  {
    __typename: "ModelUserSignInActivityConnection",
    items:  Array< {
      __typename: "UserSignInActivity",
      id: string,
      device?: string | null,
      browser?: string | null,
      ip?: string | null,
      city?: string | null,
      country?: string | null,
      timezone?: string | null,
      date?: string | null,
      username?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserProductQueryVariables = {
  productId: string,
  username: string,
};

export type GetUserProductQuery = {
  getUserProduct?:  {
    __typename: "UserProduct",
    productId: string,
    username: string,
    expirationDate?: string | null,
    status?: UserProductStatus | null,
    product?:  {
      __typename: "MarketPlaceProduct",
      id: string,
      slug: string,
      name: string,
      externalReference?: string | null,
      icon?: string | null,
      price?: number | null,
      description?: string | null,
      productType?: MarketPlaceProductType | null,
      status?: MarketPlaceProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUserProductsQueryVariables = {
  productId?: string | null,
  username?: ModelStringKeyConditionInput | null,
  filter?: ModelUserProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserProductsQuery = {
  listUserProducts?:  {
    __typename: "ModelUserProductConnection",
    items:  Array< {
      __typename: "UserProduct",
      productId: string,
      username: string,
      expirationDate?: string | null,
      status?: UserProductStatus | null,
      product?:  {
        __typename: "MarketPlaceProduct",
        id: string,
        slug: string,
        name: string,
        externalReference?: string | null,
        icon?: string | null,
        price?: number | null,
        description?: string | null,
        productType?: MarketPlaceProductType | null,
        status?: MarketPlaceProductStatus | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserProductsByUsernameQueryVariables = {
  username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserProductsByUsernameQuery = {
  getUserProductsByUsername?:  {
    __typename: "ModelUserProductConnection",
    items:  Array< {
      __typename: "UserProduct",
      productId: string,
      username: string,
      expirationDate?: string | null,
      status?: UserProductStatus | null,
      product?:  {
        __typename: "MarketPlaceProduct",
        id: string,
        slug: string,
        name: string,
        externalReference?: string | null,
        icon?: string | null,
        price?: number | null,
        description?: string | null,
        productType?: MarketPlaceProductType | null,
        status?: MarketPlaceProductStatus | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTokenPriceQueryVariables = {
  input?: TokenPriceInput | null,
};

export type GetTokenPriceQuery = {
  getTokenPrice?:  {
    __typename: "TokenPriceResult",
    price?: number | null,
    symbol?: string | null,
  } | null,
};

export type GetSignalAnalyticsQueryVariables = {
  input?: GetSignalAnalyticsInput | null,
};

export type GetSignalAnalyticsQuery = {
  getSignalAnalytics?:  {
    __typename: "GetSignalAnalyticsResult",
    numbers?: string | null,
    topGainers?: string | null,
    trending?: string | null,
    takeVsLoss?: string | null,
  } | null,
};

export type GetHistoricalPriceDataQueryVariables = {
  input?: GetHistoricalPriceDataInput | null,
};

export type GetHistoricalPriceDataQuery = {
  getHistoricalPriceData?:  Array< {
    __typename: "GetHistoricalPriceDataItem",
    time?: number | null,
    open?: number | null,
    high?: number | null,
    low?: number | null,
    close?: number | null,
    volume?: number | null,
  } | null > | null,
};

export type GetStripeCheckoutSessionQueryVariables = {
  input?: GetStripeCheckoutSessionInput | null,
};

export type GetStripeCheckoutSessionQuery = {
  getStripeCheckoutSession?:  {
    __typename: "GetStripeCheckoutSessionResult",
    customerId: string,
    orderId?: string | null,
    name: string,
    email: string,
    total: number,
    address?:  {
      __typename: "CheckoutAddress",
      city?: string | null,
      country?: string | null,
      line1?: string | null,
      line2?: string | null,
      postal_code?: string | null,
      state?: string | null,
    } | null,
    currency: string,
    orderDate: string,
    lineItems?:  Array< {
      __typename: "GetStripeCheckoutSessionLineItems",
      id: string,
      amountSubTotal: number,
      amountTotal: number,
      quantity: number,
      currency: string,
      description: string,
      planName?: string | null,
      planInterval?: string | null,
      planId?: string | null,
    } > | null,
  } | null,
};

export type GetStripeSubscriptionQueryVariables = {
  input?: GetStripeSubscriptionInput | null,
};

export type GetStripeSubscriptionQuery = {
  getStripeSubscription?: string | null,
};

export type GetStripePlansQueryVariables = {
  productId?: string | null,
};

export type GetStripePlansQuery = {
  getStripePlans?:  {
    __typename: "GetStripePlansResult",
    plans?:  Array< {
      __typename: "StripePlan",
      id: string,
      name: string,
      price: number,
      frequency: string,
      interval: number,
      membershipName?: string | null,
      features?: Array< string | null > | null,
      totalPrice?: number | null,
      priority?: number | null,
    } | null > | null,
  } | null,
};

export type CalculateStripeChangePlansProrationQueryVariables = {
  input: CalculateStripeChangePlansProrationInput,
};

export type CalculateStripeChangePlansProrationQuery = {
  calculateStripeChangePlansProration?: string | null,
};

export type OnCreateSignalPublicSubscriptionVariables = {
};

export type OnCreateSignalPublicSubscription = {
  onCreateSignalPublic?:  {
    __typename: "Signal",
    id: string,
    title?: string | null,
    type?: SignalType | null,
    status: SignalStatus,
    exchange: SignalExchange,
    isFutures?: boolean | null,
    video?: string | null,
    thumbnail?: string | null,
    stopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    trailingStopLoss?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    minRetainer?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    description?: string | null,
    entryOnActivation?: number | null,
    entry?: number | null,
    futuresOperation?: SignalFuturesOperation | null,
    leverage?: number | null,
    interval?: string | null,
    tags?: Array< string | null > | null,
    link?: string | null,
    linkLabel?: string | null,
    totalGains?: number | null,
    totalGainsFutures?: number | null,
    lastPrice?: number | null,
    lastMonitorCheck?: string | null,
    takeProfitNum?: number | null,
    breakThroughTime?: number | null,
    breakThroughCount?: number | null,
    showOnFreeGroup?: boolean | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramEntryNotificationId?: number | null,
    telegramEntryNotificationDate?: string | null,
    tokenName?: string | null,
    tokenSymbol?: string | null,
    tokenAddress?: string | null,
    createdByName?: string | null,
    createdByUserName?: string | null,
    manualOperation?: boolean | null,
    isTesting?: boolean | null,
    useCopyTrade?: boolean | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSignalPublicSubscriptionVariables = {
};

export type OnUpdateSignalPublicSubscription = {
  onUpdateSignalPublic?:  {
    __typename: "Signal",
    id: string,
    title?: string | null,
    type?: SignalType | null,
    status: SignalStatus,
    exchange: SignalExchange,
    isFutures?: boolean | null,
    video?: string | null,
    thumbnail?: string | null,
    stopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    trailingStopLoss?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    minRetainer?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    description?: string | null,
    entryOnActivation?: number | null,
    entry?: number | null,
    futuresOperation?: SignalFuturesOperation | null,
    leverage?: number | null,
    interval?: string | null,
    tags?: Array< string | null > | null,
    link?: string | null,
    linkLabel?: string | null,
    totalGains?: number | null,
    totalGainsFutures?: number | null,
    lastPrice?: number | null,
    lastMonitorCheck?: string | null,
    takeProfitNum?: number | null,
    breakThroughTime?: number | null,
    breakThroughCount?: number | null,
    showOnFreeGroup?: boolean | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramEntryNotificationId?: number | null,
    telegramEntryNotificationDate?: string | null,
    tokenName?: string | null,
    tokenSymbol?: string | null,
    tokenAddress?: string | null,
    createdByName?: string | null,
    createdByUserName?: string | null,
    manualOperation?: boolean | null,
    isTesting?: boolean | null,
    useCopyTrade?: boolean | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSignalPublicSubscriptionVariables = {
};

export type OnDeleteSignalPublicSubscription = {
  onDeleteSignalPublic?:  {
    __typename: "Signal",
    id: string,
    title?: string | null,
    type?: SignalType | null,
    status: SignalStatus,
    exchange: SignalExchange,
    isFutures?: boolean | null,
    video?: string | null,
    thumbnail?: string | null,
    stopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    trailingStopLoss?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    minRetainer?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    description?: string | null,
    entryOnActivation?: number | null,
    entry?: number | null,
    futuresOperation?: SignalFuturesOperation | null,
    leverage?: number | null,
    interval?: string | null,
    tags?: Array< string | null > | null,
    link?: string | null,
    linkLabel?: string | null,
    totalGains?: number | null,
    totalGainsFutures?: number | null,
    lastPrice?: number | null,
    lastMonitorCheck?: string | null,
    takeProfitNum?: number | null,
    breakThroughTime?: number | null,
    breakThroughCount?: number | null,
    showOnFreeGroup?: boolean | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramEntryNotificationId?: number | null,
    telegramEntryNotificationDate?: string | null,
    tokenName?: string | null,
    tokenSymbol?: string | null,
    tokenAddress?: string | null,
    createdByName?: string | null,
    createdByUserName?: string | null,
    manualOperation?: boolean | null,
    isTesting?: boolean | null,
    useCopyTrade?: boolean | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCryptofyBotTradePublicSubscriptionVariables = {
};

export type OnCreateCryptofyBotTradePublicSubscription = {
  onCreateCryptofyBotTradePublic?:  {
    __typename: "CryptofyBotTrade",
    id: string,
    status: CryptofyBotTradeStatus,
    tokenName: string,
    symbol: string,
    exchange: SignalExchange,
    type: CryptofyBotTradeTypes,
    side?: CryptofyBotTradeSides | null,
    leverage?: number | null,
    signalEntry?: number | null,
    tradeEntry?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    signalStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    tradeStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    peakLossProfit?: number | null,
    currentLossProfit?: number | null,
    telegramSignalLink?: string | null,
    lastMonitorCheck?: string | null,
    lastPrice?: number | null,
    activationManagement?:  {
      __typename: "CryptofyBotActivationManagement",
      crossingTime?: number | null,
      crossingPrice?: number | null,
      wasWithinThreshold?: boolean | null,
      hasActivated?: boolean | null,
    } | null,
    customFields?: string | null,
    history?:  {
      __typename: "ModelCryptofyBotTradeHistoryConnection",
      items:  Array< {
        __typename: "CryptofyBotTradeHistory",
        id: string,
        action: CryptofyBotTradeHistoryActions,
        message?: string | null,
        tradeId?: string | null,
        trade?:  {
          __typename: "CryptofyBotTrade",
          id: string,
          status: CryptofyBotTradeStatus,
          tokenName: string,
          symbol: string,
          exchange: SignalExchange,
          type: CryptofyBotTradeTypes,
          side?: CryptofyBotTradeSides | null,
          leverage?: number | null,
          signalEntry?: number | null,
          tradeEntry?: number | null,
          targets?:  Array< {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null > | null,
          signalStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          tradeStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          peakLossProfit?: number | null,
          currentLossProfit?: number | null,
          telegramSignalLink?: string | null,
          lastMonitorCheck?: string | null,
          lastPrice?: number | null,
          activationManagement?:  {
            __typename: "CryptofyBotActivationManagement",
            crossingTime?: number | null,
            crossingPrice?: number | null,
            wasWithinThreshold?: boolean | null,
            hasActivated?: boolean | null,
          } | null,
          customFields?: string | null,
          history?:  {
            __typename: "ModelCryptofyBotTradeHistoryConnection",
            items:  Array< {
              __typename: "CryptofyBotTradeHistory",
              id: string,
              action: CryptofyBotTradeHistoryActions,
              message?: string | null,
              tradeId?: string | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          signalID?: string | null,
          signal?:  {
            __typename: "Signal",
            id: string,
            title?: string | null,
            type?: SignalType | null,
            status: SignalStatus,
            exchange: SignalExchange,
            isFutures?: boolean | null,
            video?: string | null,
            thumbnail?: string | null,
            stopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            trailingStopLoss?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            minRetainer?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            description?: string | null,
            entryOnActivation?: number | null,
            entry?: number | null,
            futuresOperation?: SignalFuturesOperation | null,
            leverage?: number | null,
            interval?: string | null,
            tags?: Array< string | null > | null,
            link?: string | null,
            linkLabel?: string | null,
            totalGains?: number | null,
            totalGainsFutures?: number | null,
            lastPrice?: number | null,
            lastMonitorCheck?: string | null,
            takeProfitNum?: number | null,
            breakThroughTime?: number | null,
            breakThroughCount?: number | null,
            showOnFreeGroup?: boolean | null,
            telegramMessages?:  Array< {
              __typename: "TelegramMessageResult",
              channel?: string | null,
              id?: string | null,
              link?: string | null,
            } | null > | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramEntryNotificationId?: number | null,
            telegramEntryNotificationDate?: string | null,
            tokenName?: string | null,
            tokenSymbol?: string | null,
            tokenAddress?: string | null,
            createdByName?: string | null,
            createdByUserName?: string | null,
            manualOperation?: boolean | null,
            isTesting?: boolean | null,
            useCopyTrade?: boolean | null,
            referenceId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    signalID?: string | null,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCryptofyBotTradePublicSubscriptionVariables = {
};

export type OnUpdateCryptofyBotTradePublicSubscription = {
  onUpdateCryptofyBotTradePublic?:  {
    __typename: "CryptofyBotTrade",
    id: string,
    status: CryptofyBotTradeStatus,
    tokenName: string,
    symbol: string,
    exchange: SignalExchange,
    type: CryptofyBotTradeTypes,
    side?: CryptofyBotTradeSides | null,
    leverage?: number | null,
    signalEntry?: number | null,
    tradeEntry?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    signalStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    tradeStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    peakLossProfit?: number | null,
    currentLossProfit?: number | null,
    telegramSignalLink?: string | null,
    lastMonitorCheck?: string | null,
    lastPrice?: number | null,
    activationManagement?:  {
      __typename: "CryptofyBotActivationManagement",
      crossingTime?: number | null,
      crossingPrice?: number | null,
      wasWithinThreshold?: boolean | null,
      hasActivated?: boolean | null,
    } | null,
    customFields?: string | null,
    history?:  {
      __typename: "ModelCryptofyBotTradeHistoryConnection",
      items:  Array< {
        __typename: "CryptofyBotTradeHistory",
        id: string,
        action: CryptofyBotTradeHistoryActions,
        message?: string | null,
        tradeId?: string | null,
        trade?:  {
          __typename: "CryptofyBotTrade",
          id: string,
          status: CryptofyBotTradeStatus,
          tokenName: string,
          symbol: string,
          exchange: SignalExchange,
          type: CryptofyBotTradeTypes,
          side?: CryptofyBotTradeSides | null,
          leverage?: number | null,
          signalEntry?: number | null,
          tradeEntry?: number | null,
          targets?:  Array< {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null > | null,
          signalStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          tradeStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          peakLossProfit?: number | null,
          currentLossProfit?: number | null,
          telegramSignalLink?: string | null,
          lastMonitorCheck?: string | null,
          lastPrice?: number | null,
          activationManagement?:  {
            __typename: "CryptofyBotActivationManagement",
            crossingTime?: number | null,
            crossingPrice?: number | null,
            wasWithinThreshold?: boolean | null,
            hasActivated?: boolean | null,
          } | null,
          customFields?: string | null,
          history?:  {
            __typename: "ModelCryptofyBotTradeHistoryConnection",
            items:  Array< {
              __typename: "CryptofyBotTradeHistory",
              id: string,
              action: CryptofyBotTradeHistoryActions,
              message?: string | null,
              tradeId?: string | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          signalID?: string | null,
          signal?:  {
            __typename: "Signal",
            id: string,
            title?: string | null,
            type?: SignalType | null,
            status: SignalStatus,
            exchange: SignalExchange,
            isFutures?: boolean | null,
            video?: string | null,
            thumbnail?: string | null,
            stopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            trailingStopLoss?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            minRetainer?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            description?: string | null,
            entryOnActivation?: number | null,
            entry?: number | null,
            futuresOperation?: SignalFuturesOperation | null,
            leverage?: number | null,
            interval?: string | null,
            tags?: Array< string | null > | null,
            link?: string | null,
            linkLabel?: string | null,
            totalGains?: number | null,
            totalGainsFutures?: number | null,
            lastPrice?: number | null,
            lastMonitorCheck?: string | null,
            takeProfitNum?: number | null,
            breakThroughTime?: number | null,
            breakThroughCount?: number | null,
            showOnFreeGroup?: boolean | null,
            telegramMessages?:  Array< {
              __typename: "TelegramMessageResult",
              channel?: string | null,
              id?: string | null,
              link?: string | null,
            } | null > | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramEntryNotificationId?: number | null,
            telegramEntryNotificationDate?: string | null,
            tokenName?: string | null,
            tokenSymbol?: string | null,
            tokenAddress?: string | null,
            createdByName?: string | null,
            createdByUserName?: string | null,
            manualOperation?: boolean | null,
            isTesting?: boolean | null,
            useCopyTrade?: boolean | null,
            referenceId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    signalID?: string | null,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCryptofyBotTradePublicSubscriptionVariables = {
};

export type OnDeleteCryptofyBotTradePublicSubscription = {
  onDeleteCryptofyBotTradePublic?:  {
    __typename: "CryptofyBotTrade",
    id: string,
    status: CryptofyBotTradeStatus,
    tokenName: string,
    symbol: string,
    exchange: SignalExchange,
    type: CryptofyBotTradeTypes,
    side?: CryptofyBotTradeSides | null,
    leverage?: number | null,
    signalEntry?: number | null,
    tradeEntry?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    signalStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    tradeStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    peakLossProfit?: number | null,
    currentLossProfit?: number | null,
    telegramSignalLink?: string | null,
    lastMonitorCheck?: string | null,
    lastPrice?: number | null,
    activationManagement?:  {
      __typename: "CryptofyBotActivationManagement",
      crossingTime?: number | null,
      crossingPrice?: number | null,
      wasWithinThreshold?: boolean | null,
      hasActivated?: boolean | null,
    } | null,
    customFields?: string | null,
    history?:  {
      __typename: "ModelCryptofyBotTradeHistoryConnection",
      items:  Array< {
        __typename: "CryptofyBotTradeHistory",
        id: string,
        action: CryptofyBotTradeHistoryActions,
        message?: string | null,
        tradeId?: string | null,
        trade?:  {
          __typename: "CryptofyBotTrade",
          id: string,
          status: CryptofyBotTradeStatus,
          tokenName: string,
          symbol: string,
          exchange: SignalExchange,
          type: CryptofyBotTradeTypes,
          side?: CryptofyBotTradeSides | null,
          leverage?: number | null,
          signalEntry?: number | null,
          tradeEntry?: number | null,
          targets?:  Array< {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null > | null,
          signalStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          tradeStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          peakLossProfit?: number | null,
          currentLossProfit?: number | null,
          telegramSignalLink?: string | null,
          lastMonitorCheck?: string | null,
          lastPrice?: number | null,
          activationManagement?:  {
            __typename: "CryptofyBotActivationManagement",
            crossingTime?: number | null,
            crossingPrice?: number | null,
            wasWithinThreshold?: boolean | null,
            hasActivated?: boolean | null,
          } | null,
          customFields?: string | null,
          history?:  {
            __typename: "ModelCryptofyBotTradeHistoryConnection",
            items:  Array< {
              __typename: "CryptofyBotTradeHistory",
              id: string,
              action: CryptofyBotTradeHistoryActions,
              message?: string | null,
              tradeId?: string | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          signalID?: string | null,
          signal?:  {
            __typename: "Signal",
            id: string,
            title?: string | null,
            type?: SignalType | null,
            status: SignalStatus,
            exchange: SignalExchange,
            isFutures?: boolean | null,
            video?: string | null,
            thumbnail?: string | null,
            stopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            trailingStopLoss?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            minRetainer?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            description?: string | null,
            entryOnActivation?: number | null,
            entry?: number | null,
            futuresOperation?: SignalFuturesOperation | null,
            leverage?: number | null,
            interval?: string | null,
            tags?: Array< string | null > | null,
            link?: string | null,
            linkLabel?: string | null,
            totalGains?: number | null,
            totalGainsFutures?: number | null,
            lastPrice?: number | null,
            lastMonitorCheck?: string | null,
            takeProfitNum?: number | null,
            breakThroughTime?: number | null,
            breakThroughCount?: number | null,
            showOnFreeGroup?: boolean | null,
            telegramMessages?:  Array< {
              __typename: "TelegramMessageResult",
              channel?: string | null,
              id?: string | null,
              link?: string | null,
            } | null > | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramEntryNotificationId?: number | null,
            telegramEntryNotificationDate?: string | null,
            tokenName?: string | null,
            tokenSymbol?: string | null,
            tokenAddress?: string | null,
            createdByName?: string | null,
            createdByUserName?: string | null,
            manualOperation?: boolean | null,
            isTesting?: boolean | null,
            useCopyTrade?: boolean | null,
            referenceId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    signalID?: string | null,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreatePriceAlertPublicSubscriptionVariables = {
};

export type OnCreatePriceAlertPublicSubscription = {
  onCreatePriceAlertPublic?:  {
    __typename: "PriceAlert",
    id: string,
    status: PriceAlertStatus,
    type: PriceAlertType,
    price: string,
    description?: string | null,
    tokenName: string,
    tokenAddress: string,
    tokenSymbol: string,
    triggeredDate?: string | null,
    triggeredPrice?: number | null,
    username?: string | null,
    userEmail?: string | null,
    chain?: string | null,
    isFutures?: boolean | null,
    createdAlertAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePriceAlertPublicSubscriptionVariables = {
};

export type OnUpdatePriceAlertPublicSubscription = {
  onUpdatePriceAlertPublic?:  {
    __typename: "PriceAlert",
    id: string,
    status: PriceAlertStatus,
    type: PriceAlertType,
    price: string,
    description?: string | null,
    tokenName: string,
    tokenAddress: string,
    tokenSymbol: string,
    triggeredDate?: string | null,
    triggeredPrice?: number | null,
    username?: string | null,
    userEmail?: string | null,
    chain?: string | null,
    isFutures?: boolean | null,
    createdAlertAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePriceAlertPublicSubscriptionVariables = {
};

export type OnDeletePriceAlertPublicSubscription = {
  onDeletePriceAlertPublic?:  {
    __typename: "PriceAlert",
    id: string,
    status: PriceAlertStatus,
    type: PriceAlertType,
    price: string,
    description?: string | null,
    tokenName: string,
    tokenAddress: string,
    tokenSymbol: string,
    triggeredDate?: string | null,
    triggeredPrice?: number | null,
    username?: string | null,
    userEmail?: string | null,
    chain?: string | null,
    isFutures?: boolean | null,
    createdAlertAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateAdSubscriptionVariables = {
  filter?: ModelSubscriptionAdFilterInput | null,
};

export type OnCreateAdSubscription = {
  onCreateAd?:  {
    __typename: "Ad",
    id: string,
    title?: string | null,
    frequency?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    dates?: Array< string | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessage",
      photo?: string | null,
      message?: string | null,
      callbackActions?:  Array< {
        __typename: "TelegramCallbackActions",
        url?: string | null,
        text?: string | null,
      } | null > | null,
    } | null > | null,
    adType?: AdType | null,
    status: AdStatus,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateAdSubscriptionVariables = {
  filter?: ModelSubscriptionAdFilterInput | null,
};

export type OnUpdateAdSubscription = {
  onUpdateAd?:  {
    __typename: "Ad",
    id: string,
    title?: string | null,
    frequency?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    dates?: Array< string | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessage",
      photo?: string | null,
      message?: string | null,
      callbackActions?:  Array< {
        __typename: "TelegramCallbackActions",
        url?: string | null,
        text?: string | null,
      } | null > | null,
    } | null > | null,
    adType?: AdType | null,
    status: AdStatus,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteAdSubscriptionVariables = {
  filter?: ModelSubscriptionAdFilterInput | null,
};

export type OnDeleteAdSubscription = {
  onDeleteAd?:  {
    __typename: "Ad",
    id: string,
    title?: string | null,
    frequency?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    dates?: Array< string | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessage",
      photo?: string | null,
      message?: string | null,
      callbackActions?:  Array< {
        __typename: "TelegramCallbackActions",
        url?: string | null,
        text?: string | null,
      } | null > | null,
    } | null > | null,
    adType?: AdType | null,
    status: AdStatus,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateAutoTradingBotConfigSubscriptionVariables = {
  filter?: ModelSubscriptionAutoTradingBotConfigFilterInput | null,
};

export type OnCreateAutoTradingBotConfigSubscription = {
  onCreateAutoTradingBotConfig?:  {
    __typename: "AutoTradingBotConfig",
    id: string,
    name: string,
    apiKey: string,
    apiSecret: string,
    exchange?: AutoTradingExchange | null,
    amountToTrade?: number | null,
    quoteAsset?: string | null,
    maxNumTrades?: number | null,
    fixedLeverage?: number | null,
    fixedStopLoss?: number | null,
    type: AutoTradingBotConfigTypes,
    status: AutoTradingBotConfigStatus,
    username: string,
    user?:  {
      __typename: "User",
      username: string,
      email: string,
      name: string,
      initials?: string | null,
      photo?: string | null,
      lastLogin?: string | null,
      lastVisit?: string | null,
      status: UserStatus,
      statusReason?: string | null,
      membership: Membership,
      permissions?: Array< string | null > | null,
      permissionGroup: UserPermissionGroup,
      expirationDate?: string | null,
      dateOfRenewal?: string | null,
      memberSince?: string | null,
      termsAndConditions?: boolean | null,
      secretKey?: string | null,
      telegramGroups?:  Array< {
        __typename: "UserTelegramGroupAllowed",
        id?: string | null,
        name?: string | null,
        channel?: string | null,
        label?: string | null,
        link?: string | null,
      } | null > | null,
      telegramId?: string | null,
      searchName?: string | null,
      customerId?: string | null,
      currentPlanPeriod?: number | null,
      currentPlanName?: string | null,
      currentPlanId?: string | null,
      currentPlanPrice?: number | null,
      currentPlanCurrency?: string | null,
      subscriptionId?: string | null,
      markedAsCancelled?: boolean | null,
      isTrial?: boolean | null,
      isTestSubscription?: boolean | null,
      products?:  {
        __typename: "ModelUserProductConnection",
        items:  Array< {
          __typename: "UserProduct",
          productId: string,
          username: string,
          expirationDate?: string | null,
          status?: UserProductStatus | null,
          product?:  {
            __typename: "MarketPlaceProduct",
            id: string,
            slug: string,
            name: string,
            externalReference?: string | null,
            icon?: string | null,
            price?: number | null,
            description?: string | null,
            productType?: MarketPlaceProductType | null,
            status?: MarketPlaceProductStatus | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAutoTradingBotConfigSubscriptionVariables = {
  filter?: ModelSubscriptionAutoTradingBotConfigFilterInput | null,
};

export type OnUpdateAutoTradingBotConfigSubscription = {
  onUpdateAutoTradingBotConfig?:  {
    __typename: "AutoTradingBotConfig",
    id: string,
    name: string,
    apiKey: string,
    apiSecret: string,
    exchange?: AutoTradingExchange | null,
    amountToTrade?: number | null,
    quoteAsset?: string | null,
    maxNumTrades?: number | null,
    fixedLeverage?: number | null,
    fixedStopLoss?: number | null,
    type: AutoTradingBotConfigTypes,
    status: AutoTradingBotConfigStatus,
    username: string,
    user?:  {
      __typename: "User",
      username: string,
      email: string,
      name: string,
      initials?: string | null,
      photo?: string | null,
      lastLogin?: string | null,
      lastVisit?: string | null,
      status: UserStatus,
      statusReason?: string | null,
      membership: Membership,
      permissions?: Array< string | null > | null,
      permissionGroup: UserPermissionGroup,
      expirationDate?: string | null,
      dateOfRenewal?: string | null,
      memberSince?: string | null,
      termsAndConditions?: boolean | null,
      secretKey?: string | null,
      telegramGroups?:  Array< {
        __typename: "UserTelegramGroupAllowed",
        id?: string | null,
        name?: string | null,
        channel?: string | null,
        label?: string | null,
        link?: string | null,
      } | null > | null,
      telegramId?: string | null,
      searchName?: string | null,
      customerId?: string | null,
      currentPlanPeriod?: number | null,
      currentPlanName?: string | null,
      currentPlanId?: string | null,
      currentPlanPrice?: number | null,
      currentPlanCurrency?: string | null,
      subscriptionId?: string | null,
      markedAsCancelled?: boolean | null,
      isTrial?: boolean | null,
      isTestSubscription?: boolean | null,
      products?:  {
        __typename: "ModelUserProductConnection",
        items:  Array< {
          __typename: "UserProduct",
          productId: string,
          username: string,
          expirationDate?: string | null,
          status?: UserProductStatus | null,
          product?:  {
            __typename: "MarketPlaceProduct",
            id: string,
            slug: string,
            name: string,
            externalReference?: string | null,
            icon?: string | null,
            price?: number | null,
            description?: string | null,
            productType?: MarketPlaceProductType | null,
            status?: MarketPlaceProductStatus | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAutoTradingBotConfigSubscriptionVariables = {
  filter?: ModelSubscriptionAutoTradingBotConfigFilterInput | null,
};

export type OnDeleteAutoTradingBotConfigSubscription = {
  onDeleteAutoTradingBotConfig?:  {
    __typename: "AutoTradingBotConfig",
    id: string,
    name: string,
    apiKey: string,
    apiSecret: string,
    exchange?: AutoTradingExchange | null,
    amountToTrade?: number | null,
    quoteAsset?: string | null,
    maxNumTrades?: number | null,
    fixedLeverage?: number | null,
    fixedStopLoss?: number | null,
    type: AutoTradingBotConfigTypes,
    status: AutoTradingBotConfigStatus,
    username: string,
    user?:  {
      __typename: "User",
      username: string,
      email: string,
      name: string,
      initials?: string | null,
      photo?: string | null,
      lastLogin?: string | null,
      lastVisit?: string | null,
      status: UserStatus,
      statusReason?: string | null,
      membership: Membership,
      permissions?: Array< string | null > | null,
      permissionGroup: UserPermissionGroup,
      expirationDate?: string | null,
      dateOfRenewal?: string | null,
      memberSince?: string | null,
      termsAndConditions?: boolean | null,
      secretKey?: string | null,
      telegramGroups?:  Array< {
        __typename: "UserTelegramGroupAllowed",
        id?: string | null,
        name?: string | null,
        channel?: string | null,
        label?: string | null,
        link?: string | null,
      } | null > | null,
      telegramId?: string | null,
      searchName?: string | null,
      customerId?: string | null,
      currentPlanPeriod?: number | null,
      currentPlanName?: string | null,
      currentPlanId?: string | null,
      currentPlanPrice?: number | null,
      currentPlanCurrency?: string | null,
      subscriptionId?: string | null,
      markedAsCancelled?: boolean | null,
      isTrial?: boolean | null,
      isTestSubscription?: boolean | null,
      products?:  {
        __typename: "ModelUserProductConnection",
        items:  Array< {
          __typename: "UserProduct",
          productId: string,
          username: string,
          expirationDate?: string | null,
          status?: UserProductStatus | null,
          product?:  {
            __typename: "MarketPlaceProduct",
            id: string,
            slug: string,
            name: string,
            externalReference?: string | null,
            icon?: string | null,
            price?: number | null,
            description?: string | null,
            productType?: MarketPlaceProductType | null,
            status?: MarketPlaceProductStatus | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAutoTradingBotTradeSubscriptionVariables = {
  filter?: ModelSubscriptionAutoTradingBotTradeFilterInput | null,
  username?: string | null,
};

export type OnCreateAutoTradingBotTradeSubscription = {
  onCreateAutoTradingBotTrade?:  {
    __typename: "AutoTradingBotTrade",
    id: string,
    exchange?: AutoTradingExchange | null,
    type?: AutoTradingBotConfigTypes | null,
    tokenName?: string | null,
    symbol?: string | null,
    side?: string | null,
    amount?: number | null,
    stopLossPrice?: number | null,
    entryPrice?: number | null,
    closedPrice?: number | null,
    quantity?: number | null,
    leverage?: number | null,
    createOrderId?: string | null,
    createOrderLinkId?: string | null,
    stopLossOrderId?: string | null,
    closedOrderId?: string | null,
    status?: AutoTradingBotTradeStatus | null,
    telegramSignalLink?: string | null,
    pnlAmount?: number | null,
    pnlPercentage?: number | null,
    quoteAsset?: string | null,
    username?: string | null,
    signalID: string,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAutoTradingBotTradeSubscriptionVariables = {
  filter?: ModelSubscriptionAutoTradingBotTradeFilterInput | null,
  username?: string | null,
};

export type OnUpdateAutoTradingBotTradeSubscription = {
  onUpdateAutoTradingBotTrade?:  {
    __typename: "AutoTradingBotTrade",
    id: string,
    exchange?: AutoTradingExchange | null,
    type?: AutoTradingBotConfigTypes | null,
    tokenName?: string | null,
    symbol?: string | null,
    side?: string | null,
    amount?: number | null,
    stopLossPrice?: number | null,
    entryPrice?: number | null,
    closedPrice?: number | null,
    quantity?: number | null,
    leverage?: number | null,
    createOrderId?: string | null,
    createOrderLinkId?: string | null,
    stopLossOrderId?: string | null,
    closedOrderId?: string | null,
    status?: AutoTradingBotTradeStatus | null,
    telegramSignalLink?: string | null,
    pnlAmount?: number | null,
    pnlPercentage?: number | null,
    quoteAsset?: string | null,
    username?: string | null,
    signalID: string,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAutoTradingBotTradeSubscriptionVariables = {
  filter?: ModelSubscriptionAutoTradingBotTradeFilterInput | null,
  username?: string | null,
};

export type OnDeleteAutoTradingBotTradeSubscription = {
  onDeleteAutoTradingBotTrade?:  {
    __typename: "AutoTradingBotTrade",
    id: string,
    exchange?: AutoTradingExchange | null,
    type?: AutoTradingBotConfigTypes | null,
    tokenName?: string | null,
    symbol?: string | null,
    side?: string | null,
    amount?: number | null,
    stopLossPrice?: number | null,
    entryPrice?: number | null,
    closedPrice?: number | null,
    quantity?: number | null,
    leverage?: number | null,
    createOrderId?: string | null,
    createOrderLinkId?: string | null,
    stopLossOrderId?: string | null,
    closedOrderId?: string | null,
    status?: AutoTradingBotTradeStatus | null,
    telegramSignalLink?: string | null,
    pnlAmount?: number | null,
    pnlPercentage?: number | null,
    quoteAsset?: string | null,
    username?: string | null,
    signalID: string,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAutoTradingBotHistorySubscriptionVariables = {
  filter?: ModelSubscriptionAutoTradingBotHistoryFilterInput | null,
  username?: string | null,
};

export type OnCreateAutoTradingBotHistorySubscription = {
  onCreateAutoTradingBotHistory?:  {
    __typename: "AutoTradingBotHistory",
    id: string,
    exchange?: AutoTradingExchange | null,
    symbol?: string | null,
    tokenName?: string | null,
    side?: string | null,
    leverage?: number | null,
    action?: string | null,
    content?: string | null,
    payload?: string | null,
    totalGains?: number | null,
    username?: string | null,
    orderID?: string | null,
    type?: AutoTradingBotHistoryType | null,
    signalID?: string | null,
    autoTradingID?: string | null,
    autoTradeInfo?:  {
      __typename: "AutoTradingBotTrade",
      id: string,
      exchange?: AutoTradingExchange | null,
      type?: AutoTradingBotConfigTypes | null,
      tokenName?: string | null,
      symbol?: string | null,
      side?: string | null,
      amount?: number | null,
      stopLossPrice?: number | null,
      entryPrice?: number | null,
      closedPrice?: number | null,
      quantity?: number | null,
      leverage?: number | null,
      createOrderId?: string | null,
      createOrderLinkId?: string | null,
      stopLossOrderId?: string | null,
      closedOrderId?: string | null,
      status?: AutoTradingBotTradeStatus | null,
      telegramSignalLink?: string | null,
      pnlAmount?: number | null,
      pnlPercentage?: number | null,
      quoteAsset?: string | null,
      username?: string | null,
      signalID: string,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAutoTradingBotHistorySubscriptionVariables = {
  filter?: ModelSubscriptionAutoTradingBotHistoryFilterInput | null,
  username?: string | null,
};

export type OnUpdateAutoTradingBotHistorySubscription = {
  onUpdateAutoTradingBotHistory?:  {
    __typename: "AutoTradingBotHistory",
    id: string,
    exchange?: AutoTradingExchange | null,
    symbol?: string | null,
    tokenName?: string | null,
    side?: string | null,
    leverage?: number | null,
    action?: string | null,
    content?: string | null,
    payload?: string | null,
    totalGains?: number | null,
    username?: string | null,
    orderID?: string | null,
    type?: AutoTradingBotHistoryType | null,
    signalID?: string | null,
    autoTradingID?: string | null,
    autoTradeInfo?:  {
      __typename: "AutoTradingBotTrade",
      id: string,
      exchange?: AutoTradingExchange | null,
      type?: AutoTradingBotConfigTypes | null,
      tokenName?: string | null,
      symbol?: string | null,
      side?: string | null,
      amount?: number | null,
      stopLossPrice?: number | null,
      entryPrice?: number | null,
      closedPrice?: number | null,
      quantity?: number | null,
      leverage?: number | null,
      createOrderId?: string | null,
      createOrderLinkId?: string | null,
      stopLossOrderId?: string | null,
      closedOrderId?: string | null,
      status?: AutoTradingBotTradeStatus | null,
      telegramSignalLink?: string | null,
      pnlAmount?: number | null,
      pnlPercentage?: number | null,
      quoteAsset?: string | null,
      username?: string | null,
      signalID: string,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAutoTradingBotHistorySubscriptionVariables = {
  filter?: ModelSubscriptionAutoTradingBotHistoryFilterInput | null,
  username?: string | null,
};

export type OnDeleteAutoTradingBotHistorySubscription = {
  onDeleteAutoTradingBotHistory?:  {
    __typename: "AutoTradingBotHistory",
    id: string,
    exchange?: AutoTradingExchange | null,
    symbol?: string | null,
    tokenName?: string | null,
    side?: string | null,
    leverage?: number | null,
    action?: string | null,
    content?: string | null,
    payload?: string | null,
    totalGains?: number | null,
    username?: string | null,
    orderID?: string | null,
    type?: AutoTradingBotHistoryType | null,
    signalID?: string | null,
    autoTradingID?: string | null,
    autoTradeInfo?:  {
      __typename: "AutoTradingBotTrade",
      id: string,
      exchange?: AutoTradingExchange | null,
      type?: AutoTradingBotConfigTypes | null,
      tokenName?: string | null,
      symbol?: string | null,
      side?: string | null,
      amount?: number | null,
      stopLossPrice?: number | null,
      entryPrice?: number | null,
      closedPrice?: number | null,
      quantity?: number | null,
      leverage?: number | null,
      createOrderId?: string | null,
      createOrderLinkId?: string | null,
      stopLossOrderId?: string | null,
      closedOrderId?: string | null,
      status?: AutoTradingBotTradeStatus | null,
      telegramSignalLink?: string | null,
      pnlAmount?: number | null,
      pnlPercentage?: number | null,
      quoteAsset?: string | null,
      username?: string | null,
      signalID: string,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCollectionSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionFilterInput | null,
  owner?: string | null,
};

export type OnCreateCollectionSubscription = {
  onCreateCollection?:  {
    __typename: "Collection",
    id: string,
    title: string,
    description: string,
    status: CollectionStatus,
    tags?: Array< string | null > | null,
    contents?:  {
      __typename: "ModelCollectionContentConnection",
      items:  Array< {
        __typename: "CollectionContent",
        id: string,
        slug?: string | null,
        order: number,
        title: string,
        description: string,
        body?: string | null,
        thumbnail?: string | null,
        image?: string | null,
        video?: string | null,
        duration?: number | null,
        date?: string | null,
        attachments?:  Array< {
          __typename: "CollectionAttachment",
          file?: string | null,
          url?: string | null,
          text?: string | null,
          mimeType?: string | null,
        } | null > | null,
        status: CollectionStatus,
        collectionID: string,
        collection?:  {
          __typename: "Collection",
          id: string,
          title: string,
          description: string,
          status: CollectionStatus,
          tags?: Array< string | null > | null,
          contents?:  {
            __typename: "ModelCollectionContentConnection",
            items:  Array< {
              __typename: "CollectionContent",
              id: string,
              slug?: string | null,
              order: number,
              title: string,
              description: string,
              body?: string | null,
              thumbnail?: string | null,
              image?: string | null,
              video?: string | null,
              duration?: number | null,
              date?: string | null,
              status: CollectionStatus,
              collectionID: string,
              authorName?: string | null,
              authorUsername?: string | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramTemplate?: string | null,
              type?: CollectionContentType | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramTemplate?: string | null,
          thumbnail?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            contentID: string,
            body: string,
            createdByName?: string | null,
            createdByUserName?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        authorName?: string | null,
        authorUsername?: string | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramTemplate?: string | null,
        type?: CollectionContentType | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramTemplate?: string | null,
    thumbnail?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCollectionSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCollectionSubscription = {
  onUpdateCollection?:  {
    __typename: "Collection",
    id: string,
    title: string,
    description: string,
    status: CollectionStatus,
    tags?: Array< string | null > | null,
    contents?:  {
      __typename: "ModelCollectionContentConnection",
      items:  Array< {
        __typename: "CollectionContent",
        id: string,
        slug?: string | null,
        order: number,
        title: string,
        description: string,
        body?: string | null,
        thumbnail?: string | null,
        image?: string | null,
        video?: string | null,
        duration?: number | null,
        date?: string | null,
        attachments?:  Array< {
          __typename: "CollectionAttachment",
          file?: string | null,
          url?: string | null,
          text?: string | null,
          mimeType?: string | null,
        } | null > | null,
        status: CollectionStatus,
        collectionID: string,
        collection?:  {
          __typename: "Collection",
          id: string,
          title: string,
          description: string,
          status: CollectionStatus,
          tags?: Array< string | null > | null,
          contents?:  {
            __typename: "ModelCollectionContentConnection",
            items:  Array< {
              __typename: "CollectionContent",
              id: string,
              slug?: string | null,
              order: number,
              title: string,
              description: string,
              body?: string | null,
              thumbnail?: string | null,
              image?: string | null,
              video?: string | null,
              duration?: number | null,
              date?: string | null,
              status: CollectionStatus,
              collectionID: string,
              authorName?: string | null,
              authorUsername?: string | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramTemplate?: string | null,
              type?: CollectionContentType | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramTemplate?: string | null,
          thumbnail?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            contentID: string,
            body: string,
            createdByName?: string | null,
            createdByUserName?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        authorName?: string | null,
        authorUsername?: string | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramTemplate?: string | null,
        type?: CollectionContentType | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramTemplate?: string | null,
    thumbnail?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCollectionSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCollectionSubscription = {
  onDeleteCollection?:  {
    __typename: "Collection",
    id: string,
    title: string,
    description: string,
    status: CollectionStatus,
    tags?: Array< string | null > | null,
    contents?:  {
      __typename: "ModelCollectionContentConnection",
      items:  Array< {
        __typename: "CollectionContent",
        id: string,
        slug?: string | null,
        order: number,
        title: string,
        description: string,
        body?: string | null,
        thumbnail?: string | null,
        image?: string | null,
        video?: string | null,
        duration?: number | null,
        date?: string | null,
        attachments?:  Array< {
          __typename: "CollectionAttachment",
          file?: string | null,
          url?: string | null,
          text?: string | null,
          mimeType?: string | null,
        } | null > | null,
        status: CollectionStatus,
        collectionID: string,
        collection?:  {
          __typename: "Collection",
          id: string,
          title: string,
          description: string,
          status: CollectionStatus,
          tags?: Array< string | null > | null,
          contents?:  {
            __typename: "ModelCollectionContentConnection",
            items:  Array< {
              __typename: "CollectionContent",
              id: string,
              slug?: string | null,
              order: number,
              title: string,
              description: string,
              body?: string | null,
              thumbnail?: string | null,
              image?: string | null,
              video?: string | null,
              duration?: number | null,
              date?: string | null,
              status: CollectionStatus,
              collectionID: string,
              authorName?: string | null,
              authorUsername?: string | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramTemplate?: string | null,
              type?: CollectionContentType | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramTemplate?: string | null,
          thumbnail?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            contentID: string,
            body: string,
            createdByName?: string | null,
            createdByUserName?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        authorName?: string | null,
        authorUsername?: string | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramTemplate?: string | null,
        type?: CollectionContentType | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramTemplate?: string | null,
    thumbnail?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCollectionContentSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionContentFilterInput | null,
  owner?: string | null,
};

export type OnCreateCollectionContentSubscription = {
  onCreateCollectionContent?:  {
    __typename: "CollectionContent",
    id: string,
    slug?: string | null,
    order: number,
    title: string,
    description: string,
    body?: string | null,
    thumbnail?: string | null,
    image?: string | null,
    video?: string | null,
    duration?: number | null,
    date?: string | null,
    attachments?:  Array< {
      __typename: "CollectionAttachment",
      file?: string | null,
      url?: string | null,
      text?: string | null,
      mimeType?: string | null,
    } | null > | null,
    status: CollectionStatus,
    collectionID: string,
    collection?:  {
      __typename: "Collection",
      id: string,
      title: string,
      description: string,
      status: CollectionStatus,
      tags?: Array< string | null > | null,
      contents?:  {
        __typename: "ModelCollectionContentConnection",
        items:  Array< {
          __typename: "CollectionContent",
          id: string,
          slug?: string | null,
          order: number,
          title: string,
          description: string,
          body?: string | null,
          thumbnail?: string | null,
          image?: string | null,
          video?: string | null,
          duration?: number | null,
          date?: string | null,
          attachments?:  Array< {
            __typename: "CollectionAttachment",
            file?: string | null,
            url?: string | null,
            text?: string | null,
            mimeType?: string | null,
          } | null > | null,
          status: CollectionStatus,
          collectionID: string,
          collection?:  {
            __typename: "Collection",
            id: string,
            title: string,
            description: string,
            status: CollectionStatus,
            tags?: Array< string | null > | null,
            contents?:  {
              __typename: "ModelCollectionContentConnection",
              nextToken?: string | null,
            } | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramTemplate?: string | null,
            thumbnail?: string | null,
            order?: number | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              contentID: string,
              body: string,
              createdByName?: string | null,
              createdByUserName?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          authorName?: string | null,
          authorUsername?: string | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramMessages?:  Array< {
            __typename: "TelegramMessageResult",
            channel?: string | null,
            id?: string | null,
            link?: string | null,
          } | null > | null,
          telegramTemplate?: string | null,
          type?: CollectionContentType | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramTemplate?: string | null,
      thumbnail?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        contentID: string,
        body: string,
        createdByName?: string | null,
        createdByUserName?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    authorName?: string | null,
    authorUsername?: string | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramTemplate?: string | null,
    type?: CollectionContentType | null,
    customFields?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCollectionContentSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionContentFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCollectionContentSubscription = {
  onUpdateCollectionContent?:  {
    __typename: "CollectionContent",
    id: string,
    slug?: string | null,
    order: number,
    title: string,
    description: string,
    body?: string | null,
    thumbnail?: string | null,
    image?: string | null,
    video?: string | null,
    duration?: number | null,
    date?: string | null,
    attachments?:  Array< {
      __typename: "CollectionAttachment",
      file?: string | null,
      url?: string | null,
      text?: string | null,
      mimeType?: string | null,
    } | null > | null,
    status: CollectionStatus,
    collectionID: string,
    collection?:  {
      __typename: "Collection",
      id: string,
      title: string,
      description: string,
      status: CollectionStatus,
      tags?: Array< string | null > | null,
      contents?:  {
        __typename: "ModelCollectionContentConnection",
        items:  Array< {
          __typename: "CollectionContent",
          id: string,
          slug?: string | null,
          order: number,
          title: string,
          description: string,
          body?: string | null,
          thumbnail?: string | null,
          image?: string | null,
          video?: string | null,
          duration?: number | null,
          date?: string | null,
          attachments?:  Array< {
            __typename: "CollectionAttachment",
            file?: string | null,
            url?: string | null,
            text?: string | null,
            mimeType?: string | null,
          } | null > | null,
          status: CollectionStatus,
          collectionID: string,
          collection?:  {
            __typename: "Collection",
            id: string,
            title: string,
            description: string,
            status: CollectionStatus,
            tags?: Array< string | null > | null,
            contents?:  {
              __typename: "ModelCollectionContentConnection",
              nextToken?: string | null,
            } | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramTemplate?: string | null,
            thumbnail?: string | null,
            order?: number | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              contentID: string,
              body: string,
              createdByName?: string | null,
              createdByUserName?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          authorName?: string | null,
          authorUsername?: string | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramMessages?:  Array< {
            __typename: "TelegramMessageResult",
            channel?: string | null,
            id?: string | null,
            link?: string | null,
          } | null > | null,
          telegramTemplate?: string | null,
          type?: CollectionContentType | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramTemplate?: string | null,
      thumbnail?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        contentID: string,
        body: string,
        createdByName?: string | null,
        createdByUserName?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    authorName?: string | null,
    authorUsername?: string | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramTemplate?: string | null,
    type?: CollectionContentType | null,
    customFields?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCollectionContentSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionContentFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCollectionContentSubscription = {
  onDeleteCollectionContent?:  {
    __typename: "CollectionContent",
    id: string,
    slug?: string | null,
    order: number,
    title: string,
    description: string,
    body?: string | null,
    thumbnail?: string | null,
    image?: string | null,
    video?: string | null,
    duration?: number | null,
    date?: string | null,
    attachments?:  Array< {
      __typename: "CollectionAttachment",
      file?: string | null,
      url?: string | null,
      text?: string | null,
      mimeType?: string | null,
    } | null > | null,
    status: CollectionStatus,
    collectionID: string,
    collection?:  {
      __typename: "Collection",
      id: string,
      title: string,
      description: string,
      status: CollectionStatus,
      tags?: Array< string | null > | null,
      contents?:  {
        __typename: "ModelCollectionContentConnection",
        items:  Array< {
          __typename: "CollectionContent",
          id: string,
          slug?: string | null,
          order: number,
          title: string,
          description: string,
          body?: string | null,
          thumbnail?: string | null,
          image?: string | null,
          video?: string | null,
          duration?: number | null,
          date?: string | null,
          attachments?:  Array< {
            __typename: "CollectionAttachment",
            file?: string | null,
            url?: string | null,
            text?: string | null,
            mimeType?: string | null,
          } | null > | null,
          status: CollectionStatus,
          collectionID: string,
          collection?:  {
            __typename: "Collection",
            id: string,
            title: string,
            description: string,
            status: CollectionStatus,
            tags?: Array< string | null > | null,
            contents?:  {
              __typename: "ModelCollectionContentConnection",
              nextToken?: string | null,
            } | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramTemplate?: string | null,
            thumbnail?: string | null,
            order?: number | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              contentID: string,
              body: string,
              createdByName?: string | null,
              createdByUserName?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          authorName?: string | null,
          authorUsername?: string | null,
          telegramChannels?: Array< TelegramChannel | null > | null,
          telegramMessages?:  Array< {
            __typename: "TelegramMessageResult",
            channel?: string | null,
            id?: string | null,
            link?: string | null,
          } | null > | null,
          telegramTemplate?: string | null,
          type?: CollectionContentType | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramTemplate?: string | null,
      thumbnail?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        contentID: string,
        body: string,
        createdByName?: string | null,
        createdByUserName?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    authorName?: string | null,
    authorUsername?: string | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramTemplate?: string | null,
    type?: CollectionContentType | null,
    customFields?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
  owner?: string | null,
};

export type OnCreateCommentSubscription = {
  onCreateComment?:  {
    __typename: "Comment",
    id: string,
    contentID: string,
    body: string,
    createdByName?: string | null,
    createdByUserName?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?:  {
    __typename: "Comment",
    id: string,
    contentID: string,
    body: string,
    createdByName?: string | null,
    createdByUserName?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?:  {
    __typename: "Comment",
    id: string,
    contentID: string,
    body: string,
    createdByName?: string | null,
    createdByUserName?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateLessonProgressSubscriptionVariables = {
  filter?: ModelSubscriptionLessonProgressFilterInput | null,
  owner?: string | null,
};

export type OnCreateLessonProgressSubscription = {
  onCreateLessonProgress?:  {
    __typename: "LessonProgress",
    collectionContentId: string,
    username: string,
    progress?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateLessonProgressSubscriptionVariables = {
  filter?: ModelSubscriptionLessonProgressFilterInput | null,
  owner?: string | null,
};

export type OnUpdateLessonProgressSubscription = {
  onUpdateLessonProgress?:  {
    __typename: "LessonProgress",
    collectionContentId: string,
    username: string,
    progress?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteLessonProgressSubscriptionVariables = {
  filter?: ModelSubscriptionLessonProgressFilterInput | null,
  owner?: string | null,
};

export type OnDeleteLessonProgressSubscription = {
  onDeleteLessonProgress?:  {
    __typename: "LessonProgress",
    collectionContentId: string,
    username: string,
    progress?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCryptofyBotTradeSubscriptionVariables = {
  filter?: ModelSubscriptionCryptofyBotTradeFilterInput | null,
};

export type OnCreateCryptofyBotTradeSubscription = {
  onCreateCryptofyBotTrade?:  {
    __typename: "CryptofyBotTrade",
    id: string,
    status: CryptofyBotTradeStatus,
    tokenName: string,
    symbol: string,
    exchange: SignalExchange,
    type: CryptofyBotTradeTypes,
    side?: CryptofyBotTradeSides | null,
    leverage?: number | null,
    signalEntry?: number | null,
    tradeEntry?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    signalStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    tradeStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    peakLossProfit?: number | null,
    currentLossProfit?: number | null,
    telegramSignalLink?: string | null,
    lastMonitorCheck?: string | null,
    lastPrice?: number | null,
    activationManagement?:  {
      __typename: "CryptofyBotActivationManagement",
      crossingTime?: number | null,
      crossingPrice?: number | null,
      wasWithinThreshold?: boolean | null,
      hasActivated?: boolean | null,
    } | null,
    customFields?: string | null,
    history?:  {
      __typename: "ModelCryptofyBotTradeHistoryConnection",
      items:  Array< {
        __typename: "CryptofyBotTradeHistory",
        id: string,
        action: CryptofyBotTradeHistoryActions,
        message?: string | null,
        tradeId?: string | null,
        trade?:  {
          __typename: "CryptofyBotTrade",
          id: string,
          status: CryptofyBotTradeStatus,
          tokenName: string,
          symbol: string,
          exchange: SignalExchange,
          type: CryptofyBotTradeTypes,
          side?: CryptofyBotTradeSides | null,
          leverage?: number | null,
          signalEntry?: number | null,
          tradeEntry?: number | null,
          targets?:  Array< {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null > | null,
          signalStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          tradeStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          peakLossProfit?: number | null,
          currentLossProfit?: number | null,
          telegramSignalLink?: string | null,
          lastMonitorCheck?: string | null,
          lastPrice?: number | null,
          activationManagement?:  {
            __typename: "CryptofyBotActivationManagement",
            crossingTime?: number | null,
            crossingPrice?: number | null,
            wasWithinThreshold?: boolean | null,
            hasActivated?: boolean | null,
          } | null,
          customFields?: string | null,
          history?:  {
            __typename: "ModelCryptofyBotTradeHistoryConnection",
            items:  Array< {
              __typename: "CryptofyBotTradeHistory",
              id: string,
              action: CryptofyBotTradeHistoryActions,
              message?: string | null,
              tradeId?: string | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          signalID?: string | null,
          signal?:  {
            __typename: "Signal",
            id: string,
            title?: string | null,
            type?: SignalType | null,
            status: SignalStatus,
            exchange: SignalExchange,
            isFutures?: boolean | null,
            video?: string | null,
            thumbnail?: string | null,
            stopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            trailingStopLoss?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            minRetainer?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            description?: string | null,
            entryOnActivation?: number | null,
            entry?: number | null,
            futuresOperation?: SignalFuturesOperation | null,
            leverage?: number | null,
            interval?: string | null,
            tags?: Array< string | null > | null,
            link?: string | null,
            linkLabel?: string | null,
            totalGains?: number | null,
            totalGainsFutures?: number | null,
            lastPrice?: number | null,
            lastMonitorCheck?: string | null,
            takeProfitNum?: number | null,
            breakThroughTime?: number | null,
            breakThroughCount?: number | null,
            showOnFreeGroup?: boolean | null,
            telegramMessages?:  Array< {
              __typename: "TelegramMessageResult",
              channel?: string | null,
              id?: string | null,
              link?: string | null,
            } | null > | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramEntryNotificationId?: number | null,
            telegramEntryNotificationDate?: string | null,
            tokenName?: string | null,
            tokenSymbol?: string | null,
            tokenAddress?: string | null,
            createdByName?: string | null,
            createdByUserName?: string | null,
            manualOperation?: boolean | null,
            isTesting?: boolean | null,
            useCopyTrade?: boolean | null,
            referenceId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    signalID?: string | null,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCryptofyBotTradeSubscriptionVariables = {
  filter?: ModelSubscriptionCryptofyBotTradeFilterInput | null,
};

export type OnUpdateCryptofyBotTradeSubscription = {
  onUpdateCryptofyBotTrade?:  {
    __typename: "CryptofyBotTrade",
    id: string,
    status: CryptofyBotTradeStatus,
    tokenName: string,
    symbol: string,
    exchange: SignalExchange,
    type: CryptofyBotTradeTypes,
    side?: CryptofyBotTradeSides | null,
    leverage?: number | null,
    signalEntry?: number | null,
    tradeEntry?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    signalStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    tradeStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    peakLossProfit?: number | null,
    currentLossProfit?: number | null,
    telegramSignalLink?: string | null,
    lastMonitorCheck?: string | null,
    lastPrice?: number | null,
    activationManagement?:  {
      __typename: "CryptofyBotActivationManagement",
      crossingTime?: number | null,
      crossingPrice?: number | null,
      wasWithinThreshold?: boolean | null,
      hasActivated?: boolean | null,
    } | null,
    customFields?: string | null,
    history?:  {
      __typename: "ModelCryptofyBotTradeHistoryConnection",
      items:  Array< {
        __typename: "CryptofyBotTradeHistory",
        id: string,
        action: CryptofyBotTradeHistoryActions,
        message?: string | null,
        tradeId?: string | null,
        trade?:  {
          __typename: "CryptofyBotTrade",
          id: string,
          status: CryptofyBotTradeStatus,
          tokenName: string,
          symbol: string,
          exchange: SignalExchange,
          type: CryptofyBotTradeTypes,
          side?: CryptofyBotTradeSides | null,
          leverage?: number | null,
          signalEntry?: number | null,
          tradeEntry?: number | null,
          targets?:  Array< {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null > | null,
          signalStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          tradeStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          peakLossProfit?: number | null,
          currentLossProfit?: number | null,
          telegramSignalLink?: string | null,
          lastMonitorCheck?: string | null,
          lastPrice?: number | null,
          activationManagement?:  {
            __typename: "CryptofyBotActivationManagement",
            crossingTime?: number | null,
            crossingPrice?: number | null,
            wasWithinThreshold?: boolean | null,
            hasActivated?: boolean | null,
          } | null,
          customFields?: string | null,
          history?:  {
            __typename: "ModelCryptofyBotTradeHistoryConnection",
            items:  Array< {
              __typename: "CryptofyBotTradeHistory",
              id: string,
              action: CryptofyBotTradeHistoryActions,
              message?: string | null,
              tradeId?: string | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          signalID?: string | null,
          signal?:  {
            __typename: "Signal",
            id: string,
            title?: string | null,
            type?: SignalType | null,
            status: SignalStatus,
            exchange: SignalExchange,
            isFutures?: boolean | null,
            video?: string | null,
            thumbnail?: string | null,
            stopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            trailingStopLoss?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            minRetainer?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            description?: string | null,
            entryOnActivation?: number | null,
            entry?: number | null,
            futuresOperation?: SignalFuturesOperation | null,
            leverage?: number | null,
            interval?: string | null,
            tags?: Array< string | null > | null,
            link?: string | null,
            linkLabel?: string | null,
            totalGains?: number | null,
            totalGainsFutures?: number | null,
            lastPrice?: number | null,
            lastMonitorCheck?: string | null,
            takeProfitNum?: number | null,
            breakThroughTime?: number | null,
            breakThroughCount?: number | null,
            showOnFreeGroup?: boolean | null,
            telegramMessages?:  Array< {
              __typename: "TelegramMessageResult",
              channel?: string | null,
              id?: string | null,
              link?: string | null,
            } | null > | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramEntryNotificationId?: number | null,
            telegramEntryNotificationDate?: string | null,
            tokenName?: string | null,
            tokenSymbol?: string | null,
            tokenAddress?: string | null,
            createdByName?: string | null,
            createdByUserName?: string | null,
            manualOperation?: boolean | null,
            isTesting?: boolean | null,
            useCopyTrade?: boolean | null,
            referenceId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    signalID?: string | null,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCryptofyBotTradeSubscriptionVariables = {
  filter?: ModelSubscriptionCryptofyBotTradeFilterInput | null,
};

export type OnDeleteCryptofyBotTradeSubscription = {
  onDeleteCryptofyBotTrade?:  {
    __typename: "CryptofyBotTrade",
    id: string,
    status: CryptofyBotTradeStatus,
    tokenName: string,
    symbol: string,
    exchange: SignalExchange,
    type: CryptofyBotTradeTypes,
    side?: CryptofyBotTradeSides | null,
    leverage?: number | null,
    signalEntry?: number | null,
    tradeEntry?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    signalStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    tradeStopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    peakLossProfit?: number | null,
    currentLossProfit?: number | null,
    telegramSignalLink?: string | null,
    lastMonitorCheck?: string | null,
    lastPrice?: number | null,
    activationManagement?:  {
      __typename: "CryptofyBotActivationManagement",
      crossingTime?: number | null,
      crossingPrice?: number | null,
      wasWithinThreshold?: boolean | null,
      hasActivated?: boolean | null,
    } | null,
    customFields?: string | null,
    history?:  {
      __typename: "ModelCryptofyBotTradeHistoryConnection",
      items:  Array< {
        __typename: "CryptofyBotTradeHistory",
        id: string,
        action: CryptofyBotTradeHistoryActions,
        message?: string | null,
        tradeId?: string | null,
        trade?:  {
          __typename: "CryptofyBotTrade",
          id: string,
          status: CryptofyBotTradeStatus,
          tokenName: string,
          symbol: string,
          exchange: SignalExchange,
          type: CryptofyBotTradeTypes,
          side?: CryptofyBotTradeSides | null,
          leverage?: number | null,
          signalEntry?: number | null,
          tradeEntry?: number | null,
          targets?:  Array< {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null > | null,
          signalStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          tradeStopLoss?:  {
            __typename: "SignalTarget",
            value?: number | null,
            percentage?: number | null,
            hit?: boolean | null,
          } | null,
          peakLossProfit?: number | null,
          currentLossProfit?: number | null,
          telegramSignalLink?: string | null,
          lastMonitorCheck?: string | null,
          lastPrice?: number | null,
          activationManagement?:  {
            __typename: "CryptofyBotActivationManagement",
            crossingTime?: number | null,
            crossingPrice?: number | null,
            wasWithinThreshold?: boolean | null,
            hasActivated?: boolean | null,
          } | null,
          customFields?: string | null,
          history?:  {
            __typename: "ModelCryptofyBotTradeHistoryConnection",
            items:  Array< {
              __typename: "CryptofyBotTradeHistory",
              id: string,
              action: CryptofyBotTradeHistoryActions,
              message?: string | null,
              tradeId?: string | null,
              customFields?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          signalID?: string | null,
          signal?:  {
            __typename: "Signal",
            id: string,
            title?: string | null,
            type?: SignalType | null,
            status: SignalStatus,
            exchange: SignalExchange,
            isFutures?: boolean | null,
            video?: string | null,
            thumbnail?: string | null,
            stopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            trailingStopLoss?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            minRetainer?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            description?: string | null,
            entryOnActivation?: number | null,
            entry?: number | null,
            futuresOperation?: SignalFuturesOperation | null,
            leverage?: number | null,
            interval?: string | null,
            tags?: Array< string | null > | null,
            link?: string | null,
            linkLabel?: string | null,
            totalGains?: number | null,
            totalGainsFutures?: number | null,
            lastPrice?: number | null,
            lastMonitorCheck?: string | null,
            takeProfitNum?: number | null,
            breakThroughTime?: number | null,
            breakThroughCount?: number | null,
            showOnFreeGroup?: boolean | null,
            telegramMessages?:  Array< {
              __typename: "TelegramMessageResult",
              channel?: string | null,
              id?: string | null,
              link?: string | null,
            } | null > | null,
            telegramChannels?: Array< TelegramChannel | null > | null,
            telegramEntryNotificationId?: number | null,
            telegramEntryNotificationDate?: string | null,
            tokenName?: string | null,
            tokenSymbol?: string | null,
            tokenAddress?: string | null,
            createdByName?: string | null,
            createdByUserName?: string | null,
            manualOperation?: boolean | null,
            isTesting?: boolean | null,
            useCopyTrade?: boolean | null,
            referenceId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFields?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    signalID?: string | null,
    signal?:  {
      __typename: "Signal",
      id: string,
      title?: string | null,
      type?: SignalType | null,
      status: SignalStatus,
      exchange: SignalExchange,
      isFutures?: boolean | null,
      video?: string | null,
      thumbnail?: string | null,
      stopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      trailingStopLoss?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      minRetainer?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      description?: string | null,
      entryOnActivation?: number | null,
      entry?: number | null,
      futuresOperation?: SignalFuturesOperation | null,
      leverage?: number | null,
      interval?: string | null,
      tags?: Array< string | null > | null,
      link?: string | null,
      linkLabel?: string | null,
      totalGains?: number | null,
      totalGainsFutures?: number | null,
      lastPrice?: number | null,
      lastMonitorCheck?: string | null,
      takeProfitNum?: number | null,
      breakThroughTime?: number | null,
      breakThroughCount?: number | null,
      showOnFreeGroup?: boolean | null,
      telegramMessages?:  Array< {
        __typename: "TelegramMessageResult",
        channel?: string | null,
        id?: string | null,
        link?: string | null,
      } | null > | null,
      telegramChannels?: Array< TelegramChannel | null > | null,
      telegramEntryNotificationId?: number | null,
      telegramEntryNotificationDate?: string | null,
      tokenName?: string | null,
      tokenSymbol?: string | null,
      tokenAddress?: string | null,
      createdByName?: string | null,
      createdByUserName?: string | null,
      manualOperation?: boolean | null,
      isTesting?: boolean | null,
      useCopyTrade?: boolean | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCryptofyBotTradeHistorySubscriptionVariables = {
  filter?: ModelSubscriptionCryptofyBotTradeHistoryFilterInput | null,
};

export type OnCreateCryptofyBotTradeHistorySubscription = {
  onCreateCryptofyBotTradeHistory?:  {
    __typename: "CryptofyBotTradeHistory",
    id: string,
    action: CryptofyBotTradeHistoryActions,
    message?: string | null,
    tradeId?: string | null,
    trade?:  {
      __typename: "CryptofyBotTrade",
      id: string,
      status: CryptofyBotTradeStatus,
      tokenName: string,
      symbol: string,
      exchange: SignalExchange,
      type: CryptofyBotTradeTypes,
      side?: CryptofyBotTradeSides | null,
      leverage?: number | null,
      signalEntry?: number | null,
      tradeEntry?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      signalStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      tradeStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      peakLossProfit?: number | null,
      currentLossProfit?: number | null,
      telegramSignalLink?: string | null,
      lastMonitorCheck?: string | null,
      lastPrice?: number | null,
      activationManagement?:  {
        __typename: "CryptofyBotActivationManagement",
        crossingTime?: number | null,
        crossingPrice?: number | null,
        wasWithinThreshold?: boolean | null,
        hasActivated?: boolean | null,
      } | null,
      customFields?: string | null,
      history?:  {
        __typename: "ModelCryptofyBotTradeHistoryConnection",
        items:  Array< {
          __typename: "CryptofyBotTradeHistory",
          id: string,
          action: CryptofyBotTradeHistoryActions,
          message?: string | null,
          tradeId?: string | null,
          trade?:  {
            __typename: "CryptofyBotTrade",
            id: string,
            status: CryptofyBotTradeStatus,
            tokenName: string,
            symbol: string,
            exchange: SignalExchange,
            type: CryptofyBotTradeTypes,
            side?: CryptofyBotTradeSides | null,
            leverage?: number | null,
            signalEntry?: number | null,
            tradeEntry?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            signalStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            tradeStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            peakLossProfit?: number | null,
            currentLossProfit?: number | null,
            telegramSignalLink?: string | null,
            lastMonitorCheck?: string | null,
            lastPrice?: number | null,
            activationManagement?:  {
              __typename: "CryptofyBotActivationManagement",
              crossingTime?: number | null,
              crossingPrice?: number | null,
              wasWithinThreshold?: boolean | null,
              hasActivated?: boolean | null,
            } | null,
            customFields?: string | null,
            history?:  {
              __typename: "ModelCryptofyBotTradeHistoryConnection",
              nextToken?: string | null,
            } | null,
            signalID?: string | null,
            signal?:  {
              __typename: "Signal",
              id: string,
              title?: string | null,
              type?: SignalType | null,
              status: SignalStatus,
              exchange: SignalExchange,
              isFutures?: boolean | null,
              video?: string | null,
              thumbnail?: string | null,
              trailingStopLoss?: number | null,
              description?: string | null,
              entryOnActivation?: number | null,
              entry?: number | null,
              futuresOperation?: SignalFuturesOperation | null,
              leverage?: number | null,
              interval?: string | null,
              tags?: Array< string | null > | null,
              link?: string | null,
              linkLabel?: string | null,
              totalGains?: number | null,
              totalGainsFutures?: number | null,
              lastPrice?: number | null,
              lastMonitorCheck?: string | null,
              takeProfitNum?: number | null,
              breakThroughTime?: number | null,
              breakThroughCount?: number | null,
              showOnFreeGroup?: boolean | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramEntryNotificationId?: number | null,
              telegramEntryNotificationDate?: string | null,
              tokenName?: string | null,
              tokenSymbol?: string | null,
              tokenAddress?: string | null,
              createdByName?: string | null,
              createdByUserName?: string | null,
              manualOperation?: boolean | null,
              isTesting?: boolean | null,
              useCopyTrade?: boolean | null,
              referenceId?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      signalID?: string | null,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    customFields?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCryptofyBotTradeHistorySubscriptionVariables = {
  filter?: ModelSubscriptionCryptofyBotTradeHistoryFilterInput | null,
};

export type OnUpdateCryptofyBotTradeHistorySubscription = {
  onUpdateCryptofyBotTradeHistory?:  {
    __typename: "CryptofyBotTradeHistory",
    id: string,
    action: CryptofyBotTradeHistoryActions,
    message?: string | null,
    tradeId?: string | null,
    trade?:  {
      __typename: "CryptofyBotTrade",
      id: string,
      status: CryptofyBotTradeStatus,
      tokenName: string,
      symbol: string,
      exchange: SignalExchange,
      type: CryptofyBotTradeTypes,
      side?: CryptofyBotTradeSides | null,
      leverage?: number | null,
      signalEntry?: number | null,
      tradeEntry?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      signalStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      tradeStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      peakLossProfit?: number | null,
      currentLossProfit?: number | null,
      telegramSignalLink?: string | null,
      lastMonitorCheck?: string | null,
      lastPrice?: number | null,
      activationManagement?:  {
        __typename: "CryptofyBotActivationManagement",
        crossingTime?: number | null,
        crossingPrice?: number | null,
        wasWithinThreshold?: boolean | null,
        hasActivated?: boolean | null,
      } | null,
      customFields?: string | null,
      history?:  {
        __typename: "ModelCryptofyBotTradeHistoryConnection",
        items:  Array< {
          __typename: "CryptofyBotTradeHistory",
          id: string,
          action: CryptofyBotTradeHistoryActions,
          message?: string | null,
          tradeId?: string | null,
          trade?:  {
            __typename: "CryptofyBotTrade",
            id: string,
            status: CryptofyBotTradeStatus,
            tokenName: string,
            symbol: string,
            exchange: SignalExchange,
            type: CryptofyBotTradeTypes,
            side?: CryptofyBotTradeSides | null,
            leverage?: number | null,
            signalEntry?: number | null,
            tradeEntry?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            signalStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            tradeStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            peakLossProfit?: number | null,
            currentLossProfit?: number | null,
            telegramSignalLink?: string | null,
            lastMonitorCheck?: string | null,
            lastPrice?: number | null,
            activationManagement?:  {
              __typename: "CryptofyBotActivationManagement",
              crossingTime?: number | null,
              crossingPrice?: number | null,
              wasWithinThreshold?: boolean | null,
              hasActivated?: boolean | null,
            } | null,
            customFields?: string | null,
            history?:  {
              __typename: "ModelCryptofyBotTradeHistoryConnection",
              nextToken?: string | null,
            } | null,
            signalID?: string | null,
            signal?:  {
              __typename: "Signal",
              id: string,
              title?: string | null,
              type?: SignalType | null,
              status: SignalStatus,
              exchange: SignalExchange,
              isFutures?: boolean | null,
              video?: string | null,
              thumbnail?: string | null,
              trailingStopLoss?: number | null,
              description?: string | null,
              entryOnActivation?: number | null,
              entry?: number | null,
              futuresOperation?: SignalFuturesOperation | null,
              leverage?: number | null,
              interval?: string | null,
              tags?: Array< string | null > | null,
              link?: string | null,
              linkLabel?: string | null,
              totalGains?: number | null,
              totalGainsFutures?: number | null,
              lastPrice?: number | null,
              lastMonitorCheck?: string | null,
              takeProfitNum?: number | null,
              breakThroughTime?: number | null,
              breakThroughCount?: number | null,
              showOnFreeGroup?: boolean | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramEntryNotificationId?: number | null,
              telegramEntryNotificationDate?: string | null,
              tokenName?: string | null,
              tokenSymbol?: string | null,
              tokenAddress?: string | null,
              createdByName?: string | null,
              createdByUserName?: string | null,
              manualOperation?: boolean | null,
              isTesting?: boolean | null,
              useCopyTrade?: boolean | null,
              referenceId?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      signalID?: string | null,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    customFields?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCryptofyBotTradeHistorySubscriptionVariables = {
  filter?: ModelSubscriptionCryptofyBotTradeHistoryFilterInput | null,
};

export type OnDeleteCryptofyBotTradeHistorySubscription = {
  onDeleteCryptofyBotTradeHistory?:  {
    __typename: "CryptofyBotTradeHistory",
    id: string,
    action: CryptofyBotTradeHistoryActions,
    message?: string | null,
    tradeId?: string | null,
    trade?:  {
      __typename: "CryptofyBotTrade",
      id: string,
      status: CryptofyBotTradeStatus,
      tokenName: string,
      symbol: string,
      exchange: SignalExchange,
      type: CryptofyBotTradeTypes,
      side?: CryptofyBotTradeSides | null,
      leverage?: number | null,
      signalEntry?: number | null,
      tradeEntry?: number | null,
      targets?:  Array< {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null > | null,
      signalStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      tradeStopLoss?:  {
        __typename: "SignalTarget",
        value?: number | null,
        percentage?: number | null,
        hit?: boolean | null,
      } | null,
      peakLossProfit?: number | null,
      currentLossProfit?: number | null,
      telegramSignalLink?: string | null,
      lastMonitorCheck?: string | null,
      lastPrice?: number | null,
      activationManagement?:  {
        __typename: "CryptofyBotActivationManagement",
        crossingTime?: number | null,
        crossingPrice?: number | null,
        wasWithinThreshold?: boolean | null,
        hasActivated?: boolean | null,
      } | null,
      customFields?: string | null,
      history?:  {
        __typename: "ModelCryptofyBotTradeHistoryConnection",
        items:  Array< {
          __typename: "CryptofyBotTradeHistory",
          id: string,
          action: CryptofyBotTradeHistoryActions,
          message?: string | null,
          tradeId?: string | null,
          trade?:  {
            __typename: "CryptofyBotTrade",
            id: string,
            status: CryptofyBotTradeStatus,
            tokenName: string,
            symbol: string,
            exchange: SignalExchange,
            type: CryptofyBotTradeTypes,
            side?: CryptofyBotTradeSides | null,
            leverage?: number | null,
            signalEntry?: number | null,
            tradeEntry?: number | null,
            targets?:  Array< {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null > | null,
            signalStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            tradeStopLoss?:  {
              __typename: "SignalTarget",
              value?: number | null,
              percentage?: number | null,
              hit?: boolean | null,
            } | null,
            peakLossProfit?: number | null,
            currentLossProfit?: number | null,
            telegramSignalLink?: string | null,
            lastMonitorCheck?: string | null,
            lastPrice?: number | null,
            activationManagement?:  {
              __typename: "CryptofyBotActivationManagement",
              crossingTime?: number | null,
              crossingPrice?: number | null,
              wasWithinThreshold?: boolean | null,
              hasActivated?: boolean | null,
            } | null,
            customFields?: string | null,
            history?:  {
              __typename: "ModelCryptofyBotTradeHistoryConnection",
              nextToken?: string | null,
            } | null,
            signalID?: string | null,
            signal?:  {
              __typename: "Signal",
              id: string,
              title?: string | null,
              type?: SignalType | null,
              status: SignalStatus,
              exchange: SignalExchange,
              isFutures?: boolean | null,
              video?: string | null,
              thumbnail?: string | null,
              trailingStopLoss?: number | null,
              description?: string | null,
              entryOnActivation?: number | null,
              entry?: number | null,
              futuresOperation?: SignalFuturesOperation | null,
              leverage?: number | null,
              interval?: string | null,
              tags?: Array< string | null > | null,
              link?: string | null,
              linkLabel?: string | null,
              totalGains?: number | null,
              totalGainsFutures?: number | null,
              lastPrice?: number | null,
              lastMonitorCheck?: string | null,
              takeProfitNum?: number | null,
              breakThroughTime?: number | null,
              breakThroughCount?: number | null,
              showOnFreeGroup?: boolean | null,
              telegramChannels?: Array< TelegramChannel | null > | null,
              telegramEntryNotificationId?: number | null,
              telegramEntryNotificationDate?: string | null,
              tokenName?: string | null,
              tokenSymbol?: string | null,
              tokenAddress?: string | null,
              createdByName?: string | null,
              createdByUserName?: string | null,
              manualOperation?: boolean | null,
              isTesting?: boolean | null,
              useCopyTrade?: boolean | null,
              referenceId?: string | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFields?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      signalID?: string | null,
      signal?:  {
        __typename: "Signal",
        id: string,
        title?: string | null,
        type?: SignalType | null,
        status: SignalStatus,
        exchange: SignalExchange,
        isFutures?: boolean | null,
        video?: string | null,
        thumbnail?: string | null,
        stopLoss?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        trailingStopLoss?: number | null,
        targets?:  Array< {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null > | null,
        minRetainer?:  {
          __typename: "SignalTarget",
          value?: number | null,
          percentage?: number | null,
          hit?: boolean | null,
        } | null,
        description?: string | null,
        entryOnActivation?: number | null,
        entry?: number | null,
        futuresOperation?: SignalFuturesOperation | null,
        leverage?: number | null,
        interval?: string | null,
        tags?: Array< string | null > | null,
        link?: string | null,
        linkLabel?: string | null,
        totalGains?: number | null,
        totalGainsFutures?: number | null,
        lastPrice?: number | null,
        lastMonitorCheck?: string | null,
        takeProfitNum?: number | null,
        breakThroughTime?: number | null,
        breakThroughCount?: number | null,
        showOnFreeGroup?: boolean | null,
        telegramMessages?:  Array< {
          __typename: "TelegramMessageResult",
          channel?: string | null,
          id?: string | null,
          link?: string | null,
        } | null > | null,
        telegramChannels?: Array< TelegramChannel | null > | null,
        telegramEntryNotificationId?: number | null,
        telegramEntryNotificationDate?: string | null,
        tokenName?: string | null,
        tokenSymbol?: string | null,
        tokenAddress?: string | null,
        createdByName?: string | null,
        createdByUserName?: string | null,
        manualOperation?: boolean | null,
        isTesting?: boolean | null,
        useCopyTrade?: boolean | null,
        referenceId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    customFields?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateMarketPlaceProductSubscriptionVariables = {
  filter?: ModelSubscriptionMarketPlaceProductFilterInput | null,
};

export type OnCreateMarketPlaceProductSubscription = {
  onCreateMarketPlaceProduct?:  {
    __typename: "MarketPlaceProduct",
    id: string,
    slug: string,
    name: string,
    externalReference?: string | null,
    icon?: string | null,
    price?: number | null,
    description?: string | null,
    productType?: MarketPlaceProductType | null,
    status?: MarketPlaceProductStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMarketPlaceProductSubscriptionVariables = {
  filter?: ModelSubscriptionMarketPlaceProductFilterInput | null,
};

export type OnUpdateMarketPlaceProductSubscription = {
  onUpdateMarketPlaceProduct?:  {
    __typename: "MarketPlaceProduct",
    id: string,
    slug: string,
    name: string,
    externalReference?: string | null,
    icon?: string | null,
    price?: number | null,
    description?: string | null,
    productType?: MarketPlaceProductType | null,
    status?: MarketPlaceProductStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMarketPlaceProductSubscriptionVariables = {
  filter?: ModelSubscriptionMarketPlaceProductFilterInput | null,
};

export type OnDeleteMarketPlaceProductSubscription = {
  onDeleteMarketPlaceProduct?:  {
    __typename: "MarketPlaceProduct",
    id: string,
    slug: string,
    name: string,
    externalReference?: string | null,
    icon?: string | null,
    price?: number | null,
    description?: string | null,
    productType?: MarketPlaceProductType | null,
    status?: MarketPlaceProductStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePriceAlertSubscriptionVariables = {
  filter?: ModelSubscriptionPriceAlertFilterInput | null,
  owner?: string | null,
};

export type OnCreatePriceAlertSubscription = {
  onCreatePriceAlert?:  {
    __typename: "PriceAlert",
    id: string,
    status: PriceAlertStatus,
    type: PriceAlertType,
    price: string,
    description?: string | null,
    tokenName: string,
    tokenAddress: string,
    tokenSymbol: string,
    triggeredDate?: string | null,
    triggeredPrice?: number | null,
    username?: string | null,
    userEmail?: string | null,
    chain?: string | null,
    isFutures?: boolean | null,
    createdAlertAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePriceAlertSubscriptionVariables = {
  filter?: ModelSubscriptionPriceAlertFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePriceAlertSubscription = {
  onUpdatePriceAlert?:  {
    __typename: "PriceAlert",
    id: string,
    status: PriceAlertStatus,
    type: PriceAlertType,
    price: string,
    description?: string | null,
    tokenName: string,
    tokenAddress: string,
    tokenSymbol: string,
    triggeredDate?: string | null,
    triggeredPrice?: number | null,
    username?: string | null,
    userEmail?: string | null,
    chain?: string | null,
    isFutures?: boolean | null,
    createdAlertAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePriceAlertSubscriptionVariables = {
  filter?: ModelSubscriptionPriceAlertFilterInput | null,
  owner?: string | null,
};

export type OnDeletePriceAlertSubscription = {
  onDeletePriceAlert?:  {
    __typename: "PriceAlert",
    id: string,
    status: PriceAlertStatus,
    type: PriceAlertType,
    price: string,
    description?: string | null,
    tokenName: string,
    tokenAddress: string,
    tokenSymbol: string,
    triggeredDate?: string | null,
    triggeredPrice?: number | null,
    username?: string | null,
    userEmail?: string | null,
    chain?: string | null,
    isFutures?: boolean | null,
    createdAlertAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateSignalSubscriptionVariables = {
  filter?: ModelSubscriptionSignalFilterInput | null,
};

export type OnCreateSignalSubscription = {
  onCreateSignal?:  {
    __typename: "Signal",
    id: string,
    title?: string | null,
    type?: SignalType | null,
    status: SignalStatus,
    exchange: SignalExchange,
    isFutures?: boolean | null,
    video?: string | null,
    thumbnail?: string | null,
    stopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    trailingStopLoss?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    minRetainer?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    description?: string | null,
    entryOnActivation?: number | null,
    entry?: number | null,
    futuresOperation?: SignalFuturesOperation | null,
    leverage?: number | null,
    interval?: string | null,
    tags?: Array< string | null > | null,
    link?: string | null,
    linkLabel?: string | null,
    totalGains?: number | null,
    totalGainsFutures?: number | null,
    lastPrice?: number | null,
    lastMonitorCheck?: string | null,
    takeProfitNum?: number | null,
    breakThroughTime?: number | null,
    breakThroughCount?: number | null,
    showOnFreeGroup?: boolean | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramEntryNotificationId?: number | null,
    telegramEntryNotificationDate?: string | null,
    tokenName?: string | null,
    tokenSymbol?: string | null,
    tokenAddress?: string | null,
    createdByName?: string | null,
    createdByUserName?: string | null,
    manualOperation?: boolean | null,
    isTesting?: boolean | null,
    useCopyTrade?: boolean | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSignalSubscriptionVariables = {
  filter?: ModelSubscriptionSignalFilterInput | null,
};

export type OnUpdateSignalSubscription = {
  onUpdateSignal?:  {
    __typename: "Signal",
    id: string,
    title?: string | null,
    type?: SignalType | null,
    status: SignalStatus,
    exchange: SignalExchange,
    isFutures?: boolean | null,
    video?: string | null,
    thumbnail?: string | null,
    stopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    trailingStopLoss?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    minRetainer?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    description?: string | null,
    entryOnActivation?: number | null,
    entry?: number | null,
    futuresOperation?: SignalFuturesOperation | null,
    leverage?: number | null,
    interval?: string | null,
    tags?: Array< string | null > | null,
    link?: string | null,
    linkLabel?: string | null,
    totalGains?: number | null,
    totalGainsFutures?: number | null,
    lastPrice?: number | null,
    lastMonitorCheck?: string | null,
    takeProfitNum?: number | null,
    breakThroughTime?: number | null,
    breakThroughCount?: number | null,
    showOnFreeGroup?: boolean | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramEntryNotificationId?: number | null,
    telegramEntryNotificationDate?: string | null,
    tokenName?: string | null,
    tokenSymbol?: string | null,
    tokenAddress?: string | null,
    createdByName?: string | null,
    createdByUserName?: string | null,
    manualOperation?: boolean | null,
    isTesting?: boolean | null,
    useCopyTrade?: boolean | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSignalSubscriptionVariables = {
  filter?: ModelSubscriptionSignalFilterInput | null,
};

export type OnDeleteSignalSubscription = {
  onDeleteSignal?:  {
    __typename: "Signal",
    id: string,
    title?: string | null,
    type?: SignalType | null,
    status: SignalStatus,
    exchange: SignalExchange,
    isFutures?: boolean | null,
    video?: string | null,
    thumbnail?: string | null,
    stopLoss?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    trailingStopLoss?: number | null,
    targets?:  Array< {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null > | null,
    minRetainer?:  {
      __typename: "SignalTarget",
      value?: number | null,
      percentage?: number | null,
      hit?: boolean | null,
    } | null,
    description?: string | null,
    entryOnActivation?: number | null,
    entry?: number | null,
    futuresOperation?: SignalFuturesOperation | null,
    leverage?: number | null,
    interval?: string | null,
    tags?: Array< string | null > | null,
    link?: string | null,
    linkLabel?: string | null,
    totalGains?: number | null,
    totalGainsFutures?: number | null,
    lastPrice?: number | null,
    lastMonitorCheck?: string | null,
    takeProfitNum?: number | null,
    breakThroughTime?: number | null,
    breakThroughCount?: number | null,
    showOnFreeGroup?: boolean | null,
    telegramMessages?:  Array< {
      __typename: "TelegramMessageResult",
      channel?: string | null,
      id?: string | null,
      link?: string | null,
    } | null > | null,
    telegramChannels?: Array< TelegramChannel | null > | null,
    telegramEntryNotificationId?: number | null,
    telegramEntryNotificationDate?: string | null,
    tokenName?: string | null,
    tokenSymbol?: string | null,
    tokenAddress?: string | null,
    createdByName?: string | null,
    createdByUserName?: string | null,
    manualOperation?: boolean | null,
    isTesting?: boolean | null,
    useCopyTrade?: boolean | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateTelegramBotStateSubscriptionVariables = {
  filter?: ModelSubscriptionTelegramBotStateFilterInput | null,
};

export type OnCreateTelegramBotStateSubscription = {
  onCreateTelegramBotState?:  {
    __typename: "TelegramBotState",
    telegramId: string,
    nextStep?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTelegramBotStateSubscriptionVariables = {
  filter?: ModelSubscriptionTelegramBotStateFilterInput | null,
};

export type OnUpdateTelegramBotStateSubscription = {
  onUpdateTelegramBotState?:  {
    __typename: "TelegramBotState",
    telegramId: string,
    nextStep?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTelegramBotStateSubscriptionVariables = {
  filter?: ModelSubscriptionTelegramBotStateFilterInput | null,
};

export type OnDeleteTelegramBotStateSubscription = {
  onDeleteTelegramBotState?:  {
    __typename: "TelegramBotState",
    telegramId: string,
    nextStep?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSignInActivitySubscriptionVariables = {
  filter?: ModelSubscriptionUserSignInActivityFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserSignInActivitySubscription = {
  onCreateUserSignInActivity?:  {
    __typename: "UserSignInActivity",
    id: string,
    device?: string | null,
    browser?: string | null,
    ip?: string | null,
    city?: string | null,
    country?: string | null,
    timezone?: string | null,
    date?: string | null,
    username?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSignInActivitySubscriptionVariables = {
  filter?: ModelSubscriptionUserSignInActivityFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserSignInActivitySubscription = {
  onUpdateUserSignInActivity?:  {
    __typename: "UserSignInActivity",
    id: string,
    device?: string | null,
    browser?: string | null,
    ip?: string | null,
    city?: string | null,
    country?: string | null,
    timezone?: string | null,
    date?: string | null,
    username?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSignInActivitySubscriptionVariables = {
  filter?: ModelSubscriptionUserSignInActivityFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserSignInActivitySubscription = {
  onDeleteUserSignInActivity?:  {
    __typename: "UserSignInActivity",
    id: string,
    device?: string | null,
    browser?: string | null,
    ip?: string | null,
    city?: string | null,
    country?: string | null,
    timezone?: string | null,
    date?: string | null,
    username?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateUserProductSubscriptionVariables = {
  filter?: ModelSubscriptionUserProductFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserProductSubscription = {
  onCreateUserProduct?:  {
    __typename: "UserProduct",
    productId: string,
    username: string,
    expirationDate?: string | null,
    status?: UserProductStatus | null,
    product?:  {
      __typename: "MarketPlaceProduct",
      id: string,
      slug: string,
      name: string,
      externalReference?: string | null,
      icon?: string | null,
      price?: number | null,
      description?: string | null,
      productType?: MarketPlaceProductType | null,
      status?: MarketPlaceProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserProductSubscriptionVariables = {
  filter?: ModelSubscriptionUserProductFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserProductSubscription = {
  onUpdateUserProduct?:  {
    __typename: "UserProduct",
    productId: string,
    username: string,
    expirationDate?: string | null,
    status?: UserProductStatus | null,
    product?:  {
      __typename: "MarketPlaceProduct",
      id: string,
      slug: string,
      name: string,
      externalReference?: string | null,
      icon?: string | null,
      price?: number | null,
      description?: string | null,
      productType?: MarketPlaceProductType | null,
      status?: MarketPlaceProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserProductSubscriptionVariables = {
  filter?: ModelSubscriptionUserProductFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserProductSubscription = {
  onDeleteUserProduct?:  {
    __typename: "UserProduct",
    productId: string,
    username: string,
    expirationDate?: string | null,
    status?: UserProductStatus | null,
    product?:  {
      __typename: "MarketPlaceProduct",
      id: string,
      slug: string,
      name: string,
      externalReference?: string | null,
      icon?: string | null,
      price?: number | null,
      description?: string | null,
      productType?: MarketPlaceProductType | null,
      status?: MarketPlaceProductStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};
