import { Theme } from "@mui/material";

export default function componentStyleOverrides(theme: Theme) {

    return {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            width: '100%',
            height: '100%',
            WebkitOverflowScrolling: 'touch'
          },
          body: {
            width: '100%',
            height: '100%',
            background: '#1a1c26'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
            root: {
                background: "#17181f",
            },
        }
      },

      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'capitalize' as const,
          },
          containedPrimaryContrast: {
            "&:hover": {
              background: '#23243d'
            },
          },
          containedSuccess: {
            color: '#FFF',
            background: 'rgb(4 120 87 / 80%)',
            "&:hover": {
              background: 'rgb(4 120 87)',
            },
          }
        }
      },
      MuiAlert: {
        styleOverrides: {
          // standardInfo: ...,
          // standardSuccess: ... #235a2b,
          standardInfo: {
            color: '#acb8e0',
            backgroundColor: 'rgb(0 0 0 / 17%)',
            '& .MuiAlert-icon': {
              color: '#2846a7',
            },
          },
          standardWarning: {
            color: '#d2c6b5',
            backgroundColor: 'rgb(255 255 255 / 14%)',
            '& .MuiAlert-icon': {
              color: '#ff9800',
            },
          },
          standardError: {
            color: '#b89494',
            backgroundColor: 'rgb(0 0 0 / 17%)',
            '& .MuiAlert-icon': {
              color: '#ef5350',
            },
          },
        }
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '.MuiBackdrop-root': {
              backgroundColor: 'rgb(0 0 0 / 85%)',
            }
          }
        }
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            background: "#282a34",
            '.Mui-disabled': {
              background: '#282b38',
              '-webkit-text-fill-color': '#404040'
            },
            ':hover': {
              background: '#282b38',
              '&:not(.Mui-disabled):before': {
                borderBottom: '1px solid #464769'
              }
            },
            ':before': {
              borderBottom: '1px solid #3f4150'
            }
          },
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0
          }
        }
      }
    };
}
