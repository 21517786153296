import SignalIcon from '@mui/icons-material/CandlestickChart';
import UsersIcon from '@mui/icons-material/People';
import ArticleIcon from '@mui/icons-material/Newspaper';
import CoursesIcon from '@mui/icons-material/School';
import NewslettersIcon from '@mui/icons-material/Feed';
import AdsIcon from '@mui/icons-material/Campaign';
import CollectionsIcon from '@mui/icons-material/LibraryBooks';
import TelegramIcon from '@mui/icons-material/Telegram';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PatternIcon from '@mui/icons-material/Pattern';

const menuItems = [
  {
    type: 'menuTitle',
    label: 'Admin',
  },
  {
    type: 'menu',
    icon: <UsersIcon color="inherit" />,
    label: 'Users',
    children: [
      {
        type: 'menu',
        label: 'User List',
        link: '/admin/users/list'
      },
      {
        type: 'menu',
        label: 'Add User',
        link: '/admin/users/new'
      },
    ]
  },
  {
    type: 'menu',
    icon: <TelegramIcon color="inherit" />,
    label: 'Telegram',
    children: [
      {
        type: 'menu',
        label: 'Check invalid users',
        link: '/admin/telegram/*'
      },
    ]
  },
  {
    type: 'menu',
    icon: <SignalIcon color="inherit" />,
    label: 'Signals',
    children: [
      {
        type: 'menu',
        label: 'Add Signal',
        link: '/admin/signals/new'
      },
      {
        type: 'menu',
        label: 'Signal List',
        link: '/admin/signals/list'
      },
    ]
  },
  {
    type: 'menu',
    icon: <NewslettersIcon color="inherit" />,
    label: 'Newsletters',
    children: [
      {
        type: 'menu',
        label: 'Newsletter List',
        link: '/admin/newsletters/list'
      },
      {
        type: 'menu',
        label: 'Add Newsletter',
        link: '/admin/newsletters/new'
      },
    ]
  },
  {
    type: 'menu',
    icon: <ArticleIcon color="inherit" />,
    label: 'Daily News',
    children: [
      {
        type: 'menu',
        label: 'Daily News List',
        link: '/admin/dailynews/list'
      },
      {
        type: 'menu',
        label: 'Add Daily News',
        link: '/admin/dailynews/new'
      },
    ]
  },
  {
    type: 'menu',
    icon: <PatternIcon color="inherit" />,
    label: 'Trading Patterns',
    children: [
      {
        type: 'menu',
        label: 'Trading Patterns List',
        link: '/admin/trading-patterns/list'
      },
      {
        type: 'menu',
        label: 'Add Trading Patterns',
        link: '/admin/trading-patterns/new'
      },
    ]
  },
  // {
  //   type: 'menu',
  //   icon: <ShoppingCartIcon color="inherit" />,
  //   label: 'Market Place',
  //   children: [
  //     {
  //       type: 'menu',
  //       label: 'Product List',
  //       link: '/admin/marketplace/list'
  //     },
  //     {
  //       type: 'menu',
  //       label: 'Add Product',
  //       link: '/admin/marketplace/new'
  //     },
  //   ]
  // },
  {
    type: 'menu',
    icon: <CoursesIcon color="inherit" />,
    label: 'Courses',
    children: [
      {
        type: 'menu',
        label: 'Course List',
        link: '/admin/courses/list'
      },
      {
        type: 'menu',
        label: 'Add Course',
        link: '/admin/courses/new'
      },
    ]
  },
  {
    type: 'menu',
    icon: <AdsIcon color="inherit" />,
    label: 'Ads',
    children: [
      {
        type: 'menu',
        label: 'Ads List',
        link: '/admin/ads/list'
      },
      {
        type: 'menu',
        label: 'Add Ad',
        link: '/admin/ads/new'
      },
    ]
  },
  {
    type: 'menu',
    icon: <CollectionsIcon color="inherit" />,
    label: 'Collections',
    children: [
      {
        type: 'menu',
        label: 'Collections List',
        link: '/admin/collections/list'
      },
      {
        type: 'menu',
        label: 'Add Collection',
        link: '/admin/collections/new'
      },
    ]
  },
  {
    type: 'menu',
    icon: <SmartToyIcon color="inherit" />,
    label: 'Trading Bot',
    children: [
      {
        type: 'menu',
        label: 'Latest Trades',
        link: '/admin/tradingbot/trades/latest'
      },
      {
        type: 'menu',
        label: 'Trading List',
        link: '/admin/tradingbot/trades/list'
      },
      {
        type: 'menu',
        label: 'Auto Trading Users',
        link: '/admin/tradingbot/auto-trading-users/list'
      },
    ]
  },

];

export default menuItems;
