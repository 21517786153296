import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useGoogleAnalytics from '../hooks/useGoogleAnalytics';
import useAuth from '../hooks/useAuth';
import AuthLoading from '../ui/components/auth/AuthLoading';
import { endOfDayISO } from '../lib/helpers/date-time';
// import Payment from '../ui/pages/Payment';

type Props = {
  needAdminAccess?: boolean
}

export default function RequireAuth({ needAdminAccess }: Props) {
  const { authUser, isChecking, hasPermission } = useAuth();
  const GA = useGoogleAnalytics();
  const location = useLocation();

  if (!authUser && isChecking === false) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (authUser && needAdminAccess && (!(authUser?.isAdmin || authUser?.isSuperAdmin) || !hasPermission(location.pathname))) {
    return <Navigate to="/" replace />;
  }

  let now = new Date();

  console.log()
  if (
      (!authUser?.isAdmin) &&
      (location.pathname.indexOf('payment') === -1) &&
      (
        (authUser && !authUser?.dateOfRenewal) ||  // First time (right after the registration)
        (new Date(endOfDayISO(authUser?.expirationDate || new Date(), 0, 1)) < now) // give them a buffer of 1 day
      )
  ) {
    return <Navigate to="/payment" replace />
  }

  if (authUser) {
    GA.sendPageView(location.pathname);
    return <Outlet />;
  }

  if (!authUser && isChecking) {
    return <AuthLoading />;
  }

  return null;
}