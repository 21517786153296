import { useForm } from "react-hook-form";
import useApp from "../../../hooks/useApp";
import ButtonWithSpinner from "../ButtonWithSpinner";
import TextField from "../forms/TextField";
import { Typography } from "@mui/material";
import { AuthUser, CognitoUserInterface } from "../../../lib/auth/types";

type FormData = {
  newPassword: string;
}

type Props = {
  isLoading: boolean;
  user?: CognitoUserInterface,
  onSubmit: () => void;
  onSuccess: (user: AuthUser) => void;
  onError: (err: string) => void;
};

export default function ChangeTemporaryPasswordForm({ onSubmit, onSuccess, onError, isLoading, user }: Props) {
  const { authRepository } = useApp();
  const formActions = useForm<FormData>();

	const handleSubmitForm = async ({ newPassword }: FormData) => {
    try {
      onSubmit()
      const loggedUser = await authRepository.completeNewPassword(user as CognitoUserInterface, newPassword);
      onSuccess(loggedUser);
    } catch(err: any) {
      console.error('ChangeTemporaryPasswordForm: handleSubmitForm', err);
      onError(err.message);
    }
	}

  return (
    <form onSubmit={formActions.handleSubmit(handleSubmitForm)}>
      <Typography variant="body1" sx={{ textAlign: 'center', mb: 3 }}>Welcome {user?.challengeParam?.userAttributes?.name} 👋.</Typography>

      <Typography variant="body1" sx={{ mb: 4 }}>Please enter a new password. Please do not use common words, phrases, or personal information in your password to ensure the security of your account.</Typography>

      <TextField type="password" label="New Password"
        {...formActions.register("newPassword", { required: true })}
        fullWidth
        sx={{ mb: 2 }}
      />

      <ButtonWithSpinner type="submit" variant="contained" fullWidth showSpinner={isLoading}>
        Save
      </ButtonWithSpinner>

    </form>
  );
}
