import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';
import SignUpUpdates from './landing-page/SignUpUpdates';
import FacebookIcon from '../../assets/icons/icon-facebook.png';
import YoutubeIcon from '../../assets/icons/icon-youtube.png';
import TwitterIcon from '../../assets/icons/icon-twitter.png';
import TikTokIcon from '../../assets/icons/icon-tiktok.png';
import InstagramIcon from '../../assets/icons/icon-instagram.png';
import AppConfig from '../../config';
import useTrackLinkClick from '../../hooks/useTrackLinkClick';

export default function Footer() {

  const { trackLinkClick } = useTrackLinkClick();

  return (
    <>
      <FooterContainer>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" sx={{ mb: 2 }}>Cryptofy Hub</Typography>
              <Typography variant="body2">Reinventing the way information is provided, we aim to facilitate seamless trading by providing the best and most up-to-date information on the market.</Typography>
              <Box sx={{ mt: 4 }}>
                <a href="https://twitter.com/CryptofyHub" target="_blank" rel="noreferrer" onClick={trackLinkClick}><img src={TwitterIcon} alt="Twitter" width="40" /></a>&nbsp; &nbsp;
                <a href="https://www.tiktok.com/@cryptofy_hub" target="_blank" rel="noreferrer" onClick={trackLinkClick}><img src={TikTokIcon} alt="TikTok" width="40" /></a>&nbsp; &nbsp;
                <a href="https://instagram.com/Cryptofy_Hub/" target="_blank" rel="noreferrer" onClick={trackLinkClick}><img src={InstagramIcon} alt="Instagram" width="40" /></a>&nbsp; &nbsp;
                <a href="https://facebook.com/CryptofyHub" target="_blank" rel="noreferrer" onClick={trackLinkClick}><img src={FacebookIcon} alt="Facebook" width="40" /></a>&nbsp; &nbsp;
                <a href="https://youtube.com/@cryptofyhub" target="_blank" rel="noreferrer" onClick={trackLinkClick}><img src={YoutubeIcon} alt="Youtube" width="40" /></a>&nbsp; &nbsp;
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <FooterTitle>SUPPORT</FooterTitle>
              <Link href={AppConfig.supportURL} target="_blank" sx={{ display: 'flex', textDecoration: 'none', color: '#FFF', marginBottom: '14px' }} onClick={trackLinkClick}>
                <TelegramIcon sx={{ marginRight: '7px' }} />
                <Typography>
                  Telegram Support
                </Typography>
              </Link>
              <Link href="mailto:support@cryptofyhub.com" sx={{ display: 'flex', textDecoration: 'none', color: '#FFF', marginBottom: '14px' }} onClick={trackLinkClick}>
                <EmailIcon sx={{ marginRight: '7px' }} />
                <Typography>
                  support@cryptofyhub.com
                </Typography>
              </Link>
            </Grid>


            <Grid item xs={12} md={4}>
              <FooterTitle>GET EARLY ACCESS TO PROMOTIONS</FooterTitle>
              <SignUpUpdates />
              <small>By clicking "Subscribe" you agree to receive promotional emails from Cryptofy Hub. You may unsubscribe at any time.</small>
            </Grid>

          </Grid>
        </Container>
      </FooterContainer>
      <Box sx={{ textAlign: 'center', background: '#2b2c39', p: 1, fontSize: 12 }}>
        <Link href="/privacy-policy" sx={{ color: '#FFF', textDecoration: 'none', }} onClick={trackLinkClick}>Privacy Policy</Link>&nbsp;|&nbsp;
        <Link href="/terms-and-conditions" sx={{ color: '#FFF', textDecoration: 'none', }} onClick={trackLinkClick}>Terms and Conditions</Link>&nbsp;|&nbsp;
        {/* <Link href="/disclaimer" sx={{ color: '#FFF', textDecoration: 'none', }} onClick={trackLinkClick}>Disclaimer</Link>&nbsp;|&nbsp; */}
        <Typography component="span" sx={{ fontSize: 12, display: { xs: 'block', sm: 'inline-block'} }}>Cryptofy Hub &copy; 2023</Typography>
      </Box>

    </>
  );
}


const FooterTitle = styled(Typography)(() => ({
  '&:before': {
    content: '"|"',
    width: 4,
    fontWeight: 800,
    fontSize: 14,
    display: 'inline-block',
    background: '#35bb91',
    color: '#35bb91',
    marginRight: '0.5rem',
    marginBottom: '10px',
  },
  textTransform: 'uppercase'
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  backgroundImage: 'radial-gradient(at top left, #262939 0%, #22232b 100%)',
  padding: '40px',
  [theme.breakpoints.down('sm')]: {
    padding: '20px 5px'
  }
}));
