import { Outlet } from 'react-router-dom';

import FullwidthWrapper from '../../components/FullwidthWrapper';
import { Box, Link } from '@mui/material';
import Logo from '../../../assets/logo-horizontal-dark.png';
import AppConfig from '../../../config';

export default function AuthLayout() {
  return (
    <FullwidthWrapper>
      <Box sx={{ maxWidth: 700, px: 4 }}>
        <Link href={AppConfig.site.baseURL}><img src={Logo} alt="Cryptofy Hub" width="100%"/></Link>
      </Box>
      <Outlet />
    </FullwidthWrapper>
  );
}
