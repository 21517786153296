import { Auth } from "aws-amplify";
import { User, UserPermissionGroup, UserStatus } from "../amplify/API";
import { getErrorMessage, isLocalHost } from "../helpers";
import { getReadableDateTime } from "../helpers/date-time";
import { AuthRepositoryInterface, RegisterAttributes, AuthUser, CognitoUserInterface } from "./types";
import UsersRepositoryAmplify from "../users/UsersRepositoryAmplify";
import { differenceInMilliseconds } from "date-fns";
import UserSignInActivitiesRepositoryAmplify from "../users/UserSignInActivitiesRepositoryAmplify";

export default class AuthRepositoryAmplify implements AuthRepositoryInterface {

  private userRepository;
  private userSignInActivitiesRepository;

  constructor() {
    this.userRepository = new UsersRepositoryAmplify();
    this.userSignInActivitiesRepository = new UserSignInActivitiesRepositoryAmplify();
  }

  /**
   * Get the current logged user and construct the User object with the groups and settings
   */
  async getCurrent() {
    try {
      const authUser = await Auth.currentAuthenticatedUser(
       /*{ bypassCache: true }*/ // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      );
      const userGroups = authUser.signInUserSession.accessToken.payload["cognito:groups"] || [];
      const user = await this.userRepository.getByUsername(authUser.attributes.sub);

      if (!user) {
        throw new Error('Invalid User');
      }

      const { remainingTime, planProgress }:any = this.calculatePlanDays(user);

      return {
        ...user,
        username: authUser.username,
        email: authUser.attributes.email,
        userGroups,
        isAdmin: userGroups.includes('Admin') && user.permissionGroup.includes('ADMIN'),
        isSuperAdmin: userGroups.includes('Admin') && user.permissionGroup === UserPermissionGroup.SUPER_ADMIN,
        isEmailVerified: authUser.attributes.email_verified,
        userPoolId: authUser.pool.userPoolId,
        remainingTime,
        planProgress
      } as AuthUser;

    } catch(err: any) {
      console.warn('getCurrent', { err });
      this.logOut();
      throw err;
    }
  }

  /**
   * Sign In method, will check the user and password and create the user settings if needed
   */
  async signIn(email: string, password: string) {
    try {
      const authUser = await Auth.signIn(email, password);
      if (authUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        throw authUser;
      }

      const username = authUser.attributes.sub;
      const userGroups = authUser.signInUserSession.accessToken.payload["cognito:groups"] || [];

      let user = {} as User;
      try {
        // Get the user settings
        user = await this.userRepository.getByUsername(authUser.attributes.sub);

        // If the user settings doesn't existe then create it
        if (user) {

          // If the user is suspended, show them a message with the reason for it.
          if (user.status === UserStatus.SUSPENDED) {
            throw new Error(`Your account has been suspended. ${user.statusReason ? `Reason: ${user.statusReason}` : 'Your access has been restricted. Contact the administrator if you need help.'}`);
          }

          if (user.isTestSubscription && user.subscriptionId && !isLocalHost()) {
            throw new Error('This is a Test subscription, only works on your dev enviroment.');
          }

          await this.userRepository.save(username, {
            lastLogin: getReadableDateTime()
          });
        }

        this.userSignInActivitiesRepository.create(username);

       } catch (err) {
          console.log('signIn - Settings', err);
          this.logOut();
          throw new Error(getErrorMessage(err));
       }

      const { remainingTime, planProgress }:any = this.calculatePlanDays(user);

      return {
        ...user,
        userGroups,
        isAdmin: userGroups.includes('Admin'),
        isSuperAdmin: userGroups.includes('Admin') && user.permissionGroup === UserPermissionGroup.SUPER_ADMIN,
        isEmailVerified: authUser.attributes.email_verified,
        userPoolId: authUser.pool.userPoolId,
        remainingTime,
        planProgress
      };
    } catch(err: any) {
      console.log('signIn', err);
      this.logOut();

      if (err.name === 'QuotaExceededError') {
        window.localStorage.clear();
      }
      throw err;
    }
  }

  async completeNewPassword(user: CognitoUserInterface, newPassword: string) {
    const authUser = await Auth.completeNewPassword(user, newPassword);
    return this.signIn(authUser.challengeParam.userAttributes.email, newPassword);
  }

  async register(email: string, password: string, attributes: RegisterAttributes) {
    const response = await Auth.signUp({ username: email, password, attributes, clientMetadata: { group: 'Free' } });
    // the "Destination" is a obfuscated email of the user requested a confirmation code. Ex: r***@g***.com
    return response.codeDeliveryDetails.Destination;
  }

  async confirmRegistration(email: string, code: string) {
    await Auth.confirmSignUp(email, code);
    return true;
  }

  async resendConfirmationCode(email: string) {
    const response = await Auth.resendSignUp(email);
    // the "Destination" is a obfuscated email of the user requested a confirmation code. Ex: r***@g***.com
    return response.CodeDeliveryDetails.Destination;
  }

  async resetPassword(email: string) {
    const response = await Auth.forgotPassword(email);
    // the "Destination" is a obfuscated email of the user requested a confirmation code. Ex: r***@g***.com
    return response.CodeDeliveryDetails.Destination;
  }

  async setNewPassword(email: string, code: string, newPassword: string) {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    return true;
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const authUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(authUser, oldPassword, newPassword);
    return true;
  }

  async logOut() {
    try {
      await Auth.signOut({ global: true });
      return true;
    } catch(err) {
      console.log(err);
      return false;
    }
  }

  /**
   * Calculate the progress and number of days remaining in the plan
   */
  calculatePlanDays = ({ permissionGroup, dateOfRenewal, expirationDate }: User) => {

    try {

      if (permissionGroup !== UserPermissionGroup.USER) {
        return { remainingTime: 99999, planProgress: 0 }
      }

      if (!expirationDate || !dateOfRenewal) {
        throw new Error('invalid dates');
      }

      // Convert all to dates
      const now = new Date();
      const expirationDateD = new Date(expirationDate);
      const dateOfRenewalD = new Date(dateOfRenewal);

      // Get the remaining date, between now and the expiration date
      const remainingTime = Math.max(0, differenceInMilliseconds(expirationDateD, now));

      // Now calculate the progress, the full duration would be 100%, and the "tilNow" would be the % of the plan so far
      const planFullDuration = Math.max(0, differenceInMilliseconds(expirationDateD, dateOfRenewalD));
      const planDurationTilNow = Math.max(0, differenceInMilliseconds(now, dateOfRenewalD));

      // Allow max of 100% and make sure if there is no remaining days, it sets to 100%
      let planProgress = remainingTime <= 0 ? 100 : Math.min(100, (planDurationTilNow * 100) / planFullDuration);

      return { remainingTime, planProgress }

    } catch(err) {
      return { remainingTime: 0, planProgress: 0 }
    }
  }
}
