const AppConfig = {
  site: {
    name: 'Cryptofy Hub',
    description: 'Empowering Your Crypto Journey',
    baseURL: 'https://cryptofyhub.com',
  },
  supportURL: 'https://t.me/pixelraf',
  menu: {
    drawerWidth:270,
    drawerWidthSmall: 60,
  },
  billing: {
    freeTrialDays: 7,
  },
  stripe: {
    test: {
      publicKey: 'pk_test_51LpAdeCArYSy4OunqXPVY7Hkpvg5pJAc0DPKJQRWRlzkrhCC6KPNkLYb3vkHq3O1YVIOg2Cs7waB0ZNhKNxIkKmD00MSMzxCgg',
    },
    prod: {
      publicKey: 'pk_live_51LpAdeCArYSy4OunVSqECcLe1z549q54UvJ6UU4xcTdWiNREdYArmD7Aeum8eGmQaykUaSNiWbKjwEETw8EGIyBP001S1NLtum'
    }
  },
  telegram: {
    botLink: 'https://t.me/CryptofyHubBot'
  }
};

export default AppConfig;