import React, { forwardRef } from 'react';
import useHelmet from '../../../hooks/useHelmet';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  description?: string;
  canonical?: string;
  children?: React.ReactNode;
}

const Page: React.FC<Props> = forwardRef(({ children, title = '', description = '', canonical, ...other }, ref: any) => {

  const Helmet = useHelmet({ title, description, canonical });

  return (
    <main>
      <Box ref={ref} {...other}>
        <Helmet />
        {children}
      </Box>
    </main>
  )
});


export default Page;
