import { Box } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
// import ReactPlayer, { ReactPlayerProps } from 'react-player';

import { default as _ReactPlayer } from 'react-player/lazy';
import { ReactPlayerProps } from "react-player/types/lib";
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

const VideoPlayer: React.FC<ReactPlayerProps> = (props) => {

  // useEffect(() => {
  //   if (props.url) {
  //     console.log(ReactPlayer.canPlay(props.url as string))
  //   }

  // }, [props.url]);

  return (
    <VideoResponsiveContainer>
      <ReactPlayer
        style={{ display: 'block' }}
        className="react-player"
        {...props}
        config={{
          file: {
            attributes: {
	            crossOrigin: 'true'
            }
          }
        }}
        onError={e => console.log('onError', e)}
      />
    </VideoResponsiveContainer>
  )
}

export const VideoResponsiveContainer = styled(Box)(() => ({
'position': 'relative',
'paddingTop': '56.25%',
 '> .react-player': {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  }
}));

export default VideoPlayer;