import ReactGA from 'react-ga4';
import { isLocalHost } from '../lib/helpers';
import useAuth from './useAuth';

const useGoogleAnalytics = () => {

  const { authUser } = useAuth();

  /**
   * Set the current page and send the page view
   */
  const sendPageView = (pathname: string, search: string = '') => {
    if (isLocalHost()) return;

    ReactGA.event('page_view', {
      page_location: window.location.href,
      page_path: `${pathname}${search}`,
      page_title: document.title,
    });
  }

  /**
   * Send the event for Google analytics
   */
  const sendEvent = (event: string, options?: any) => {
    if (isLocalHost()) return;

    ReactGA.event(event, {
      ...(options || {}),
      user_id: authUser?.username
    });
  }

  return {
    sendPageView,
    sendEvent
  }
};

export default useGoogleAnalytics;
