import React, { useEffect, useState } from "react";
import { AuthContext } from ".";
import useApp from "../hooks/useApp";
import { User, UserPermissionGroup, UserStatus } from "../lib/amplify/API";
import { AuthUser } from "../lib/auth/types";
import { matchPath, useNavigate } from "react-router-dom";
import useToastNotification from "../hooks/useToastNotification";


export interface AuthProviderInterface {
  authUser?: AuthUser;
  setAuthUser: (user?: AuthUser) => void;
  checkAuth: (user?: AuthUser) => Promise<AuthUser>;
  isChecking?: boolean;
  saveUser: (username: string, settings: Partial<User>) => Promise<User>
  changePassword: (oldPassword: string, newPassword: string) => Promise<boolean>
  hasPermission: (checkPermission: string) => boolean
  logOut: () => Promise<void>;
}

type Props = {
  children: React.ReactNode;
}

const AuthProvider: React.FC<Props> = ({ children }) => {

  const navigate = useNavigate();
  const { authRepository, usersRepository } = useApp();
  const [ authUser, setAuthUser ] = useState<AuthUser | undefined>();
  const [ isChecking, setIsChecking ] = useState<boolean>();
  const { showErrorNotification } = useToastNotification();

  useEffect(() => {
    (async () => {
      try {
        await checkAuth();
      } catch(err) {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAuth = async () => {
    try {
      setIsChecking(true);
      const user = await authRepository?.getCurrent();

      if (!user) {
        throw new Error('Not logged in');
      }

      // validat ethe user's status. If the user is not active, log them out
      await validateUserStatus(user);

      setAuthUser(user);
      setIsChecking(false);

      return user;
    } catch(err: any) {
      console.error('AuthProvider', err);
      setIsChecking(false);
      throw new Error(err);
    }
  };

  const saveUser = async (username: string, input: Partial<User>) => {
    let newUpdatedUser = await usersRepository.save(username, input);

    const user = {
      ...(authUser as AuthUser),
      ...newUpdatedUser
    } as AuthUser;

    setAuthUser(user);

    return newUpdatedUser;
  }

  const changePassword = async (oldPassword: string, newPassword: string) => {
    return await authRepository.changePassword(oldPassword, newPassword);
  }

  const hasPermission = (checkPermission: string) => {
    if (authUser?.permissionGroup === UserPermissionGroup.SUPER_ADMIN) return true;

    let hasPermission = false;
    for (let permission of (authUser?.permissions || [])) {
      if (matchPath(permission as string, checkPermission)) {
        hasPermission = true;
      }
    }

    return hasPermission;
  }

  const validateUserStatus =  async (user: AuthUser) => {
    if (user?.status === UserStatus.INACTIVE) {
      showErrorNotification('Your subscription has ended. If you want to continue accesing our platform please renew your subscription.');
    }
    if (user?.status === UserStatus.SUSPENDED) {
      showErrorNotification(`Your subscription has been suspended.\n\n${user?.statusReason}`);
    }

    if (user?.status !== UserStatus.ACTIVE) {
      await authRepository.logOut();
      navigate('/login');
    }
  }

  const logOut = async () => {
    setIsChecking(true);
    await authRepository.logOut();
    setAuthUser(undefined);
    setIsChecking(false);
  }

  const value = { authUser, setAuthUser, changePassword, saveUser, logOut, isChecking, checkAuth, hasPermission };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
