import { API } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { subscribeToMailingList } from '../lib/amplify/graphql/mutations';
import { MailingListMember, SubscribeToMailingListInput, SubscribeToMailingListResult } from '../lib/amplify/API';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';
import MetaPixel from '../lib/helpers/metapixel';

const useSubscribeMailingList = () => {
  const subscribeToMailingListFn = async (input: SubscribeToMailingListInput) => {
    const { data } = await API.graphql(
      {
        query: subscribeToMailingList,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM
      }
    ) as GraphQLResult<{ subscribeToMailingList: SubscribeToMailingListResult; }>;

    if (!data) {
      throw new Error('Subscribing email failed');
    }

    return data.subscribeToMailingList;
  }

  const subscribeToMailingMarketing = async (memberInfo: MailingListMember) => {
    await subscribeToMailingListFn({ mailingListId: 'marketing', memberInfo });
    MetaPixel.track('CompleteRegistration');
  }

  return {
    subscribeToMailingList: subscribeToMailingListFn,
    subscribeToMailingMarketing
  }
};

export default useSubscribeMailingList;
