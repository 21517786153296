import { CreatePriceAlertResult, DeletePriceAlertResult, GetPriceAlertResult, ListPriceAlertsResult, PriceAlertsRepositoryInterface, UpdatePriceAlertResult } from "./types";
import { API, graphqlOperation } from "aws-amplify";
import { CreatePriceAlertInput, UpdatePriceAlertInput, PriceAlertStatus, PriceAlert } from "../amplify/API";
import { getPriceAlert, listPriceAlerts, } from "../amplify/graphql/queries";
import { createPriceAlert, deletePriceAlert, updatePriceAlert } from "../amplify/graphql/mutations";
import orderBy from 'lodash/orderBy';
import { ListParams } from "../factories/types";

export default class PriceAlertsRepositoryAmplify implements PriceAlertsRepositoryInterface {

  /**
   * Fetch the alert details
   */
  async getById(alertId: string) {
    const { data } = await API.graphql(
      graphqlOperation(getPriceAlert, { id: alertId })
    ) as GetPriceAlertResult;

    if (!data) {
      throw new Error(`Could not get all information of the alert ${alertId}`);
    }

    return data.getPriceAlert;
  }

  /**
   * Create a alert and its details
   */
  async create(input: CreatePriceAlertInput) {
    const { data } = await API.graphql(
      graphqlOperation(createPriceAlert, { input:
      {
          ...input,
          chain: input.chain?.toUpperCase(),
          status: PriceAlertStatus.ACTIVE,
          createdAlertAt: (new Date()).toISOString()
        }
      })
    ) as CreatePriceAlertResult;

    if (!data) {
      throw new Error('Failed creating new alert!');
    }

    return data.createPriceAlert;
  }

  /**
   * Update the alert details
   */
  async save(alertId: string, input: Partial<UpdatePriceAlertInput>) {
    const { data } = await API.graphql(
      graphqlOperation(updatePriceAlert, { input: { ...input, id: alertId } })
    ) as UpdatePriceAlertResult;

    if (!data) {
      throw new Error(`Failed saving alert ${alertId}`);
    }

    return data.updatePriceAlert;
  }

  /**
   * Delete alert
   */
  async delete(alertId: string) {
    const { data } = (await API.graphql(
      graphqlOperation(deletePriceAlert, { input: { id: alertId } })
    )) as DeletePriceAlertResult;

    if (!data) {
      throw new Error(`Failed deleting alert ${alertId}`);
    }

    return data.deletePriceAlert;
  }

  /**
   * Fetch the list of events associated to the tokens
   */
  async list(params?: ListParams, results?: PriceAlert[]): Promise<PriceAlert[]> {

    let { limit = 1000, filter = null, nextToken } = params || {};

    const { data } = (await API.graphql(
      graphqlOperation(listPriceAlerts, { limit, filter, nextToken })
    )) as ListPriceAlertsResult;

    if (!data) {
      throw new Error("There was an issue trying to fetch the list");
    }

    const items = [ ...(!results ? [] : results), ...data.listPriceAlerts.items ] as PriceAlert[];

    if (!data.listPriceAlerts.nextToken) {
      return orderBy(items, ['status', 'createdAt'], ['asc', 'desc'])
    } else {
      return this.list({ ...params, nextToken: data.listPriceAlerts.nextToken }, items) as Promise<PriceAlert[]>;
    }
  }
}
