import React, { useMemo, useReducer, Reducer, useEffect } from 'react';
import { BillingContext } from '.';
import useApp from '../hooks/useApp';
import { CalculateStripeChangePlansProrationInput, CreateStripeCheckoutSessionInput, GetStripeCheckoutSessionResult, StripeModeTypes, UpdateStripeSubscriptionInput } from '../lib/amplify/API';
import { getErrorMessage, isLocalHost } from '../lib/helpers';
import useAuth from '../hooks/useAuth';
import { Subscription } from '../lib/billing/types';
import AppConfig from '../config';
import { loadStripe } from '@stripe/stripe-js';
import orderBy from 'lodash/orderBy';
import { PlansConfig } from '../config/plans/types';

// We check if this is local host so we can use the test stripe key to redirect the checkouts.
const isTestSubscription = isLocalHost();
const stripePromise = loadStripe(AppConfig.stripe[isTestSubscription ? 'test' : 'prod'].publicKey);


export type State = {
  plans?: PlansConfig;
  subscription?: Subscription | null;
  isError: boolean;
  isProcessing: boolean;
  isEstimating: boolean;
  isLoading: boolean;
  errorMessage?: string;
};

export type Actions = {
  getSubscription(subscriptionId: string, isTestSubscription?: boolean): Promise<Subscription | null | undefined>;
  createCheckoutSession: (input: CreateStripeCheckoutSessionInput) => Promise<string>;
  getCheckoutSession(sessionId: string): Promise<GetStripeCheckoutSessionResult>;
  getPlans(): Promise<PlansConfig>;
  cancelSubscription(subscriptionId: string, cancel: boolean): Promise<Subscription | null | undefined>;
  updateSubscription(input: Omit<UpdateStripeSubscriptionInput, "username">): Promise<Subscription | null | undefined>;
  calculateStripeChangePlansProration(input: CalculateStripeChangePlansProrationInput): Promise<any>;
};

export interface BillingProviderInterface {
  state: State;
  actions: Actions;
}

type Props = {
  children: React.ReactNode;
}

const defaultState: State = {
  subscription: null,
  isLoading: false,
  isEstimating: false,
  isError: false,
  isProcessing: false,
};


const BillingProvider: React.FC<Props> = ({ children }) => {

  const { billingRepository } = useApp();
  const { authUser } = useAuth();

  // Use reducer to keep the state of data
  const [state, setState] = useReducer<Reducer<State, Partial<State>>>(
    (state, newState) => ({...state, ...newState}),
    defaultState
  );


  const setError = (err: any) => {
    setState({ errorMessage: getErrorMessage(err), isLoading: false, isProcessing: false, isError: true, isEstimating: false });
  }

  const actions: Actions = useMemo(() => {

    return {
      /**
       * Create a checkout session for payment
       */
      async getSubscription(subscriptionId: string) {
        try {
          if (!subscriptionId) return null;

          setState({ isLoading: true });
          const subscription = await billingRepository.getSubscription(subscriptionId, Boolean(authUser?.isTestSubscription));
          setState({ subscription, isLoading: false });
          return subscription;
        } catch(err: any) {
          console.error(err);
          setError(err);
          throw err;
        }
      },

      /**
       * Cancel user's subscription
       */
      async cancelSubscription(subscriptionId: string, cancel: boolean) {
        try {
          setState({ isProcessing: true });
          const result = await billingRepository.cancelSubscription(subscriptionId, cancel, authUser?.username as string);
          setState({
            isProcessing: false,
            subscription: { ...state.subscription, ...result } as Subscription
          });
          return result;
        } catch(err: any) {
          console.error(err);
          setError(err);
          throw err;
        }
      },


      /**
       * Update user's subscription, either downgrade, upgrade or change commitnment (period)
       */
      async updateSubscription(input: Omit<UpdateStripeSubscriptionInput, "username">) {
        try {
          setState({ isProcessing: true });
          const result = await billingRepository.updateSubscription({
            ...input,
            username: authUser?.username as string,
            isTestSubscription: Boolean(authUser?.isTestSubscription)
          });

          setState({
            isProcessing: false,
            subscription: { ...state.subscription, ...result } as Subscription
          });
          return result;
        } catch(err: any) {
          console.error(err);
          setError(err);
          throw err;
        }
      },

      /**
       * Calculate the proration of any changes to a subscription.
       * Ex: When downgrading, upgrading or changing the period of a subscription
       */
      async calculateStripeChangePlansProration(input: CalculateStripeChangePlansProrationInput) {
        try {
          setState({ isEstimating: true });
          const result = await billingRepository.calculateStripeChangePlansProration({ ...input, isTestSubscription: Boolean(authUser?.isTestSubscription) });
          setState({ isEstimating: false });
          return result;
        } catch(err: any) {
          console.error(err);
          setError(err);
          throw err;
        }
      },

      /**
       * Create a checkout session for payment
       */
      async createCheckoutSession(input: CreateStripeCheckoutSessionInput) {
        try {
          setState({ isProcessing: true });
          const stripe = await stripePromise;

          if (!stripe) {
            throw new Error('The payment system returned an error. Contact support.');
          }

          let { sessionId } = await billingRepository.createCheckoutSession({
            mode: StripeModeTypes.subscription,
            ...input,
            automaticTax: true,
            cancelURL: window.location.href,
            isTestSubscription,
          });

          const { error } = await stripe.redirectToCheckout({ sessionId });

          if (error) {
            throw new Error(error?.message)
          }

          setState({ isProcessing: false });

          return sessionId;
        } catch(err: any) {
          console.error(err);
          setError(err);
          throw err;
        }
      },

      /**
       * Create a checkout session for payment
       */
      async getCheckoutSession(sessionId: string) {
        try {
          return await billingRepository.getCheckoutSession({ sessionId, isTestSubscription: Boolean(authUser?.isTestSubscription) });
        } catch(err: any) {
          console.error(err);
          setError(err);
          throw err;
        }
      },

      /**
       * Get the prices from a product in stripe
       */
      async getPlans() {
        try {
          let plans = await billingRepository.getPlans();
          plans.Monthly = orderBy(plans.Monthly, ['priority'], ['asc'])
          plans.Quarterly = orderBy(plans.Quarterly, ['priority'], ['asc'])
          plans.Yearly = orderBy(plans.Yearly, ['priority'], ['asc'])
          setState({ plans });
          return plans;
        } catch(err: any) {
          console.error(err);
          setError(err);
          throw err;
        }
      },


    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, billingRepository]);


  // Do some pre-work to initialise some of the data
  useEffect(() => {
    (async () => {
      await actions.getPlans();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (authUser) {
       await actions.getSubscription(authUser.subscriptionId as string, Boolean(authUser?.isTestSubscription));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return <BillingContext.Provider value={{ state, actions }}>{children}</BillingContext.Provider>;
};

export default BillingProvider;
