import { CreateUserProductResult, DeleteUserProductResult, GetUserProductResult, ListUserProductsResult, UserProductsRepositoryInterface, UpdateUserProductResult } from './types';
import { API, graphqlOperation } from 'aws-amplify';
import { CreateUserProductInput, UserProduct } from '../amplify/API';
import { getUserProduct, listUserProducts } from '../amplify/graphql/queries';
import { createUserProduct, deleteUserProduct, updateUserProduct } from '../amplify/graphql/mutations';
import orderBy from 'lodash/orderBy';
import { ListParams } from '../factories/types';

export default class UserProductsRepositoryAmplify implements UserProductsRepositoryInterface {

  /**
   * Fetch the user's product details
   */
  async getById(productId: string, username: string) {
    const { data } = await API.graphql(
      graphqlOperation(getUserProduct, { productId, username })
    ) as GetUserProductResult;

    if (!data) {
      throw new Error(`Could not get the details of ${productId}`);
    }

    return data.getUserProduct;
  }

  /**
   * Create a user's product and its details
   */
  async create(input: CreateUserProductInput) {
    const { data } = await API.graphql(
      graphqlOperation(createUserProduct, { input })
    ) as CreateUserProductResult;

    if (!data) {
      throw new Error('Error trying to create an association for the new product');
    }

    return data.createUserProduct;
  }

  /**
   * Update the user's product details
   */
  async save(input: UserProduct) {
    const { createdAt, updatedAt, __typename, owner, product, ...otherInput } = input;

    const { data } = await API.graphql(
      graphqlOperation(updateUserProduct, { input: otherInput })
    ) as UpdateUserProductResult;

    if (!data) {
      throw new Error(`Error trying to save product ${otherInput.productId}`);
    }

    return data.updateUserProduct;
  }

  /**
   * Delete user's product
   */
  async delete(productId: string, username: string) {
    const { data } = (await API.graphql(
      graphqlOperation(deleteUserProduct, { input: { productId, username } })
    )) as DeleteUserProductResult;

    if (!data) {
      throw new Error(`Error trying to save product ${productId}`);
    }

    return data.deleteUserProduct;
  }

  /**
   * Fetch the list of events associated to the tokens
   */
  async list(params?: ListParams, results?: UserProduct[]): Promise<UserProduct[]> {

    let { limit = 1000, filter = null, nextToken } = params || {};

    const { data } = (await API.graphql(
      graphqlOperation(listUserProducts, { limit, filter, nextToken })
    )) as ListUserProductsResult;

    if (!data) {
      throw new Error('There was an issue trying to fetch the list');
    }

    const items = [ ...(!results ? [] : results), ...data.listUserProducts.items ] as UserProduct[];

    if (!data.listUserProducts.nextToken) {
      return orderBy(items, ['createdAt'], ['desc'])
    } else {
      return this.list({ ...params, nextToken: data.listUserProducts.nextToken }, items) as Promise<UserProduct[]>;
    }
  }

}
