import React from 'react';
import { createApp } from '../lib/factories/AppFactoryAmplify';
import { App } from '../lib/factories/types';
import { AuthProviderInterface } from './AuthProvider';
import { ConfirmProviderInterface } from './ConfirmProvider';
import { BillingProviderInterface } from './BillingProvider';

export const AppContext = React.createContext<App>(createApp());
export const AuthContext = React.createContext<AuthProviderInterface>({} as AuthProviderInterface);
export const BillingContext = React.createContext<BillingProviderInterface>({} as BillingProviderInterface);
export const ConfirmContext = React.createContext<ConfirmProviderInterface>({} as ConfirmProviderInterface)