import features from './plans-features';
import { PlansConfig } from './types';


const plansConfig:PlansConfig = {
  Monthly: [
    {
      id: "price_1MvUdPCArYSy4Oun0sGuAUxy",
      name: "Monthly",
      frequency: "month",
      price: 69,
      interval: 1,
      membershipName: "PREMIUM",
      features: features.PREMIUM,
      priority: 3,
    },
    {
      id: "price_1MvUcSCArYSy4OunKAGaTwIh",
      name: "Monthly",
      frequency: "month",
      price: 39,
      interval: 1,
      membershipName: "PRO",
      features: features.PRO,
      priority: 2,
    },
    {
      id: "price_1MvUa6CArYSy4OunBMg5Yb7D",
      name: "Monthly",
      frequency: "month",
      price: 6,
      interval: 1,
      membershipName: "BASIC",
      features: features.BASIC,
      priority: 1,
    },
  ],
  Quarterly: [
    {
      id: "price_1MvUdPCArYSy4Ounks0OAw95",
      name: "Quarterly",
      frequency: "quarter",
      price: 177,
      interval: 3,
      membershipName: "PREMIUM",
      features: features.PREMIUM,
      priority: 3,
    },
    {
      id: "price_1MvUcRCArYSy4OunnpFnQYS5",
      name: "Quarterly",
      frequency: "quarter",
      price: 99,
      interval: 3,
      membershipName: "PRO",
      features: features.PRO,
      priority: 2,
    },
    {
      id: "price_1MvUa6CArYSy4OunGeDzN0AY",
      name: "Quarterly",
      frequency: "quarter",
      price: 15,
      interval: 3,
      membershipName: "BASIC",
      features: features.BASIC,
      priority: 1,
    },
  ],
  Yearly: [
    {
      id: "price_1MvUdPCArYSy4Oun7EkntPY8",
      name: "Yearly",
      frequency: "year",
      price: 624,
      interval: 12,
      membershipName: "PREMIUM",
      features: features.PREMIUM,
      priority: 3,
    },
    {
      id: "price_1MvUcRCArYSy4Ounml2vS9Hk",
      name: "Yearly",
      frequency: "year",
      price: 348,
      interval: 12,
      membershipName: "PRO",
      features: features.PRO,
      priority: 2,
    },
    {
      id: "price_1MvUa6CArYSy4OunropQDwSJ",
      name: "Yearly",
      frequency: "year",
      price: 48,
      interval: 12,
      membershipName: "BASIC",
      features: features.BASIC,
      priority: 1,
    },
  ]
}

export default plansConfig;