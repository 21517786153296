import { useEffect, useState } from "react";
import { Navigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import Loading from "../../components/Loading";
import { Typography } from "@mui/material";

export default function Logout() {

  const [ isLoggingOut, setIsLoggingOut ] = useState<boolean>(true);
  const { logOut } = useAuth() ;

  useEffect(() => {
    (async () => {
      await logOut();
      setIsLoggingOut(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggingOut) {
    return (
      <Loading type="spin" color="#d7e0e8" width={100} height={100}>
        <Typography variant="h6" color="#c3c3c3">Logging out...</Typography>
      </Loading>
    )
  }

  return (isLoggingOut ? null : <Navigate to="/login" />);
}