import { useRef, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { Avatar, Box, Divider, IconButton, MenuItem, Typography } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import MenuPopover from '../../components/MenuPopover';
import { Link } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import { UserStatus } from '../../../lib/amplify/API';
import SubscriptionsIcon from '@mui/icons-material/CardMembership';
import useBilling from '../../../hooks/useBilling';

const MENU_OPTIONS = [
  {
    label: 'My Account',
    icon: <SettingsIcon sx={{ color: '#797979' }} />,
    linkTo: '/account'
  },
  {
    label: 'My Subscription',
    icon: <SubscriptionsIcon sx={{ color: '#797979' }} />,
    linkTo: '/account/subscription'
  },
  {
    label: 'Logout',
    icon: <LogoutIcon sx={{ color: '#797979' }} />,
    linkTo: '/logout'
  }
];

type Props = {
  simple?: Boolean
}

export default function AccountPopover({ simple }: Props) {
  const { authUser } = useAuth();
  const [{ subscription }] = useBilling();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

   return (
     <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          ml: 2
        }}
      >
        <Avatar src={authUser?.photo as string} alt={authUser?.name} sx={{ width: 30, height: 30, fontSize: 13 }}>{authUser?.initials}</Avatar>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {authUser?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {authUser?.email}
          </Typography>
        </Box>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <PlanProgress progress={authUser?.planProgress} title={`${authUser?.planProgress && Math.ceil(authUser?.planProgress)}%`}>
              <Box component="span" sx={{ position: 'relative', zIndex: 2 }}>
                {authUser?.status === UserStatus.ACTIVE ? (subscription?.membership || authUser?.membership) : authUser?.status}
              </Box>
            </PlanProgress>
          </Box>
          <Divider sx={{ my: 1 }} />

          {MENU_OPTIONS.filter(option => {
            return (authUser?.status !== UserStatus.ACTIVE || simple) ? option.label === 'Logout' : true;
          }).map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={Link}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              {option.icon}
              <Typography variant="body2" sx={{ color: 'text.primary', ml: 1 }} noWrap>
                {option.label}
              </Typography>
            </MenuItem>
          ))}

      </MenuPopover>
    </>
  );
}

/*********************************************************************************
 * This styles are used to show the progress of the plan
 ********************************************************************************/

type PlanProgressProps = {
  progress?: number;
};
const PlanProgress = styled(Box)<PlanProgressProps>(({ theme, progress }) => ({
  position: 'relative',
  width: '100%',
  borderRadius: '8px',
  border: '1px solid #88baec',
  borderColor: theme.palette.mode === 'dark' ? '#b7b7b7' : '#88baec',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#1d6ac7',
  padding: '2px',
  textAlign: 'center',
  ':before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    background: theme.palette.mode === 'dark' ? '#242637' : '#e3f6ff',
    width: `${progress}%`,
    height: '100%',
    borderRadius: '8px',
  }
}));