import features from './plans-features';
import { PlansConfig } from './types';

const plansConfig:PlansConfig = {
  Monthly: [
    {
      id: "price_1LpAhTCArYSy4OunWMt4G6xB",
      name: "Monthly",
      frequency: "month",
      price: 69,
      interval: 1,
      membershipName: "PREMIUM",
      features: features.PREMIUM,
      priority: 3,
    },
    {
      id: "price_1M9gSmCArYSy4OunZlK7FKSU",
      name: "Monthly",
      frequency: "month",
      price: 39,
      interval: 1,
      membershipName: "PRO",
      features: features.PRO,
      priority: 2,
    },
    {
      id: "price_1MRYl0CArYSy4Oun8s61d7ve",
      name: "Monthly",
      frequency: "month",
      price: 6,
      interval: 1,
      membershipName: "BASIC",
      features: features.BASIC,
      priority: 1,
    },
  ],
  Quarterly: [
    {
      id: "price_1MYhdaCArYSy4OundLkaGQTT",
      name: "Quarterly",
      frequency: "quarter",
      price: 177,
      interval: 3,
      membershipName: "PREMIUM",
      features: features.PREMIUM,
      priority: 3,
    },
    {
      id: "price_1MYhhzCArYSy4Oun90v7zQJB",
      name: "Quarterly",
      frequency: "quarter",
      price: 99,
      interval: 3,
      membershipName: "PRO",
      features: features.PRO,
      priority: 2,
    },
    {
      id: "price_1MRZRWCArYSy4Oun5Q29fSaW",
      name: "Quarterly",
      frequency: "quarter",
      price: 15,
      interval: 3,
      membershipName: "BASIC",
      features: features.BASIC,
      priority: 1,
    },
  ],
  Yearly: [
    {
      id: "price_1MYhf1CArYSy4OunZi5IK6z9",
      name: "Yearly",
      frequency: "year",
      price: 624,
      interval: 12,
      membershipName: "PREMIUM",
      features: features.PREMIUM,
      priority: 3,
    },
    {
      id: "price_1MYhilCArYSy4OunVizWzWCi",
      name: "Yearly",
      frequency: "year",
      price: 348,
      interval: 12,
      membershipName: "PRO",
      features: features.PRO,
      priority: 2,
    },
    {
      id: "price_1MZujHCArYSy4OunVCmjvGks",
      name: "Yearly",
      frequency: "year",
      price: 48,
      interval: 12,
      membershipName: "BASIC",
      features: features.BASIC,
      priority: 1,
    },
  ]
}

export default plansConfig;