import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// project imports
import AppLayout from '../ui/layout/AppLayout';
import SimpleLayout from '../ui/layout/SimpleLayout';
import Loadable from '../ui/components/Loadable';
import AuthLayout from '../ui/layout/AuthLayout';
import RequireAuthWrap from './RequireAuthWrap';

// Auth
import Login from '../ui/pages/auth/Login';
import Register from '../ui/pages/auth/Register';
import ResetPassword from '../ui/pages/auth/ResetPassword';
import Logout from '../ui/pages/auth/Logout';
// import RedirectRouter from './RedirectRouter';
import RequireProductPermission from './RequireProductPermission';
import RewriteSubDomainRouter from './RewriteSubDomainRouter';
import TrackingRoute from './TrackingRoute';

const FourOFour = Loadable(lazy(() => import('../ui/pages/404')));

// landing page
const LandingPage = Loadable(lazy(() => import('../ui/pages/landing-page')));
const NewslettersPage = Loadable(lazy(() => import('../ui/pages/landing-page/Newsletters')));
const FreeTelegramGroupPage = Loadable(lazy(() => import('../ui/pages/landing-page/FreeTelegramGroup')));
const Payment = Loadable(lazy(() => import('../ui/pages/app/Payment')));
const PaymentSuccess = Loadable(lazy(() => import('../ui/pages/landing-page/PaymentSuccess')));
const TermsAndConditionsPage = Loadable(lazy(() => import('../ui/pages/TermsAndConditionsPage')));
const PrivacyPolicyPage = Loadable(lazy(() => import('../ui/pages/PrivacyPolicyPage')));
const EbookPage = Loadable(lazy(() => import('../ui/pages/landing-page/Ebook')));

// App
const AppDashboard = Loadable(lazy(() => import('../ui/pages/app/Dashboard')));
const AppSignals = Loadable(lazy(() => import('../ui/pages/app/Signals')));
const AppMyAccount = Loadable(lazy(() => import('../ui/pages/app/MyAccount')));
const AppPriceAlerts = Loadable(lazy(() => import('../ui/pages/app/PriceAlerts')));
const AppCourses = Loadable(lazy(() => import('../ui/pages/app/Courses')));
const AppCourseDetails = Loadable(lazy(() => import('../ui/pages/app/CourseDetails')));
const AppNewsletters = Loadable(lazy(() => import('../ui/pages/app/Newsletters')));
const AppNewsletterDetails = Loadable(lazy(() => import('../ui/pages/app/NewsletterDetails')));
const AppTradingPatterns = Loadable(lazy(() => import('../ui/pages/app/TradingPatterns')));
const AppTradingPatternDetails = Loadable(lazy(() => import('../ui/pages/app/TradingPatternDetails')));
const AppDailyNews = Loadable(lazy(() => import('../ui/pages/app/DailyNews')));
const AppTelegramGroups = Loadable(lazy(() => import('../ui/pages/app/TelegramGroups')));
const AppManageSubscription = Loadable(lazy(() => import('../ui/pages/app/ManageSubscription')));

// admin pages
const AdminSignals = Loadable(lazy(() => import('../ui/pages/admin/AdminSignals')));
const AdminSignalsForm = Loadable(lazy(() => import('../ui/pages/admin/AdminSignalForm')));
const AdminUsers = Loadable(lazy(() => import('../ui/pages/admin/AdminUsers')));
const AdminUserForm = Loadable(lazy(() => import('../ui/pages/admin/AdminUserForm')));
const AdminUnwelcomeTelegramUsers = Loadable(lazy(() => import('../ui/pages/admin/AdminUnwelcomeTelegramUsers')));
const AdminCourses = Loadable(lazy(() => import('../ui/pages/admin/AdminCourses')));
const AdminCourseForm = Loadable(lazy(() => import('../ui/pages/admin/AdminCourseForm')));
const AdminMarketPlace = Loadable(lazy(() => import('../ui/pages/admin/AdminMarketPlace')));
const AdminMarketPlaceProductForm = Loadable(lazy(() => import('../ui/pages/admin/AdminMarketPlaceProductForm')));
const AdminNewsletters = Loadable(lazy(() => import('../ui/pages/admin/AdminNewsletters')));
const AdminNewsletterForm = Loadable(lazy(() => import('../ui/pages/admin/AdminNewsletterForm')));
const AdminDailyNews = Loadable(lazy(() => import('../ui/pages/admin/AdminDailyNews')));
const AdminDailyNewsForm = Loadable(lazy(() => import('../ui/pages/admin/AdminDailyNewsForm')));
const AdminTradingPatterns = Loadable(lazy(() => import('../ui/pages/admin/AdminTradingPatterns')));
const AdminTradingPatternsForm = Loadable(lazy(() => import('../ui/pages/admin/AdminTradingPatternsForm')));
const AdminAds = Loadable(lazy(() => import('../ui/pages/admin/AdminAds')));
const AdminAdsForm = Loadable(lazy(() => import('../ui/pages/admin/AdminAdForm')));
const AdminCollections = Loadable(lazy(() => import('../ui/pages/admin/AdminCollections')));
const AdminCollectionForm = Loadable(lazy(() => import('../ui/pages/admin/AdminCollectionForm')));
const AdminTradingBot = Loadable(lazy(() => import('../ui/pages/admin/AdminTradingBot')));
const AdminAutoTradingUsers = Loadable(lazy(() => import('../ui/pages/admin/AdminAutoTradingUsers')));
const AdminAutoTradingUsersForm = Loadable(lazy(() => import('../ui/pages/admin/AdminAutoTradingUsersForm')));

// ==============================|| ROUTING RENDER ||============================== //
export default function Routes() {
  return useRoutes([{
    element: <TrackingRoute />,
    path: '/',
    children: [
    {
        path: '/',
        children: [
          // This makes a decision on what do to if we are in the homepage
          {
            path: '/',
            element: <RewriteSubDomainRouter redirect="/dashboard" element={<LandingPage />} />
          },
          {
            path: '/newsletter',
            element: <RewriteSubDomainRouter redirect="/dashboard" element={<NewslettersPage />} />
          },
          {
            path: '/free-telegram-group',
            element: <RewriteSubDomainRouter redirect="/dashboard" element={<FreeTelegramGroupPage />} />
          },
          {
            path: '/ebook',
            element: <RewriteSubDomainRouter redirect="/dashboard" element={<EbookPage />} />
          },
          {
            path: '/payment/success/:id',
            element: <PaymentSuccess />
          },
        ]
    },
    {
        path: '/',
        element: <RequireAuthWrap />,
        children: [
          {
            path: '/',
            element: <AppLayout />,
            children: [
              { path: '/dashboard', element: <AppDashboard /> },
              { path: '/account', element: <AppMyAccount /> },
              { path: '/account/subscription', element: <AppManageSubscription /> },
              { path: '/account/:path', element: <AppMyAccount /> },
              { path: '/telegram-groups', element: <AppTelegramGroups /> },
              {
                path: '/',
                element: <RequireProductPermission membership={['BASIC', 'PRO', 'PREMIUM']}/>,
                children: [
                  { path: '/signals', element: <AppSignals /> },
                  { path: '/signals/:path', element: <AppSignals /> },
                  { path: '/signals/:path/:id', element: <AppSignals /> },
                ]
              },
              {
                path: '/',
                element: <RequireProductPermission membership={['PRO', 'PREMIUM']} />,
                children: [
                  { path: '/price-alerts', element: <AppPriceAlerts /> },
                ]
              },
              {
                path: '/',
                element: <RequireProductPermission membership={['BASIC', 'PRO', 'PREMIUM']} />,
                children: [
                  { path: '/dailynews', element: <AppDailyNews /> },
                ]
              },
              {
                path: '/',
                element: <RequireProductPermission membership={['PRO', 'PREMIUM']} />,
                children: [
                  { path: '/courses', element: <AppCourses /> },
                  { path: '/courses/:id/*', element: <AppCourseDetails /> },
                  { path: '/courses/:id/lesson/:lessonId/*', element: <AppCourseDetails /> },
                ]
              },
              {
                path: '/',
                element: <RequireProductPermission membership={['BASIC', 'PRO', 'PREMIUM']} />,
                children: [
                  { path: '/newsletters', element: <AppNewsletters /> },
                  { path: '/newsletters/:id/*', element: <AppNewsletterDetails /> },
                ]
              },
              {
                path: '/',
                element: <RequireProductPermission membership={['PRO', 'PREMIUM']} />,
                children: [
                  { path: '/trading-patterns', element: <AppTradingPatterns /> },
                  { path: '/trading-patterns/:id/*', element: <AppTradingPatternDetails /> },
                ]
              },
            ]
          }
        ]
    },
    {
        path: '/',
        element: <RequireAuthWrap />,
        children: [
          { path: '/payment', element: <Payment /> },
        ]
      },
      {
        path: '/admin',
        element: <RequireAuthWrap needAdminAccess={true} />,
        children: [
          {
            path: '/admin',
            element: <AppLayout />,
            children: [
              { path: 'users/list', element: <AdminUsers /> },
              { path: 'users/new', element: <AdminUserForm /> },
              { path: 'users/edit/:id', element: <AdminUserForm /> },
              { path: 'telegram', element: <AdminUnwelcomeTelegramUsers /> },
              { path: 'telegram/:path', element: <AdminUnwelcomeTelegramUsers /> },
              { path: 'signals/list', element: <AdminSignals /> },
              { path: 'signals/new', element: <AdminSignalsForm /> },
              { path: 'signals/edit/:id', element: <AdminSignalsForm /> },
              { path: 'courses/list', element: <AdminCourses /> },
              { path: 'courses/new', element: <AdminCourseForm /> },
              { path: 'courses/edit/:id', element: <AdminCourseForm /> },
              { path: 'courses/edit/:id/contents', element: <AdminCourseForm /> },
              { path: 'courses/edit/:id/contents/new', element: <AdminCourseForm /> },
              { path: 'courses/edit/:id/contents/:contentId', element: <AdminCourseForm /> },
              { path: 'collections/list', element: <AdminCollections /> },
              { path: 'collections/new', element: <AdminCollectionForm /> },
              { path: 'collections/edit/:id', element: <AdminCollectionForm /> },
              { path: 'collections/edit/:id/details', element: <AdminCollectionForm /> },
              { path: 'collections/edit/:id/contents', element: <AdminCollectionForm /> },
              { path: 'collections/edit/:id/contents/new', element: <AdminCollectionForm /> },
              { path: 'collections/edit/:id/contents/:contentId', element: <AdminCollectionForm /> },
              { path: 'marketplace/list', element: <AdminMarketPlace /> },
              { path: 'marketplace/new', element: <AdminMarketPlaceProductForm /> },
              { path: 'marketplace/edit/:id', element: <AdminMarketPlaceProductForm /> },
              { path: 'newsletters/list', element: <AdminNewsletters /> },
              { path: 'newsletters/new', element: <AdminNewsletterForm /> },
              { path: 'newsletters/edit/:id', element: <AdminNewsletterForm /> },
              { path: 'dailynews/list', element: <AdminDailyNews /> },
              { path: 'dailynews/new', element: <AdminDailyNewsForm /> },
              { path: 'dailynews/edit/:id', element: <AdminDailyNewsForm /> },
              { path: 'trading-patterns/list', element: <AdminTradingPatterns /> },
              { path: 'dailynews/new', element: <AdminTradingPatternsForm /> },
              { path: 'trading-patterns/edit/:id', element: <AdminTradingPatternsForm /> },
              { path: 'ads/list', element: <AdminAds /> },
              { path: 'ads/new', element: <AdminAdsForm /> },
              { path: 'ads/edit/:id', element: <AdminAdsForm /> },
              { path: 'tradingbot/trades/:path', element: <AdminTradingBot /> },
              { path: 'tradingbot/auto-trading-users/list', element: <AdminAutoTradingUsers /> },
              { path: 'tradingbot/auto-trading-users/new', element: <AdminAutoTradingUsersForm /> },
              { path: 'tradingbot/auto-trading-users/edit/:id', element: <AdminAutoTradingUsersForm /> },
            ]
          }
        ]
      },
      {
        path: '/',
        element: <SimpleLayout />,
        children: [
          { path: '/terms-and-conditions', element: <TermsAndConditionsPage /> },
          { path: '/privacy-policy', element: <PrivacyPolicyPage /> },
        ]
      },
      {
        path: '/',
        element: <AuthLayout />,
        children: [
          { path: 'login', element: <Login /> },
          { path: 'register', element: <Register /> },
          { path: 'forgot-password', element: <ResetPassword /> },
          { path: 'logout', element: <Logout /> },
        ]
      },
      { path: '*', element: <FourOFour /> }
    ]
  }
  ]);
}
