import useGoogleAnalytics from './useGoogleAnalytics';

const useTrackLinkClick = () => {
  const GA = useGoogleAnalytics();

  const trackLinkClick = (e: any) => {
    console.log(e.currentTarget.href);
    GA.sendEvent('link_click', { href: e.currentTarget.href });
  }

  return {
    trackLinkClick,
  }
};

export default useTrackLinkClick;
