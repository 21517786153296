import { CreateCollectionResult, DeleteCollectionResult, GetCollectionResult, ListCollectionResult, CollectionsRepositoryInterface, UpdateCollectionResult, CreateContentResult, DeleteContentResult, GetContentResult, ListContentResult, UpdateContentResult } from './types';
import { API, graphqlOperation } from 'aws-amplify';
import { CreateCollectionInput, Collection, CollectionContent, CreateCollectionContentInput } from '../amplify/API';
import { getCollection as getCollectionGQL, listCollections as listCollectionsGQL, getCollectionContent as getCollectionContentGQL, listCollectionContents as listCollectionContentsGQL, } from '../amplify/graphql/queries';
import { createCollection as createCollectionGQL, deleteCollection as deleteCollectionGQL, updateCollection as updateCollectionGQL, createCollectionContent as createCollectionContentGQL, deleteCollectionContent as deleteCollectionContentGQL, updateCollectionContent as updateCollectionContentGQL  } from '../amplify/graphql/mutations';
import orderBy from 'lodash/orderBy';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import { ListParams } from "../factories/types";

export default class CollectionsRepositoryAmplify implements CollectionsRepositoryInterface {

  /**
   * Fetch a collection details
   */
  async getCollectionById(collectionId: string) {
    const { data } = await API.graphql({
          query: getCollectionGQL,
          variables: { id: collectionId },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      }
    ) as GetCollectionResult;

    if (!data) {
      throw new Error(`Error trying to get collection details ${collectionId}`);
    }

    if (data?.getCollection?.contents?.items) {
      data.getCollection.contents.items = orderBy(data.getCollection.contents?.items, ['order'], ['asc'])
    }


    return data.getCollection;
  }

  /**
   * Create a collection and its details
   */
  async createCollection(input: CreateCollectionInput) {

    const { data } = await API.graphql(
      graphqlOperation(createCollectionGQL, { input })
    ) as CreateCollectionResult;

    if (!data) {
      throw new Error('Error trying to create a collection');
    }

    return data.createCollection;
  }

  /**
   * Update a collection details
   */
  async saveCollection(collectionId: string, input: Collection) {

    const { createdAt, updatedAt, __typename, owner, contents, ...otherInput } = input;

    const { data } = await API.graphql(
      graphqlOperation(updateCollectionGQL, { input: { ...otherInput, id: collectionId } })
    ) as UpdateCollectionResult;

    if (!data) {
      throw new Error(`Error trying to save collection ${collectionId}`);
    }

    return data.updateCollection;
  }

  /**
   * Delete collection
   */
  async deleteCollections(collectionId: string) {

    const { data } = (await API.graphql(
      graphqlOperation(deleteCollectionGQL, { input: { id: collectionId } })
    )) as DeleteCollectionResult;


    if (!data) {
      throw new Error(`Error trying to delete collection ${collectionId}`);
    }

    return data.deleteCollection;
  }


  /**
   * Fetch the list of collections associated to the tokens
   */
  async listCollections(params?: ListParams, results?: Collection[]): Promise<Collection[]> {

    let { limit = 1000, filter = null, nextToken } = params || {};
    const { data } = (await API.graphql({
          query: listCollectionsGQL,
          variables: { limit, filter, nextToken },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      })) as ListCollectionResult;

    if (!data) {
      throw new Error('There was an error trying to retrieve the collections list');
    }

    let items = [ ...(!results ? [] : results), ...data.listCollections.items ] as Collection[];

    if (!data.listCollections.nextToken) {
      items = items.map((item) => {
        if (item.contents && item.contents.items) {
          item.contents.items = orderBy(item.contents.items, ['order', 'createdAt'], ['asc', 'desc']);
        }
        return item;
      });
      return orderBy(items, ['order', 'status', 'updatedAt', 'createdAt'], ['asc', 'asc', 'desc', 'desc'])
    } else {
      return this.listCollections({ ...params, nextToken: data.listCollections.nextToken }, items) as Promise<Collection[]>;
    }
  }

  /**
   * CONTENTS
   ************************************************************************************/

  /**
   * Fetch a content details
   */
  async getContentById(contentId: string) {
    const { data } = await API.graphql({
          query: getCollectionContentGQL,
          variables: { id: contentId },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      }
    ) as GetContentResult;

    if (!data) {
      throw new Error(`Error trying to get content details ${contentId}`);
    }

    return data.getCollectionContent;
  }

  /**
   * Create a content and its details
   */
  async createContent(input: CreateCollectionContentInput) {

    const { data } = await API.graphql(
      graphqlOperation(createCollectionContentGQL, { input })
    ) as CreateContentResult;

    if (!data) {
      throw new Error('Error trying to create a content');
    }

    return data.createCollectionContent;
  }

  /**
   * Update a content details
   */
  async saveContent(contentId: string, input: Partial<CollectionContent>) {

    const { createdAt, updatedAt, __typename, owner, comments, collection, telegramMessages, ...otherInput } = input;

    const { data } = await API.graphql(
      graphqlOperation(updateCollectionContentGQL, { input: { ...otherInput, id: contentId } })
    ) as UpdateContentResult;

    if (!data) {
      throw new Error(`Error trying to save content ${contentId}`);
    }

    return data.updateCollectionContent;
  }

  /**
   * Delete content
   */
  async deleteContent(contentId: string) {

    const { data } = (await API.graphql(
      graphqlOperation(deleteCollectionContentGQL, { input: { id:  contentId } })
    )) as DeleteContentResult;


    if (!data) {
      throw new Error(`Error trying to delete ${ contentId}`);
    }

    return data.deleteCollectionContent;
  }


  /**
   * Fetch the list of contents associated to the tokens
   */
  async listContent(params?: ListParams, results?: CollectionContent[]): Promise<CollectionContent[]> {

    let { limit = 1000, filter = null, nextToken } = params || {};
    const { data } = (await API.graphql({
          query: listCollectionContentsGQL,
          variables: { limit, filter, nextToken },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      })) as ListContentResult;

    if (!data) {
      throw new Error("There was an error trying to retrieve the contents list");
    }

    const items = [ ...(!results ? [] : results), ...data.listCollectionContents.items ] as CollectionContent[];

    if (!data.listCollectionContents.nextToken) {
      return orderBy(items, ['order', 'createdAt'], ['asc', 'desc'])
    } else {
      return this.listContent({ ...params, nextToken: data.listCollectionContents.nextToken }, items) as Promise<CollectionContent[]>;
    }
  }
}
