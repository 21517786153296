import { API } from 'aws-amplify';
import { GetHistoricalPriceDataResult, TradingRepositoryInterface } from './types';
import { getHistoricalPriceData } from '../amplify/graphql/queries';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { GetHistoricalPriceDataInput } from '../amplify/API';

export default class TradingRepositoryAmplify implements TradingRepositoryInterface {
  /**
   * Fetch the historial price data of an pair of an specific exchange
   */
  async getHistoricalPriceData(input: GetHistoricalPriceDataInput) {
    const { data } = (await API.graphql({
          query: getHistoricalPriceData,
          variables: { input },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      })) as GetHistoricalPriceDataResult;

    if (!data) {
      throw new Error('Error when fetching trade information');
    }

    return data.getHistoricalPriceData;
  }

}